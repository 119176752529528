import { Component, Output, EventEmitter, Input } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-filter-input",
  templateUrl: "./filter-input.component.html",
  styleUrls: ["./filter-input.component.scss"]
})
export class FilterInputComponent {
  private debouncer: Subject<any> = new Subject();

  @Output() public filterSearch: EventEmitter<string>;
  @Input() disable: boolean;
  @Input() label: string;
  @Input() holderType = "";
  public valueSelect = "";
  constructor() {
    this.filterSearch = new EventEmitter<string>();
    this.debouncer.pipe(debounceTime(500)).subscribe((value) => this.filterSearch.emit(this.valueSelect));
  }

  public findInputFilter(event) {
    this.debouncer.next(this.valueSelect);
  }

  public reset() {
    this.valueSelect = "";
  }
}
