import { Component, OnInit } from "@angular/core";
import * as XLSX from "xlsx";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { EnumUtil } from "src/lib/enum";
import { LANGS } from "src/app/facades/enum/langs.enum";
import { TranslationQueriesService } from "src/app/facades/queries/translation/translation-queries.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { Subject } from "rxjs";
import { translationKeys } from "./translation-keys.const";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { TranslateService } from "src/app/facades/services/translate.service";


export enum REPORTER_SOURCES {
    BACK_OFFICE = "BACK_OFFICE",
    MOBILE_APP = "MOBILE_APP",
    WEB_APP = "WEB_APP"
}

export enum REPORTER_SOURCES_PRECISIONS {
    ANDROID = "ANDROID",
    IOS = "IOS",
    NONE = "NONE"
}

@Component({
    selector: "app-translation-page",
    templateUrl: "./translation-page.component.html",
    styleUrls: ["./translation-page.component.css"]
})
export class TranslationPageComponent implements OnInit {

    public _wb: XLSX.WorkBook;
    public infoboxContent: string;
    public resetForm: Subject<void>;
    public __BOX_INFO_TYPE = BOX_INFO_TYPE;
    public optionsHeader: IHeaderOptions = {
        title: "translation_pageTitle-txt"
    };

    constructor(
        private _translationQueriesSrv: TranslationQueriesService,
        private _translationSrv: TranslateService,
        private _snackSrv: SnackService
    ) { }

    ngOnInit() {
        this.resetForm = new Subject();
        this.infoboxContent = this._translationSrv.translate("translations_infobox");
    }

    onFileChange(file: any) {
        // const target: DataTransfer = <DataTransfer>(file);
        // if (target.files.length !== 1) {
        //     throw new Error("Cannot use multiple files");
        // }
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const bstr: string = e.target.result;
            this._wb = XLSX.read(bstr, { type: "binary" });
            this.upload();
        };
        reader.readAsBinaryString(file);
    }

    upload() {
        const jsonWb = this.wbToJson(this._wb);
        this._translationQueriesSrv.updateTranslations(jsonWb)
            .toPromise()
            .then(res => this._snackSrv.open(
                this._translationSrv.translate("general_snackbarTitleSuccess-txt"),
                this._translationSrv.translate("translation_uploadFileSuccess-txt", ),
                STATUS_ALERTS.SUCCESS,
                5000
            ))
            .then(() => delete this._wb)
            .then(() => this.resetForm.next())
            .catch(e => { throw new Error(e); });
    }

    download() {
        this._translationQueriesSrv.getAllTranslations().toPromise()
            .then(translations => this.jsonToWb(translations.data["translations"]))
            .then(wb => XLSX.writeFile(wb, "fmx_translations.xlsx"))
            .catch(e => { throw new Error(e); });
    }

    private jsonToWb(files: { [filename: string]: { [key: string]: string } }): XLSX.WorkBook {
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const sources: string[] = Array.from(new Set(Object.keys(files).map(fn => fn.split(".")[0])));
        const sheets: { [name: string]: XLSX.WorkSheet } = {};
        for (const src of sources) {
            const defaultFileName: string = `${src}.${LANGS.FR}.json`;
            const lines: string[][] = [["KEY", ...EnumUtil.getStringValues(LANGS)]];

            for (const key of Object.keys(files[defaultFileName])) {
                const line: string[] = [key];
                for (const lang of EnumUtil.getStringValues(LANGS)) {
                    const fileName: string = `${src}.${lang}.json`;
                    if (files[fileName]) {
                        line.push(files[fileName][key] ? files[fileName][key] : "");
                    }
                }
                lines.push(line);
            }

            sheets[src] = XLSX.utils.aoa_to_sheet(lines);
            XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(lines), src);
        }
        return wb;
    }

    private wbToJson(wb: XLSX.WorkBook) {
        const result: { [fileName: string]: { [key: string]: string } } = {};
        for (const sheetName in wb.Sheets) {
            if (wb.Sheets.hasOwnProperty(sheetName)) {
                const jsonSheets = this.sheetToJson(wb.Sheets[sheetName], sheetName);
                if (jsonSheets) {
                    for (const trad of jsonSheets) {
                        result[`${sheetName}.${trad.lang}.json`] = trad.translations;
                    }
                }
            }
        }
        return result;
    }

    private sheetToJson(sheet: XLSX.WorkSheet, sheetName: string): { lang: string, translations: { [key: string]: string } }[] {
        const keys: Set<string> = new Set(translationKeys[sheetName]); // used for check if all required keys are set in uploaded xls file
        const result: { lang: string, translations: { [key: string]: string } }[] = [];
        const jsonSheet: { [key: string]: string }[] = XLSX.utils.sheet_to_json(sheet);
        for (const lang of EnumUtil.getStringValues(LANGS)) {
            const res: { [key: string]: string } = {};
            for (const trad of jsonSheet) {
                if (trad.KEY && trad[lang]) {
                    res[trad.KEY] = trad[lang];
                    if (lang === LANGS.FR && keys.has(trad.KEY)) {
                        keys.delete(trad.KEY);
                    }
                }
            }
            result.push({ lang, translations: res });
        }

        if (keys.size === 0) {
            return result;
        } else {
            this._snackSrv.open(
                "ERROR",
                `Missing keys for ${sheetName}:\n ${Array.from(keys).join(", ")}`,
                STATUS_ALERTS.DANGER,
                0
            );
            return null;
        }
    }

}
