import {
  Component, Input, Renderer2, EventEmitter, Output, SimpleChanges, OnChanges,
  AfterViewInit, ViewChild, ElementRef
} from "@angular/core";

import { AInputs } from "../../facades/inputs";
import { ISelectOptionWithDatas } from "../../facades/interfaces/selectOption.interface";
import { IClickOutsideComponent } from "src/app/facades/directives/click-outside.directive";
import { ErrorMessageService } from "../../facades/services/error-message.service";

@Component({
  selector: "app-text-search-input",
  templateUrl: "./text-search-input.component.html",
  styleUrls: ["./text-search-input.component.css"]
})
export class TextSearchInputComponent extends AInputs implements OnChanges, IClickOutsideComponent, AfterViewInit {
  public isClosed = true;
  private isClear = false;
  private _handler: any;
  public haveError = false;
  public hiddenIcon = true;

  @Input() selectName: string;
  @Input() options: ISelectOptionWithDatas[];
  @Input() autoSelect = false;
  @Input() openOnChangeOption = false;

  @Output() public returnSelectedOption: EventEmitter<ISelectOptionWithDatas> = new EventEmitter<ISelectOptionWithDatas>();
  @Output() public onSubmitData: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("inputFocus", { static: true }) inputFocus: ElementRef;

  constructor(
    private _elRef: ElementRef,
    protected renderer: Renderer2,
    protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (this.isClear) {
      this.isClear = false;
      this.inputFocus.nativeElement.focus();
    }
    if (this.openOnChangeOption && changes.options) {
      this.open();
    }
    this.group.get(this.name).valueChanges.subscribe(result => {
        this.haveError = this.group.get(this.name).errors ? true : false;
        this.hiddenIcon = result ? false : true;
    });
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      setTimeout(() => {
        this.open();
        this.inputFocus.nativeElement.focus();
      }, 400);
    }
  }

  public showInfo() {
    if (!this.isClosed) { this.close(null); }
  }

  private checkIsElement(event: any) {
    if (!this._elRef.nativeElement.contains(event.target) && !this.isClosed) {
      this.close(null);
    }
  }

  public open() {
    if ( this.isClosed ) {
      if ( this._handler ) { this._handler(); }
      this._handler = this.renderer.listen(document, "click", event => this.checkIsElement(event));
    }
    this.inputReference.nativeElement.focus();
    this.isClosed = false;
  }

  public close(option: ISelectOptionWithDatas): void {
    if (option) {
      this.group.get(this.name).patchValue(option.value);
      this.group.get(this.name).markAsTouched();
      this.returnSelectedOption.emit(option.datas);
    }
    this._handler();
    this.isClosed = true;
  }

  clearSelect(event: Event) {
    this.isClear = true;
    if (event) { event.stopPropagation(); }
    this.group.get(this.name).patchValue(null);
    this.open();
  }

  public onKeydown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.onSubmitData.emit(this.group.get(this.name).value);
    }
  }
}
