import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "src/app/facades/services/translate.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { AuthService } from "src/lib/auth/auth.service";
import { CopywritingQueriesService } from "src/app/facades/queries/copywriting/copywriting-queries.service";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { Router, ActivatedRoute } from "@angular/router";
import { IHeaderOptions } from 'src/app/facades/interfaces/header.interface';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subject } from 'rxjs';
import { LANGS } from 'src/app/facades/enum/langs.enum';
import { STATUS_ALERTS } from 'src/app/facades/enum/status.enum';
import { EnumUtil } from 'src/lib/enum';

interface IEditableContent {
  id: number;
  key: string;
  value: string;
  translation?: {
    fr?: { value: string };
    nl?: { value: string };
    en?: { value: string };
    de?: { value: string };
  }
}

@Component({
  selector: "app-copywriting-page",
  templateUrl: "./copywriting.component.html",
  styleUrls: ["./copywriting.component.css"]
})
export class CopywritingPage implements OnInit {

  protected _originValues: IEditableContent[] = [];
  protected _currentKey: string;
  protected _currentValue: string = "";
  protected _currentLang: LANGS;

  public optionsHeader: IHeaderOptions;
  public KeySelectOptions = [];
  public copywritingFormGroup: FormGroup;
  public LangSelectOptions = EnumUtil.getValues(LANGS)
    .map(l => ({ label: l, value: l }));
  public Data: Subject<string> = new Subject();
  public IsLocked: boolean = true;
  public Editor;

  constructor(
    private _router: Router,
    private _translateSrv: TranslateService,
    private _authSrv: AuthService,
    private _fb: FormBuilder,
    private _snackBarSrv: SnackService,
    private _activatedRoute: ActivatedRoute,
    private _copywritingQueriesSrv: CopywritingQueriesService,
    private _modalSrv: ModalService) {
    this.optionsHeader = {
      title: "copywriting_pageTitle-txt",
      buttons: []
    };
  }

  ngOnInit() {
    // Init form group for key's select and lang's select 
    this.copywritingFormGroup = this._fb.group({
      editableContentKey: null,
      editableContentLang: LANGS.FR,
    });
    this.copywritingFormGroup.get("editableContentKey").valueChanges
      .subscribe(key => this.switchTo(key));
    this.copywritingFormGroup.get("editableContentLang").valueChanges
      .subscribe(lang => this.switchTo(this.CurrentKey, lang));

    // Load data and Set instance data
    this._copywritingQueriesSrv.getEditableContents()
      .subscribe(
        next => {
          if (next.data && next.data["editableContents"]) {
            this._originValues = next.data["editableContents"];
            this._originValues.forEach(ov => {
              this.KeySelectOptions.push({
                value: ov.key,
                label: ov.key
              });
            });
            this.reset();
          }
        },
        err => console.error(err));

    // Init ClassicEditor
    ClassicEditor
      .create(document.querySelector("#copywriting-editor"))
      .then(editor => {
        this.Editor = editor;
        this.Editor.model.document.on('change:data', () => {
          this.CurrentValue = this.Editor.getData();
        });
        this.IsLocked = false;
      })
      .catch(e => { console.error(e); });
  }

  /*
   *  GETTER
   */
  public get CurrentKey() {
    return this._currentKey;
  }

  public get CurrentValue() {
    return this._currentValue;
  }

  public get CurrentLang() {
    return this._currentLang;
  }

  /*
   *  SETTER
   */
  public set CurrentKey(key: string) {
    this._currentKey = key;
  }

  public set CurrentValue(value: string) {
    this._currentValue = value;
  }

  public set CurrentLang(lang: LANGS) {
    this._currentLang = lang;
  }

  /*
   * NOT USED cause there arn't header's buttons
   */
  public headerAction(datas: { link: string }) {
    this._router.navigateByUrl(datas.link);
  }

  /**
   * @description Save editor content in server
   * @author Gaetan Geenens
   * @memberof copywritingPage
   */
  public async save() {
    this.IsLocked = true;
    this._copywritingQueriesSrv.saveEditableContent(this.CurrentKey, this.CurrentValue, this.CurrentLang)
      .subscribe(
        next => {
          if (next && next.data["editableContent"]) {
            const idx = this._originValues.findIndex(v => v.key === this.CurrentKey);
            if (idx !== -1) {
              this._originValues[idx] = next.data["editableContent"];
              this._snackBarSrv.open(
                this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                this._translateSrv.translate("copywriting_saveEditableContentSuccess-txt"),
                STATUS_ALERTS.SUCCESS,
                5000
              );
              this.IsLocked = false;
            }
          }
        },
        err => {
          console.error("SAVE COPIRIGHT ERROR", err);
          this._snackBarSrv.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("copywriting_saveEditableContentError-txt"),
            STATUS_ALERTS.DANGER,
            0
          );
          this.IsLocked = false;
        }
      )
  }

  /**
   * @description Replace editor content with the origin value of current key
   * @author Gaetan Geenens
   * @memberof copywritingPage
   */
  public async cancel() {
    const idx = this._originValues.findIndex(v => v.key === this._currentKey);
    if (this.Editor && idx !== -1) {
      this._currentValue = this._originValues[idx].value;
      this.Editor.setData(this._currentValue);
    }
  }

  /**
   * @description Change editor content and instance properties by provided key
   * @author Gaetan Geenens
   * @param {string} key
   * @memberof copywritingPage
   */
  public async switchTo(key: string, lang: LANGS = LANGS.FR) {
    const idx = this._originValues.findIndex(v => v.key === key);
    if (this.CurrentLang !== lang) {
      this.CurrentLang = lang;
      this.copywritingFormGroup.get("editableContentLang").patchValue(this._currentLang);
    }
    if (this.CurrentKey !== key) {
      this.CurrentKey = key;
      this.copywritingFormGroup.get("editableContentKey").patchValue(this._currentKey);
    }
    if (this.Editor && idx !== -1) {
      this.CurrentValue = this._originValues[idx].translation[this.CurrentLang.toLowerCase()]
        ? this._originValues[idx].translation[this.CurrentLang.toLowerCase()].value
        : this._originValues[idx].value;
      this.Editor.setData(this.CurrentValue);
    }
  }

  /** Reset select and editor to default values
   * @description
   * @author Gaetan Geenens
   * @memberof copywritingPage
   */
  public async reset() {
    this._currentValue = this._originValues[0].value;
    this._currentKey = this._originValues[0].key;
    this.copywritingFormGroup.get("editableContentKey").patchValue(this._originValues[0].key);
    this.Editor.setData(this._currentValue);
  }

}
