import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BreadcrumbComponent } from "./organisms/breadcrumb/breadcrumb.component";
import { HeaderComponent } from "./organisms/header/header.component";
import { PipeModule } from "src/app/facades/pipes/pipe.module";
import { ButtonModule } from "../button/button.module";
import { InputModule } from "../input/input.module";



@NgModule({
  declarations: [
    BreadcrumbComponent,
    HeaderComponent,
    ],
  imports: [
    CommonModule,
    PipeModule,
    ButtonModule,
    InputModule
  ],
  exports: [
    BreadcrumbComponent,
    HeaderComponent
  ]
})
export class HeaderModule { }
