import gql from "graphql-tag";

const paginationCitizens: string = `
    pagination {
        total
        page
        hasNext
        limit
        totalPage
        hasPrevious
    }
`;

export const GET_LIST_CITIZENS = gql`
query citizens($filter: FilterCitizens, $pagination: Pagination){
    citizens(filter: $filter, pagination: $pagination){
        id
        lastName
        firstName
        email
        phone
        type
        lang
        createdAt
        isAmbassador
    }
    ${paginationCitizens}
}`;

export const GET_CITIZENS_XLS = gql`
query citizensXls($filter: FilterCitizens, $tableHeaders: [TableHeader!]!, $pagination: Pagination) {
  citizensXls(filter: $filter, tableHeaders: $tableHeaders, pagination: $pagination) {
    filename,
    content
  }
}`;

export const GET_CITIZEN = gql`
query getCitizen($id: Int!){
    citizen(id: $id){
        id
        lastName
        firstName
        roles

    }
}`;

export const DELETE_CITIZEN = gql`
mutation deleteCitizen($id: Int!, $kind: KIND){
    deleteCitizen(id:$id, kind: $kind)
}`;
