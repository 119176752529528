import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "src/app/facades/model/modal-content-form";
import { ModalService, DATA } from "src/app/facades/services/modal/modal.service";
import { FormBuilder, Validators } from "@angular/forms";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { TranslateService } from "src/app/facades/services/translate.service";

@Component({
  selector: "app-modal-create-theme",
  templateUrl: "./modal-create-theme.component.html"
})
export class ModalCreateThemeComponent extends ModalContentForm implements OnInit, OnDestroy {

  constructor(
    protected _modalSrv: ModalService,
    protected _fb: FormBuilder,
    protected _snackBar: SnackService,
    protected _errorMessageSrv: ErrorMessageService,
    private _translateSrv: TranslateService
  ) {
    super(_modalSrv, _fb, _snackBar, _errorMessageSrv);
    this.inputErrorsLabelMap = new Map<string, string>([
      ["nameFr", this._translateSrv.translate("category_nameFr-input")],
      ["nameEn", this._translateSrv.translate("category_nameEn-input")],
      ["nameNl", this._translateSrv.translate("category_nameNl-input")],
      ["nameDe", this._translateSrv.translate("category_nameDe-input")]
    ]);
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public initForm() {
    this._formGroup = this._fb.group({
      nameFr: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      nameNl: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      nameDe: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      nameEn: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
    });
  }

  public confirmModal(): void {
    if (this._formGroup.valid) {
      this.save();
      this.modalSrv.closeModal();
    } else {
      this.markFormGroupTouched(this._formGroup);
      this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
    }
  }

  protected save(): void {
    const objData = {
      confirm: true,
      data: this._formGroup.value
    };
    this.afterClosed.next(objData);
  }

}
