import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import * as moment from "moment";
import { TranslateService } from "../../../../facades/services/translate.service";


@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.css"]
})
export class DatePickerComponent implements OnInit {
  public navigationDate: any;
  public weekDaysHeaderArr: Array<string> = [];
  public calendarDays: Array<any> = [];
  private _dateFormat: string = "DD/MM/YYYY";
  private _todayDate: any;
  private _selectedDay: any;

  @Output() OnDateSelected = new EventEmitter<string>();
  constructor(private _translateSrv: TranslateService,) {
    moment.locale(this._translateSrv.activeLanguage.toLowerCase());
   }

  ngOnInit() {
    this.setDatas();
    this._todayDate = moment();
    this.calendarWeekName();
    this.buildCalendarDays();
  }

  private setDatas() {
    this.navigationDate = moment();
  }
  public changeNavMonth(num: number) {
    this.navigationDate.add(num, "month");
    this.buildCalendarDays();
  }
  public calendarWeekName() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format("ddd")));
  }
  public buildCalendarDays() {
    this.calendarDays = [];
    const startCalendarDate = moment({
      y : this.navigationDate.year(),
      M : this.navigationDate.month(),
      d : 1
    }).startOf("week"),
    endCalendarDate = moment({
      y : this.navigationDate.year(),
      M : this.navigationDate.month(),
      d : this.navigationDate.daysInMonth()
    }).endOf("week").add(1, "days");

    while (startCalendarDate.valueOf() < endCalendarDate.valueOf()) {
      const obj: any = {
        "value" : startCalendarDate.format("D"),
        "pastDay" : startCalendarDate.endOf("day").valueOf() < this._todayDate.valueOf() && startCalendarDate.format("M") === this.navigationDate.format("M"),
        "disabled" : startCalendarDate.format("M") != this.navigationDate.format("M"),
        "today" : startCalendarDate.format(this._dateFormat) === this._todayDate.format(this._dateFormat),
        "selected" : startCalendarDate.format(this._dateFormat) === this._selectedDay,
        "dateValue" : startCalendarDate.unix()
      };
      this.calendarDays.push(obj);
      startCalendarDate.add(1, "days");
    }
  }

  public selectDay(daySelected) {
      this.calendarDays.forEach(day => {
        day.selected = day.dateValue === daySelected.dateValue;
      });
      this.OnDateSelected.emit(daySelected.dateValue);
    }
}
