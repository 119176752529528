import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExpandComponent } from "./organisms/expand/expand.component";
import { ExpandContentComponent } from "./organisms/expand-content/expand-content.component";
import { InputModule } from "../input/input.module";
import { ButtonModule } from "../button/button.module";
import { ExpandCategoryComponent } from "./organisms/expand-category/expand-category.component";
import { LabelModule } from "../label/LabelModule";
import { PipeModule } from "src/app/facades/pipes/pipe.module";
import { ExpandCategoryDetailComponent } from "./organisms/expand-category-detail/expand-category-detail.component";

@NgModule({
  declarations: [
    ExpandComponent,
    ExpandContentComponent,
    ExpandCategoryComponent,
    ExpandCategoryDetailComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    ButtonModule,
    LabelModule,
    PipeModule
  ],
  exports: [
    ExpandComponent,
    ExpandContentComponent,
    ExpandCategoryComponent
  ]
})
export class ExpandModule { }
