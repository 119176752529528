import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "src/app/facades/model/modal-content-form";
import { ModalService, DATA } from "src/app/facades/services/modal/modal.service";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { IIncidentDuplicate, IIncidentPhotoAndComment, IIncidentDetail, IIncidentInput, IIncident } from "src/app/facades/interfaces/incident.interface";
import { ISelectOptionWithDatas, ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { GeoservicesWallonieService } from "src/app/facades/services/geoservices-wallonie/geoservices-wallonie.service";
import { IReplaceMarkerData, IMarkerData } from "src/app/presentationnal/maps/facades/interfaces/esri-map.interface";
import esri = __esri; // Esri TypeScript Types
import { loadModules } from "esri-loader";
import { ESRI_MAP_CONFIG } from "src/app/presentationnal/maps/facades/configs/esri-map.config";
import { CategoryQueriesService } from "src/app/facades/queries/categorie/category-queries.service";
import { ICategory } from "src/app/facades/interfaces/category.interface";
import { ISelectMultipleOptions } from "src/app/presentationnal/input/facades/interfaces/selectMultiple.interface";
import { recursiveBreadcrumbs, formatBreacrumb } from "src/app/presentationnal/input/atoms/select-multiple-input/facades/recursive-breadcrumb.helper";
import { TranslateService } from "src/app/facades/services/translate.service";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { IModalData } from "src/app/facades/interfaces/modal-data.interface";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { FILES_BLOCK_ACTIONS_ENUM } from "src/app/presentationnal/input/facades/enums/files-block-actions.enum";
import * as moment from "moment";
import { IComment } from "src/app/facades/interfaces/comment.interface";
import { IAttachment } from "src/app/facades/interfaces/attachment.interface";
import { IContainerComment } from "src/app/presentationnal/comment/facades/interfaces/container-comment.interface";
import { IncidentQueriesService } from "src/app/facades/queries/incident/incident-queries.service";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { USER_ID } from "src/lib/auth/auth.service";
import { UserQueriesService } from "src/app/facades/queries/user/user-queries.service";
import { ApplicationConfig } from "src/app/app.config";
enum EnumIncidentSteps {
	DETAIL,
	DUPLICATE,
	PHOTO_AND_COMMENT,
	RESUME
}

@Component({
	selector: "app-modal-create-incident",
	templateUrl: "./modal-create-incident.component.html",
	styleUrls: ["./modal-create-incident.component.css"]
})
export class ModalCreateIncidentComponent extends ModalContentForm implements OnInit {
	public stepValues: any = EnumIncidentSteps;
	public stepsList: { value: EnumIncidentSteps, label: string, formRelated: string, isDisabled: boolean }[] = [
		{ value: EnumIncidentSteps.DETAIL, label: "incident_detail-stepper", formRelated: "formGroupDetail", isDisabled: true },
		{ value: EnumIncidentSteps.DUPLICATE, label: "incident_duplicate-stepper", formRelated: "formGroupDuplicate", isDisabled: true },
		{ value: EnumIncidentSteps.PHOTO_AND_COMMENT, label: "incident_commentary-stepper", formRelated: "formGroupPhotoAndComment", isDisabled: true },
		{ value: EnumIncidentSteps.RESUME, label: "incident_summary-stepper", formRelated: "formGroupResume", isDisabled: true },
	];
	public selectedStep: EnumIncidentSteps = EnumIncidentSteps.DETAIL;
	public formGroupDetail: FormGroup;
	public formGroupDuplicate: FormGroup;
	public formGroupPhotoAndComment: FormGroup;
	public formGroupResume: FormGroup;

	public categoryOptions: ISelectMultipleOptions[] = [];
	public duplicateIncident: IIncidentDuplicate;
	public incidentIsUnassignable: string;
	public currentUserAvatar: string = "";


	// Files input
	public attachments: File[] = [];
	public isFileError: boolean = false;

	// MAP DATAS
	public markerData: IMarkerData = null;
	private _tempPositionFound: any;
	public addressesOptions: ISelectOptionWithDatas[] = [];
	public __BOX_INFO_TYPE = BOX_INFO_TYPE;


	public duplicateIncidentOptions: ISelectOption[] = [];
	private _possibleDuplicates: IIncidentDuplicate[] = [];
	private _needMerge: boolean = false;
	public isOldAPIUsed: boolean = false;

	public resumeDatas: any = null;
	public categoryDetails: ICategory[] = null;
	// private _orthoLastConfig: any = require("../../facades/configs/esri-map-ortho-last.config.json");
	constructor(
		protected _modalSrv: ModalService,
		protected _fb: FormBuilder,
		protected _snackBar: SnackService,
		private _translateSrv: TranslateService,
		@Inject(DATA) private _data,
		private _categoryQueriesSrv: CategoryQueriesService,
		private _geoserviceWallonieSrv: GeoservicesWallonieService,
		protected _errorMessageSrv: ErrorMessageService,
		private _incidentQueriesSrv: IncidentQueriesService,
		private _userQueriesSrv: UserQueriesService
	) {
		super(_modalSrv, _fb, _snackBar, _errorMessageSrv);
		this.inputErrorsLabelMap = new Map<string, string>([
			["categoryId", this._translateSrv.translate("incident_category-input")],
			["address", this._translateSrv.translate("incident_address-input")],
		]);
		this.incidentIsUnassignable = null;
	}

	ngOnInit() {
		this.initForm();
		this.getCategoriesOptions();
		this.getCurrentUserAvatar();
	}

	private getCurrentUserAvatar(){
		const userId = localStorage.getItem(USER_ID);
		this._userQueriesSrv.getUser(+userId).subscribe((res: any) => {
			let user = null;
			if(res && res.data) user = res.data.user; 
			if(user) {
				const avatarName = user.point && user.point.item_used? user.point.item_used.name : 'base';
				const higherRole = user.higherRole? user.higherRole : 'citoyen';
				this.currentUserAvatar = ApplicationConfig.Url+`/img/avatars/${avatarName}/${avatarName}_${higherRole}_created.png`;
			}
		}, err => {
			console.log("err", err);
		})
	}

	private getCategoriesOptions() {
		this._categoryQueriesSrv.getMappedCategories().subscribe(result => {
			const resultData: { mappedCategories: any[] } = <any>result.data;
			if (resultData && resultData.mappedCategories) {
				this.categoryOptions = resultData.mappedCategories;
			}
		}, error => {
			console.error(error);
		});
	}

	/********** START MAP METHODS **********/
	public mapLoadedEvent(status: boolean) {
		console.info("The map loaded: " + status);
	}

	// Use when we click on the map
	public updateAddressField(dataAddress: 
		{ address: { ShortLabel: string, AddNum: string, District: string, City: string, Match_addr: string, Postal: string, Subregion: string, Address: string }, x: number, y: number, oldUrl: any, rue: any, adresse: string }) {
		this.isOldAPIUsed = dataAddress.oldUrl? true : false;
		if(!dataAddress.oldUrl){
			this.formGroupDetail.get("lambertX").patchValue(dataAddress.x);
			this.formGroupDetail.get("lambertY").patchValue(dataAddress.y);
			this.formGroupDetail.get("zipCode").patchValue(dataAddress.address.Postal);
			this.formGroupDetail.get("city").patchValue(dataAddress.address.District);
			this.formGroupDetail.get("municipality").patchValue(dataAddress.address.City);
			this.formGroupDetail.get("address").patchValue(dataAddress.address.Match_addr);
			// this.formGroupDetail.get("addressNumber").patchValue(dataAddress.address.AddNum);
			// this.formGroupDetail.get("street").patchValue(dataAddress.address.ShortLabel);
		} else {
			this.formGroupDetail.get("lambertX").patchValue(dataAddress.x);
			this.formGroupDetail.get("lambertY").patchValue(dataAddress.y);
			this.formGroupDetail.get("zipCode").patchValue(dataAddress.rue.cps[0]);
			this.formGroupDetail.get("city").patchValue(dataAddress.rue.localites[0]);
			this.formGroupDetail.get("municipality").patchValue(dataAddress.rue.commune);
			this.formGroupDetail.get("address").patchValue(dataAddress.adresse);

		}
	}

	// use when we click on button "locate"
	public locateAddress() {
		if (this.formGroupDetail.get("address").value) {
			const addressValue: string = this.formGroupDetail.get("address").value.toLowerCase().replace(/\//g, "");
			this.getPosition(addressValue);
		} else {
			this.formGroupDetail.get("address").updateValueAndValidity();
		}
	}

	// get a position if the address contain enough data
	private getPosition(addressValue: string) {
		this._tempPositionFound = null;
		const searchExtent = {
			"xmin": 42000.1053417176,
			"ymin": 19999.916642703116,
			"xmax": 296000.1053417176,
			"ymax": 167999.91664270312,
			"spatialReference": {
				"wkid": 31370
			}
		}

		this._geoserviceWallonieSrv.searchPosition(addressValue, searchExtent).subscribe(resultPos => {
			const resultDataPos: any = resultPos;
			if (resultDataPos) {
				this._tempPositionFound = resultDataPos;
			} else if (resultDataPos.errorCode !== 4) {
				console.error("ERR CODE POSITION >>> ", resultDataPos);
			}
			this.getAddresses(resultDataPos);
		}, error => {
			console.error(error);
		});
	}

	// get list of roads
	private async getAddresses(addressValue: any) {
		this.addressesOptions = [];
		const searchExtent = {
			"xmin": 42000.1053417176,
			"ymin": 19999.916642703116,
			"xmax": 296000.1053417176,
			"ymax": 167999.91664270312,
			"spatialReference": {
				"wkid": 31370
			}
		}

		 addressValue.suggestions.map((result: any) => {
			this._geoserviceWallonieSrv.findAddress(result.text, result.magicKey, searchExtent).subscribe((adresse: any) => {
				if (adresse.candidates && adresse.candidates[0]) {
					const newAdress = adresse.candidates[0];
					if(newAdress.attributes.City){
						this.addressesOptions.push({
							datas: {
								isPosition: true,
								x: newAdress.location.x,
								y: newAdress.location.y,
								zipCode: newAdress.attributes.Postal,
								city: newAdress.attributes.District,
								municipality: newAdress.attributes.City,
								addressNumber: newAdress.attributes.AddNum,
								street: newAdress.attributes.ShortLabel
							},
							value: newAdress.address,
							label: newAdress.address.toUpperCase()
							// label: `${result.attributes.ShortLabel? result.attributes.ShortLabel + ", " : ""} ${result.attributes.Postal? result.attributes.Postal : ""} ${result.attributes.City? result.attributes.City : ""} ${result.attributes.Subregion? "("+result.attributes.Subregion+")" : ""}`
						}) 
					}
				}
			});
		})
		// const results = await Promise.all(queries);
		// console.log(results);
		// if(results){
		// 	results.forEach(item => {
		// 		if(item.candidates && item.candidates[0]){
		// 			const newAdress = item.candidates[0];
		// 			if(newAdress.attributes.City){
		// 				promptResult.push({
		// 					datas: {
		// 						isPosition: true,
		// 						x: newAdress.location.x,
		// 						y: newAdress.location.y,
		// 						zipCode: newAdress.attributes.Postal,
		// 						city: newAdress.attributes.City,
		// 						municipality: newAdress.attributes.Subregion,
		// 						addressNumber: newAdress.attributes.AddNum,
		// 						street: newAdress.attributes.ShortLabel
		// 					},
		// 					value: newAdress.address,
		// 					label: newAdress.address.toUpperCase()
		// 					// label: `${result.attributes.ShortLabel? result.attributes.ShortLabel + ", " : ""} ${result.attributes.Postal? result.attributes.Postal : ""} ${result.attributes.City? result.attributes.City : ""} ${result.attributes.Subregion? "("+result.attributes.Subregion+")" : ""}`
		// 				}) 
		// 			}
		// 		}
		// 	})
		// 	this.addressesOptions = promptResult;
		// }


	}

	// send makerData to the map
	public async markAddressValue(data: IReplaceMarkerData) {
		const [EsriExtent] = await loadModules([
			"esri/geometry/Extent",
		]);
		let tempReplaceMarker: IMarkerData = data;
		if (!data.isPosition) {
			const extent: esri.Extent = new EsriExtent({
				xmin: data.xMin,
				xmax: data.xMax,
				ymin: data.yMin,
				ymax: data.yMax,
				spatialReference: { wkid: ESRI_MAP_CONFIG.wkid }
			});
			tempReplaceMarker = {
				x: extent.center.x,
				y: extent.center.y
			};
		}
		this.formGroupDetail.get("lambertX").patchValue(tempReplaceMarker.x);
		this.formGroupDetail.get("lambertY").patchValue(tempReplaceMarker.y);
		this.formGroupDetail.get("zipCode").patchValue(data.zipCode);
		this.formGroupDetail.get("city").patchValue(data.city);
		this.formGroupDetail.get("municipality").patchValue(data.municipality);
		this.markerData = tempReplaceMarker;
	}

	// filter on ALL position/location >> NOT USE
	public locateAddressOld() {
		const addressValue: string = this.formGroupDetail.get("address").value.toLowerCase().replace("/", "");
		this._geoserviceWallonieSrv.searchAll(addressValue).subscribe(result => {
			const resultData: any = result;
			const resultList: any[] = resultData.resultats;
			const splittedAddress: string[] = addressValue.split(" ");
			let foundData: boolean;
			let communeSplitted: string[];
			const resultFilterByAdresseAndName: any[] = resultList.filter(resultFound => {
				switch (resultFound.type) {
					// use x y
					case "POSITION":
						foundData = splittedAddress.every(addressPart => resultFound.adresse.toLowerCase().includes(addressPart));
						break;
					case "RUE":
					case "LOCALITE":
						foundData = addressValue.includes(resultFound.nom.toLowerCase());
						break;
					case "COMMUNE":
						foundData = splittedAddress.every(addressPart => resultFound.nom.toLowerCase().includes(addressPart));
						break;
					default:
						foundData = true;
						break;
				}
				return foundData;
			});
			const resultFilterByCommune: any[] = resultFilterByAdresseAndName.filter(resultFound => {
				switch (resultFound.type) {
					case "RUE":
					case "LOCALITE":
						communeSplitted = resultFound.commune.toLowerCase().split("/");
						foundData = communeSplitted.some(communePart => addressValue.includes(communePart.trim()));
						break;
					default:
						foundData = true;
						break;
				}
				return foundData;
			});
		}, error => {
			console.error(error);
		});
	}
	/********** END MAP METHODS **********/

	public async changeStep(step: EnumIncidentSteps, checkDuplicate: boolean = false) {
		if (step < this.selectedStep) {
			this.selectedStep = step;
			this.inputErrorsLabel = [];
			this.changeModalData(step);
		} else if (step > this.selectedStep) {
			// const formIsValid = await this.checkActualFormValidity(step);
			this.checkActualFormValidity(step)
				.then(async formIsValid => {
					if (formIsValid) {
						if (step === EnumIncidentSteps.DUPLICATE) {
							const isDuplicated: boolean = await this.checkDuplicateIncident(step).toPromise();
							step = isDuplicated ? step : step + 1;
						}
						this.selectedStep = step;
						this.changeModalData(this.selectedStep);
						// If form is valid, check if previous forms are defined
						const formsData: FormGroup[] = this.stepsList.filter(stepData => stepData.value < step)
							.map(stepData => {
								return this[stepData.formRelated];
							});
						if (formsData.every(form => form !== undefined)) {
							this.selectedStep = step;
						} else {
							this.selectedStep = formsData.filter(form => form !== undefined).length;
						}
						this.initForm();
					}
				});
		}
	}

	private recursiveSearchCategory(options, categorySearchId) {
		let selectedCategory = null;
		options.forEach(category => {
			if (category.id === categorySearchId) {
				selectedCategory = category;
			} else if (category.children && category.children.length > 0) {
				const resultRecursive = this.recursiveSearchCategory(category.children, categorySearchId);
				if (resultRecursive != null) {
					selectedCategory = resultRecursive;
				}
			}
		});
		return selectedCategory;
	}

	private async checkActualFormValidity(stepSelected: EnumIncidentSteps): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const actualForm: FormGroup = this[this.stepsList.find(step => step.value === this.selectedStep).formRelated];
			let validForm: boolean = true;

			this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(actualForm, this.inputErrorsLabelMap, true);

			if (actualForm && !actualForm.valid) {
				if (actualForm.get("lambertX") && !actualForm.get("lambertX").valid) {
					(<Array<IBoxInfo>>this.inputErrorsLabel).push({
						message: this._translateSrv.translate("incident_pointOnMapNotSelected-error"),
						type: BOX_INFO_TYPE.DANGER
					});
				}
				// validForm = false;
				this.markFormGroupTouched(actualForm);
				resolve(false);
			} else if (this.selectedStep === EnumIncidentSteps.DETAIL) {

				const selectedCategoryId = this.formGroupDetail.get("categoryId").value;
				const selectedCategory = this.recursiveSearchCategory(this.categoryOptions, selectedCategoryId);
				if (selectedCategory.children && selectedCategory.children.length > 0) {
					(<Array<IBoxInfo>>this.inputErrorsLabel).push({
						message: `${this._translateSrv.translate("incident_category-input")}: ${this._translateSrv.translate("incident_categoryNotCompleteError-txt")}`,
						type: BOX_INFO_TYPE.DANGER
					});
					resolve(false);
				} else if (selectedCategory.categoryDetails && selectedCategory.categoryDetails.length > 0 && (!this.categoryDetails || this.categoryDetails.length === 0)) {
					(<Array<IBoxInfo>>this.inputErrorsLabel).push({
						message: `${this._translateSrv.translate("incident_precisions-input")}: ${this._translateSrv.translate("incident_needPrecisionsError-txt")}`,
						type: BOX_INFO_TYPE.DANGER
					});
					resolve(false);
				} else {
					this.markerData = {
						x: this.formGroupDetail.get("lambertX").value,
						y: this.formGroupDetail.get("lambertY").value,
					};

					// check if location is supported
					this._incidentQueriesSrv.checkLocation(
						this.formGroupDetail.get("lambertX").value,
						this.formGroupDetail.get("lambertY").value,
						this.formGroupDetail.get("categoryId").value
					).subscribe(
						res => {
							if (!res.message && res.organisationId > 1) {
								this.addressesOptions = [];
								this.incidentIsUnassignable = null;
								resolve(true);
							} else if (res.organisationId === 1) { // Location unassignable
								this.incidentIsUnassignable = res.message || this._translateSrv.translate("copywriting_key-unassignable-location");
								resolve(true);
							} else { // Location outside of walonie
								(<Array<IBoxInfo>>this.inputErrorsLabel).push({
									message: res.message,
									type: BOX_INFO_TYPE.DANGER
								});
								this.incidentIsUnassignable = res.message || this._translateSrv.translate("incident_unsupported-location-error");
								resolve(false);
							}
						},
						err => reject(err)
					)
				}

			}
			//  else if (this.selectedStep === EnumIncidentSteps.PHOTO_AND_COMMENT && (!this.attachments || this.attachments.length === 0)) {
			// 	// validForm = false;
			// 	this.isFileError = true;
			// 	(<Array<IBoxInfo>>this.inputErrorsLabel).push({
			// 		message: `${this._translateSrv.translate("incident_photoList-input")}: ${this._translateSrv.translate("general_requiredInputError-txt")}`,
			// 		type: BOX_INFO_TYPE.DANGER
			// 	});
			// 	resolve(false);
			// }
			else {
				resolve(validForm);
			}

		})
	}

	private initForm(stepSelected: EnumIncidentSteps = this.selectedStep) {
		const formRelated: string = this.stepsList.find(step => step.value === stepSelected).formRelated;
		switch (stepSelected) {
			case EnumIncidentSteps.DETAIL:
				if (!this[formRelated]) {
					this[formRelated] = this._fb.group(this.setIncidentFormPartOne());
				}
				break;
			case EnumIncidentSteps.DUPLICATE:
				if (!this[formRelated]) {
					this[formRelated] = this._fb.group(this.setIncidentDuplicate());
					this[formRelated].get("selectedDuplicateIncident").valueChanges.subscribe(incidentId => {
						const itemFind = this._possibleDuplicates.find(incident => incident.id === incidentId);
						this.duplicateIncident = this._possibleDuplicates[0] ? this.formatDuplicateDatas(itemFind) : null;
					});
				}
				if (this.duplicateIncidentOptions.length > 0) {
					this.formGroupDuplicate.get("selectedDuplicateIncident").setValue(this.duplicateIncidentOptions[0].value);
				}
				break;
			case EnumIncidentSteps.PHOTO_AND_COMMENT:
				if (!this[formRelated]) {
					this[formRelated] = this._fb.group(this.setIncidentFormPhotoAndComment());
				}
				break;
			case EnumIncidentSteps.RESUME:
				if (!this[formRelated]) {
					this[formRelated] = this._fb.group({});
				}
				// const categories: ICategory[] = recursiveBreadcrumbs(this.categoryOptions, this.formGroupDetail.get("categoryId").value);
				this.resumeDatas = {
					address: this._needMerge ? this.duplicateIncident.address : this.formatAddress(this.formGroupDetail.get("address").value),
					zipCode: this._needMerge ? this.duplicateIncident.zipCode : this.formGroupDetail.get("zipCode").value,
					city: this._needMerge ? this.duplicateIncident.city : this.formGroupDetail.get("city").value,
					municipality: this._needMerge ? this.duplicateIncident.municipality : this.formGroupDetail.get("municipality").value,
					isMultipleLocations: this._needMerge ? this.duplicateIncident.isMultipleLocations : this.formGroupDetail.get("isMultipleLocations").value,
					// selectedCategory: this._needMerge ? this.duplicateIncident.category : formatBreacrumb(recursiveBreadcrumbs(this.categoryOptions, this.formGroupDetail.get("categoryId").value)),
					selectedCategory: this._needMerge ? this.duplicateIncident.category : this.generateCompleteCategoryName(),
				};
				break;
		}
	}

	/**
	 * @description Change the modal buttonName
	 * @author Kevin Palade
	 * @private
	 * @param {EnumIncidentSteps} [stepSelected=this.selectedStep]
	 * @memberof ModalCreateIncidentComponent
	 */
	private changeModalData(stepSelected: EnumIncidentSteps = this.selectedStep) {
		const newData: IModalData = this._data;
		newData.customData.cancelButtonName = stepSelected === EnumIncidentSteps.DETAIL ?
			this._translateSrv.translate("general_cancel-button") : this._translateSrv.translate("general_return-button");
		newData.customData.confirmButtonName = stepSelected === EnumIncidentSteps.RESUME ?
			this._translateSrv.translate("general_save-button") : this._translateSrv.translate("general_continue-button");
		newData.customData.confirmButtonType = stepSelected === EnumIncidentSteps.RESUME ?
			TYPE_BUTTON.VALID : TYPE_BUTTON.CONTINUE;
		newData.customData.confirmButtonIcon = stepSelected === EnumIncidentSteps.RESUME ? undefined : "far fa-arrow-alt-circle-right";
		newData.customData.cancelButtonIcon = stepSelected === EnumIncidentSteps.DETAIL ? undefined : "far fa-arrow-alt-circle-left";
		this._modalSrv.setModalData = newData;
	}

	private setIncidentFormPartOne(): IIncidentDetail | any {
		return {
			// categoryId: [null, Validators.required],
			// TODO TESTING ERROR multi-select-input
			categoryId: [null, Validators.required],
			address: [null, Validators.required],
			isMultipleLocations: false,
			lambertX: [null, Validators.required],
			lambertY: [null, Validators.required],
			zipCode: null,
			city: null,
			municipality: null,
			addressNumber: null,
			street: null
		};
	}

	private setIncidentDuplicate(): any {
		return {
			selectedDuplicateIncident: null,
		};
	}

	private setIncidentFormPhotoAndComment(): IIncidentPhotoAndComment {
		return {
			comment: null,
			autoSubscribe: true
		};
	}

	// TODO: Must check if we can go to photo and comment or if we need to pass to duplicate step
	private checkDuplicateIncident(stepSelected: EnumIncidentSteps): Observable<boolean> {
		const currentData = this.formGroupDetail.value;
		const datasToCheck = {
			position: {
				lambertX: currentData.lambertX,
				lambertY: currentData.lambertY,
			},
			categoryId: currentData.categoryId
		};
		return this._incidentQueriesSrv.getPossibleDuplicate(datasToCheck.position, datasToCheck.categoryId)
			.pipe(map(res => {
				const resultData: any = res.data;
				this._possibleDuplicates = resultData ? resultData.possibleDuplicates : [];
				if (this._possibleDuplicates.length > 0) {
					this.duplicateIncidentOptions = this._possibleDuplicates.map(dup => {
						return { value: dup.id, label: this._translateSrv.translate("incident_numberX-txt", { incidentId: dup.orgacode+"-"+dup.generatedid }) };
					});
					// this.duplicateIncident = this._possibleDuplicates[0] ? this.formatDuplicateDatas(this._possibleDuplicates[0]) : null;
					// this.changeStep(EnumIncidentSteps.DUPLICATE, true);
					return true;
				} else {
					this.initForm(EnumIncidentSteps.DUPLICATE);
					this.changeStep((stepSelected === EnumIncidentSteps.DUPLICATE) ? ++stepSelected : stepSelected, true);
					return false;
				}
			}));
	}

	private formatDuplicateDatas(incidentData): IIncidentDuplicate {
		return {
			id: incidentData.id,
			generatedid: incidentData.generatedid,
			orgacode: incidentData.orgacode,
			duplicatePercentage: incidentData.duplicatePercentage,
			address: (incidentData.address && incidentData.address.includes("(")) ? incidentData.address.replace(incidentData.zipCode, "").replace(",", "").replace(incidentData.city, "").replace(",", "").replace(",", "") : incidentData.address ? incidentData.address.replace(incidentData.zipCode, "").replace(",", "").replace(incidentData.city, "").replace(",", "").replace(incidentData.municipality, "(" + incidentData.municipality + ")").replace(",", "") : "",
			zipCode: incidentData.zipCode,
			municipality: incidentData.municipality,
			city: incidentData.city,
			// category: this.formatCompleteCategoryName(incidentData.categoryChain),
			category: this.formatCompleteCategoryName(incidentData.categoryChain, incidentData.categoryDetails),
			organisation: incidentData.assignment && incidentData.assignment.organisation ? incidentData.assignment.organisation.name : "-",
			group: incidentData.assignment && incidentData.assignment.group ? incidentData.assignment.group.name : "-",
			agent: incidentData.assignment && incidentData.assignment.agent ? `${incidentData.assignment.agent.lastName} ${incidentData.assignment.agent.firstName}` : "-",
			status: this._translateSrv.translate(`incident_${incidentData.status.toLowerCase()}Status`),
			statusColor: incidentData.status,
			priority: this._translateSrv.translate(`priority.${incidentData.priority.toLowerCase()}`),
			createdAt: moment(incidentData.createdAt).format("DD/MM/YYYY"),
			visibility: this._translateSrv.translate(`incident.detail.${(incidentData.isPublic ? "public" : "private")}`),
			complementAddress: incidentData.complementAddress,
			isMultipleLocations: incidentData.isMultipleLocations,
			plannedDate: incidentData.plannedDate ? moment(incidentData.plannedDate).startOf("month").format("MM/YYYY") : null,
			isPrivateLocation: incidentData.isPrivateLocation,

			isMasked: incidentData.isMasked,
			isThirdPartyResponsability: incidentData.isThirdPartyResponsability,
			attachments: incidentData.attachments,
			commentDatas: this.formatCommentDatas(incidentData.comments, incidentData.attachments)
		};
	}


	private formatCommentDatas(comments: IComment[], attachments: IAttachment[]) {
		const allComments: any[] | any[] = [...comments, ...attachments].sort((a, b) => {
			return (a.createdAt > b.createdAt) ? -1 : 1;
		});
		return allComments.map(comment => {
			return this.setCommentData(comment);
		});
	}


	private setCommentData(comment: IComment | IAttachment): IContainerComment {
		return {
			id: comment.id,
			datas: { isPublic: comment.isPublic, isComment: !(<any>comment).filePath },
			title: `${this._translateSrv.translate("incident_commentAdded-txt")} ${moment(comment.createdAt).format("DD/MM/YYYY HH:mm")}
      ${this._translateSrv.translate("incident_commentBy-txt")} ${this.formatAuthor(comment)}`,
			comment: comment.comment,
			imageSrc: (<any>comment).filePath ? (<any>comment).filePath : "../../../../assets/img/text_message_1x.png"
		};
	}

	/**
	 * @description Format author of a comment / attachment for display in comment's / attachment's title
	 * @author Quentin Wolfs
	 * @private
	 * @param {(IComment | IAttachment)} comment
	 * @returns {string}
	 * @memberof ModalDetailIncidentComponent
	 */
	private formatAuthor(comment: IComment | IAttachment): string {
		if (!!comment.user) {
			return `${comment.user.lastName} ${comment.user.firstName}`;
		} else if (!!comment.citizen) {
			return `${this._translateSrv.translate("incident_commentNeightbour-txt")} (${comment.citizen.lastName} ${comment.citizen.firstName}`
				+ `${comment.citizen.phone ? " | " + comment.citizen.phone : ""}${comment.citizen.email ? " | " + comment.citizen.email : ""})`;
		} else {
			return this._translateSrv.translate("incident_commentNeightbour-txt");
		}
	}

	// private formatCompleteCategoryName(category: ICategory): string {
	// 	let categoryName = category.name;
	// 	if (category.children.length !== 0) {
	// 		categoryName += ` / ${this.formatCompleteCategoryName(category.children[0])}`;
	// 	}
	// 	return categoryName;
	// }

	private formatCompleteCategoryName(category: ICategory, categoryDetails): string {
		let categoryName = category.name;
		if (category.children.length !== 0) {
			categoryName += ` / ${this.formatCompleteCategoryName(category.children[0], categoryDetails)}`;
		}
		if ((!category.children || category.children.length === 0) && categoryDetails && categoryDetails.length > 0) {
			if (categoryDetails && categoryDetails.length > 0) {
				categoryName += " ( " + categoryDetails.map(prec => prec.name).join(", ") + " )";
			}
		}
		return categoryName;
	}


	public fileChanged(file) {
		if (file) {
			this.attachments.push(file);
			// this.isFileError = false;
		} else {
			this.attachments = [];
		}
	}

	public deleteFile(index) {
		if (index !== null) {
			this.attachments.splice(index, 1);
			// if (this.attachments.length === 0) {
			// 	this.isFileError = true;
			// }
		} else {
			console.error("No index selected");
		}
	}

	public fileTouched(event) {
		if (event.index != null) {
			switch (event.action) {
				case FILES_BLOCK_ACTIONS_ENUM.DELETE:
					// this.markAsTouched();
					this.deleteFile(event.index);
					break;
				case FILES_BLOCK_ACTIONS_ENUM.ZOOM:
					break;
				default:
			}
		}
	}

	public confirmModal(needMerge: boolean = false) {
		if (this.selectedStep !== EnumIncidentSteps.RESUME) {
			if (this.selectedStep === EnumIncidentSteps.DUPLICATE) {
				this._needMerge = needMerge;
			}
			this.changeStep(this.selectedStep + 1);
		} else {
			this.save();
			this._modalSrv.closeModal();
		}
	}

	// TODO format data received
	private formatIncidentData(): any {
		const attachments: File[] = this.attachments;
		const formatedDatas: IIncidentInput = {
			lambertY: this.formGroupDetail.get("lambertY").value,
			lambertX: this.formGroupDetail.get("lambertX").value,
			categoryId: this.formGroupDetail.get("categoryId").value,
			isMultipleLocations: this.formGroupDetail.get("isMultipleLocations").value,
			attachments,
			address: this.formGroupDetail.get("address").value,
			zipCode: this.formGroupDetail.get("zipCode").value.toString(),
			city: this.formGroupDetail.get("city").value.toString(),
			municipality: this.formGroupDetail.get("municipality").value.toString(),
			comment: this.formGroupPhotoAndComment.get("comment").value,
			autoSubscribe: this.formGroupPhotoAndComment.get("autoSubscribe").value,
			categoryDetailIds: this.categoryDetails ? this.categoryDetails.map(cat => cat.id) : null,
			addressNumber: this.formGroupDetail.get("addressNumber").value,
			street: this.formGroupDetail.get("street").value,
		};
		return { datas: formatedDatas, merge: { needMerge: this._needMerge, incidentId: this._needMerge ? this.duplicateIncident.id : null } };
		// return formatedDatas;
	}

	public cancelModal(isCloseCrossButton: boolean) {
		if (!isCloseCrossButton && this.selectedStep !== EnumIncidentSteps.DETAIL) {
			let newStep = this.selectedStep - 1;
			this.changeStep((newStep === EnumIncidentSteps.DUPLICATE && !this.duplicateIncident) ? --newStep : newStep);
		} else {
			this._modalSrv.closeModal();
		}
	}

	protected save(): void {
		const objData = {
			confirm: true,
			data: this.formatIncidentData()
		};
		this.afterClosed.next(objData);
	}

	public formatAddress(item): any {
		const zipCode = this.formGroupDetail.get("zipCode").value.toString();
		const municipality = this.formGroupDetail.get("municipality").value.toString();
		const city = this.formGroupDetail.get("city").value.toString();
		const zip = this.formGroupDetail.get("zipCode").value.toString();
		let formattedAddress = "";
		if(!this.isOldAPIUsed){
			let splittedItem = item.split(",");
			formattedAddress = splittedItem? splittedItem[0].toUpperCase() : ""
			if(municipality){
				formattedAddress += " (" + municipality.toUpperCase() + ")";
			}
		} else {
			formattedAddress = item.replace(zip, "").replace(city, "");
		}
		return formattedAddress;

		// let formattedAddress = item.replace(zipCode, "");
		// formattedAddress = formattedAddress.replace(city, "");
		// formattedAddress = formattedAddress.replace("(" + municipality + ")", "");
		// return formattedAddress;
	}

	public precisionUpdate(categoryDetails) {
		this.categoryDetails = categoryDetails;
	}

	private generateCompleteCategoryName() {
		let categoryName = formatBreacrumb(recursiveBreadcrumbs(this.categoryOptions, this.formGroupDetail.get("categoryId").value));
		if (this.categoryDetails && this.categoryDetails.length > 0) {
			categoryName += " ( " + this.categoryDetails.map(prec => prec.name).join(", ") + " )";
		}
		return categoryName;
	}
}
