// tslint:disable: no-inferrable-types
// tslint:disable: no-input-rename

import { Directive, Input, ElementRef, Renderer2, OnInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit, OnChanges {

  @Input('tooltipPosition') public tooltipPosition: string = 'top';
  @Input('tooltipColor') public tooltipColor: string = 'primary';
  @Input('appTooltip') public appTooltip: string;

  constructor(private el: ElementRef,
              private renderer: Renderer2) { }

  ngOnInit() {
    this.el.nativeElement.classList.add('tooltip_position_' + this.tooltipPosition);
    this.el.nativeElement.classList.add('tooltip_color_' + this.tooltipColor);
    this.renderer.setAttribute(this.el.nativeElement, 'data-tooltip', this.appTooltip);
  }

  ngOnChanges() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-tooltip', this.appTooltip);
  }

}
