import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import {
  CREATE_USER,
  GET_LIST_USERS,
  GET_USER,
  GET_GROUPS_AVAILABLE,
  DELETE_USER,
  GET_ALL_GROUPS_FOR_USER,
  IS_EMAIL_FREE,
  USER_UPDATE_IMPACTS,
  UPDATE_USER,
  GET_USERS_XLS
} from "./user.queries";
import { ROLES } from "../../enum/roles.enum";
import { IFilterUsers, IUserInput } from "../../interfaces/user.interface";
import { IPagination } from "../../interfaces/pagination.interface";
import { IIncidentColumnFilter } from "../../interfaces/incident.interface";
import { KIND } from '../../enum/user-kind.enum';

@Injectable({
  providedIn: "root"
})
export class UserQueriesService {
  public _userPagination: IPagination = {limit: 20, page: 1};
  private _appliedFilters: IIncidentColumnFilter = null;

  constructor(private _apollo: Apollo) { }

  public set userPagination(pagination: IPagination ) { this._userPagination = pagination; }
  public get userPagination(): IPagination { return this._userPagination; }

  public get appliedFilters(): IIncidentColumnFilter { return this._appliedFilters; }
  public set appliedFilters(appliedFilters: IIncidentColumnFilter) { this._appliedFilters = appliedFilters; }

  public resetPagination() {
    this._userPagination = {
      ...this._userPagination,
      page: 1
    };
  }
  public createUser(dataInput: any) {
    return this._apollo.mutate({
      mutation: CREATE_USER,
      variables: {
        user: dataInput,
      },
      refetchQueries: [{
        query: GET_LIST_USERS,
        variables: {
          pagination: this._userPagination,
          filter: this._appliedFilters
        }
      }]
    });
  }

  public listUsers(pagination?: any) {
    if (this._appliedFilters) {
      Object.keys(this._appliedFilters).forEach(key => {
        if (this._appliedFilters[key] === "") {
          this._appliedFilters[key] = null;
        }
      });
    }
    return this._apollo.watchQuery({
      query: GET_LIST_USERS,
      variables: {
        filter: this._appliedFilters,
        pagination: this._userPagination
      },
      fetchPolicy: "cache-and-network"
    });
  }

  public getUsersList(filter: IFilterUsers = {}) {
    return this._apollo.query({
      query: GET_LIST_USERS,
      variables: {
        filter
      },
      fetchPolicy: "no-cache"
    });
  }

  public getUser(idUser: number) {
    return this._apollo.query({
      query: GET_USER,
      fetchPolicy: "no-cache",
      variables: {
        id: idUser,
      }
    });
  }

  // TODO > DELETE THIS QUERY
  public getGroupsAvailable(idUser: number, role: string) {
    return this._apollo.query({
      query: GET_GROUPS_AVAILABLE,
      fetchPolicy: "no-cache",
      variables: {
        userId: idUser,
        role : ROLES[role]
      }
    });
  }

  public deleteUser(userId: number, deleteKind: KIND) {
    return this._apollo.mutate({
      mutation: DELETE_USER,
      variables: {
        id: userId,
        kind: deleteKind
      },
      refetchQueries: [{
        query: GET_LIST_USERS,
        variables: {
          pagination: this._userPagination,
          filter: this._appliedFilters
        }
      }]
    });
  }

  public getAllGroupsForUser(userId: number) {
    return this._apollo.query({
      query: GET_ALL_GROUPS_FOR_USER,
      fetchPolicy: "no-cache",
      variables: {
        id: userId,
      }
    });
  }

  public isEmailFree(email: string) {
    return this._apollo.query({
      query: IS_EMAIL_FREE,
      variables: {email},
      fetchPolicy: "network-only"
    });
  }

  public checkUserUpdateImpacts(userUpdate: IUserInput) {
    return this._apollo.query({
      query: USER_UPDATE_IMPACTS,
      variables: {userUpdate},
      fetchPolicy: "no-cache"
    });
  }

  public getUsersXls(tableHeaders: any[]) {
    if (this._appliedFilters) {
      Object.keys(this._appliedFilters).forEach(key => {
        if (this._appliedFilters[key] === "") {
          this._appliedFilters[key] = null;
        }
      });
    }

    const pagination = {
      ...this._userPagination,
      limit: 0,
      page: 1
    };

    return this._apollo.query({
      query: GET_USERS_XLS,
      variables: {
        filter: this._appliedFilters,
        pagination: pagination,
        tableHeaders
      },
      fetchPolicy: "no-cache"
    });
  }

public updateUser(user: IUserInput) {
    return this._apollo.mutate({
      mutation: UPDATE_USER,
      variables: {
        user: user
      }
    });
}


}
