import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BoxInfoComponent } from "./molecules/box-info/box-info.component";
import { MultipleBoxInfoComponent } from "./organisms/multiple-box-info/multiple-box-info.component";
import { SignalBoxComponent } from "./molecules/signal-box/signal-box.component";
import { PipeModule } from "src/app/facades/pipes/pipe.module";

@NgModule({
    declarations: [
        BoxInfoComponent,
        MultipleBoxInfoComponent,
        SignalBoxComponent
    ],
    imports: [
        CommonModule,
        PipeModule
    ],
    exports : [
        BoxInfoComponent,
        MultipleBoxInfoComponent,
        SignalBoxComponent

    ]
})
export class BoxInfoModule { }
