import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { FormArray } from "@angular/forms";
import { ISelectMultipleOptions } from "src/app/presentationnal/input/facades/interfaces/selectMultiple.interface";

export interface ITableActionData {
  action: EnumTableAction;
  content: any;
}
export enum EnumTableAction {
  EDIT = "edit",
  DELETE = "delete",
  FILTER = "filter",
  SORT = "sort",
  SELECT = "select",
  SELECT_GLOBAL = "select_global"
}

export interface ITableFilter {
  key: string;
  type: EnumTableDataType;
  options?: ISelectOption[];
  selectMultipleOptions?: ISelectMultipleOptions[];
  disabled?: boolean;
  precision?: boolean;
  precisionKey?: string;
  precisionFilterKey?: string;
  scrollable?: boolean;
}

export enum EnumTableDataType {
  text = "text",
  boolean = "boolean",
  select = "select",
  selectMultiple = "selectMultiple",
  selectcheckbox = "app-select-checkbox-input",
  selectSearchMultiple = "select-search-multiple",
  date = "date",
  action = "action",
  number = "number"
}