import { Component, OnInit } from "@angular/core";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { Router } from "@angular/router";
import { AuthService } from "src/lib/auth/auth.service";
import { EnumUserRoles } from "src/app/facades/interfaces/user.interface";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";
import { TranslateService } from "src/app/facades/services/translate.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { UserGroupQueriesService } from "src/app/facades/queries/user-group/user-group-queries.service";
import { CategoryDispatchingQueriesService } from "src/app/facades/queries/category-dispatching/category-dispatching-queries.service";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { IExpandContent, IExpandContentFormConfig, EnumExpandContentFormConfigType } from "src/app/presentationnal/expand/facades/interfaces/expand-content.interface";
import { CategoryQueriesService } from "src/app/facades/queries/categorie/category-queries.service";
import { ICategory } from "src/app/facades/interfaces/category.interface";
import { IGroup } from "src/app/facades/interfaces/group.interface";
import { ICategoryDispatching, ICategoryDispatchingInput } from "src/app/facades/interfaces/dispatching.interface";
import { ICategoryDetail } from "src/app/facades/interfaces/category-detail.interface";

@Component({
  selector: "app-dispatching-categories-page",
  templateUrl: "./dispatching-categories-page.component.html",
  styleUrls: ["./dispatching-categories-page.component.css"]
})
export class DispatchingCategoriesPageComponent implements OnInit {
  public optionsHeader: IHeaderOptions;
  public _formGroup: FormGroup;
  public TYPE_BUTTON_ENUM = TYPE_BUTTON;
  public groupOptions = [];
  public editMode: boolean = false;
  // Formgroup boxinfo error
  public inputErrorsLabelMap = new Map<string, string>([
    ["groupId", this._translateSrv.translate("dispatching_groupByDefaultError-txt")],
  ]);
  public inputErrorsLabel: Array<IBoxInfo> | Object;
  public expandContent: IExpandContent[] = [];

  protected _origin: {defaultGroup?: IGroup, dispatchs?: ICategoryDispatching[]} = {};

  constructor(
    private _router: Router,
    private _translateSrv: TranslateService,
    private _snackBarSrv: SnackService,
    private _authSrv: AuthService,
    private _fb: FormBuilder,
    private _userGroupQueriesSrv: UserGroupQueriesService,
    private _categoryQueriesSrv: CategoryQueriesService,
    private _categoryDispatchSrv: CategoryDispatchingQueriesService,
    private _errorMessageSrv: ErrorMessageService,
  ) {
    this.optionsHeader = {
      title: "dispatching_pageTitle-txt",
      buttons: [
        {
          name: this._translateSrv.translate("dispatching_toOrganisationBasedOnGeographicalZone-button"),
          datas: {
            link: "/dispatching"
          },
          disabled: !this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)
        },
        {
          name: this._translateSrv.translate("dispatching_toGroupBasedOnCategories-button"),
          datas: {
            link: "/dispatching/categories"
          },
          disabled: !this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN)
        },
        {
          name: this._translateSrv.translate("dispatching_toGroupBasedOnGeographicalZone-button"),
          datas: {
            link: "/dispatching/groupes"
          },
          disabled: !this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN)
        },
      ]
    };
  }

  ngOnInit() {
    this.initForm();
    if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN)) {
      this.loadDispatchingDatas();
    }
  }

  public headerAction(datas: { link: string }) {
    this._router.navigateByUrl(datas.link);
  }

  private initForm() {
    this._formGroup = this._fb.group({
      groupId: [null, Validators.required]
    });
  }

  public setEditMode() {
    this.editMode = true;
  }

  public save() {
    if (this._formGroup.valid) {
      this._categoryDispatchSrv.setCategoryDispatching(this._formGroup.value).subscribe(result => {
        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("dispatching_updateGroupByDefaultSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
        this.editMode = false;
      }, error => {
        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("dispatching_updateGroupByDefaultFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      });
    } else {
      this.markFormGroupTouched(this._formGroup);
      this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
    }
  }

  public cancelDefaultGroupEdit() {
    if (this._origin.defaultGroup) {
      this._formGroup.get("groupId").patchValue(this._origin.defaultGroup.id);
    }
    this.editMode = false;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      (control as FormControl).updateValueAndValidity();
      control.markAsTouched();
      if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
    });
  }

  private async loadDispatchingDatas() {
    try {
      const promiseDatas = await Promise.all([
        this._categoryQueriesSrv.getMappedCategories().toPromise(),
        this._categoryDispatchSrv.listCategoryDispatchings().toPromise(),
        this._userGroupQueriesSrv.getListGroupByOrganisation().toPromise()
      ]);
      const resultDatas: { mappedCategories: ICategory[], groups: IGroup[], categoryDispatchings: ICategoryDispatching[] } = {
        mappedCategories: (<any>promiseDatas.find(data => (<any>data.data).mappedCategories).data).mappedCategories,
        categoryDispatchings: (<any>promiseDatas.find(data => (<any>data.data).categoryDispatchings).data).categoryDispatchings,
        groups: (<any>promiseDatas.find(data => (<any>data.data).groups).data).groups
      };

      resultDatas.mappedCategories = this._filterCategoriesIsActive(resultDatas.mappedCategories);

      this.groupOptions = resultDatas.groups.map(group => {
        return {
          value: group.id,
          label: group.name
        };
      });
      const defaultDispatch: ICategoryDispatching = resultDatas.categoryDispatchings.find(dispatch => !dispatch.category);
      if (defaultDispatch) {
        this._formGroup.get("groupId").patchValue(defaultDispatch.groups[0].id);
        this._origin.defaultGroup = defaultDispatch.groups[0];
      }

      // Remove default dispatching
      const categoriesAlreadyDispatch: ICategoryDispatching[] = resultDatas.categoryDispatchings.filter(dispatch => dispatch.category);
      this._origin.dispatchs = categoriesAlreadyDispatch;
      this.expandContent = this.generateExpandContent(resultDatas.mappedCategories, categoriesAlreadyDispatch);
    } catch (error) {
      console.log({ error });
    }
  }

  private _filterCategoriesIsActive(categories: ICategory[]) {
    const filteredCategories = [];
    categories.forEach(categ => {
      if (categ.isActive) {
        if (categ.children && categ.children.length > 0) {
          categ.children = this._filterCategoriesIsActive(categ.children);
        }
        if (categ.categoryDetails && categ.categoryDetails.length > 0) {
          categ.categoryDetails = categ.categoryDetails.filter(catDetail => catDetail.isActive);
        }
        filteredCategories.push(categ);
      }
    });
    return filteredCategories;
  }

  private generateExpandContent(categories: ICategory[], dispatching: ICategoryDispatching[], level: number = 1): IExpandContent[] {
    let dispatchFound: ICategoryDispatching;
    let groupIds: number[];
    let configInline: IExpandContentFormConfig;
    const expandData: IExpandContent[] = categories.map(cat => {
      // Prepare checkbox select config for this category
      dispatchFound = dispatching.find(disp => disp.category.id === cat.id);
      groupIds = dispatchFound && dispatchFound.groups.length > 0 ? dispatchFound.groups.map(g => g.id) : [];
      configInline = {
        type: EnumExpandContentFormConfigType.selectCheckbox,
        key: `groupIds_${cat.id}`,
        options: this.groupOptions.map(option => ({ ...option, isSelected: groupIds.includes(option.value) }))
      };

      // Generate child content for either categories or categoryDetails
      if (level > 6) {
        console.log("OUT OF LEVEL");
      }
      let childContents: IExpandContent[] = [];
      if (cat.children && cat.children.length > 0) {
        childContents = this.generateExpandContent(cat.children, dispatching, level + 1);
      } else if (cat.categoryDetails && cat.categoryDetails.length > 0) {
        childContents = this.generateDetailExpandContent(cat.categoryDetails, level + 1);
      }
      return {
        id: cat.id,
        title: `${cat.name}`,
        level,
        showChildren: false,
        children: childContents,
        button: {
          label: this._translateSrv.translate("general_edit-button"),
          icon: "fas fa-edit",
          action: this._updateDispatchingValue
        },
        formDatas: {
          isInlineInput: true,
          formGroup: this._fb.group({ [`groupIds_${cat.id}`]: [dispatchFound ? dispatchFound.groups.map(g => g.id) : null, Validators.required] }),
          configInline
        }
      };
    });
    return expandData;
  }

  /**
   * @description Generate ExpandContent for CategoryDetails
   * @author Quentin Wolfs
   * @private
   * @param {ICategoryDetail[]} categoryDetails
   * @param {number} level
   * @returns {IExpandContent[]}
   * @memberof DispatchingCategoriesPageComponent
   */
  private generateDetailExpandContent(categoryDetails: ICategoryDetail[], level: number): IExpandContent[] {
    return categoryDetails.map(catDetail => {
      if (level > 6) {
        console.log("OUT OF LEVEL");
      }
      return {
        id: catDetail.id,
        title: `${catDetail.name}`,
        level,
        showChildren: false,
        children: [],
        button: null,
        formDatas: null
      };
    });
  }

  private _updateDispatchingValue = (datas: { expandData: IExpandContent, oldData: any }) => {
    const catDispatchingInput: ICategoryDispatchingInput = {
      groupIds: datas.expandData.formDatas.formGroup.get(`groupIds_${datas.expandData.id}`).value,
      categoryId: datas.expandData.id
    };

    this._categoryDispatchSrv.setCategoryDispatching(catDispatchingInput).subscribe(result => {

      const idx = this.expandContent.findIndex(ec => ec.id === datas.expandData.id);
      datas.expandData.formDatas.formGroup.get(`groupIds_${datas.expandData.id}`).patchValue(catDispatchingInput.groupIds);
      datas.expandData.formDatas.configInline = {
        type: EnumExpandContentFormConfigType.selectCheckbox,
        key: `groupIds_${datas.expandData.id}`,
        options: this.groupOptions.map(option => ({ ...option, isSelected: catDispatchingInput.groupIds.includes(option.value) }))
      };

      // console.log(this.expandContent[idx].formDatas.formGroup.get(`groupIds_${datas.expandData.id}`).value);
      // console.log(datas.expandData.formDatas.formGroup.get(`groupIds_${datas.expandData.id}`).value);

      this._snackBarSrv.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate("dispatching_updateGroupByDefaultSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
      // this.updateChildValue(datas.expandData, catDispatchingInput.groupIds);
    }, error => {
      datas.expandData.formDatas.formGroup.get(`groupIds_${datas.expandData.id}`).patchValue(datas.oldData.groupIds);
      this._snackBarSrv.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("dispatching_updateGroupByDefaultFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  private updateChildValue(expandContent: IExpandContent, newValue: number[]) {
    if (expandContent.children.length > 0) {
      expandContent.children.forEach(child => {
        if (child.formDatas) {
          const fg = child.formDatas.formGroup.get(`groupIds_${expandContent.id}`);
          if (fg) {
            fg.patchValue(newValue);
          }
          this.updateChildValue(child, newValue);
        }
      });
    }
  }

}
