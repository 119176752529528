import gql from "graphql-tag";

export const GET_LAYERS = gql`
  query getLayers{
    layers{
      id
      url
      mapServerId
      attributeKey
      name
    }
  }
`;