import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ROLES } from "src/app/facades/enum/roles.enum";
import { Subscription } from "rxjs";
import { ModalService } from "src/app/facades/services/modal/modal.service";
// import { ModalCreateUserComponent } from "src/app/modal/component/modal-create-user/modal-create-user.component";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { ModalConfirmComponent } from "src/app/modal/component/modal-confirm/modal-confirm.component";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { ITableActionData, EnumTableAction, ITableFilter, EnumTableDataType } from "src/app/presentationnal/table/facades/interfaces/table.interface";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { IFilterBarPagination } from "src/app/presentationnal/bar/facades/interfaces/filter-bar.interface";
import { TranslateService } from "src/app/facades/services/translate.service";
import { CitizenQueriesService } from "src/app/facades/queries/citizen/citizen-queries.service";
import { ICitizen } from "src/app/facades/interfaces/citizen.interface";
import { headerInputDefaultCitizen } from "src/app/facades/configs/citizen-list-filters";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { CITIZEN_TYPE } from "src/app/facades/enum/citizen-type.enum";
// import { LANGS } from "src/app/facades/enum/langs.enum";
import { KIND } from "src/app/facades/enum/user-kind.enum";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { filter } from "rxjs/operators";
import { IIncident } from "../../../facades/interfaces/incident.interface";
import * as moment from "moment";
import { XlsService } from "src/app/facades/services/xls/xls.service";
import {CITIZEN_IS_AMBASSADOR} from "../../../facades/enum/citizen-isAmbassador.enum";
import {posix} from "path";

@Component({
  selector: "app-citizen-page",
  templateUrl: "./citizens-page.component.html",
  styleUrls: ["./citizens-page.component.css"]
})
export class CitizenPageComponent implements OnInit, OnDestroy {
  public rolesCitizen = ROLES;
  private _listCitizenSub: Subscription;
  public formGroup: FormGroup;
  public citizenList: Array<ICitizen> = [];
  public contentTable: any = [];
  public optionsHeader: IHeaderOptions = {
    title: "citizen_pageTitle-txt",
    // buttonsOption : {
    //   size: "small",
    //   name: "general_create-button"
    // }
  };

  public paginationFilter: IFilterBarPagination = {
    label: "citizen_citizensOnX-txt",
    listNumber: null,
    listTotalCounter: null,
    totalPage: null,
    currentPage: 1
  };

  public headersTable = [
    { key: "lastName", type: "text", translate: "citizen_lastname-th", sortable: true },
    { key: "firstName", type: "text", translate: "citizen_firstname-th", sortable: true },
    { key: "phone", type: "text", translate: "citizen_phone-th", sortable: true },
    { key: "email", type: "text", translate: "citizen_mail-th", sortable: true },
    { key: "type", type: "text", translate: "citizen_type-th", sortable: true },
    { key: "createdAt", type: "text", translate: "citizen_createdAt-th", sortable: true },
    { key: "isAmbassador", type: "boolean", translate: "citizen_isAmbassador-th", sortable: true },
    // { key: "langue", type: "text", translate: "citizen_lang-th", sortable: true },
    { key: "Action", type: "action", translate: "citizen_actionColumn-th", sortable: false },
  ];
  public filterOptions: ITableFilter[] = headerInputDefaultCitizen;

  public filtersMapping: Map<string, string> = new Map([
    ["lastName", "lastName"],
    ["firstName", "firstName"],
    ["phone", "phone"],
    ["email", "email"],
    ["type", "type"],
    ["createdAt", "createdAt"],
    ["isAmbassador", "isAmbassador"]
    // ["lang", "lang"],
  ]);
  loadingExport = false;

  constructor(private _fb: FormBuilder,
    private _modalSrv: ModalService,
    private _citizenQueriesSrv: CitizenQueriesService,
    private _snackBar: SnackService,
    private _translateSrv: TranslateService,
    private _xlsSrv: XlsService,
  ) { }
  ngOnInit() {
    const appliedFilters = {};
    this.filtersMapping.forEach(value => {
      appliedFilters[value] = null;
    });
    this._citizenQueriesSrv.appliedFilters = appliedFilters;
    this._citizenQueriesSrv.resetPagination();

    this.paginationFilter.listNumber = this._citizenQueriesSrv.citizenPagination.limit;
    this.listCitizens();
    this.initFilterOptions();
  }

  ngOnDestroy(): void {
    this._listCitizenSub.unsubscribe();
  }

  searchElement(event) {
    // TODO Mette query pour filtrer le tableau
  }

  public initFilter() {
    this.formGroup = this._fb.group({
      searching: null,
    });
  }

  public listCitizens() {
    if (this._listCitizenSub) { this._listCitizenSub.unsubscribe(); }
    this._listCitizenSub = this._citizenQueriesSrv.listCitizen().valueChanges.subscribe(citizens => {
      const data: any = <any>citizens.data;
      if (data && data.citizens) {
        this.citizenList = data.citizens;
        this.formatDatasForTable();
      }
      if (data && data.pagination) {
        this.paginationFilter = {
          ...this.paginationFilter,
          listTotalCounter: data.pagination.total,
          totalPage: data.pagination.totalPage,
          currentPage: data.pagination.page
        };
      }
    });
  }

  private formatDatasForTable(): void {
    this.contentTable = this.citizenList.map(Citizen =>
      this.formatCitizenForTable(Citizen))
  }

  private formatCitizenForTable(Citizen: ICitizen) {
    return {
      id: Citizen.id,
      lastName: Citizen.lastName,
      firstName: Citizen.firstName,
      email: Citizen.email,
      phone: Citizen.phone,
      type: this._translateSrv.translate(`citizen_${Citizen.type}-filter`),
      createdAt: Citizen.createdAt? moment(Citizen.createdAt).format("DD/MM/YYYY HH:mm") : "",
      isAmbassador: Citizen.isAmbassador
    };
  }

  public setRoles(data) {
    const rolesArray = [];
    if (data.isSuperAdmin) {
      rolesArray.push(this.rolesCitizen.SUPER_ADMIN);
    }
    if (data.isAdmin) {
      rolesArray.push(this.rolesCitizen.ADMIN);
    }
    if (data.isManager) {
      rolesArray.push(this.rolesCitizen.MANAGER);
    }
    if (data.isAgent) {
      rolesArray.push(this.rolesCitizen.AGENT);
    }
    return rolesArray;
  }

  public modalDeleteCitizen(citizen: ICitizen) {
    const deleteId = citizen.id;
    this._modalSrv.openModal(ModalConfirmComponent,
      {
        title: this._translateSrv.translate("citizen_deleteAreYouSure-txt"),
        type: MODAL_TYPE.CONFIRM,
        data: {
          // tslint:disable-next-line: no-unused-expression
          message: `${this._translateSrv.translate("citizen_deleteConfirm-txt")} <b>${citizen.firstName}</b>?`,
          actions: [this._translateSrv.translate("citizen_deleteDefinitively-txt")],
          formGroup: [
            {
              type: "radio",
              name: "deleteCitizen",
              id: "deleteCitizen",
              options: [
                { label: this._translateSrv.translate("citizen_deleteSoft-txt"), value: KIND.SOFT },
                { label: this._translateSrv.translate("citizen_deleteHard-txt"), value: KIND.HARD }
              ],
            }
          ]
        },
        params: { deleteId },
        confirmCallback: this.deleteCitizen
      }
    );
  }

  public deleteCitizen = (params: { deleteId: number, formGroupValue: any }) => {
    this._citizenQueriesSrv.deleteCitizen(+ params.deleteId, params.formGroupValue.deleteCitizen).subscribe(result => {
      const { citizenPagination } = this._citizenQueriesSrv;
      if (citizenPagination.page > 1 && this.contentTable.length === 1 && citizenPagination.page === this.paginationFilter.totalPage) {
        this._citizenQueriesSrv.citizenPagination = {
          ...this._citizenQueriesSrv.citizenPagination,
          page: this._citizenQueriesSrv.citizenPagination.page - 1
        };
        this.listCitizens();
      }
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate("citizen_deleteCitizenSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
    }, error => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("citizen_deleteCitizenFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  public action(event: ITableActionData) {
    switch (event.action) {
      // case EnumTableAction.EDIT:
      //   this.loadCitizenData(event.content.id);
      //   break;
      case EnumTableAction.DELETE:
        this.modalDeleteCitizen(event.content);
        break;
      case EnumTableAction.FILTER:
        this.processFilters(event.content);
        break;
      case EnumTableAction.SORT:
        this.sort(event.content);
        break;
    }
  }

  private sort(sortObject: any) {
    let { order } = this._citizenQueriesSrv.citizenPagination;

    if (!order) {
      order = {};
      this.headersTable.forEach(header => {
        if (header.sortable && header.key !== "Action") { order[header.key] = null; }
      });
    }
    Object.keys(order).forEach(key => {
      if (key === sortObject.key) {
        order[key] = order[key] === "ASC" ? "DESC" : "ASC";
      } else {
        order[key] = null;
      }
    });
    this._citizenQueriesSrv.citizenPagination = {
      ...this._citizenQueriesSrv.citizenPagination,
      order
    };
    this.listCitizens();
  }

  // public loadCitizenData(citizenId: number) {
  //   this._citizenQueriesSrv.getCitizen(citizenId).subscribe(result => {
  //     const resultData: any = result.data;
  //     if (resultData && resultData.citizen) {
  //       this.addCitizen(resultData.citizen, false);
  //     }
  //   }, error => {
  //     console.log("ERROR LOADING USER DATA", {error});
  //   });
  // }

  public setPagination(currentPage) {
    this._citizenQueriesSrv.citizenPagination = {
      ...this._citizenQueriesSrv.citizenPagination,
      page: currentPage
    };
    this.listCitizens();
  }

  public countItemsChanged(nbItemsToDisplay: number) {
    this._citizenQueriesSrv.citizenPagination = {
      ...this._citizenQueriesSrv.citizenPagination,
      limit: nbItemsToDisplay,
      page: 1
    };
    this.listCitizens();
  }

  private processFilters(filterInputValues: any): void {
    const appliedFilters = {};
    Object.keys(filterInputValues).forEach(key => {
      if (this.filtersMapping.get(key)) {
        if (this.filtersMapping.get(key) == "createdAt" && filterInputValues[key] && (typeof filterInputValues[key] === 'string' || filterInputValues[key] instanceof String) ) {
          appliedFilters[this.filtersMapping.get(key)] = null;
        } else {
          appliedFilters[this.filtersMapping.get(key)] = filterInputValues[key];
        }
      }
    });
    this._citizenQueriesSrv.appliedFilters = appliedFilters;
    this._citizenQueriesSrv.resetPagination();
    this.listCitizens();
  }

  public async initFilterOptions(): Promise<void> {
    const iTableFilters = this.filterOptions;
    for (const filter of iTableFilters) {
      switch (filter.type) {
        case EnumTableDataType.select:
          filter.options = await this.getFilterSelectOptions(filter.key);
          filter.options.unshift({
            value: null,
            label: "citizen-All-filter"
          });
          break;
      }
    }
  }

  private async getFilterSelectOptions(fieldName: string): Promise<ISelectOption[]> {
    switch (fieldName) {
      case "type":
        return await Object.keys(CITIZEN_TYPE).map(type => {
          return {
            value: type,
            label: this._translateSrv.translate(`citizen_${type}-filter`)
          };
        });

      case "isAmbassador":
        return await Object.keys(CITIZEN_IS_AMBASSADOR).map((isAmbassador: any)  => {
          return {
            value:JSON.parse(isAmbassador),
            label: this._translateSrv.translate(`citizen_isAmbassador_${isAmbassador}-filter`)
          };
        })
      // case "lang":
      //   return await Object.keys(LANGS).map(element => {
      //     return {label: `citizen-lang${element}-filter`, value: LANGS[element]};
      // });
      default:
        return [];
    }
  }

  public generateExcel() {
    this.loadingExport = true;
    const headers = this.headersTable.filter(header => header.key != "Action").map((header) => {
      return {
        key: header.key,
        translate: header.translate
      }
    });
    this._citizenQueriesSrv.getCitizensXls(headers).subscribe(result => {
      const exportXls = result.data['citizensXls'];
      this._xlsSrv.downloadXls(exportXls);
      this.loadingExport = false;
    }, error => {
      console.log("ERROR GETTING USER XLS LIST", { error });
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("citizen_generateExcelFail-txt"),
        STATUS_ALERTS.DANGER,
        0);
    });
  }

}
