import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { TranslateService } from "src/app/facades/services/translate.service";
import { EnumLoginForms, ExternalService } from "src/app/facades/services/external/external.service";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { AuthService } from "src/lib/auth/auth.service";
import { LOGIN_ERRORS_MAP } from "../facades/login-errors-map";
import { LoginQueriesService } from "src/app/facades/queries/login/login-queries.service";


@Component({
    selector: "app-login-form-external",
    templateUrl: "./login-form-external.component.html",
    styleUrls: ["./login-form-external.component.css"]
})
export class LoginFormExternalComponent implements OnInit {

    public formGroup: FormGroup;
    public errorMessage: string;
    public _EnumLoginForms = EnumLoginForms;
    public inputErrorsLabel: Array<IBoxInfo> | Object = [];
    public inputErrorsLabelMap: Map<string, string> = new Map<string, string>([
        ["username", this._translateSrv.translate("login_email-input")],
        ["password", this._translateSrv.translate("login_password-input")],
    ]);
    public disableContinueButton: boolean = false;

    constructor(
        private _fb: FormBuilder,
        private _authService: AuthService,
        private _translateSrv: TranslateService,
        private _errorMessageSrv: ErrorMessageService,
        public externalSrv: ExternalService,
        public _loginSrv: LoginQueriesService
    ) { }

    ngOnInit(): void {
        this.initFormGroup();
    }


    private initFormGroup() {
        this.formGroup = this._fb.group({
            username: [this.externalSrv.wepUserEmail? this.externalSrv.wepUserEmail : null, Validators.required],
            password: [null, Validators.required]
        });
    }

    public login() {
        if (!this.disableContinueButton) {
            this.disableContinueButton = true;
            if (this.formGroup.valid) {
                this.inputErrorsLabel = [];
                this._authService.loginExternal(this.formGroup.get("username").value, this.formGroup.get("password").value).subscribe((result: any) => {
                    if (result.Error && result.Error[0]) {
                        const code = result.Error[0].code;
                        this.getErrors(code);
                    } else {
                        const wepAuthenticate = result && result.wepAuthenticate? result.wepAuthenticate : null;
                        const userToken = wepAuthenticate? wepAuthenticate.userToken : null;
                        if(userToken){
                            this._loginSrv.sendUserTokenToExternal(this.externalSrv.wepUserEmail, userToken).subscribe(res => {
                                if (res.errors && res.errors[0]) {
                                    const code = (<any>res.errors[0]).code;
                                    this.getErrors(code);
                                    this.disableContinueButton = false;
                                } else {
                                    window.open(this.externalSrv.wepUrl, '_self')
                                }
                            }, err => {
                                if (err.graphQLErrors && err.graphQLErrors[0]) {
                                    const code = err.graphQLErrors[0].code;
                                    this.getErrors(code);
                                }
                                this.disableContinueButton = false;
                            })
                        } else {
                            this.getErrors(40102);
                            this.disableContinueButton = false;
                        }
                    }
                }, error => {
                    if (error.graphQLErrors && error.graphQLErrors[0]) {
                        const code = error.graphQLErrors[0].code;
                        this.getErrors(code);
                    }
                    this.disableContinueButton = false;
                });
            } else {
                this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.formGroup, this.inputErrorsLabelMap, true);
                this.markFormGroupTouched(this.formGroup);
                this.disableContinueButton = false;
            }
        }
    }

    public getErrors(errorCode) {
        if (errorCode) {
            const errorKey = LOGIN_ERRORS_MAP.get(errorCode);
            if (errorKey) {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate(errorKey)}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            } else {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate("errorOccured-error")}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            }
        } else {
            (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                message: `${this._translateSrv.translate("errorOccured-error")}`,
                type: BOX_INFO_TYPE.DANGER
            });
        }
    }

    public goToForgottenPassword(event: Event): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.externalSrv.goTo(EnumLoginForms.FORGOTTEN_PASSWORD);
    }

    /**
     * @description Get the page to navigate to the FAQ page
     * @author Quentin Wolfs
     * @returns {string}
     * @memberof LoginFormComponent
     */
    // public goToFaq(): string {
    //     return "/" + this._routingService.getRoute(PAGE_REFERENCE.FAQ, this._translateSrv.activeLanguage);
    // }

    public markFormGroupTouched = (formGroup: FormGroup) => {
        (Object as any).values(formGroup.controls).forEach(control => {
            (control as FormControl).updateValueAndValidity();
            control.markAsTouched();
            if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
        });
    };
}
