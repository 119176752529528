import gql from "graphql-tag";

const paginationExternal: string = `
pagination {
  total
  page
  hasNext
  limit
  totalPage
  hasPrevious
}
`;

export const GET_LIST_EXTERNALS = gql`
query getListExternals {
    externals(pagination: {limit: 1000, page: 1}){
      id
      name
      email
      deletedAt
    }
    ${paginationExternal}
}`;




