import { Injectable } from "@angular/core"
import { Apollo } from "apollo-angular";
import {
  GET_AN_ORGANISATION_ARCHIVING,
  GET_ALL_ORGANISATIONS_ARCHIVING,
  GET_ALL_ORGA_ARCHIVES,
  GET_BEWAPP,
  SET_MANUAL_ARCHIVING,
  SET_GENERAL_CONFIG,
  SET_ORGA_CONFIG
} from "./archiving.queries";
import { ARCHIVING } from "../../enum/archiveConfig-type.enum";
import { IIncidentColumnFilter } from "../../interfaces/incident.interface";


@Injectable({
  providedIn: "root"
})
export class OrgaArchivingQueriesService {

  constructor(private _apollo: Apollo) { }

  
  public getAllOrgaArchives() {
    return this._apollo.query({
      query: GET_ALL_ORGA_ARCHIVES,
      fetchPolicy: "no-cache"
    });
  }

  public getBeWapp() {
    return this._apollo.query({
      query: GET_BEWAPP,
      fetchPolicy: "no-cache"
    });
  }

  public getAllOrga(isSuperAdmin: boolean = false) {
    const query = isSuperAdmin? GET_ALL_ORGANISATIONS_ARCHIVING : GET_AN_ORGANISATION_ARCHIVING;
    return this._apollo.query({
      query,      
      fetchPolicy: "no-cache"
    });
  }

  public manualArchiving(orgaId: number, date: Date) {
    return this._apollo.mutate({
      mutation: SET_MANUAL_ARCHIVING,
      variables: {orgaId, date},
      fetchPolicy: "no-cache"
    });
  }

  public configOrgaArchiving(orgaId: number, time: number, isGeneralConfig: boolean = false) {
    const mutation = !isGeneralConfig? SET_ORGA_CONFIG : SET_GENERAL_CONFIG;
    return this._apollo.mutate({
      mutation,
      variables: { orgaId, time},
      fetchPolicy: "no-cache"
    });
  }

}