import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { RouterModule } from "@angular/router";
import { PipeModule } from 'src/app/facades/pipes/pipe.module';



@NgModule({
  declarations: [SidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    PipeModule
  ],
  exports: [
    SidebarComponent
  ]
})
export class MenuModule { }
