import { Component, Output, EventEmitter, Renderer2 } from "@angular/core";
import { AInputs } from "../../facades/inputs";
import { ErrorMessageService } from "../../facades/services/error-message.service";

@Component({
  selector: "app-password-input",
  templateUrl: "./password-input.component.html",
  styleUrls: ["./password-input.component.scss"]
})
export class PasswordInputComponent extends AInputs {

  @Output() public enter: EventEmitter<void>;

  public constructor(protected renderer: Renderer2,
                    protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
    this.enter = new EventEmitter<void>();
  }

  public keyUp(event: any) {
    if (event.keyCode === 13) {
      this.enter.emit();
    }
  }

}
