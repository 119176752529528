// tslint:disable: no-inferrable-types
// tslint:disable: variable-name
import { Component, OnChanges, Input } from "@angular/core";
import { TYPE_BUTTON } from "../../type-button.enum";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"]
})
export class ButtonComponent implements OnChanges {

  @Input() public disabled: boolean;
  // @Input() public noMargin: boolean = false;
  @Input() public full: boolean;
  @Input() public size: string;
  @Input() public icon: string;
  @Input() public action: TYPE_BUTTON = null;
  public _generatedClass: string;
  public buttonType = TYPE_BUTTON;
  constructor() { }

  ngOnChanges() {
    this._generatedClass = "";
    this._generatedClass += this.size ? " button__size_" + this.size : "";
    this.defineButtonType(this.action);
  }

  defineButtonType(type: TYPE_BUTTON) {
    if (type != null) {
      switch (type) {
        case this.buttonType.CREATE:
          this._generatedClass += " button__actions_create";
          break;
        case this.buttonType.CANCEL:
          this._generatedClass +=  " button__actions_cancel";

          break;
        case this.buttonType.CONTINUE:
          this._generatedClass += " button__actions_continue";

          break;
        case this.buttonType.DELETE:
          this._generatedClass +=  " button__actions_delete";

          break;
        case this.buttonType.EDIT:
          this._generatedClass +=  " button__actions_edit";

          break;
        case this.buttonType.VALID:
          this._generatedClass +=  " button__actions_valid";

          break;
        default:
          break;
      }
    }
  }

}

