import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { categoryHeaderInputDefaultHistory, headerInputDefaultHistory } from 'src/app/facades/configs/history-list-filters';
import { CATEGORY_HISTORY_STATE_TYPES } from 'src/app/facades/enum/category-history-state-types.enum';
import { EnumHistory } from 'src/app/facades/enum/history.enum';
import { STAGE_TYPES } from 'src/app/facades/enum/stage-types.enum';
import { STATUS_ALERTS } from 'src/app/facades/enum/status.enum';
import { IHeaderOptions } from 'src/app/facades/interfaces/header.interface';
import { CategoryQueriesService } from 'src/app/facades/queries/categorie/category-queries.service';
import { HistoryQueriesService } from 'src/app/facades/queries/history/history.queries.service';
import { OrganisationQueriesService } from 'src/app/facades/queries/organisation/organisation-queries.service';
import { SnackService } from 'src/app/facades/services/snackbar/snackbar.service';
import { TranslateService } from 'src/app/facades/services/translate.service';
import { XlsService } from 'src/app/facades/services/xls/xls.service';
import { IFilterBarPagination } from 'src/app/presentationnal/bar/facades/interfaces/filter-bar.interface';
import { ISelectOption } from 'src/app/presentationnal/input/facades/interfaces/selectOption.interface';
import { EnumTableAction, EnumTableDataType, ITableActionData, ITableFilter } from 'src/app/presentationnal/table/facades/interfaces/table.interface';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  private _listHistorySub: Subscription;
  public contentTable: any = [];
  public optionsHeader: IHeaderOptions = {
    title: "history_pageTitle-txt",
  };
  public headersTableOrganisation = [
    { key: "organisation", type: "text", translate: "history_organisation-th", sortable: true },
    { key: "changeDate", type: "text", translate: "history_updateAt-th", sortable: true },
    { key: "fromStage", type: "text", translate: "history_fromStage-th", sortable: true },
    { key: "toStage", type: "text", translate: "history_toStage-th", sortable: true },
    { key: "user", type: "text", translate: "history_user-th", sortable: true },
  ];

  public headersTableCategories = [
    { key: "organisation", type: "text", translate: "history_organisation-th", sortable: true },
    { key: "changeDate", type: "text", translate: "history_updateAt-th", sortable: true },
    { key: "oldState", type: "text", translate: "history_oldState-th", sortable: true },
    { key: "newState", type: "text", translate: "history_newState-th", sortable: true },
    { key: "category", type: "text", translate: "history_category-th", sortable: true },
    { key: "categoryDetail", type: "text", translate: "history_categoryDetail-th", sortable: true },
    { key: "user", type: "text", translate: "history_user-th", sortable: true },
  ];

  public headersTable = [];
  public paginationFilter: IFilterBarPagination = {
    label: "history_historyOnX-txt",
    listNumber: null,
    listTotalCounter: null,
    totalPage: null,
    currentPage: 1
  };
  public filterOptions: ITableFilter[] = [];
  public justAfterChange: boolean = false;
  public filtersMapping: Map<string, string> = new Map([
    ["organisation", "organisation"],
    ["changeDate", "changeDate"],
    ["fromStage", "fromStage"],
    ["toStage", "toStage"],
    ["user", "user"]
  ]);
  public categoryFiltersMapping: Map<string, string> = new Map([
    ["organisation", "organisation"],
    ["changeDate", "changeDate"],
    ["category", "category"],
    ["categoryDetail", "categoryDetail"],
    ["oldState", "oldState"],
    ["newState", "newState"],
    ["user", "user"]
  ]);
  public KeySelectOptions = [
    { "label": this._translateSrv.translate("orga_phase_history-options"), "value":EnumHistory.CHANGE_PHASE },
    { "label":this._translateSrv.translate("categories_activation_history-options"), "value":EnumHistory.CHANGE_CATEGORY }
  ];
  public selectFormGroup: FormGroup;
  public historyType: EnumHistory = EnumHistory.CHANGE_PHASE;
  loadingExport = false

  constructor(
    private _historyQueriesSrv: HistoryQueriesService,
    private _orgaSrv: OrganisationQueriesService,
    private _translateSrv: TranslateService,
    private _snackBar: SnackService,
    private _xlsSrv: XlsService,
    private _categoryQueriesSrv: CategoryQueriesService,
    private _fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.selectFormGroup = this._fb.group({
      historyKey: EnumHistory.CHANGE_PHASE,
    });

    this.headersTable = this.headersTableOrganisation;
    this.filterOptions = headerInputDefaultHistory;

    this.selectFormGroup.get("historyKey").valueChanges.subscribe(key => {
      this.historyType = key;
      this.headersTable = key == EnumHistory.CHANGE_PHASE? this.headersTableOrganisation : this.headersTableCategories;
      this.filterOptions = key == EnumHistory.CHANGE_PHASE? headerInputDefaultHistory : categoryHeaderInputDefaultHistory;
      this.justAfterChange = true;
      this._historyQueriesSrv.resetPagination();
      this.initFilterOptions();
      this.listHistory();
    });

    const appliedFilters = {};
    const categoryAppliedFilters = {};

    this.filtersMapping.forEach(value => {
      appliedFilters[value] = null;
    });

    this.categoryFiltersMapping.forEach(value => {
      categoryAppliedFilters[value] = null;
    })

    this.paginationFilter.listNumber = this._historyQueriesSrv.historyPagination.limit;
    this.initFilterOptions();
    this.listHistory();

  }
  ngOnDestroy(): void {
    this._listHistorySub.unsubscribe();
  }

  public listHistory() {
    const isChangeOrgaHistory = this.historyType == EnumHistory.CHANGE_PHASE;
    const request = isChangeOrgaHistory ?
      this._historyQueriesSrv.listOrganisationPhaseHistory().valueChanges :
      this._historyQueriesSrv.listCategoryStateHistory().valueChanges;

    if (this._listHistorySub) { this._listHistorySub.unsubscribe(); }
    this._listHistorySub = request.subscribe(result => {
      const key = isChangeOrgaHistory? "organisationsStageHistory" : "categoryStateHistory";
      const data: any = result.data;
      if (data && data[key]) {
        if(isChangeOrgaHistory) this.formatDatasForTable(data[key]);
        else this.formatDatasForCategoryHistoryTable(data[key]);
      }

      if (data && data.pagination) {
        this.paginationFilter = {
          ...this.paginationFilter,
          listTotalCounter: data.pagination.total,
          totalPage: data.pagination.totalPage,
          currentPage: data.pagination.page
        };
      }
    }, error => {
      console.log("ERROR GETTING HISTORY LIST", { error });
    });
  }

  formatDatasForCategoryHistoryTable(histories: any[]): void {
    this.contentTable = histories.map(data =>
      this.formatHistoryForCategoryTable(data)
    );
  }

  private formatDatasForTable(histories: any[]): void {
    this.contentTable = histories.map(history =>
      this.formatHistoryForTable(history)
    )
  }

  private formatHistoryForTable(item: any) {
    return {
      ...item,
        organisation: item.organisation ? item.organisation.name : " - ",
        changeDate: item.changeDate ? moment(item.changeDate).format("DD/MM/YYYY HH:mm") : " - ",
        fromStage: item.fromStage? this._translateSrv.translate(`organisation_stage_${item.fromStage.toLowerCase()}-value`) : " - ",
        toStage: item.toStage? this._translateSrv.translate(`organisation_stage_${item.toStage.toLowerCase()}-value`) : " - ",
        user: item.user? item.user.lastName+" "+item.user.firstName : " - ",
    };
  }

  private formatHistoryForCategoryTable(item: any) {
    return {
      ...item,
        organisation: item.organisation ? item.organisation.name : " - ",
        category: item.category? item.category.name : " - ",
        categoryDetail: item.categoryDetail? item.categoryDetail.name : " - ",
        changeDate: item.changeDate ? moment(item.changeDate).format("DD/MM/YYYY HH:mm") : " - ",
        oldState: item.oldState? this._translateSrv.translate(`category_history_state_${item.oldState.toLowerCase()}-value`) : " - ",
        newState: item.newState? this._translateSrv.translate(`category_history_state_${item.newState.toLowerCase()}-value`) : " - ",
        user: item.user? item.user.lastName+" "+item.user.firstName : " - ",
    };
  }

  public setPagination(currentPage) {
    this._historyQueriesSrv.historyPagination = {
      ...this._historyQueriesSrv.historyPagination,
      page: currentPage
    };
    this.listHistory();
  }

  public action(event: ITableActionData) {
    switch (event.action) {
      case EnumTableAction.FILTER:
        this.processFilters(event.content);
        break;
      case EnumTableAction.SORT:
        this.sort(event.content);
        break;
    }
  }

  private processFilters(filterInputValues: any): void {
    if(!this.justAfterChange) {
      const isChangeOrgaHistory = this.historyType == EnumHistory.CHANGE_PHASE;
      const filterKey = isChangeOrgaHistory? "filtersMapping" : "categoryFiltersMapping";
      const appliedFilters = {};
      Object.keys(filterInputValues).forEach(key => {
        if (this.filtersMapping.get(key)) {
          if (this.filtersMapping.get(key) == "changeDate" && filterInputValues[key] && (typeof filterInputValues[key] === 'string' || filterInputValues[key] instanceof String) ) {
            appliedFilters[this.filtersMapping.get(key)] = null;
          } else {
            appliedFilters[this.filtersMapping.get(key)] = filterInputValues[key];
          }
        }
      });

      if(isChangeOrgaHistory) this._historyQueriesSrv.appliedFilters = appliedFilters;
      else this._historyQueriesSrv.categoryStateAppliedFilters = appliedFilters;
    } else {
      this.justAfterChange = false;
    }

    this._historyQueriesSrv.resetPagination();
    this.listHistory();
  }

  private sort(sortObject: any) {
    let { order } = this._historyQueriesSrv.historyPagination;

    if (!order) {
      order = {};
      this.headersTable.forEach(header => {
        if (header.sortable && header.key !== "Action") { order[header.key] = null; }
      });
    }
    Object.keys(order).forEach(key => {
      if (key === sortObject.key) {
        order[key] = order[key] === "ASC" ? "DESC" : "ASC";
      } else {
        order[key] = null;
      }
    });
    this._historyQueriesSrv.historyPagination = {
      ...this._historyQueriesSrv.historyPagination,
      order
    };
    this.listHistory();
  }

  public async initFilterOptions(): Promise<void> {
    for (const filter of this.filterOptions) {
        switch (filter.type) {
            case EnumTableDataType.selectcheckbox:
            case EnumTableDataType.selectSearchMultiple:
                filter.options = await this.getFilterSelectOptions(filter.key);
                break;
        }
    }
  }

  private async getFilterSelectOptions(fieldName: string, filter: any = null): Promise<ISelectOption[]> {
    switch (fieldName) {
      case "organisation":
        return await this._orgaSrv.getOrganisationListQuery(filter)
          .toPromise()
          .then(res => {
            return res.data["organisations"].map(orga => ({ label: orga.name, value: orga.id }));
          })
          .catch(e => { throw e; });
      case "fromStage":
      case "toStage":
        return await Object.keys(STAGE_TYPES).map( type => {
          return {
            value: type,
            label: this._translateSrv.translate(`organisation_${type.toLowerCase()}-filter`)
          };
        });
      case "oldState":
      case "newState":
        return await Object.keys(CATEGORY_HISTORY_STATE_TYPES).map( type => {
          return {
            value: type,
            label: this._translateSrv.translate(`category_history_${type.toLowerCase()}-filter`)
          };
        })
      case "category":
          return await this._categoryQueriesSrv.getCategories(filter, this._translateSrv.activeLanguage)
              .toPromise()
              .then(res => res.data["categories"].map(c => ({ label: c.name, value: c.id })))
              .catch(e => { throw e; });
      case "categoryDetail":
          return await this._categoryQueriesSrv.getCategoryDetails(filter, this._translateSrv.activeLanguage)
              .toPromise()
              .then(res => res.data["categoryDetails"].map(c => ({ label: c.name, value: c.id })))
              .catch(e => { throw e; });
      default:
        return [];
    }
  }

  public countItemsChanged(nbItemsToDisplay: number) {
    this._historyQueriesSrv.historyPagination = {
      ...this._historyQueriesSrv.historyPagination,
      limit: nbItemsToDisplay,
      page: 1
    };
    this.listHistory();
  }

  public generateExcel() {
    this.loadingExport = true;
    const tableHeaders = this.headersTable.filter(header => header.key != "Action").map(header => ({
      key: header.key,
      translate: header.translate
    }));
    const isChangeOrgaHistory = this.historyType == EnumHistory.CHANGE_PHASE;
    const request = isChangeOrgaHistory ?
      this._historyQueriesSrv.getOrganisationsStageHistoryXls(tableHeaders) :
      this._historyQueriesSrv.getCategoryStateHistoryXls(tableHeaders);
    request.subscribe(result => {
      const exportXls = result.data[isChangeOrgaHistory ? "organisationsStageHistoryXls" : "categoryStateHistoryXls"];
      this._xlsSrv.downloadXls(exportXls);
      this.loadingExport = false;
    }, error => {
      console.log("ERROR GETTING HISTORY XLS LIST", { error });
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("organisationPhaseHistory_generateExcelFail-txt"),
        STATUS_ALERTS.DANGER,
        0);
    });
  }

  public async search(event){
    const filterToChange = this.filterOptions.find(item => item.key == event.key);
    let filter = {
      [this.searchFieldMap.get(event.key)]: event.search && event.search != ""? event.search : null
    }

    if(filterToChange) filterToChange.options = await this.getFilterSelectOptions(event.key, filter);
  }

  public searchFieldMap = new Map([
    ["organisation", "name"],
    ["group", "name"],
    ["category", "categoryName"],
    ["categoryDetail", "categoryDetailName"]
  ])
}
