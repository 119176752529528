import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_CATEGORIES, GET_MAPPED_CATEGORIES, MUTATE_CATEGORY, DELETE_CATEGORY, ENABLE_CATEGORY, MUTATE_CATEGORY_DETAIL, DELETE_CATEGORY_DETAIL, ENABLE_CATEGORY_DETAIL, GET_CATEGORY_DETAILS, GET_CATEGORY_THEMES, MUTATE_CATEGORY_THEME, DELETE_CATEGORY_THEME } from "./categorie.queries";
import { ICategoryInput } from "../../interfaces/category.interface";
import { ICategoryDetailInput } from "../../interfaces/category-detail.interface";
import { ICategoryTheme, ICategoryThemeInput } from "../../interfaces/category-theme.interface";

@Injectable({
  providedIn: "root"
})
export class CategoryQueriesService {

  constructor(private _apollo: Apollo) { }

  public getCategoryThemes(usedLanguage: string = null) {
    return this._apollo.query({
      query: GET_CATEGORY_THEMES,
      variables: {
        usedLanguage
      },
      fetchPolicy: "no-cache"
    });
  }

  public watchCategoryThemes() {
    return this._apollo.watchQuery({
      query: GET_CATEGORY_THEMES,
    });
  }

  public mutateCategoryTheme(categoryTheme: ICategoryThemeInput) {
    return this._apollo.mutate({
      mutation: MUTATE_CATEGORY_THEME,
      variables: { categoryTheme },
      refetchQueries: [{
        query: GET_CATEGORY_THEMES
      }]
    });
  }

  public deleteCategoryTheme(themeId: number) {
    return this._apollo.mutate({
      mutation: DELETE_CATEGORY_THEME,
      variables: {
        id: themeId
      },
      refetchQueries: [{
        query: GET_CATEGORY_THEMES
      }]
    });
  }

  public getCategories(filter: any = null, usedLanguage: string = null) {
    return this._apollo.query({
      query: GET_CATEGORIES,
      variables: {
        filter,
        usedLanguage
      },
      fetchPolicy: "cache-first"
    });
  }

  public getMappedCategories() {
    return this._apollo.query({
      query: GET_MAPPED_CATEGORIES,
      fetchPolicy: "no-cache"
    });
  }

  public watchMappedCategories(includeDisabled: boolean = false) {
    return this._apollo.watchQuery({
      query: GET_MAPPED_CATEGORIES,
      variables: { includeDisabled },
      fetchPolicy: "no-cache",
    });
  }

  public mutateCategory(category: ICategoryInput) {
    return this._apollo.mutate({
      mutation: MUTATE_CATEGORY,
      variables: { category },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_MAPPED_CATEGORIES,
        variables: { includeDisabled: true }
      }]
    });
  }

  public deleteCategory(categoryId: number) {
    return this._apollo.mutate({
      mutation: DELETE_CATEGORY,
      variables: {
        id: categoryId
      },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_MAPPED_CATEGORIES,
        variables: { includeDisabled: true }
      }]
    });
  }

  public enableCategory(categoryId: number, enabled: boolean) {
    return this._apollo.mutate({
      mutation: ENABLE_CATEGORY,
      variables: {
        id: categoryId,
        enabled
      },
      refetchQueries: [{
        query: GET_MAPPED_CATEGORIES,
        variables: { includeDisabled: true }
      }],
      fetchPolicy: "no-cache",
    });
  }

  public mutateCategoryDetail(categoryDetail: ICategoryDetailInput) {
    return this._apollo.mutate({
      mutation: MUTATE_CATEGORY_DETAIL,
      variables: { categoryDetail },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_MAPPED_CATEGORIES,
        variables: { includeDisabled: true }
      }]
    });
  }

  public deleteCategoryDetail(categoryDetailId: number) {
    return this._apollo.mutate({
      mutation: DELETE_CATEGORY_DETAIL,
      variables: {
        id: categoryDetailId
      },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_MAPPED_CATEGORIES,
        variables: { includeDisabled: true }
      }]
    });
  }

  public enableCategoryDetail(categoryDetailId: number, enabled: boolean) {
    return this._apollo.mutate({
      mutation: ENABLE_CATEGORY_DETAIL,
      variables: {
        id: categoryDetailId,
        enabled
      },
      refetchQueries: [{
        query: GET_MAPPED_CATEGORIES,
        variables: { includeDisabled: true }
      }],
      fetchPolicy: "no-cache",
    });
  }

  public getCategoryDetails(filter: any = null, usedLanguage: string = null) {
    return this._apollo.query({
      query: GET_CATEGORY_DETAILS,
      variables: {
        filter,
        usedLanguage,
        pagination: {
          limit: 0,
          page: 1
        }
      },
      fetchPolicy: "no-cache"
    });
  }

}
