import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { TranslateService } from 'src/app/facades/services/translate.service';
import { AInputs } from '../../facades/inputs';
import { ErrorMessageService } from '../../facades/services/error-message.service';

@Component({
  selector: 'app-switch-input',
  templateUrl: './switch-input.component.html',
  styleUrls: ['./switch-input.component.css']
})
export class SwitchInputComponent extends AInputs implements OnChanges {
  @Input() label: string;
  @Input() small = false;
  @Input() isCheckboxValid: boolean = true;
  @Input() isToggle: boolean = false;
  @Input() toggleDisabled: boolean = false;
  @Input() labels: any [] = [];
  public valueCheckbox: boolean;
  text: string;
  constructor(private _elRef: ElementRef, public renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService, private _translateSrv: TranslateService) {
    super(renderer, _errorMessageSrv);
    this.errorClass = "checkbox__error";
  }

  ngOnInit(){
    this.eventCheck(this.group.get(this.name).value);
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.isCheckboxValid) {
      if ( this.isCheckboxValid) {
        this.renderer.removeClass(this._elRef.nativeElement, this.errorClass);
      } else {
        this.renderer.addClass(this._elRef.nativeElement, this.errorClass);
      }
    }

  }

  /**
   * checkValue
   */
  public checkValue() {
    if (!this.disable) {
      this.group.get(this.name).patchValue( this.group.get(this.name).value ? false : true);
      this.group.get(this.name).markAsTouched();
    }
  }
  public eventCheck(event) {
    if(this.labels && this.labels.length > 0) {
      this.text = event ? this.labels.find(item => item.value).label : this.labels.find(item => !item.value).label
    } else {
      this.text = event ? this._translateSrv.translate("category_activated-switch-txt") : this._translateSrv.translate("category_desactived-switch-txt");
    }
  }
}
