import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_LIST_EXTERNALS } from "./external.queries";
import { IOrganisationInpdate, IFilterOrganisations } from "../../interfaces/organisation.interface";
import { IPagination } from "../../interfaces/pagination.interface";
import { IIncidentColumnFilter } from "../../interfaces/incident.interface";

@Injectable({
  providedIn: "root"
})
export class ExternalQueriesService {

  constructor(private _apollo: Apollo) { }

  public getListExternals() {
    return this._apollo.query({
      query: GET_LIST_EXTERNALS,
      fetchPolicy: "no-cache",
    });
  }

}
