// tslint:disable: no-inferrable-types
import { Component, OnInit, Inject, Injector, HostBinding, AfterContentChecked } from "@angular/core";
import { Portal, ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { MODAL_ACTION_TYPE } from "src/app/facades/enum/modal/modal-action-type.enum";
import { IModalStyle } from "src/app/facades/interfaces/modal-data.interface";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { TranslateService } from "src/app/facades/services/translate.service";


@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.css"]
})
export class ModalComponent implements AfterContentChecked {
  @HostBinding("style.display") get getDisplay(): string { return "none" ; }

  public MODAL_TYPE = MODAL_TYPE;
  public MODAL_ACTION_TYPE = MODAL_ACTION_TYPE;

  public modalStyle: string = "";
  public cancelButtonName: string;
  public cancelButtonIcon: string;
  public confirmButtonName: string;
  public confirmButtonIcon: string;
  public deleteButtonName: string;
  public deleteButtonIcon: string;
  public cancelButtonType: TYPE_BUTTON = TYPE_BUTTON.CANCEL;
  public confirmButtonType: TYPE_BUTTON = TYPE_BUTTON.VALID;
  public deleteButtonType: TYPE_BUTTON = TYPE_BUTTON.DELETE;
  public cancelButtonIsLink: boolean = true;
  public displayCancelButton: boolean = true;
  public displayConfirmButton: boolean = true;
  public modalData: any;
  public buttonType = TYPE_BUTTON;

  constructor(private _modalSrv: ModalService, private _translateSrv: TranslateService) {
    this.cancelButtonName = this._translateSrv.translate("general_cancel-button");
    this.cancelButtonIcon = "fas fa-times";
    this.confirmButtonName = this._translateSrv.translate("general_save-button");
    this.confirmButtonIcon = "fas fa-save";
    this.deleteButtonName = this._translateSrv.translate("general_delete-button");
    this.deleteButtonIcon = "fas fa-trash-alt";
  }

  ngAfterContentChecked(): void {
    if (this._modalSrv && this._modalSrv.getModalData) {
      this.modalData = this._modalSrv.getModalData;
      const customData = this._modalSrv.getModalData.customData;
      this.cancelButtonName = customData && customData.cancelButtonName ?
        customData.cancelButtonName : this._translateSrv.translate("general_cancel-button");
      this.confirmButtonName = customData && customData.confirmButtonName ?
        customData.confirmButtonName : this._translateSrv.translate("general_save-button");
      this.deleteButtonName = customData && customData.deleteButtonName ?
        customData.deleteButtonName : this._translateSrv.translate("general_delete-button");
      this.cancelButtonType = customData && customData.cancelButtonType ? customData.cancelButtonType : TYPE_BUTTON.CANCEL;
      this.confirmButtonType = customData && customData.confirmButtonType ? customData.confirmButtonType : TYPE_BUTTON.VALID;
      this.deleteButtonType = customData && customData.deleteButtonType ? customData.deleteButtonType : TYPE_BUTTON.DELETE;
      this.cancelButtonIsLink = customData && customData.cancelButtonIsLink !== undefined ? customData.cancelButtonIsLink : true;
      this.displayCancelButton = customData && customData.displayCancelButton !== undefined ? customData.displayCancelButton : true;
      this.displayConfirmButton = customData && customData.displayConfirmButton !== undefined ? customData.displayConfirmButton : true;
      this.cancelButtonIcon = customData && customData.cancelButtonIcon !== undefined ? customData.cancelButtonIcon : "fas fa-times";
      this.confirmButtonIcon = customData && customData.confirmButtonIcon !== undefined ? customData.confirmButtonIcon : "fas fa-save";
      this.deleteButtonIcon = customData && customData.deleteButtonIcon !== undefined ? customData.deleteButtonIcon : "fas fa-trash-alt";

      const modalStyle: IModalStyle = this._modalSrv.getModalData.modalStyle;
      this.modalStyle = modalStyle && modalStyle.modalWidth ? `modal_width_${modalStyle.modalWidth}` : "";
    }
  }

  public buttonClick(actionType: number, isCloseCrossButton: boolean = false, deleteCancelEvent: boolean = false): void {
    this._modalSrv.buttonClick(actionType, isCloseCrossButton, deleteCancelEvent);
  }
}
