import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/lib/auth/auth.service";
import { Router } from "@angular/router";
import { ROLES, IRolesAccepted } from "src/app/facades/enum/roles.enum";
import { TranslateService } from "src/app/facades/services/translate.service";

export interface INav {
  title?: string;
  nav: INavOptions[];
}
export interface INavOptions {
  url: string;
  label: string;
  rolesAccepted: IRolesAccepted;
  disable: boolean;
  icon: string;
}
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  public roles = ROLES;
  public rolesCurrent: any;
  public adminMenuOptions: IRolesAccepted = {isSuperAdmin : true, isAdmin : true, isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false};
  public userLinks: INav = {
    title: " ",
    nav: [
      {url: "/dashboard", label: "menu_dashboard-link", rolesAccepted : {isSuperAdmin : true,  isAdmin : true,  isManager: true,  isAgent: true,  isAscertainingAgent: true,  isReadonlyAdmin: true}, disable : false, icon : "fas fa-tachometer-alt"},
      {url: "/incident",  label: "menu_incidents-link", rolesAccepted : {isSuperAdmin : true,  isAdmin : true,  isManager: true,  isAgent: true,  isAscertainingAgent: true,  isReadonlyAdmin: true }, disable : false, icon : "fas fa-exclamation-circle"},
      {url: "/planning",  label: "menu_planning-link",  rolesAccepted : {isSuperAdmin : true,  isAdmin : true,  isManager: true,  isAgent: true,  isAscertainingAgent: true,  isReadonlyAdmin: false}, disable : false, icon : "fas fa-calendar"},
      {url: "/user",      label: "menu_users-link",     rolesAccepted : {isSuperAdmin : true,  isAdmin : true,  isManager: true,  isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable : false, icon : "fas fa-user"},
      {url: "/citizen",   label: "menu_citizens-link",  rolesAccepted : {isSuperAdmin : true,  isAdmin : false, isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable : false, icon : "fas fa-user-circle"},
      {url: "/group",     label: "menu_groups-link",    rolesAccepted : {isSuperAdmin : true,  isAdmin : true,  isManager: true,  isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable : false, icon : "fas fa-users"},
      { url: "/reporting", label: "menu_reports-link", rolesAccepted: { isSuperAdmin: true, isAdmin: true, isManager: true, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false }, disable: false, icon: "far fa-chart-bar" },
    ]
  };

  public adminLinks: INav;
  public profilLinks: INav;
  public username: string = localStorage.getItem("username");
  public availableLanguages: Array<string>;
  public activeLanguage: string;

  constructor(public authSrv: AuthService,
              private _translateSrv: TranslateService,
              private router: Router) { }

  ngOnInit() {
    this.getRolesCurrent();
    this.activeLanguage = this._translateSrv.activeLanguage;
    this.availableLanguages = this._translateSrv.availableLanguages;
    this.adminLinks = {
      title: "menu_adminOptions-txt",
      nav : [
        {url: "/dispatching",  label: "menu_dispatching-link",   rolesAccepted: {isSuperAdmin: true,  isAdmin: true,  isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable: false, icon: "fas fa-sitemap"},
        {url: "/organisation", label: "menu_organisations-link", rolesAccepted: {isSuperAdmin: true,  isAdmin: true,  isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable: false, icon: "fas fa-landmark"},
        { url: "/themes", label: "menu_themes-link", rolesAccepted: { isSuperAdmin: true, isAdmin: false, isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false }, disable: false, icon: "fas fa-layer-group" },
        {url: "/categories",   label: "menu_categories-link",    rolesAccepted: {isSuperAdmin: true,  isAdmin: true,  isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable: false, icon: "fas fa-shapes"},
        {url: "/translations", label: "menu_translation-link",   rolesAccepted: {isSuperAdmin: true,  isAdmin: false, isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable: false, icon: "fas fa-globe"},
        {url: "/copywriting",  label: "menu_copywriting-link",   rolesAccepted: {isSuperAdmin: true,  isAdmin: false, isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable: false, icon: "fas fa-copyright"},
        {url: "/archiving",    label: "menu_archiving-link",     rolesAccepted: {isSuperAdmin: true,  isAdmin: true,  isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable: false, icon: "fas fa-archive"},
        {url: "/history",      label: "menu_history-link",       rolesAccepted: {isSuperAdmin: true,  isAdmin: false, isManager: false, isAgent: false, isAscertainingAgent: false, isReadonlyAdmin: false}, disable: false, icon: "fas fa-history"}
      ],
    };
    this.profilLinks = {
      title: " ",
      nav: [
        {url: "/shop",         label: "menu_account-link",       rolesAccepted: {isSuperAdmin: true,  isAdmin: true,  isManager: true,  isAgent: true,  isAscertainingAgent: true,  isReadonlyAdmin: true }, disable: false, icon: "fas fa-user-tag"}
      ]
    };
  }
  disconnect() {
    this.authSrv.logout();
  }

  public getRolesCurrent() {
    this.rolesCurrent = this.authSrv.getRolesCurrent();
  }

  /**
   * name
   */
  public checkRoles(rolesNeed: IRolesAccepted ) {
    if (this.rolesCurrent.includes(this.roles.SUPER_ADMIN)) {
      return rolesNeed.isSuperAdmin;
    } else if (this.rolesCurrent.includes(this.roles.ADMIN)) {
      return rolesNeed.isAdmin;
    } else if (this.rolesCurrent.includes(this.roles.MANAGER)) {
      return rolesNeed.isManager;
    } else if (this.rolesCurrent.includes(this.roles.AGENT)) {
      return rolesNeed.isAgent;
    } else if (this.rolesCurrent.includes(this.roles.ASCERTAINING_AGENT)) {
      return rolesNeed.isAscertainingAgent;
    } else if (this.rolesCurrent.includes(this.roles.READONLY_ADMIN)) {
      return rolesNeed.isReadonlyAdmin;
    } else {
      return false;
    }
  }

  public setActiveLanguage(language: string) {
    this._translateSrv.activeLanguage = language;
    this.activeLanguage = this._translateSrv.activeLanguage;
  }
}
