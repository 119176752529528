import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { TranslateService } from "src/app/facades/services/translate.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { DispatchingQueriesService } from "src/app/facades/queries/dispatching/dispatching-queries.service";
import { IGeoZone, IGeoZoneInput, ILayer } from "src/app/facades/interfaces/dispatching.interface";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { AuthService } from "src/lib/auth/auth.service";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { IOrganisation } from "src/app/facades/interfaces/organisation.interface";
import { Subscription } from "rxjs";
import { ITableActionData, EnumTableAction } from "src/app/presentationnal/table/facades/interfaces/table.interface";
import { IQueryTaskDatas } from "src/app/presentationnal/maps/facades/interfaces/esri-map.interface";
import { ModalConfirmComponent } from "src/app/modal/component/modal-confirm/modal-confirm.component";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { Router, ActivatedRoute } from "@angular/router";
import { EnumUserRoles } from "src/app/facades/interfaces/user.interface";

@Component({
    selector: "app-dispatching-page",
    templateUrl: "./dispatching-page.component.html",
    styleUrls: ["./dispatching-page.component.css"]
})
export class DispatchingPage implements OnInit {
  private _dispatchingQuerySub: Subscription;
  public optionsHeader: IHeaderOptions;
  public TYPE_BUTTON_ENUM = TYPE_BUTTON;

    public layersDatas: {formGroup: FormGroup, options: ISelectOption[]};
    public associationFormGroup: FormGroup;
    public organisationsOptions: ISelectOption[] = [];
    private _layersList: ILayer[];
    public selectedLayer: ILayer;
    public selectedExistingGeoZone: IQueryTaskDatas[];
    public lastSelectedLayerZone: IGeoZoneInput;
    public filter: string;
    public organisations;
    public headerLayerTable = [
      { key : "organisation", type : "text", translate: "dispatching_organisationName-th"},
      { key : "polygone", type : "text", translate: "dispatching_polygonName-th"},
      { key : "objectId", type : "text", translate: "dispatching_polygonId-th"},
      { key : "Action", type: "action", translate: "dispatching_actionColumn-th" },
    ];
    public formatedLayerTableDatas: any[] = [];

    constructor(
                private _router: Router,
                private _translateSrv: TranslateService,
                private _authSrv: AuthService,
                private _fb: FormBuilder,
                private _snackBarSrv: SnackService,
                private _dispatchingQueriesSrv: DispatchingQueriesService,
                private _organisationQueriesSrv: OrganisationQueriesService,
                private _activatedRoute: ActivatedRoute,
                private _modalSrv: ModalService) {
                  if (!this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)) {
                    this._router.navigate(["categories"], { relativeTo: this._activatedRoute});
                  }
                  this.optionsHeader = {
                    title : "dispatching_pageTitle-txt",
                    buttons: [
                      {
                        name: this._translateSrv.translate("dispatching_toOrganisationBasedOnGeographicalZone-button"),
                        datas: {
                          link: "/dispatching"
                        },
                        disabled: !this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)
                      },
                      {
                        name: this._translateSrv.translate("dispatching_toGroupBasedOnCategories-button"),
                        datas: {
                          link: "/dispatching/categories"
                        },
                        disabled: !this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN)
                      },
                      {
                        name: this._translateSrv.translate("dispatching_toGroupBasedOnGeographicalZone-button"),
                        datas: {
                          link: "/dispatching/groupes"
                        },
                        disabled: !this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN)
                      },
                    ]
                  };
                }

  ngOnInit() {
      this.initForm();
      this.loadLayers();
      this.loadOrganisations();
  }

  public headerAction(datas: {link: string}) {
    this._router.navigateByUrl(datas.link);
  }

  private initForm() {
    this.associationFormGroup = this._fb.group({
      organisationId: [null, Validators.required],
      layerUrl: null,
      objectId: [null, Validators.required],
      polygonName: [null, Validators.required]
    });
  }

  private loadLayers() {
    this._dispatchingQueriesSrv.getLayers().subscribe( result => {
      const resultData: {layers: ILayer[]} = <any>result.data;
      if (resultData && resultData.layers) {
        this._layersList = resultData.layers;
        this.layersDatas = {
          formGroup: this._fb.group({
            selectedLayers: resultData.layers[0].id
          }),
          options: resultData.layers.map( layer => {
            return {
              value: layer.id,
              label: layer.name
            };
          })
        };
      }
      this.loadGeoZone();
      this.selectedLayer = this._layersList.find( layer => layer.id === this.layersDatas.formGroup.get("selectedLayers").value);
      this.layersDatas.formGroup.get("selectedLayers").valueChanges.subscribe( value => {
        this.selectedLayer = this._layersList.find( layer => layer.id === value);
        this.resetAssociationFormGroupData();
        this.loadGeoZone();
      });
    }, error => {
      console.log("ERROR LOADING LAYERS", {error});
    });
  }

  private loadOrganisations() {
    this._organisationQueriesSrv.getOrganisationListQuery().subscribe( result => {
      const resultData: {organisations: IOrganisation[]} = <any>result.data;
      if (resultData && resultData.organisations && resultData.organisations.length > 0) {
        this.organisationsOptions = resultData.organisations.filter(o => o.isActive).map( organisation => {
          return {
            value: organisation.id,
            label: organisation.name
          };
        });
        // this.associationFormGroup.get("organisationId").patchValue(resultData.organisations[0].id);
      }
    }, error => {
      console.log("ERROR LOADING ORGANISATION", {error});
    });
  }

  private loadGeoZone() {
    if (this._dispatchingQuerySub) {this._dispatchingQuerySub.unsubscribe(); }
    const layerId: number = this.layersDatas.formGroup.get("selectedLayers").value;
    this._dispatchingQuerySub = this._dispatchingQueriesSrv.geoZonesByLayerId(layerId).valueChanges.subscribe( result => {
      const resultData: {geoZonesByLayerId: IGeoZone[]} = <any> result.data;
      if (resultData && resultData.geoZonesByLayerId) {
        const geoZoneAlreadySelected: number[] = this.formatedLayerTableDatas.filter( geoZone => geoZone.selectedLine).map( geoZone => geoZone.id );
        const geoZones = resultData.geoZonesByLayerId.filter(geoZone => geoZone.organisations && geoZone.organisations.length > 0);
        // this.formatedLayerTableDatas;
        this.formatedLayerTableDatas = [];
        geoZones.forEach(geoZone => {
          geoZone.organisations.forEach(orga => {
            this.formatedLayerTableDatas.push(
              {
                id: geoZone.id,
                organisation: orga.name,
                organisationId: orga.id,
                polygone: geoZone.name,
                objectId: geoZone.objectId,
                geoZoneData: {...geoZone, organisationId: orga.id, organisation: orga.name},
                selectedLine: geoZoneAlreadySelected.includes(geoZone.id)
              }
            );
          });
        });

        // .map( geoZone => {
        //   return {
        //     id: geoZone.id,
        //     organisation: geoZone.organisation.name,
        //     polygone: geoZone.name,
        //     objectId: geoZone.objectId,
        //     geoZoneData: geoZone,
        //     selectedLine: geoZoneAlreadySelected.includes(geoZone.id)
        //   };
        // });
        this.getExistingGraphsSelected();
      }
    }, error => {
      console.log("ERROR LOADING DISPATCHING DATAS", {error});
    });
  }

  public layerListAction(event: ITableActionData) {
    if (event.action === EnumTableAction.EDIT) {
      const layer: any = this.formatedLayerTableDatas.find( data => data.id === event.content.id);
      layer.selectedLine = !layer.selectedLine;
      this.getExistingGraphsSelected();
    }
    if (event.action === EnumTableAction.DELETE) {
      this.beforeDeleteGeoZone(event.content.geoZoneData);
    }
  }

  private getExistingGraphsSelected() {
    const selectedGeoZone: IGeoZone[] = this.formatedLayerTableDatas.filter( layer => layer.selectedLine)
    .map( layer => layer.geoZoneData);
    const distinctLayer: Set<number> = new Set(selectedGeoZone.map(geoZone => geoZone.layerId));
    this.selectedExistingGeoZone = Array.from(distinctLayer).map( layerId => {
      const layerDatas: ILayer = selectedGeoZone.find( geoZone => geoZone.layerId === layerId).layer;
      return {
        url: `${layerDatas.url}/${layerDatas.mapServerId}`,
        objectIds: selectedGeoZone.filter( geoZone => geoZone.layerId === layerId).map( geoZone => geoZone.objectId)
      };
    });
  }

  public setDispatchingGeoZoneValues(geoZones: IGeoZoneInput[] | any[]) {
    if (geoZones.length !== 0) {
      if (geoZones.length === 1) {
        this.associationFormGroup.get("layerUrl").patchValue(geoZones[0].layerUrl);
        this.associationFormGroup.get("objectId").patchValue(geoZones[0].objectId);
        this.associationFormGroup.get("polygonName").patchValue(geoZones[0].polygonName);
        this.lastSelectedLayerZone = geoZones[0];
      } else {
        // TODO IF WE FOUND THE CASE WITH MULTI GEO ZONES
        console.log("DO MULTI GRAPH SAVE");
      }
    } else {
      this.resetAssociationFormGroupData();
    }
  }

  private resetAssociationFormGroupData() {
    this.associationFormGroup.get("layerUrl").patchValue(null);
    this.associationFormGroup.get("objectId").patchValue(null);
    this.associationFormGroup.get("polygonName").patchValue(null);
    this.lastSelectedLayerZone = null;
  }

  public saveGeoZone() {
    const formValue: any = {...this.associationFormGroup.getRawValue()};
    delete formValue.polygonName;
    const geoZoneDatas: IGeoZoneInput = formValue;
    if (Object.keys(geoZoneDatas).every(key => !!geoZoneDatas[key])) {
      this._dispatchingQueriesSrv.setGeoZone(geoZoneDatas).subscribe( result => {
        this.resetAssociationFormGroupData();
        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("dispatching_setGeoZoneMessageSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, error => {
        console.log("ERROR SAVING GEOZONES", {error});
        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("dispatching_setGeoZoneMessageError-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      });
    } else {
      console.log("ERROR FORM");
    }
  }

  public beforeDeleteGeoZone(geoZone: any) {
    this._modalSrv.openModal(ModalConfirmComponent,
      {
        title: this._translateSrv.translate("dispatching_deleteAreYouSure-txt"),
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `${this._translateSrv.translate("dispatching_deleteConfirmFirstPart-txt")} <b>${geoZone.name}</b> ${this._translateSrv.translate("dispatching_deleteConfirmSecondPart-txt")} <b>${geoZone.organisation}</b>?`,
          // actions: [
          //   "A DEFINIR",
          // ],
        },
        params: { geoZoneId: geoZone.id, organisationId: geoZone.organisationId },
        confirmCallback: this.deleteGeoZone
      }
    );
  }

  private deleteGeoZone = (params: any) => {
    this._dispatchingQueriesSrv.deleteGeoZone(params.geoZoneId, params.organisationId).subscribe( result => {
      const resultData: {deleteGeoZone: boolean} = <any>result.data;
      if (resultData && resultData.deleteGeoZone) {
        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("dispatching_deleteGeoZoneMessageSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }
    }, error => {
      console.log("ERROR DELETING DISPATCHING", {error});
      this._snackBarSrv.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("dispatching_deleteGeoZoneMessageError-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  public search(event){
    this.filter = event;
    this.organisations = this._organisationQueriesSrv.getOrganisationListQuery({name: this.filter? this.filter: null}).subscribe(result => {
      const resultData: {organisations: IOrganisation[]} = <any>result.data;
      if (resultData && resultData.organisations && resultData.organisations.length > 0) {
        this.organisationsOptions = resultData.organisations.filter(o => o.isActive).map( organisation => {
          return {
            value: organisation.id,
            label: organisation.name
          };
        });
        this.associationFormGroup.get("organisationId").patchValue(resultData.organisations[0].id);
      }
    }, error => {
      console.log("ERROR LOADING ORGANISATION", {error});
    })
  }

  public searchFieldMap = new Map([
      ["organisationsIds", "name"],
  ])
}
