import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BlockListComponent } from "./organisms/block-list/block-list.component";
import { ButtonModule } from "../button/button.module";
import { PipeModule } from "src/app/facades/pipes/pipe.module";



@NgModule({
  declarations: [BlockListComponent],
  imports: [
    CommonModule,
    ButtonModule,
    PipeModule
  ],
  exports: [
    BlockListComponent
  ]
})
export class BlockModule { }
