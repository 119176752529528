import { Component, OnInit, Input, OnDestroy } from "@angular/core";


@Component({
  selector: "app-card-stats",
  templateUrl: "./cardstats.component.html",
  styleUrls: ["./cardstats.component.css"]
})
export class CardStatsComponent {
  @Input() title: string;
  @Input() icon: string;
  @Input() value: number;
  @Input() progression: number;
}
