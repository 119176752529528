import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ModalContentForm } from "src/app/facades/model/modal-content-form";
import { ModalService, DATA } from "src/app/facades/services/modal/modal.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { ITabs } from "src/app/presentationnal/tabs/facades/interfaces/tabs.interface";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { IModalData } from "src/app/facades/interfaces/modal-data.interface";
import { IIncident, EnumIncidentPriorities, EnumIncidentStatuses, IIncidentDuplicate } from "src/app/facades/interfaces/incident.interface";
import { EnumTagColor } from "src/app/presentationnal/tags/facades/interfaces/enums/tag-color.enum";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { IncidentQueriesService, EnumUpdateQueryType } from "src/app/facades/queries/incident/incident-queries.service";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import * as moment from "moment";
import { CategoryQueriesService } from "src/app/facades/queries/categorie/category-queries.service";
import { ISelectMultipleOptions } from "src/app/presentationnal/input/facades/interfaces/selectMultiple.interface";
import { ITimelineVertical } from "src/app/presentationnal/timeline/facades/interfaces/timeline-vertical.interface";
import { IHistory, EnumIncidentActions } from "src/app/facades/interfaces/history.interface";
import { TranslateService } from "src/app/facades/services/translate.service";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { ICommentInput, IComment, WAS_TYPE } from "src/app/facades/interfaces/comment.interface";
import { IContainerComment } from "src/app/presentationnal/comment/facades/interfaces/container-comment.interface";
import { UserGroupQueriesService } from "src/app/facades/queries/user-group/user-group-queries.service";
import { IGroup } from "src/app/facades/interfaces/group.interface";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { IOrganisation, EnumOrganisationStage } from "src/app/facades/interfaces/organisation.interface";
import { AuthService } from "src/lib/auth/auth.service";
import { EnumUserRoles, IUser } from "src/app/facades/interfaces/user.interface";
import { InscriptionQueriesService } from "src/app/facades/queries/inscription/inscription-queries.service";
import { IPagination } from "src/app/facades/interfaces/pagination.interface";
import { IFilterInscription, IInscription } from "src/app/facades/interfaces/inscription.interface";
import { ApplicationConfig } from "src/app/app.config";
import { ICategory } from "src/app/facades/interfaces/category.interface";
import { IAttachmentInput, IAttachment } from "src/app/facades/interfaces/attachment.interface";
import { ISelectCheckboxOption } from "src/app/presentationnal/input/facades/interfaces/selectCheckboxOption.interface";
import { UserQueriesService } from "src/app/facades/queries/user/user-queries.service";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { REPORTER_SOURCES } from "src/app/container/pages/translations/translation-page/translation-page.component";
import { ModalPhotoComponent } from "../modal-photo/modal-photo.component";
import { MODAL_ACTION_TYPE } from "src/app/facades/enum/modal/modal-action-type.enum";
import { IExternal } from "src/app/facades/interfaces/external.interface";

enum detailIncidentTabs {
  DETAIL,
  HISTORIC,
  COMMENTS,
  DUPLICATE,
  PDF,
}

interface AdditionnalDataOptions {
  agents: ISelectMultipleOptions[];
  ascertainingAgents: ISelectMultipleOptions[];
  groups: ISelectOption[];
  organisations: ISelectOption[];
  categories: ISelectMultipleOptions[];
  priority: ISelectOption[];
  isPublic: ISelectOption[];
  trueFalse: ISelectOption[];
  isHidden: ISelectOption[];
  isThirdPartyResponsability: ISelectOption[];
  isMultipleLocation: ISelectOption[];
  isPrivateProperty: ISelectOption[];
}

@Component({
  selector: "app-modal-detail-incident",
  templateUrl: "./modal-detail-incident.component.html",
  styleUrls: ["./modal-detail-incident.component.css"]
})
export class ModalDetailIncidentComponent extends ModalContentForm implements OnInit, OnDestroy {
  public display = false;
  private _incidentId: number;
  private _incidentSub: Subscription;
  private _commentFormDataSub: Subscription;
  private _formGroupSubscriptions : Subscription[] = [];
  private _formGroupDebouncer: Subject<any> = new Subject<any>();
  private _firstLoad: boolean = true;

  public displayInscriptionButton: boolean = true;
  public displayActionsPanel: boolean = true;
  public displayGeneralActions: boolean = true;
  public detailIncidentTabsList: any = detailIncidentTabs;
  public initialTabsData: ITabs[] = [
    {value: detailIncidentTabs.DETAIL, label: this._translateSrv.translate("incident_tabDetail-txt"), isActive: true},
    {value: detailIncidentTabs.HISTORIC, label: this._translateSrv.translate("incident_tabHistorical-txt"), isActive: false},
    {value: detailIncidentTabs.COMMENTS, label: this._translateSrv.translate("incident_tabCommentary-txt"), isActive: false},
    {value: detailIncidentTabs.DUPLICATE, label: this._translateSrv.translate("incident_tabDuplicate-txt"), isActive: false},
    {value: detailIncidentTabs.PDF, label: this._translateSrv.translate("incident_tabPdf-txt"), isActive: false},
  ];
  public tabsData: ITabs[] = [];
  public selectedTab: detailIncidentTabs;

  public generalData: any = null;
  public pdfFormData: FormGroup = null;
  public commentFormData: FormGroup = null;
  public updateStatusFormData: FormGroup = null;
  public commentOptions: {isComment: ISelectOption[], isPublic: ISelectOption[]} = {
    isComment: [
      {value: true, label: this._translateSrv.translate("incident_comment-value")},
      {value: false, label: this._translateSrv.translate("incident_picture-value")},
    ],
    isPublic: [
      {value: true, label: this._translateSrv.translate("incident_commentPublicVisibility-value")},
      {value: false, label: this._translateSrv.translate("incident_commentPrivateVisibility-value")},
    ]
  };
  public attachments: File = null;
  public isFileError: boolean = false;
 // TODO remove disable on options
  public pdfVersionOptions: ISelectOption[] = [
    { value: true, label: "incident_publicVersionOfPdf-value"},
    { value: false, label: "incident_professionalVersionOfPdf-value"},
  ];
  public transferTypeOptions: ISelectOption[] = [
    { value: true, label: "incident_internalTransfer-value"},
    { value: false, label: "incident_externalTransfer-value"},
  ];
  public razValues$: Subject<boolean>;
  public userList: ISelectCheckboxOption[] = [];

  public additionnalFieldOptions: AdditionnalDataOptions = null;
  public confirmAllPublic: boolean = false;
  public historyDatas: ITimelineVertical[] = [];
  public commentDatas: IContainerComment[] = [];
  public managmentAlert: {assignAscertainingAgent: boolean, assignAscertainingAlert: string, assignAgent: boolean, assignAlert: string, transferGroup: boolean, transferOrganisation: boolean} = {
    assignAscertainingAgent: false,
    assignAscertainingAlert: "",
    assignAgent: false,
    assignAlert: "",
    transferGroup: false,
    transferOrganisation: false,
  };
  public isAdmin: boolean = false;
  public isManager: boolean = false;
  public isReadonlyAdmin: boolean = false;
  public incidentStatus = EnumIncidentStatuses;
  public urlPdf = ApplicationConfig.Url;
  public isUpdatingStatus: boolean = false;

  public _potentialDuplicates: IIncidentDuplicate[] = [];
  public selectedPotentialDuplicate: IIncidentDuplicate;
  public potentialDuplicatesOptions: ISelectOption[] = [];
  public formGroupDuplicate: FormGroup;
  public __BOX_INFO_TYPE = BOX_INFO_TYPE;

  public confirmDuplicate: boolean = false;


  public categoryDetails: ICategory[] = [];
  public categoryDetailIds: number[] = [];

  // public categoryDetails: ICategoryDetail[] = [];
  public currentSelectedCategory: ICategory = null;
  public displayCategoryAlert: boolean = false;

  public isMaskedDisabled: boolean = true;
  public isResponsabiliteTiersDisabled: boolean = false;
  public isMaskedChangedAfterAccept: boolean = false;
  public isIntern: boolean = true;
  
  public externals: IExternal[]; // to check with witch external(s) the organisation work

  public displayCurrentPhotoIndex: number = null;

  constructor(protected _modalSrv: ModalService,
    protected _fb: FormBuilder,
    protected _snackBar: SnackService,
    @Inject(DATA) private _data: IModalData,
    private _incidentQueriesSrv: IncidentQueriesService,
    private _categoryQueriesSrv: CategoryQueriesService,
    private _organisationQueriesSrv: OrganisationQueriesService,
    private _userGroupQueriesSrv: UserGroupQueriesService,
    private _translateSrv: TranslateService,
    protected _errorMessageSrv: ErrorMessageService,
    private _authSrv: AuthService,
    private _inscriptionQueriesSrv: InscriptionQueriesService,
    private _userQueriesSrv: UserQueriesService) {
      super(_modalSrv, _fb, _snackBar, _errorMessageSrv);
      this._incidentId = this._data.data.incidentId;
      this._formGroupDebouncer.pipe(debounceTime(200)).subscribe( value => {
        if (!this.isMaskedChangedAfterAccept) {
          this.updateIncidentData(value);
        } else {
          this.isMaskedChangedAfterAccept = false;
        }
      });
      this.isAdmin = this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN);
      this.isManager = this._authSrv.getRolesCurrent().includes(EnumUserRoles.MANAGER);
      this.isReadonlyAdmin = this._authSrv.getRolesCurrent().includes(EnumUserRoles.READONLY_ADMIN);
      this.inputErrorsLabelMap = new Map<string, string>([
        [ "emails", this._translateSrv.translate("incident_receiverOfMail-input")],
      ]);
    }

  ngOnInit() {
    this.loadCurrentOrganisation();
    this.loadUserList();
    this.loadIncident();
    this.checkUserInscription();
    this.razValues$ = new Subject();
    this.tabsData = this.initialTabsData;
    this.selectedTab = <detailIncidentTabs>this.tabsData.find( tab => tab.isActive).value;
  }

  /**
   * Load the organisation linked to the connected user
   * And verify if the organisation is in public stage to enable the field isHidden (isMasked)
   * And verify if the organisation work with external(s) got an IExternal[]
   */
  private loadCurrentOrganisation() {
    const userOrgaId: string = this._authSrv.getOrganisationId();
    if (userOrgaId && userOrgaId != "null") {
      this._organisationQueriesSrv.getOrganisation(+userOrgaId).subscribe(result => {
        const resultData: {organisation: IOrganisation} = <any> result.data;
        if (resultData && resultData.organisation) {
          const organisation = resultData.organisation;
          if (organisation.stage === EnumOrganisationStage.PUBLIC) {
            this.isMaskedDisabled = false;
          }
          if (resultData && resultData.organisation && resultData.organisation.externals.length > 0) {
            this.externals = resultData.organisation.externals;
          }
        }
      }, err => {
        console.log("ERROR LOADING ORGANISATION", {err});
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._incidentSub.unsubscribe();
    this._commentFormDataSub.unsubscribe();
  }

  private loadUserList() {
    this._userQueriesSrv.getUsersList().subscribe( result => {
      const resultData: {users: IUser[]} = <any> result.data;
      if (resultData && resultData.users) {
        this.userList = resultData.users.map( user => {
          return {
            datas: {email: user.email},
            value: user.email,
            label: `${user.lastName} ${user.firstName}`
          };
        });
      }
    }, error => {
      console.log("ERROR LOADING USER LIST", {error});
    });
  }

  private setAdditionnalOptions() {
    this.additionnalFieldOptions = {
      agents: [],
      ascertainingAgents: [],
      groups: [],
      organisations: [],
      categories: [],
      trueFalse: [],
      priority: Object.keys(EnumIncidentPriorities).map( key => {
        return { value: EnumIncidentPriorities[key], label: `incident_${key.toLowerCase()}Priority`};
      }),
      isPublic: [
        { value: true, label: "incident_publicVisibility-value"},
        { value: false, label: "incident_privateVisibility-value"},
      ],
      isHidden: [
        { value: false, label: "incident_isHiddenFalse-value", disable: this.isMaskedDisabled},
        { value: true, label: "incident_isHiddenTrue-value", disable: this.isMaskedDisabled},
      ],
      isThirdPartyResponsability: [
        { value: false, label: "incident_isThirdPartyResponsabilityFalse-value", disable: this.isResponsabiliteTiersDisabled},
        { value: true, label: "incident_isThirdPartyResponsabilityTrue-value", disable: this.isResponsabiliteTiersDisabled},
      ],
      isMultipleLocation: [
        { value: false, label: "incident_isMultipleLocationFalse-value"},
        { value: true, label: "incident_isMultipleLocationTrue-value"},
      ],
      isPrivateProperty: [
        { value: false, label: "incident_isPrivatePropertyFalse-value"},
        { value: true, label: "incident_isPrivatePropertyTrue-value"},
      ],
    };
    this.getCategoriesOptions();
    if (this.isAdmin || this.isManager) {
      this.getMyGroupsAndAgentsOptions();
      this.getGroupsOptions();
      if (this.isAdmin) {
        this.getOrganisationsOptions();
      }
    }
  }

  private getCategoriesOptions() {
    this._categoryQueriesSrv.getMappedCategories().subscribe( result => {
      const resultData: {mappedCategories: any[]} = <any>result.data;
      if (resultData && resultData.mappedCategories) {
        this.additionnalFieldOptions.categories = <ISelectMultipleOptions[]>resultData.mappedCategories;
      }
      // this.additionnalFieldOptions.categories = categories;
    }, error => {
      console.log(error);
    });
  }

  private getMyGroupsAndAgentsOptions() {
    this._userGroupQueriesSrv.getMyGroups(true, false).subscribe( result => {
      const resultData: {myGroups: IGroup[]} = <any>result.data;
      if (resultData && resultData.myGroups) {
        this.additionnalFieldOptions.agents = resultData.myGroups.filter(group => group.agents && group.agents.length > 0).map( group => {
          return {
            id: group.id,
            name: group.name,
            parentId: null,
            children: group.agents.map( agent => {
              return {
                id: `${group.id}_${agent.id}`,
                name: `${agent.lastName} ${agent.firstName}`,
                parentId: group.id,
                children: []
              };
            })
          };
        });
        this.additionnalFieldOptions.ascertainingAgents = resultData.myGroups.filter(group => group.ascertainingAgents && group.ascertainingAgents.length > 0).map( group => {
          return {
            id: group.id,
            name: group.name,
            parentId: null,
            children: group.ascertainingAgents.map( agent => {
              return {
                id: `${group.id}_${agent.id}`,
                name: `${agent.lastName} ${agent.firstName}`,
                parentId: group.id,
                children: []
              };
            })
          };
        });
      }
    }, error => {
      console.log("ERROR LOADING GROUPS AND AGENTS LIST", {error});
    });
  }

  private getGroupsOptions() {
    this._userGroupQueriesSrv.getTransferableGroupList().subscribe( result => {
      const resultData: {transferableGroups: IGroup[]} = <any>result.data;
      if (resultData && resultData.transferableGroups) {
        this.additionnalFieldOptions.groups = resultData.transferableGroups.map( group => {
          return {
            value: group.id,
            label: group.name
          };
        });
      }
    }, error => {
      console.log("ERROR LOADING GROUPS LIST", {error});
    });
  }

  private getOrganisationsOptions() {
    this._organisationQueriesSrv.getTransferableOrganisations().subscribe(result => {
      const resultData: {transferableOrganisations: IOrganisation[]} = <any>result.data;
      if (resultData && resultData.transferableOrganisations) {
        this.additionnalFieldOptions.organisations = resultData.transferableOrganisations.map( organisation => {
          return {
            value: organisation.id,
            label: organisation.name
          };
        });
      }
    }, error => {
      console.log("ERROR LOADING ORGANISATIONS LIST", {error});
    });
  }

  // private generatePlannedDateOptions(): ISelectOption[] {
  //   const plannedDatesOptions: ISelectOption[] = [];
  //   const firstDate: moment.Moment = moment();
  //   plannedDatesOptions.push({
  //     value:  null,
  //     label: "-",
  //   });
  //   plannedDatesOptions.push({
  //     value: firstDate.startOf("month").toISOString(),
  //     label: firstDate.startOf("month").format("MM/YYYY"),
  //   });
  //   for (let index = 1; index < 12; index++) {
  //     firstDate.add(1, "month");
  //     plannedDatesOptions.push({
  //       value: firstDate.startOf("month").toISOString(),
  //       label: firstDate.startOf("month").format("MM/YYYY")
  //     });
  //   }
  //   return plannedDatesOptions;
  // }

  private loadIncident() {
    if(this._incidentSub) this._incidentSub.unsubscribe()
    this._incidentSub = this._incidentQueriesSrv.watchIncident(this._incidentId).valueChanges.subscribe( result => {
      const resultData: {incident: IIncident} = <any>result.data;
      if (resultData && resultData.incident) {
        this.checkDisplayGeneralAction(resultData.incident);
        if (this._firstLoad && this.displayActionsPanel) {

          this.categoryDetails = resultData.incident.categoryDetails;

          // this.categoryDetails = JSON.parse(JSON.stringify(resultData.incident.categoryDetails));
          // this.currentSelectedCategory = JSON.parse(JSON.stringify(resultData.incident.category));

          this.setAdditionnalOptions();
          this._firstLoad = false;
        }
        this.setFormGroupDatas(resultData.incident);
        const { potentialDuplicates } = resultData.incident;
        this.setFormGroupDuplicate();
        if (potentialDuplicates && potentialDuplicates.length > 0) {
          this._potentialDuplicates = potentialDuplicates.map(dup => this.formatPotentialDuplicates(dup));
          this.potentialDuplicatesOptions = this._potentialDuplicates.map(dup => {
            return {value: dup.id, label: this._translateSrv.translate("incident_numberX-txt", {incidentId: dup.orgacode + "-" + dup.generatedid})};
          });
          this.formGroupDuplicate.get("selectedDuplicateIncident").setValue(this.potentialDuplicatesOptions[0].value);
        }
        this.formatGeneralData(resultData.incident);
        this.formatHistoryDatas(resultData.incident.histories, resultData.incident.reporterSource);
        this.commentDatas = this.formatCommentDatas(resultData.incident.comments, resultData.incident.attachments);
        this.checkResponsabiliteTiers(resultData.incident.histories)
      }
    }, error => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("incident_loadIncidentFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  private checkUserInscription() {
    const pagination: IPagination = {limit: 0, page: 1},
          filter: IFilterInscription = {userId: +this._authSrv.getUserId()};
    this._inscriptionQueriesSrv.getInscriptions(filter, pagination).valueChanges.subscribe( result => {
      this.displayInscriptionButton = true;
      const resultData: {inscriptions: IInscription[]} = <any>result.data;
      if (resultData && resultData.inscriptions && resultData.inscriptions.some( inscription => inscription.incidentId === this._incidentId)) {
        this.displayInscriptionButton = false;
      }
    }, error => {
      console.log("ERROR LOADING INSCRIPTIONS", {error});
    });
  }

  // TODO: USE BACK ROUTE TO CHECK IF WE CAN UPDATE INCIDENT
  private checkDisplayGeneralAction(incident: IIncident) {
    this._incidentQueriesSrv.canModifyIncident(this._incidentId).subscribe( result => {
      const resultData: {canModifyIncident: boolean} = <any>result.data;
      this.displayGeneralActions = resultData.canModifyIncident;
      this.displayActionsPanel = (
        ( this.displayGeneralActions ) && incident.status === EnumIncidentStatuses.IN_PROGRESS);
      if (this._firstLoad && this.displayActionsPanel) {
        this.setAdditionnalOptions();
        this.setFormGroupDatas(incident);
        this._firstLoad = false;
      }
      this.formatGeneralData(incident);
      this.formatHistoryDatas(incident.histories, incident.reporterSource);
      this.commentDatas = this.formatCommentDatas(incident.comments, incident.attachments);
    }, error => {
      console.log("ERROR CHECKING CAN MODIFY INCIDENT", {error});
    });
  }

  private formatGeneralData(incidentData: IIncident) {
    const higherRole = incidentData.reporter? incidentData.reporter.higherRole : (incidentData.citizen && incidentData.citizen.isAmbassador === true ? 'ambassadeur' : 'citoyen');
    const avatarName = incidentData.reporter? incidentData.reporter.point.item_used.name : incidentData.citizen.user_point.item_used.name;
    let status = '';
    switch(incidentData.status) {
        case 'CREATED':
        status = 'created';
        break;

        case 'DISMISSED':
        status = 'dismissed';
        break;

        case 'IN_PROGRESS':
        status = 'inprogress';
        break;

        case 'CLOSED':
        status = 'closed';
        break;

        case 'SOLVED':
        status = 'solved';
        break;
        
        case 'FUSION':
        status = 'fusion';
        break;
    };
    let category = this.formatCompleteCategoryName(incidentData.categoryChain);
    if (incidentData.categoryDetails && incidentData.categoryDetails.length > 0) {
      category += " ( " + incidentData.categoryDetails.map(prec => prec.name).join(", ") + " )";
    }
    let address = incidentData.street? incidentData.street : "";
    address += incidentData.addressNumber? ` ${incidentData.addressNumber}` : "";
    address += incidentData.municipality? ` (${incidentData.municipality})` : "";
    this.generalData = {
      id: incidentData.id,
      reportingNumber: incidentData.orgacode + "-" + incidentData.generatedid,
      address: address,
      // address: (incidentData.address && incidentData.address.includes("(") )? incidentData.address.replace(incidentData.zipCode, "").replace(",","").replace(incidentData.city, "").replace(",","").replace(",","") : incidentData.address? incidentData.address.replace(incidentData.zipCode, "").replace(",","").replace(incidentData.city, "").replace(",","").replace(incidentData.municipality,"("+incidentData.municipality+")").replace(",","") : "",
      zipCode: incidentData.zipCode,
      city: incidentData.city,
      categoryId: incidentData.categoryId,
      category: category,
      organisation: incidentData.assignment && incidentData.assignment.organisation ? incidentData.assignment.organisation.name : "-",
      groupId: incidentData.assignment && incidentData.assignment.group ? incidentData.assignment.group.id : null,
      group: incidentData.assignment && incidentData.assignment.group ? incidentData.assignment.group.name : "-",
      agent: incidentData.assignment && incidentData.assignment.agent ? `${incidentData.assignment.agent.lastName} ${incidentData.assignment.agent.firstName}` : "-",
      status: this._translateSrv.translate(`incident_${incidentData.status.toLowerCase()}Status`),
      statusColor: incidentData.status,
      priority: this._translateSrv.translate(`incident_${incidentData.priority.toLowerCase()}Priority`),
      createdAt: moment(incidentData.createdAt).format("DD/MM/YYYY"),
      visibility: this._translateSrv.translate(incidentData.isPublic ? "incident_publicVisibility-txt" : "incident_privateVisibility-txt"),
      complementAddress: incidentData.complementAddress,
      isMultipleLocations: incidentData.isMultipleLocations,
      plannedDate: incidentData.plannedDate ? moment(incidentData.plannedDate).format("DD/MM/YYYY") : null,
      isPrivateLocation: incidentData.isPrivateLocation,
      isMasked: incidentData.isMasked,
      isThirdPartyResponsability: incidentData.isThirdPartyResponsability,
      incidentNumber: incidentData.id,
      markerData: {
        status: incidentData.status,
        x: incidentData.lambertX,
        y: incidentData.lambertY,
        avatar_url: `/img/avatars/${avatarName}/${avatarName}_${higherRole}_${status}.png`
      },
      attachments: incidentData.attachments,
      duplicateId: incidentData.duplicateId,
      ...this.setAdditionalDataFormGeneralData(incidentData.status)
    };

    console.log(this.generalData);

    this.pdfFormData = this._fb.group({
      publicVersion: true,
      transferType: true,
      emails: [null],
      mail: [null],
      object: [null],
      message: null
    });
    this.pdfFormData.get('transferType').valueChanges.subscribe(newValue => {
        this.isIntern = !!newValue,
        this.pdfFormData.get('emails').setValue(null)
        this.pdfFormData.get('mail').setValue(null)
    })
    this.initCommentFormData();
    this.showedTab();
  }

  private formatPotentialDuplicates(duplicate: IIncident, isAddedItem: boolean = false) {
    let address = duplicate.street? duplicate.street : "";
    address += duplicate.addressNumber? ` ${duplicate.addressNumber}` : "";
    address += duplicate.municipality? ` (${duplicate.municipality})` : "";

    return {
      id: duplicate.id,
      generatedid: duplicate.generatedid,
      orgacode: duplicate.orgacode,
      duplicatePercentage: duplicate.duplicatePercentage,
      address: address,
      // address: duplicate.address ? duplicate.address.replace(duplicate.zipCode, "").replace(duplicate.city, "") : "",
      // address: (duplicate.address && duplicate.address.includes("(") )? duplicate.address.replace(",","").replace(duplicate.zipCode, "").replace(",","").replace(duplicate.city, "").replace(",","").replace(",","") : duplicate.address ? duplicate.address.replace(",","").replace(duplicate.zipCode, "").replace(",","").replace(duplicate.city, "").replace(",","").replace(duplicate.municipality, "("+duplicate.municipality+")").replace(",","") : "",
      zipCode: duplicate.zipCode,
      municipality: duplicate.municipality,
      complementAddress: duplicate.complementAddress,
      city: duplicate.city,
      category: this.formatCompleteCategoryName(duplicate.categoryChain),
      status: this._translateSrv.translate(`incident_${duplicate.status.toLowerCase()}Status`),
      statusColor: duplicate.status,
      createdAt: moment(duplicate.createdAt).startOf("month").format("DD/MM/YYYY"),
      attachments: duplicate.attachments,
      commentDatas: this.formatCommentDatas(duplicate.comments, duplicate.attachments),
      isAddedItem: isAddedItem
    };
  }

  private initCommentFormData() {
    if (!this._commentFormDataSub) {
      this.attachments = null;
      this.commentFormData = this._fb.group({
        isComment: true,
        isPublic: [true, Validators.required],
        comment: [null, Validators.required]
      });
      this._commentFormDataSub = this.commentFormData.get("isComment").valueChanges.subscribe( result => {
        this.commentFormData.get("comment").clearValidators();
        if (result) {
          this.commentFormData.get("comment").setValidators([Validators.required]);
        }
      });
    }
  }

  private formatCompleteCategoryName(category: ICategory): string {
    let categoryName = category.name;
    if (category.children.length !== 0) {
      categoryName += ` / ${this.formatCompleteCategoryName(category.children[0])}`;
    }
    return categoryName;
  }

  private setAdditionalDataFormGeneralData(status: EnumIncidentStatuses) {
    return {
      isCreated: status === EnumIncidentStatuses.CREATED,
      isClose: (status !== EnumIncidentStatuses.IN_PROGRESS && status !== EnumIncidentStatuses.DISMISSED),
      isDismiss: status === EnumIncidentStatuses.DISMISSED
    };
  }

  private setFormGroupDatas( incidentData: IIncident) {
    const formData = {
      categoryId: incidentData.categoryId,
      complementAddress: incidentData.complementAddress,
      priority: incidentData.priority,
      isPublic: incidentData.isPublic,
      isMasked: incidentData.isMasked,
      isThirdPartyResponsability: incidentData.isThirdPartyResponsability,
      isPrivateLocation: incidentData.isPrivateLocation,
      isMultipleLocations: incidentData.isMultipleLocations,
      plannedDate: incidentData.plannedDate ? incidentData.plannedDate : null,
      assignAgent: null,
      assignAscertainingAgent: null,
      transferGroupId: null,
      transferOrganisationId: null,
    }

    if(this._formGroup) {
      this._formGroupSubscriptions.forEach(s => s.unsubscribe())
      this._formGroup.setValue(formData)
      this.listenFormGroupUpdate();
    } else {
      this._formGroup = this._fb.group(formData);
    // if (incidentData.plannedDate) {
    //   const setPlannedDate: moment.Moment = moment(incidentData.plannedDate).startOf("month");
    //   // if (!this.additionnalFieldOptions.plannedDates.some( dates => dates.value === setPlannedDate.toISOString() )) {
    //   //   this.additionnalFieldOptions.plannedDates.unshift({
    //   //     value: setPlannedDate.startOf("month").toISOString(),
    //   //     label: setPlannedDate.startOf("month").format("MM/YYYY")
    //   //   });
    //   // }
    // }
      this.listenFormGroupUpdate();
    }
  }

  private setFormGroupDuplicate() {
    this.formGroupDuplicate = this._fb.group({
      selectedDuplicateIncident: null,
      searchIncident: [null, Validators.pattern(/^[a-zA-Z]{3}-[0-9]+$/)]
    });
    this.formGroupDuplicate.get("selectedDuplicateIncident").valueChanges.subscribe(res => {
      this.confirmDuplicate = false;
      this.selectedPotentialDuplicate = this._potentialDuplicates.find(dup => dup.id == res);
    });
  }

  private recursiveSearchCategory(options, categorySearchId) {
    let selectedCategory = null;
    options.forEach(category => {
      if (category.id === categorySearchId) {
        selectedCategory = category;
      } else if (category.children && category.children.length > 0) {
          const resultRecursive = this.recursiveSearchCategory(category.children, categorySearchId);
          if (resultRecursive != null) {
            selectedCategory = resultRecursive;
          }
      }
    });
    return selectedCategory;
  }

  private listenFormGroupUpdate() {
    const keyNotSubscribe: string[] = ["complementAddress", "assignAgent", "assignAscertainingAgent", "transferGroupId", "transferOrganisationId"];
    Object.keys(this._formGroup.controls).forEach( controlKey => {
      if (!keyNotSubscribe.includes(controlKey)) {
        if (controlKey == 'categoryId') {
          this._formGroupSubscriptions.push(this._formGroup.get(controlKey).valueChanges.subscribe( value => {
            const currentCategory = this.recursiveSearchCategory(this.additionnalFieldOptions.categories, value);
            if (currentCategory && (!currentCategory.children || currentCategory.children.length === 0) && (!currentCategory.categoryDetails || currentCategory.categoryDetails.length === 0 )) {
              this.displayCategoryAlert = false;
              this.categoryDetailIds = [];
              this._formGroupDebouncer.next({controlKey, value});
            } else {
              this.displayCategoryAlert = true;
            }
          }));
        } else if (controlKey == "plannedDate"){
          this._formGroupSubscriptions.push(this._formGroup.get(controlKey).valueChanges.subscribe( value => {
            const formattedValue = moment(value).format('MM/DD/YYYY');
            this._formGroupDebouncer.next({controlKey, value: formattedValue});
          }));
        } else {
          this._formGroupSubscriptions.push(this._formGroup.get(controlKey).valueChanges.subscribe( value => {
            this._formGroupDebouncer.next({controlKey, value});
          }));
        }
      }
    });
    this._formGroupSubscriptions.push(this._formGroup.get("assignAgent").valueChanges.subscribe( value => {
      this.managmentAlert.assignAgent = !!value;
      if (value) {
        const ids: number[] = value.split("_");
        if (+ids[0] === +this.generalData.groupId) {
          this.managmentAlert.assignAlert = this._translateSrv.translate("incident_assignIncident-txt");
        } else {
          this.managmentAlert.assignAlert = this._translateSrv.translate("incident_forwardGroupAndAssignIncident-txt");

        }
      }
    }));
    this._formGroupSubscriptions.push(this._formGroup.get("assignAscertainingAgent").valueChanges.subscribe( value => {
      this.managmentAlert.assignAscertainingAgent = !!value;
      if (value) {
        const ids: number[] = value.split("_");
        if (+ids[0] === +this.generalData.groupId) {
          this.managmentAlert.assignAscertainingAlert = this._translateSrv.translate("incident_assignIncident-txt");
        } else {
          this.managmentAlert.assignAscertainingAlert = this._translateSrv.translate("incident_forwardGroupAndAssignIncident-txt");

        }
      }
    }));

    this._formGroupSubscriptions.push(this._formGroup.get("transferGroupId").valueChanges.subscribe( value => {
      this.managmentAlert.transferGroup = !!value;
    }));
    this._formGroupSubscriptions.push(this._formGroup.get("transferOrganisationId").valueChanges.subscribe( value => {
      this.managmentAlert.transferOrganisation = !!value;
    }));
  }

  public actionAlertAssignment(confirm: boolean = true, key: string = "assignAgent") {
    if (confirm) {
      const ids: number[] = this._formGroup.get(key).value.split("_");
      this._incidentQueriesSrv.assignIncident(this._incidentId, +ids[0], +ids[1]).subscribe( result => {
        const resultData: {assignIncident: IIncident} = result.data;
        if (resultData && resultData.assignIncident) {
          this.generalData.group = resultData.assignIncident.assignment.group.name;
          this.generalData.agent = resultData.assignIncident.assignment.agent ? `${resultData.assignIncident.assignment.agent.lastName} ${resultData.assignIncident.assignment.agent.firstName}` : "-";
        }
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("incident_assignIncidentSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, error => {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("incident_assignIncidentFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
        console.log("ERROR ASSIGN USER", {error});
      });
    }
    if(key === "assignAgent") this._formGroup.get("assignAgent").patchValue(null);
    else this._formGroup.get("assignAscertainingAgent").patchValue(null);
  }

  public actionAlertTransferGroup(confirm: boolean = true) {
    if (confirm) {
      this._incidentQueriesSrv.transferIncidentToGroup(this._incidentId, this._formGroup.get("transferGroupId").value).subscribe( result => {
        const resultData: {transfertIncidentToGroup: IIncident} = <any>result.data;
        if ( resultData && resultData.transfertIncidentToGroup ) {
          this.generalData.group = resultData.transfertIncidentToGroup.assignment.group.name;
          this.generalData.agent = resultData.transfertIncidentToGroup.assignment.agent ? `${resultData.transfertIncidentToGroup.assignment.agent.lastName} ${resultData.transfertIncidentToGroup.assignment.agent.firstName}` : "-";
        }
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("incident_transferIncidentSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, error => {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("incident_transferIncidentFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
        console.log("ERROR TRANSFERT GROUP", {error});
      });
    }
    this._formGroup.get("transferGroupId").patchValue(null);
  }

  public actionAlertTransferOrganisation(confirm: boolean = true) {
    if (confirm) {
      this._incidentQueriesSrv.transferIncidentToOrganisation(this._incidentId, this._formGroup.get("transferOrganisationId").value).subscribe( result => {
        const resultData: {transfertIncidentToOrganisation: IIncident} = <any>result.data;
        if ( resultData && resultData.transfertIncidentToOrganisation ) {
          this.generalData.organisation = resultData.transfertIncidentToOrganisation.assignment.organisation.name;
          this.generalData.group = resultData.transfertIncidentToOrganisation.assignment.group ? resultData.transfertIncidentToOrganisation.assignment.group.name : "-";
          this.generalData.agent = resultData.transfertIncidentToOrganisation.assignment.agent ? `${resultData.transfertIncidentToOrganisation.assignment.agent.lastName} ${resultData.transfertIncidentToOrganisation.assignment.agent.firstName}` : "-";
        }
        const objData = {
          confirm: true,
          data: {
            reOpenId: resultData.transfertIncidentToOrganisation.orgacode+"-"+resultData.transfertIncidentToOrganisation.generatedid
          }
        };
        this.afterClosed.next(objData);
        this._modalSrv.closeModal();
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("incident_transferIncidentSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, error => {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("incident_transferIncidentFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
        console.log("ERROR TRANSFERT ORGANISATION", {error});
      });
    }
    this._formGroup.get("transferOrganisationId").patchValue(null);
  }

  public changeTab(data: ITabs[]) {
    this.tabsData = data;
    this.selectedTab = <detailIncidentTabs>data.find( tab => tab.isActive).value;
  }

  public showedTab(){
    if(this.generalData.isClose){
      this.tabsData = this.initialTabsData.filter(item => item.value != detailIncidentTabs.DUPLICATE);
      if(this.selectedTab == detailIncidentTabs.DUPLICATE) this.selectedTab = detailIncidentTabs.DETAIL;
    } else {
      this.tabsData = this.initialTabsData;
    }
  }


  /* START DETAIL METHODS */
  public mapLoadedEvent(status: boolean) {
  }
  /* END DETAIL METHODS */

  /* START HISTORY METHODS */
  public formatHistoryDatas(incidentHistories: IHistory[], source: REPORTER_SOURCES) {
    this.historyDatas = incidentHistories.map( history => {
      let orgaDisplay: string = "- [FixMyStreet Wallonie]"; // Default case
      if (history.user && history.user.organisation) {
        orgaDisplay = ` - [${history.user.organisation.name}]`;
      } else if (history.action == EnumIncidentActions.CREATED) {
        if (source == REPORTER_SOURCES.WEB_APP) {
          orgaDisplay = `- [${this._translateSrv.translate("incident_sourceWebsite-txt")}]`;
        } else if (source == REPORTER_SOURCES.MOBILE_APP) {
          orgaDisplay = `- [${this._translateSrv.translate("incident_sourceMobileApp-txt")}]`;
        } else {
          orgaDisplay = `- [${this._translateSrv.translate("incident_sourceBackOffice-txt")}]`;
        }
      }
      return {
        date: moment(history.createdAt).format("DD/MM/YYYY HH:mm"),
        user: orgaDisplay,
        content: history.tl_message
      };
    });
  }


  public formatHistoryActionLabel(action: EnumIncidentActions) {
    return this._translateSrv.translate(`history.action.${action.toLowerCase()}`);
  }

  /* END HISTORY METHODS */

  /* START COMMENTS METHODS */
  private formatCommentDatas(comments: IComment[], attachments: IAttachment[]) {
    const allComments: IComment[] | IAttachment[] = [...comments, ...attachments].sort( (a, b) => {
      return ( a.createdAt > b.createdAt ) ? -1 : 1;
    });
    return allComments.map( comment => {
      return this.setCommentData(comment);
    });
  }

  private setCommentData(comment: IComment | IAttachment): IContainerComment {
    return {
      id: comment.id,
      datas: {
        isPublic: comment.isPublic,
        isComment: !(<IAttachment>comment).filePath
      },
      title: `${this._translateSrv.translate("incident_commentAdded-txt")} ${moment(comment.createdAt).format("DD/MM/YYYY HH:mm")}
      ${this._translateSrv.translate("incident_commentBy-txt")} ${this.formatAuthor(comment)}`,
      inputSelectConfig: {
        formGroup: this._fb.group({
          id: comment.id,
          isComment: !(<IAttachment>comment).filePath,
          isPublic: comment.isPublic
        }),
        inputName: "isPublic",
        options: [
          {value: true, label: this._translateSrv.translate("incident_commentPublicVisibility-value")},
          {value: false, label: this._translateSrv.translate("incident_commentPrivateVisibility-value")}
        ]
      },
      comment: comment.comment,
      imageSrc: (<IAttachment>comment).filePath ? (<IAttachment>comment).filePath : "../../../../assets/img/text_message_1x.png"
    };
  }

  /**
   * @description Format author of a comment / attachment for display in comment's / attachment's title
   * @author Quentin Wolfs
   * @private
   * @param {(IComment | IAttachment)} comment
   * @returns {string}
   * @memberof ModalDetailIncidentComponent
   */
  private formatAuthor(comment: IComment | IAttachment): string {
    if (!!comment.user) {
      return `${comment.user.lastName} ${comment.user.firstName}`;
    } else if (!!comment.citizen) {
      return `${this._translateSrv.translate("incident_commentNeightbour-txt")} (${comment.citizen.lastName} ${comment.citizen.firstName}`
        + `${comment.citizen.phone ? " | " + comment.citizen.phone : ""}${comment.citizen.email ? " | " + comment.citizen.email : ""})`;
    } else if (!!comment.external) {
      return `${comment.external.name}`;
    }

    else {
      return comment.wastype == WAS_TYPE.USER ? this._translateSrv.translate("incident_commentProUser-txt") :this._translateSrv.translate("incident_historyCitizen-txt");
    }
  }

  public updateCommentVisibility(commentValue: { id: number, isPublic: boolean, isComment: boolean}) {
    const commentToUpdate: IContainerComment = this.commentDatas.find( comment => comment.id === commentValue.id && comment.datas.isComment === commentValue.isComment);
    if (commentToUpdate.datas.isPublic !== commentValue.isPublic) {
      commentToUpdate.datas.isPublic = commentValue.isPublic;
      if (commentValue.isComment) {
        this._incidentQueriesSrv.updateComment(commentValue.id, {isPublic: commentValue.isPublic}).subscribe( result => {
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
            this._translateSrv.translate("incident_updateVisibilityCommentSuccess-txt"),
            STATUS_ALERTS.SUCCESS,
            5000
          );
        }, error => {
          console.log("ERROR UPDATING COMMENT VISIBILITY", {error});
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("incident_updateVisibilityCommentFail-txt"),
            STATUS_ALERTS.DANGER,
            0
          );
        });
      } else {
        this._incidentQueriesSrv.updateAttachment(+commentValue.id, {isPublic: commentValue.isPublic}).subscribe( result => {
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
            this._translateSrv.translate("incident_updateVisibilityPictureSuccess-txt"),
            STATUS_ALERTS.SUCCESS,
            5000
          );
        }, error => {
          console.log("ERROR UPDATING IMAGE VISIBILITY", {error});
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("incident_updateVisibilityPictureFail-txt"),
            STATUS_ALERTS.DANGER,
            0
          );
        });
      }
    }

  }

  public deleteComment(data: IContainerComment) {
    if (data.datas.isComment) {
      this._incidentQueriesSrv.deleteComment(data.id).subscribe( result => {
        this.commentDatas = this.commentDatas.filter(comment => (!comment.datas.isComment || comment.id !== data.id ));
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("incident_deleteCommentSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, error => {
        console.log("ERROR DELETING COMMENT", {error});
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("incident_deleteCommentFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      });
    } else {
      this._incidentQueriesSrv.deleteAttachment(data.id).subscribe( result => {
        this.commentDatas = this.commentDatas.filter(comment => (comment.datas.isComment || comment.id !== data.id ));
        this.generalData.attachments = (<IAttachment[]>this.generalData.attachments).filter( attachment => attachment.id !== data.id);
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("incident_deletePictureSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, error => {
        console.log("ERROR DELETING IMAGE", {error});
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("incident_deletePictureFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      });
    }
  }

  public saveCommentOrPhotoAction() {
    const formValue = {...this.commentFormData.value};
    if (this.commentFormData.valid) {
      const commentData: ICommentInput | IAttachmentInput = {
        comment: formValue.comment,
        isPublic: formValue.isPublic,
        incidentId: this._incidentId
      };
      if (formValue.isComment) {
        this.createComment(commentData);
      } else {
        if (this.attachments) {
          this.createAttachment(commentData, this.attachments);
        } else {
          this.isFileError = true;
        }
      }
    } else {
      this.markFormGroupTouched(this.commentFormData);
    }
  }

  public onAddingImage(file: File) {
    if (file) {
      this.attachments = file;
      this.isFileError = false;
    }
  }

  public touchFile(data: {action: string}) {
    if (data.action === "Delete") {
      this.attachments = null;
    }
  }

  public createComment(comment: ICommentInput) {
    this._incidentQueriesSrv.createComment(comment).subscribe(result => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate("incident_createCommentSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
      this.resetCommentData();
    }, error => {
      console.log("ERROR CREATING COMMENT", {error});
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("incident_createCommentFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  public createAttachment(attachment: IAttachmentInput, file: any) {
    this._incidentQueriesSrv.createAttachment(attachment, file).subscribe( result => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate("incident_createPictureSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
      this.resetCommentData();
    }, error => {
      console.log("ERROR ADDING IMAGE", {error});
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("incident_createPictureFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  private resetCommentData() {
    this.attachments = null;
    this.commentFormData.get("comment").patchValue("");
  }

  /* END COMMENTS METHODS */

  /* START PDF METHODS */
  public downloadPdf(isPublic: boolean = true) {
    const urlComplete = `${this.urlPdf}/incidents/detail/${this._incidentId}.pdf`;
    window.open(`${urlComplete}?isPro=${!isPublic}&lang=${this._translateSrv.activeLanguage}`);
  }

  public sendPdf() {
    // TODO enbable the button
    if ( this.pdfFormData.valid ) {
      this.inputErrorsLabel = [];
      const pdfData = this.pdfFormData.value;
      let emails = [];
      if(pdfData && pdfData.mail){
        emails = pdfData.mail ? pdfData.mail.split(";") : []
      }
      emails = pdfData.emails && pdfData.emails.length ? pdfData.emails.concat(emails): emails;

      var mailValide = []; // Array for the valid emails
      var mailFalse = []; // Array for the unvalid emails

      emails.forEach(mail => {
        // Trim to remove space in each emails
        const mailTrim = mail.trim();
        const regex = RegExp(/^[A-Za-z0-9._-]+@[A-Za-z0-9._-]+\.[a-zA-Z]{2,3}$/); // declare regular expression for testing email
        var emailValidate = regex.test(mailTrim); // Test if the email is matching the regexp

        // Add the email in the good array (true ==> mailValide / false ==> mailFalse)
        if (emailValidate == true) mailValide.push(mailTrim);
        else mailFalse.push(mailTrim);

      });

      // Verify if there is at least one unvalid email and display error message to the user
      if (mailFalse.length > 0) {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt", {mailFalse: mailFalse.join()}),
          this._translateSrv.translate("incident_modal_Error_Mail", {mailFalse: mailFalse.join()}),
          STATUS_ALERTS.WARNING,
          5000
        );
      }

      // Verify if there is at least one valid email to send the PDF
      if (mailValide.length > 0){
        this._incidentQueriesSrv.sendPdfMail(this.generalData.id, mailValide, !pdfData.publicVersion, pdfData.transferType , pdfData.object, pdfData.message).subscribe( reponse => {
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
            this._translateSrv.translate("incident_sendPdfSuccess-txt"),
            STATUS_ALERTS.SUCCESS,
            5000
          );
          this.pdfFormData.reset();
          this.pdfFormData.get("publicVersion").patchValue(true);
          this.pdfFormData.get("transferType").patchValue(true);
          this.razValues$.next(true);
          this.loadIncident()
        } ,
        error => {
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("incident_sendPdfFail-txt"),
            STATUS_ALERTS.DANGER,
            0
          );
        });
      }
    } else {
      this.markFormGroupTouched(this.pdfFormData);
      this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.pdfFormData, this.inputErrorsLabelMap, true);
    }
  }
  /* END PDF METHODS */

  /* START ADDITIONNAL OPTIONS METHODS */
  public updateAdditionnalTextData(formKey: string) {
    this.updateIncidentData({
      controlKey: formKey,
      value: this._formGroup.get(formKey).value
    });
  }

  // private updateIncidentData(data: {controlKey: string, value: any, categoryDetailIds?: number[]}) {
  //   const queryVariables: any = {
  //     id: this._incidentId
  //   };
  //   queryVariables[data.controlKey] = data.value;
  //   if(data.controlKey == "categoryId"){
  //     queryVariables["categoryDetailIds"] = data.categoryDetailIds? data.categoryDetailIds : [];
  //   }
  //   this._incidentQueriesSrv.updateIncidentValue(this.checkValueToUpdate(data.controlKey), queryVariables).subscribe( result => {

  private updateIncidentData(data?: {controlKey: string, value: any}) {
    const queryVariables: any = {
      id: this._incidentId
    };

    if (data) {
      queryVariables[data.controlKey] = data.value;
    }
    if (data.controlKey === "categoryId") {
      queryVariables["categoryDetailIds"] = this.categoryDetailIds && this.categoryDetailIds.length > 0 ? this.categoryDetailIds : [];
    }

    this._incidentQueriesSrv.updateIncidentValue(this.checkValueToUpdate(data.controlKey), queryVariables).subscribe( result => {
      // No treatment > watchquery on incident
    }, error => {
      console.log("ERROR UPDATING INCIDENT", {error});
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("incident_updateIncidentFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  private checkValueToUpdate(controlKey: string) {
    if (controlKey) {
      switch (controlKey) {
        case "categoryId": return EnumUpdateQueryType.CATEGORY;
        case "complementAddress": return EnumUpdateQueryType.COMPLEMENTARY_ADDRESS;
        case "priority": return EnumUpdateQueryType.PRIORITY;
        case "isPublic": return EnumUpdateQueryType.VISIBILITY;
        case "isMasked": return EnumUpdateQueryType.MASKED;
        case "isThirdPartyResponsability": return EnumUpdateQueryType.THIRD_PARTY_RESPONSABILITY;
        case "isPrivateLocation": return EnumUpdateQueryType.PRIVATE_LOCATION;
        case "isMultipleLocations": return EnumUpdateQueryType.MULTIPLE_LOCATIONS;
        case "plannedDate": return EnumUpdateQueryType.PLANNED_DATE;
      }
    }
  }

  public showConfirmAllPublic() {
    this.confirmAllPublic = !this.confirmAllPublic;
  }

  public confirmAllPublicAction() {
    this._formGroup.get("isPublic").patchValue(true);
    this.updateIncidentData({controlKey: "isPublic", value: this._formGroup.get("isPublic").value});
    this._incidentQueriesSrv.updateIncidentValue(EnumUpdateQueryType.SET_ALL_PUBLIC, {id: this._incidentId}).subscribe( result => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate("incident_goToPublicIncidentSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
      this.confirmAllPublic = false;
    }, error => {
      console.log("ERROR SETTING ALL PUBLIC", {error});
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("incident_goToPublicIncidentFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
      this.confirmAllPublic = false;
    });
  }

  public acceptIncident() {
    // Here we check if the organisation work or not with Atal (id:2) => then the success or error message change in case
    const atal = this.externals? this.externals.filter(elem => elem.id == 2): null;
    this._incidentQueriesSrv.acceptIncident(this._incidentId).subscribe( result => {
      const resultData: {acceptIncident: IIncident} = result.data;
      if ( resultData && resultData.acceptIncident) {
        this.isMaskedChangedAfterAccept = true;
        this._formGroup.get("isMasked").setValue(resultData.acceptIncident.isMasked);
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          atal? this._translateSrv.translate("incident_send_to_atal-txt"): this._translateSrv.translate("incident_updateStatusSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }
    }, error => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        atal? this._translateSrv.translate("incident_not_send_to_atal-txt"): this._translateSrv.translate("incident_updateStatusFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  public reopenIncident() {
    if (!this.generalData.duplicateId) {
      this._incidentQueriesSrv.reopenIncidentStatus(this._incidentId).subscribe( result => {
        this.isUpdatingStatus = false;
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("incident_updateStatusSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, error => {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("incident_updateStatusFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      });
    }
  }

  public updateIncidentStatus(type: EnumIncidentStatuses) {
    this.isUpdatingStatus = true;
    this.updateStatusFormData = this._fb.group({
      reason: [null, type == EnumIncidentStatuses.CLOSED || type == EnumIncidentStatuses.DISMISSED ? Validators.required : null],
      type: type
    });
  }

  public cancelUpdateIncidentStatus() {
    this.updateStatusFormData.reset();
    this.isUpdatingStatus = false;
  }

  public confirmUpdateIncidentStatus() {
    if (this.updateStatusFormData.valid) {
      const {type, reason} = this.updateStatusFormData.value;
      this._incidentQueriesSrv.updateIncidentStatus(type, this._incidentId, reason).subscribe( result => {
        this.isUpdatingStatus = false;
        this._snackBar.open(
          this._translateSrv.translate("incident.detail.updateIncident"),
          this._translateSrv.translate("incident_updateStatusSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, error => {
        this._snackBar.open(
          this._translateSrv.translate("incident.detail.updateIncident"),
          this._translateSrv.translate("incident_updateStatusFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      });
    } else {
      this.markFormGroupTouched(this.updateStatusFormData);
    }
  }

  public subscribeIncident() {
    this._inscriptionQueriesSrv.createInscription({ incidentId: this._incidentId}).subscribe( result => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate("incident_updateSubscribeSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
    }, error => {
      console.log("ERROR SUBSCRIPTION", {error});
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("incident_updateSubscribeFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  public unsubscribeIncident() {
    this._inscriptionQueriesSrv.deleteInscription({ incidentId: this._incidentId}).subscribe( result => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate("incident_updateUnsubscribeSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
    }, error => {
      console.log("ERROR SUBSCRIPTION", {error});
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("incident_updateUnsubscribeFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }


  /* END ADDITIONNAL OPTIONS METHODS */

  protected save(): void {
    const objData = {
      confirm: true,
      data: null
    };
    this.afterClosed.next(objData);
  }

  public changeConfirmDuplicate(value: boolean = false) {
    this.confirmDuplicate = value;
  }

  /**
   * @description Merge 2 duplicate incident
   * @author Kevin Palade
   * @memberof ModalDetailIncidentComponent
   */
  public mergeDuplicate() {
    this._incidentQueriesSrv.mergeIncidents(this.generalData.id, this.selectedPotentialDuplicate.id).subscribe(res => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate("incident_updateIncidentMergeSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
      const objData = {
        confirm: true,
        data: {
          reOpenId: this.selectedPotentialDuplicate.id,
        }
      };
      this.afterClosed.next(objData);
      this.modalSrv.closeModal();
    }, err => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("incident_updateIncidentMergeFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  private getDuplicateIncident(search: string) {
    this._incidentQueriesSrv.getDuplicateIncident(this.generalData.reportingNumber, search.toUpperCase()).subscribe((res: any) => {
      this.resetSearchDuplicate();
      if (!res.errors) {
        const { getDuplicateByCustomId } = res.data;
        if (!this._potentialDuplicates.find(dup => dup.id === getDuplicateByCustomId.id)) {
          this.potentialDuplicatesOptions.push({value: getDuplicateByCustomId.id, label: this._translateSrv.translate("incident_numberX-txt", {incidentId: getDuplicateByCustomId.orgacode+"-"+getDuplicateByCustomId.generatedid})});
          this._potentialDuplicates.push(this.formatPotentialDuplicates(getDuplicateByCustomId, true));
        }
        this.formGroupDuplicate.get("selectedDuplicateIncident").setValue(getDuplicateByCustomId.id);
      } else {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("incident_searchIncidentNotExist-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      }
    }, err => {
      this.resetSearchDuplicate();
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("incident_searchIncidentDuplicateFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  public sendSearch(event) {
    if (event && event !== "") {
      this.getDuplicateIncident(event);
    } else {
      this.resetSearchDuplicate();
    }
  }

  public resetSearchDuplicate() {
    const oldAddItem = this._potentialDuplicates.find(dup => dup.isAddedItem);
    if (oldAddItem) {
      this._potentialDuplicates = this._potentialDuplicates.filter(dup => !dup.isAddedItem);
      this.potentialDuplicatesOptions = this.potentialDuplicatesOptions.filter(option => option.value != oldAddItem.id);
      // If the current selected Potential duplicate is the oldAddItem we need to set the selectedPotentialDuplicate
      if (oldAddItem.id === this.selectedPotentialDuplicate.id) {
        if (this.potentialDuplicatesOptions && this.potentialDuplicatesOptions.length > 0) {
          this.formGroupDuplicate.get("selectedDuplicateIncident").setValue(this.potentialDuplicatesOptions[0].value);
        } else {
          this.formGroupDuplicate.get("selectedDuplicateIncident").setValue(null);
        }
      }
    }
  }

  // blank(url) {
  //   window.open(url, "_blank");
  // }

  public cancelModal() {
    // Used for force refresh list after edit
    this.afterClosed.next({});
    this.modalSrv.closeModal();
  }

  public precisionUpdate(categoryDetails) {
    this.categoryDetails = categoryDetails;
    if (this.categoryDetails.length > 0) {
      this.categoryDetailIds = this.categoryDetails.map(cat => cat.id);
      this.displayCategoryAlert = false;
      this.updateIncidentData({controlKey: "categoryId", value: this._formGroup.get("categoryId").value});
    } else {
      this.displayCategoryAlert = true;
    }
  }

  // public precisionUpdate(precisions: ICategoryDetail[]){
  //   this._formGroupDebouncer.next({controlKey: "categoryId", value: this.currentSelectedCategory.id, categoryDetailIds: precisions.map(item => item.id)});
  //   this.displayCategoryAlert = false;
  // }

  public onPress(index){
    this.display = !this.display;
    this.displayCurrentPhotoIndex = index;
  }

  private checkResponsabiliteTiers(histories: IHistory[]) {
    this.isResponsabiliteTiersDisabled = !!histories.filter(h => h.action === "TRANSFER_ORGANISATION_MAIL" && h.content["typeTransfer"] === "Externe").length
    if(this.isResponsabiliteTiersDisabled) this.setAdditionnalOptions()

  }
}
