import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { IFilterCitizens } from '../../interfaces/citizen.interface';
import { IIncidentColumnFilter } from '../../interfaces/incident.interface';
import { IPagination } from '../../interfaces/pagination.interface';
import { GET_LIST_CITIZENS, GET_CITIZEN, DELETE_CITIZEN, GET_CITIZENS_XLS } from './citizen.queries';
@Injectable({
  providedIn: "root"
})
export class CitizenQueriesService {

  public _citizenPagination: IPagination = { limit: 20, page: 1 };
  private _appliedFilters: IFilterCitizens = null;

  constructor(private _apollo: Apollo) { }

  public set citizenPagination(pagination: IPagination) { this._citizenPagination = pagination; }
  public get citizenPagination(): IPagination { return this._citizenPagination; }

  public get appliedFilters(): IFilterCitizens { return this._appliedFilters; }
  public set appliedFilters(appliedFilters: IFilterCitizens) { this._appliedFilters = appliedFilters; }

  public resetPagination() {
    this._citizenPagination = {
      ...this._citizenPagination,
      page: 1
    };
  }

  public listCitizen(pagination?: any) {
    if (this._appliedFilters) {
      Object.keys(this._appliedFilters).forEach(key => {
        if (this._appliedFilters[key] === "") {
          this._appliedFilters[key] = null;
        }
      });
    }
    console.log(this._appliedFilters);
    return this._apollo.watchQuery({
      query: GET_LIST_CITIZENS,
      variables: {
        filter: this._appliedFilters,
        pagination: this._citizenPagination
      },
      fetchPolicy: "cache-and-network"
    });
  }

  public getCitizensList(filter: IFilterCitizens = {}) {
    return this._apollo.query({
      query: GET_LIST_CITIZENS,
      variables: {
        filter
      },
      fetchPolicy: "no-cache"
    });
  }

  public getCitizen(idCitizen: number) {
    return this._apollo.query({
      query: GET_CITIZEN,
      fetchPolicy: "no-cache",
      variables: {
        id: idCitizen
      }
    });
  }

  public deleteCitizen(citizenId: number, formgroup: any) {
    return this._apollo.mutate({
      mutation: DELETE_CITIZEN,
      variables: {
        id: citizenId,
        kind: formgroup
      },
      refetchQueries: [{
        query: GET_LIST_CITIZENS,
        variables: {
          pagination: this._citizenPagination,
          filter: this._appliedFilters
        }
      }]
    });
  }

  public getCitizensXls(tableHeaders: any[]) {
    if (this._appliedFilters) {
      Object.keys(this._appliedFilters).forEach(key => {
        if (this._appliedFilters[key] === "") {
          this._appliedFilters[key] = null;
        }
      });
    }

    const pagination = {
      ...this._citizenPagination,
      limit: 0,
      page: 1
    };

    return this._apollo.query({
      query: GET_CITIZENS_XLS,
      variables: {
        filter: this._appliedFilters,
        tableHeaders,
        pagination: pagination
      },
      fetchPolicy: "no-cache"
    });
  }

}
