import { Component, OnInit, Input, OnChanges, Renderer2, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { AInputs } from "../../facades/inputs";
import { IClickOutsideComponent } from "src/app/facades/directives/click-outside.directive";
import { ErrorMessageService } from "../../facades/services/error-message.service";
import { ISelectCheckboxOption, ISelectCheckboxFormData } from "../../facades/interfaces/selectCheckboxOption.interface";
import { FormArray, FormGroup, FormBuilder } from "@angular/forms";
import { Subscription, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: "app-select-search-multiple-input",
    templateUrl: "./select-search-multiple-input.component.html",
    styleUrls: ["./select-search-multiple-input.component.css"]
})
export class SelectSearchMultipleInputComponent extends AInputs implements OnInit, OnChanges, IClickOutsideComponent {
    private checkboxFormSub: Subscription;
    @Input() public options: ISelectCheckboxOption[] = [];
    @Input() public autoSelectFirst = false;
    @Input() public dataKeyToGet = "email";
    @Input() public razValues$: Subject<boolean>;
    @ViewChild("inputFocus", {static: true}) inputFocus: ElementRef;
    @Output() public search: EventEmitter<string> = new EventEmitter<string>();
    private debouncer: Subject<any> = new Subject();
    public checkboxForm: FormArray;
    public selectedDatas: any[] = [];
    public valueSelect: string = "";
    public selectLabel: string = "";
    public isClosed = true;
    public optionCheckBoxInit: boolean = false;
    public selectedStoredIds: any[] = [];
    public selectedStoredLabels: string[] = [];
    public selectedLabels: string[] = [];
    constructor(public renderer: Renderer2,
        protected _errorMessageSrv: ErrorMessageService,
        private _fb: FormBuilder) {
        super(renderer, _errorMessageSrv);
        this.debouncer.pipe(debounceTime(200)).subscribe( value => {
          this.search.emit(value);
        });
    }

    ngOnInit() {
        if (this.razValues$) {
            this.razValues$.subscribe(() => this.razValues());
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setOptionCheckbox();

        if (changes && changes["disable"] && changes["disable"].currentValue) {
            this.isClosed = true;
        }

        if (changes.group && !changes.group.firstChange) {
          this.valueSelect = "";
          this.search.emit(this.valueSelect);
        }
    }

    private setOptionCheckbox() {
        if (this.checkboxFormSub) { this.checkboxFormSub.unsubscribe(); }
        this.checkboxForm = new FormArray([]);
        let formGroup: FormGroup;
        const formData: ISelectCheckboxFormData[] = [];
        this.options.forEach(option => {
            const data: ISelectCheckboxFormData = {
                isSelected: this.selectedStoredIds.indexOf(option.value) != -1? true : false,
                value: option.value,
                label: option.label
            };
            formGroup = this._fb.group(data);
            this.checkboxForm.push(formGroup);
            formData.push(data);
        });
        this.updateSelectedValues(formData);
        this.checkboxFormSub = this.checkboxForm.valueChanges.subscribe(result => {
            this.updateSelectedValues(result);
        });
        this.optionCheckBoxInit = true;
    }

    private updateSelectedValues(datas: ISelectCheckboxFormData[]) {
        this.selectedDatas = datas.filter(data => data.isSelected).map(data => data.value);
        this.selectedLabels = datas.filter(data => data.isSelected).map(data => data.label);
        const selectedRemovedDatas = datas.filter(data => !data.isSelected).map(data => data.value);
        const selectedRemovedLabels = datas.filter(data => !data.isSelected).map(data => data.label);

        // this.selectedStoredIds = this.selectedStoredIds.concat(this.selectedDatas);
        // this.selectedStoredLabels = this.selectedStoredLabels.concat(this.selectedLabels);

        this.selectedStoredIds = [...new Set([...this.selectedStoredIds, ...this.selectedDatas])];
        this.selectedStoredLabels = [...new Set([...this.selectedStoredLabels, ...this.selectedLabels])];

        this.selectedStoredIds = this.selectedStoredIds.filter(item => !selectedRemovedDatas.includes(item));
        this.selectedStoredLabels = this.selectedStoredLabels.filter(item => !selectedRemovedLabels.includes(item));
        // this.selectLabel = this.options
        //     .filter(option => this.selectedStoredIds.includes(option.value))
        //     .map(option => option.datas ? option.datas[this.dataKeyToGet] : option.label)
        //     .join("; ");
        this.selectLabel = this.selectedStoredLabels.join("; ");
        this.group.get(this.name).patchValue(
          this.selectedStoredIds
            // this.checkboxForm.controls
            //     .filter(c => c.get("isSelected").value)
            //     .map(c => c.get("value").value)
        );
    }

    public showInfo() { this.open(false); }

    /**
     * @description reset checkboxes values
     * @author Gaetan Geenens
     * @memberof SelectCheckboxInputComponent
     */
    public razValues() {
        this.checkboxForm.controls.forEach(c => c.get("isSelected").setValue(false));
    }


    public open(value: boolean = true) {
        if (!this.disable) {
            this.isClosed = value ? !this.isClosed : !value;
            setTimeout(() => {
              this.inputFocus.nativeElement.focus();
            }, 0)
        }
    }

    public findInput(event) {
      this.debouncer.next(this.valueSelect); // Send value for send "this.search" before X seconds
    }

    protected validationCheck() {
        // this.valueSelect = null;
        // this.group.get(this.name).valueChanges.subscribe(result => {
        //   // Use to change the selected value label
        //   this.valueSelect = result ? this.valueSelect : null;
        //   let msg = "";
        //   if (!this.group.get(this.name).errors) {
        //     this.renderer.removeClass(this.inputReference.nativeElement, "select__error");
        //   } else {
        //     this.renderer.addClass(this.inputReference.nativeElement, "select__error");
        //     msg = this._errorMessageSrv.getInputErrors(this.group.get(this.name).errors);
        //   }
        //   this.errorMessage = msg.length > 0 ? msg : null;
        // });
    }

}
