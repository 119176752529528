import { OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ITabs } from "./interfaces/tabs.interface";

export abstract class Tabs implements OnInit {
  @Input() public tabsData: ITabs[] = [];
  @Output() public onChangeTab: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  public selectTab(tabData) {
    this.tabsData = this.tabsData.map( tab => {
      return {...tab, isActive: tab.value === tabData.value};
    });
    this.onChangeTab.emit(this.tabsData);
  }

}
