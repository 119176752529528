import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { EDITABLE_CONTENT, SAVE_EDITABLE_CONTENT, EDITABLE_CONTENTS } from "./copywriting.queries";
import { LANGS } from '../../enum/langs.enum';

@Injectable({
  providedIn: "root"
})
export class CopywritingQueriesService {

  constructor(private _apollo: Apollo) { }

  public getEditableContent(key: string) {
    return this._apollo.query({
      query: EDITABLE_CONTENT,
      variables: { key },
      fetchPolicy: "no-cache"
    });
  }
  
  public getEditableContents() {
    return this._apollo.query({
      query: EDITABLE_CONTENTS,
      fetchPolicy: "no-cache"
    });
  }

  public saveEditableContent(key: string, value: string, lang: LANGS) {
    return this._apollo.mutate({
      mutation: SAVE_EDITABLE_CONTENT,
      variables: { key, value, lang },
      fetchPolicy: "no-cache"
    });
  }

}
