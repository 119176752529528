import { Component, OnDestroy, OnInit } from '@angular/core';
import { chartColor } from "src/app/presentationnal/chart/organisms/chart-colors";
import { ROLES } from "../../../../facades/enum/roles.enum";
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { UserGroupQueriesService } from "src/app/facades/queries/user-group/user-group-queries.service";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { IncidentQueriesService } from '../../../../facades/queries/incident/incident-queries.service';
import { AuthService } from '../../../../../lib/auth/auth.service';
import { TranslateService } from 'src/app/facades/services/translate.service';
import { ChartDataSets } from 'chart.js';
import { CategoryQueriesService } from '../../../../facades/queries/categorie/category-queries.service';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-reporting-page',
  templateUrl: "./reporting-page.component.html",
  styleUrls: ['./reporting-page.component.css']
})
export class ReportingPageComponent implements OnInit, OnDestroy {
  public optionsHeader: IHeaderOptions;
  public lineDiagramData: any;
  public categoryBarDiagramData: any;
  public averageDurationDiagramData: any;
  public treatedIncidentsByGroupDiagramData: any;
  public treatedIncidentsByAgentDiagramData: any;
  public incidentsByThemeDiagramData: any;
  public incidentsByYearAndThemeDiagramData: any;
  public data: any;
  public filterData: any = {
    createdAtRange: {
      start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    }
  };
  private _organisationsSubscription: Subscription;
  private _organisationSubscription: Subscription;
  private _groupsSubscription: Subscription;
  public userOrganisations: any[] = [];
  public selectedOrganisation: any = undefined;
  // public userGroups: any[] = [];
  public isSuperAdmin: Boolean = false;
  public isAdmin: Boolean = false;
  public isManager: Boolean = false;
  categoryThemes: { [id: string]: string };

  constructor(
    private _incidentSrv: IncidentQueriesService,
    private _authSrv: AuthService,
    private _organisationQueriesSrv: OrganisationQueriesService,
    private _userGroupSrv: UserGroupQueriesService,
    private _translationSrv: TranslateService,
    private _categoryQueriesSrv: CategoryQueriesService
  ) { }

  ngOnInit() {
    this.initUserRole();
    this.constructOptionHeader();
    this.getCategoryThemes();
    this.getMyStats();
  }

  private initUserRole() {
    const userRoles = this._authSrv.getRolesCurrent();
    if (userRoles.includes(ROLES.SUPER_ADMIN)) {
      this.isSuperAdmin = true;
      // this.getOrganisations();
    } else if (userRoles.includes(ROLES.ADMIN)) {
      this.isAdmin = true;
      // this.getOrganisations();
      // this.getGroups();
    } else if (userRoles.includes(ROLES.MANAGER)) {
      this.isManager = true;
      // this.getGroups();
    }
  }

  // onOrganisationChange(organisationId: number) {
  //   this.getGroups(organisationId);
  // }

  private constructOptionHeader() {
    const optionsHeader = {
      title: "reporting_pageTitle-txt",
      buttonsOption: {
        size: "small",
        name: "reporting_generate-txt"
      }
    };
    this.optionsHeader = !this.isManager ? {
      ...optionsHeader,
      additionButtonOption: {
        size: "small",
        name: "reporting_generate_public-txt"
      }
    } : optionsHeader;
  }

  public getMyStats(filter = this.filterData) {
    this._incidentSrv.getMyStats(filter).subscribe((result: any) => {
      this.data = result.data;
      this.lineDiagramData = {
        labels: result.data.myStats.numberOfCreatedIncidentsByDate.map(d => d.x),
        datasets: [
          this.constructChartProps(this._translationSrv.translate("stats_status_cree-txt"), result.data.myStats.numberOfCreatedIncidentsByDate, chartColor.created),
          this.constructChartProps(this._translationSrv.translate("stats_status_in_progress-txt"), result.data.myStats.numberOfInprogressIncidentsByDate, chartColor.in_progress),
          this.constructChartProps(this._translationSrv.translate("stats_status_rejected-txt"), result.data.myStats.numberOfDismissedIncidentsByDate, chartColor.dismissed),
          this.constructChartProps(this._translationSrv.translate("stats_status_closed-txt"), result.data.myStats.numberOfClosedIncidentsByDate, chartColor.closed),
          this.constructChartProps(this._translationSrv.translate("stats_status_treated-txt"), result.data.myStats.numberOfTraitedIncidentsByDate, chartColor.solved),
          this.constructChartProps(this._translationSrv.translate("stats_status_fusion-txt"), result.data.myStats.numberOfFusionIncidentsByDate, chartColor.fusion),
        ]
      }

      // number Of Incidents By Category diagram
      this.categoryBarDiagramData = this.buildBarChartData(result.data.myStats.numberOfIncidentsByCategory, result.data.myStats.lastPeriodRange);

      // average duration diagram
      this.averageDurationDiagramData = this.buildBarChartData(result.data.myStats.daysToSolveByPrincipalCategory, result.data.myStats.lastPeriodRange);

      // incidents by theme diagram
      const numberOfIncidentsByThemeName = Object.keys(result.data.myStats.numberOfIncidentsByTheme).reduce((acc, themeId) => {
        const themeName = this.categoryThemes[themeId];
        acc[themeName] = result.data.myStats.numberOfIncidentsByTheme[themeId];
        return acc;
      }, {});
      this.incidentsByThemeDiagramData = this.buildBarChartData(numberOfIncidentsByThemeName, result.data.myStats.lastPeriodRange);

      // incidents by year and theme diagram
      const allThemes = Object.keys(numberOfIncidentsByThemeName);
      this.incidentsByYearAndThemeDiagramData = this.buildIncidentsByYearAndThemeChartData(result.data.myStats.numberOfIncidentsByYearAndTheme, allThemes);

      if (this.isManager || this.isAdmin || this.filterData.organisationId) {
        // treated Incidents By Group Diagram
        this.treatedIncidentsByGroupDiagramData = this.buildBarChartData(result.data.myStats.treatedIncidentsByGroup, result.data.myStats.lastPeriodRange);

        // treated Incidents By Agent Diagram
        this.treatedIncidentsByAgentDiagramData = this.buildBarChartData(result.data.myStats.treatedIncidentsByAgent, result.data.myStats.lastPeriodRange);
      }
    });
  }

  // private getOrganisations() {
  //   if (this._organisationsSubscription) this._organisationsSubscription.unsubscribe()
  //   this._organisationsSubscription = this._organisationQueriesSrv.listOrganisations({ limit: 0, page: 1 }).valueChanges.subscribe((result: any) => {
  //     this.userOrganisations = result.data && result.data.organisations;
  //   })
  // }

  private async getOrganisation(id): Promise<any> {
    return this._organisationQueriesSrv.getOrganisation(id).pipe(take(1), map((result: any) => result.data && result.data.organisation)).toPromise();
  }

  private async getGroup(id): Promise<any>{
    return this._userGroupSrv.getUserGroup(id).pipe(take(1), map((result: any) => result.data && result.data.group)).toPromise();
  }

  // private getGroups(organisationId?: number) {
  //   if (this._groupsSubscription) this._groupsSubscription.unsubscribe()
  //   this._groupsSubscription = this._userGroupSrv.getListGroupUsers({ limit: 0, page: 1 }, organisationId ? { organisationIds: [organisationId] } : null).subscribe((result: any) => {
  //     this.userGroups = result.data && result.data.groups;
  //   })
  // }

  private constructChartProps(label: string, data: any, color: any) {
    return ({
      label: label,
      data: data,
      fill: true,
      lineTension: 0,
      backgroundColor: color.fill,
      pointBackgroundColor: color.stroke,
      borderColor: color.stroke,
      pointHighlightStroke: color.stroke,
      borderCapStyle: "butt",
      barPercentage: 0.8,
      categoryPercentage: 1
    })
  }

  private buildBarChartData(data: { [key: string]: any }, lastPeriodRange: any) {
    const datasets = Object.keys(data);
    const colors = this.generateColors(datasets.length); // Different color for each dataset
    const periods = [
      `${this._translationSrv.translate("stats_previous_period-txt")} (${moment(lastPeriodRange.start).format('DD/MM/YYYY')} - ${moment(lastPeriodRange.end).format('DD/MM/YYYY')})`,
      `${this._translationSrv.translate("stats_current_period-txt")} (${moment(this.filterData.createdAtRange.start).format('DD/MM/YYYY')} - ${moment(this.filterData.createdAtRange.end).format('DD/MM/YYYY')})`
    ];
    return {
      labels: periods,
      datasets: datasets.map((dataset, index) => {
        return {
          label: dataset,
          data: [data[dataset].lastPeriod, data[dataset].current],
          progression: data[dataset].progression,
          backgroundColor: colors[index],
          hidden: (!data[dataset].lastPeriod && !data[dataset].current) ? true : false,
          barPercentage: 0.8,
          categoryPercentage: 1
        }
      }).sort((a, b) => (a.hidden === b.hidden) ? 0 : a.hidden ? 1 : -1)
      // .filter(d => !d.hidden)
    };
  }

  private buildIncidentsByYearAndThemeChartData(results: any[], allThemes: string[]) {
    const chartData = allThemes.reduce((acc, theme) => {
      acc[theme] = {
        label: theme,
        data: []
      };
      return acc;
    }, {});

    results.forEach(entry => {
      const label = this.categoryThemes[entry.category_theme_id];
      const year = entry.year;

      chartData[label].data.push({ x: year, y: parseInt(entry.total_incident_count) });
    });

    const datasets = Object.values(chartData) as ChartDataSets[];
    const colors = this.generateColors(datasets.length); // Different color for each dataset

    return {
      labels: Array.from(new Set(results.map(entry => entry.year))), // Get unique years,
      datasets: datasets.map((dataset, index) => {
        return {
          ...dataset,
          backgroundColor: colors[index],
          hidden: dataset.data.length ? false : true,
          barPercentage: 0.8,
          categoryPercentage: 1
        }
      }).sort((a, b) => (a.hidden === b.hidden) ? 0 : a.hidden ? 1 : -1)
    };
  }

  public filter(data) {
    this.data = null;
    this.filterData = data;
    this.getMyStats(data);
  }

  public async genererRapport(publicRapport = false) {
    const organisationId = this.filterData.organisationId || this._authSrv.getOrganisationId();
    let organisation = null;
    let groupName = "";

    if (organisationId && organisationId !== 'null') {
      organisation = await this.getOrganisation(+organisationId);
    }
    if (this.filterData.groupId) {
      const group = await this.getGroup(+this.filterData.groupId);
      groupName = ` ${this._translationSrv.translate("stats_group-txt")}: ${group.name}`;
    }

    this.saveReport(publicRapport, organisation, groupName);
  }

  private async saveReport(publicRapport = false, organisation, groupName) {

    const todayFormatted = moment().format('DD/MM/YYYY');
    const endDateFilter = moment(this.filterData.createdAtRange.end).format('DD/MM/YYYY');
    const startDateFilter = moment(this.filterData.createdAtRange.start).format('DD/MM/YYYY');

    const statsBoxes = await this.convertToImage('dashboard-stats-incidents-box')

    const statsIncidentsByStatusChart = await this.convertToImage('dashboard-stats-incidents-by_status')
    const statsIncidentsByCategoryChart = await this.convertToImage('dashboard-stats-incidents-by-category')
    const statsIncidentsByAverageChart = await this.convertToImage('dashboard-stats-incidents-by-moyenne-jour')
    const statsIncidentsByThemeChart = await this.convertToImage('dashboard-stats-incidents-by-theme')
    const statsIncidentsByYearAndThemeChart = await this.convertToImage('dashboard-stats-incidents-by-year-theme')
    const statsIncidentsByGroupChart = await this.convertToImage('dashboard-stats-incidents-by-nombre-group')
    const statsIncidentsByAgentChart = await this.convertToImage('dashboard-stats-incidents-by-nombre-agent')

    const doc = new jsPDF();

    doc.addImage("assets/img/fixmystreet.png", "JPEG", 10, 10, 40, 15);

    if (organisation && organisation.imageUrl) {
      doc.addImage(organisation.imageUrl, "JPEG", 178, 10, 20, 20)
    }
    doc.text(`${this._translationSrv.translate("rapport_pdf_title-txt")}`, doc.internal.pageSize.getWidth() / 2, 30, { align: "center" })
    doc.setFontSize(10);
    doc.text(`(${startDateFilter} - ${endDateFilter})`, doc.internal.pageSize.getWidth() / 2, 35, { align: "center" })
    doc.setFont(undefined, 'bold');

    if (organisation) {
      doc.text(`${this._translationSrv.translate("stats_organisation-txt")}: ${organisation.name}`, doc.internal.pageSize.getWidth() / 2, 40, { align: "center" });
    }

    if (groupName) {
      doc.text(`${groupName}`, doc.internal.pageSize.getWidth() / 2, 45, { align: "center" });
    }

    doc.setFont(undefined, 'normal');

    doc.addImage(statsBoxes, "JPEG", 10, 50, 190, 17)
    doc.addImage(statsIncidentsByStatusChart, "JPEG", 10, 72, 190, 50)
    doc.addImage(statsIncidentsByCategoryChart, "JPEG", 10, 135, 190, 50)
    if (statsIncidentsByAverageChart) doc.addImage(statsIncidentsByAverageChart, "JPEG", 10, 200, 190, 50)

    let totalPages = 1;
    if (statsIncidentsByThemeChart) totalPages = 2
    doc.setFontSize(8);
    doc.text(`Page 1 / ${totalPages}`, 187, 288)
    doc.text(todayFormatted, 10, 288)

    if (statsIncidentsByThemeChart) doc.addPage()

    if (statsIncidentsByThemeChart) doc.addImage(statsIncidentsByThemeChart, "JPEG", 10, 10, 190, 50)
    if (statsIncidentsByYearAndThemeChart) doc.addImage(statsIncidentsByYearAndThemeChart, "JPEG", 10, 70, 190, 50)
    if (statsIncidentsByGroupChart && !publicRapport) doc.addImage(statsIncidentsByGroupChart, "JPEG", 10, 130, 190, 50)
    if (statsIncidentsByAgentChart && !publicRapport) doc.addImage(statsIncidentsByAgentChart, "JPEG", 10, 190, 190, 50)

    if (statsIncidentsByThemeChart) {
      doc.text(`Page 2 / ${totalPages}`, 187, 288);
      doc.text(todayFormatted, 10, 288);
    }

    doc.save(`Fixmystreet-rapport${publicRapport ? "-public" : ""}(${todayFormatted})`)
  }

  private convertToImage(id: string): Promise<string> {
    const element = document.getElementById(id)
    return element ? htmlToImage.toPng(element) : Promise.resolve(undefined)
  }

  private generateColors(length: number) {
    const colors = [];
    for (let i = 0; i < length; i++) {
      const color = this.generatePastelColorByIndex(i);
      colors.push(color);
    }
    return colors;
  }

  private generatePastelColorByIndex(i: number) {
    var r = (i * 57) % 256;
    var g = (i * 97) % 256;
    var b = (i * 173) % 256;

    // Increase the brightness to create pastel colors
    r = Math.floor((r + 255) / 2);
    g = Math.floor((g + 255) / 2);
    b = Math.floor((b + 255) / 2);

    return `rgb(${r}, ${g}, ${b})`;
  }

  private getCategoryThemes() {
    this._categoryQueriesSrv.getCategoryThemes().pipe(take(1)).subscribe((result: any) => {
      this.categoryThemes = result.data.categoryThemes.reduce((acc, theme) => {
        acc[theme.id] = theme.name;
        return acc;
      }, {});
    },
      error => {
        console.log("ERROR LOADING THEMES", { error });
      }
    );
  }

  ngOnDestroy(): void {
    if (this._groupsSubscription) this._groupsSubscription.unsubscribe();
    if (this._organisationsSubscription) this._organisationsSubscription.unsubscribe();
  }
}
