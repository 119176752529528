import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { LoginQueriesService } from "src/app/facades/queries/login/login-queries.service";
import { EnumLoginForms, ExternalService } from "src/app/facades/services/external/external.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { LOGIN_ERRORS_MAP } from "../facades/login-errors-map";


@Component({
    selector: "app-login-modify-email-external",
    templateUrl: "./login-modify-email-external.component.html",
    styleUrls: ["./login-modify-email-external.component.css"]
})
export class LoginModifyEmailExternalComponent implements OnInit {

    public formGroup: FormGroup;
    public _EnumLoginForms = EnumLoginForms;
    public inputErrorsLabel: Array<IBoxInfo> | Object = [];
    public resendSuccessMessage: IBoxInfo = null;
    public inputErrorsLabelMap: Map<string, string> = new Map<string, string>([
        ["email", this._translateSrv.translate("login_email-input")],
    ]);
    public resendCodeSuccess: boolean = false;
    constructor(private _fb: FormBuilder,
        public externalSrv: ExternalService,
        private _translateSrv: TranslateService,
        private _errorMessageSrv: ErrorMessageService,
        private _loginSrv: LoginQueriesService) { }

    ngOnInit(): void {
        this.initFormGroup();
    }

    /**
     * @description Init the form group
     * @author Kevin Palade
     * @private
     * @memberof LoginModifyEmailComponent
     */
    private initFormGroup() {
        this.formGroup = this._fb.group({
            email: [null, Validators.required],
        });
    }

    public changeEmail() {
        if (this.formGroup.valid) {
            this._loginSrv.changeUnconfirmedEmail(this.externalSrv.wepUserEmail, this.formGroup.get("email").value).subscribe(result => {
                if(result.errors && result.errors[0]){
                    const code = (<any>result.errors[0]).code;
                    this.getErrors(code);
                } else {
                    this.externalSrv.wepUserEmail = this.formGroup.get("email").value;
                    this.externalSrv.currentForm = EnumLoginForms.CONFIRM_CODE;
                }
            }, error => {
                if (error.graphQLErrors && error.graphQLErrors[0]) {
                    const code = error.graphQLErrors[0].code;
                    this.getErrors(code);
                }
            });
        } else {
            this.markFormGroupTouched(this.formGroup);
            this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.formGroup, this.inputErrorsLabelMap, true);
        }
    }

    public getErrors(errorCode){
        if(errorCode) {
            const errorKey = LOGIN_ERRORS_MAP.get(errorCode);
            if(errorKey) {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate(errorKey)}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            } else {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate("errorOccured-error")}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            }
        } else {
            (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                message: `${this._translateSrv.translate("errorOccured-error")}`,
                type: BOX_INFO_TYPE.DANGER
            });
        }
    }

    public markFormGroupTouched = (formGroup: FormGroup) => {
        (Object as any).values(formGroup.controls).forEach(control => {
            (control as FormControl).updateValueAndValidity();
            control.markAsTouched();
            if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
        });
    };

}
