import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FILES_BLOCK_ACTIONS_ENUM } from "../../facades/enums/files-block-actions.enum";

@Component({
  selector: "app-files-block",
  templateUrl: "./files-block.component.html",
  styleUrls: ["./files-block.component.css"]
})


export class FilesBlockComponent {

  @Input() public disabled = false;
  @Input() public hiddenAction = false;
  // @Input() public overlay : boolean = true;
  @Input() files: Array<string | ArrayBuffer> = [];
  @Output() public fileTouched = new EventEmitter<any>();
  public FILES_BLOCK_ACTIONS_ENUM = FILES_BLOCK_ACTIONS_ENUM;

  constructor() { }

  public touchFile(index: number, imgAction) {
    this.fileTouched.emit({index: index, action: imgAction.action});
  }
}
