import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ROLES } from "src/app/facades/enum/roles.enum";
import { Subscription } from "rxjs";
import { IUserInput, IUser, IOptionsModalCreateUser } from "src/app/facades/interfaces/user.interface";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { UserQueriesService } from "src/app/facades/queries/user/user-queries.service";
import { ModalCreateUserComponent } from "src/app/modal/component/modal-create-user/modal-create-user.component";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { ModalConfirmComponent } from "src/app/modal/component/modal-confirm/modal-confirm.component";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { ITableActionData, EnumTableAction, ITableFilter, EnumTableDataType } from "src/app/presentationnal/table/facades/interfaces/table.interface";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { IFilterBarPagination } from "src/app/presentationnal/bar/facades/interfaces/filter-bar.interface";
import { TranslateService } from "src/app/facades/services/translate.service";
import { headerInputDefaultUser } from "src/app/facades/configs/user-list-filters";
import { KIND } from "src/app/facades/enum/user-kind.enum";
import { AuthService } from 'src/lib/auth/auth.service';
import { XlsService } from "src/app/facades/services/xls/xls.service";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { UserGroupQueriesService } from "src/app/facades/queries/user-group/user-group-queries.service";
import * as moment from "moment";

@Component({
  selector: "app-user-page",
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.css"]
})
export class UserPageComponent implements OnInit, OnDestroy {
  public rolesUser = ROLES;
  public dataModalCreatUser: IOptionsModalCreateUser;
  private _listUserSub: Subscription;
  public formGroup: FormGroup;
  public contentTable: any = [];
  public headerTableOption = [];
  public optionsHeader: IHeaderOptions = {
    title: "user_pageTitle-txt",
    buttonsOption: {
      size: "small",
      name: "general_create-button"
    }
  };
  public paginationFilter: IFilterBarPagination = {
    label: "user_usersOnX-txt",
    listNumber: null,
    listTotalCounter: null,
    totalPage: null,
    currentPage: 1
  };

  public headersTable = [
    { key: "lastName", type: "text", translate: "user_lastname-th", sortable: true },
    { key: "firstName", type: "text", translate: "user_firstname-th", sortable: true },
    { key: "phone", type: "text", translate: "user_phone-th", sortable: true },
    { key: "email", type: "text", translate: "user_mail-th", sortable: true },
    { key: "organisationName", type: "text", translate: "user_organisation-th", sortable: true },
    { key: "groupName", type: "text", translate: "user_groups-th", sortable: true, fixedSize: "40", tooltip: true, tooltipKey: "groupName"},
    { key: "isOrgaAdmin", type: "boolean", translate: "user_isOrgaAdmin-th", size: "medium", sortable: false },
    { key: "createdAt", type: "text", translate: "user_createdAt-th", sortable: true },
    { key: "isActive", type: "boolean", translate: "user_isActive-th", size: "medium", sortable: true },
    { key: "Action", type: "action", translate: "user_actionColumn-th", sortable: false },
  ];

  public filterOptions: ITableFilter[] = headerInputDefaultUser;

  public filtersMapping: Map<string, string> = new Map([
    ["lastName", "lastName"],
    ["firstName", "firstName"],
    ["phone", "phone"],
    ["email", "email"],
    ["organisationId", "organisationId"],
    ["groupId", "groupId"],
    ["isOrgaAdmin", "isOrgaAdmin"],
    ["isActive", "isActive"],
    ["createdAt", "createdAt"],
  ]);
  loadingExport = false;

  constructor(private _fb: FormBuilder,
    private _modalSrv: ModalService,
    private _userQueriesSrv: UserQueriesService,
    private _snackBar: SnackService,
    private _translateSrv: TranslateService,
    private _authSrv: AuthService,
    private _xlsSrv: XlsService,
    private _orgaSrv: OrganisationQueriesService,
    private _groupQueriesSrv: UserGroupQueriesService
  ) { }
  ngOnInit() {
    const appliedFilters = {};
    this.filtersMapping.forEach(value => {
      appliedFilters[value] = null;
    });
    this._userQueriesSrv.appliedFilters = appliedFilters;
    this._userQueriesSrv.resetPagination();
    this.paginationFilter.listNumber = this._userQueriesSrv.userPagination.limit;
    this.initFilterOptions();
    this.listUsers();
  }

  ngOnDestroy(): void {
    this._listUserSub.unsubscribe();
  }

  searchElement(event) {
    // TODO Mette query pour filtrer le tableau
  }

  public initFilter() {
    this.formGroup = this._fb.group({
      searching: null,
    });
  }
  public addUser(userEdit?: any, isAddingForm: boolean = true, createAdmin: boolean = false, createManager: boolean = false, createAgent: boolean = false, createAscertainingAgent: boolean = false, createReadonlyAdmin: boolean = false) {
    this.dataModalCreatUser = {
      user: userEdit,
      createAdmin: createAdmin,
      createManager: createManager,
      createAgent: createAgent,
      createAscertainingAgent: createAscertainingAgent,
      createReadonlyAdmin: createReadonlyAdmin
    };
    const addUser = this._modalSrv.openModal(ModalCreateUserComponent,
      {
        title: isAddingForm ? this._translateSrv.translate("user_create-title") : this._translateSrv.translate("user_edit-title"),
        type: isAddingForm ? MODAL_TYPE.CREATE : MODAL_TYPE.EDIT,
        data: this.dataModalCreatUser,
        modalStyle: {
          modalWidth: "x-large"
        },
      });
    if (addUser) {
      addUser.afterClosed.subscribe(res => {
        let userData: IUserInput = null;
        if (res && res.confirm && res.data) {
          userData = res.data;
          if (userEdit && userEdit.id) {
            delete userData.password;
          }
          this._userQueriesSrv.createUser(userData).subscribe(newUser => {
            if (isAddingForm) {
              this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                this._translateSrv.translate("user_createUserSuccess-txt"),
                STATUS_ALERTS.SUCCESS,
                5000
              );
            } else {
              this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                this._translateSrv.translate("user_updateUserSuccess-txt"),
                STATUS_ALERTS.SUCCESS,
                5000
              );
            }
          }, error => {
            if (isAddingForm) {
              this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleError-txt"),
                this._translateSrv.translate("user_createUserFail-txt"),
                STATUS_ALERTS.DANGER,
                0
              );
            } else {
              this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleError-txt"),
                this._translateSrv.translate("user_updateUserFail-txt"),
                STATUS_ALERTS.DANGER,
                0
              );
            }
          });
        }
      });
    }
  }

  public getGroupAndRoleName(userGroups) {
    let name = "";
    if (userGroups && userGroups.length > 0) {
      userGroups.forEach((item, index) => {
        if (item.group) {
          name += `${item.group.name} (${this._translateSrv.translate("user_groupRole_"+item.onRole.toLowerCase()+"-txt")})`
          if (index < userGroups.length - 1) name += " / ";
        }
      });
    }
    return name;
  }

  private formatUsersForTable(users: any[]){
    return users.map(item => {
      const adminGroups = item.roles.filter(item => item == ROLES.ADMIN);
      return {
        ...item,
        organisationName: item.organisation ? item.organisation.name : ' - ',
        groupName: this.getGroupAndRoleName(item.groupsWithRole),
        isOrgaAdmin: adminGroups.length > 0 ? true : false,
        createdAt: item.loginInfo.createdAt ? moment(item.loginInfo.createdAt).format("DD/MM/YYYY HH:mm") : "",
      }
    })
  }

  public listUsers() {
    if (this._listUserSub) { this._listUserSub.unsubscribe(); }
    this._listUserSub = this._userQueriesSrv.listUsers().valueChanges.subscribe(users => {
      const data: any = users.data;
      if (data && data.users) {
        this.contentTable = this.formatUsersForTable(data.users);
      }

      if (data && data.pagination) {
        this.paginationFilter = {
          ...this.paginationFilter,
          listTotalCounter: data.pagination.total,
          totalPage: data.pagination.totalPage,
          currentPage: data.pagination.page
        };
      }
    }, error => {
      console.log("ERROR GETTING USERS LIST", { error });
    });
  }

  public setRoles(data) {
    const rolesArray = [];
    if (data.isSuperAdmin) {
      rolesArray.push(this.rolesUser.SUPER_ADMIN);
    }
    if (data.isAdmin) {
      rolesArray.push(this.rolesUser.ADMIN);
    }
    if (data.isManager) {
      rolesArray.push(this.rolesUser.MANAGER);
    }
    if (data.isAgent) {
      rolesArray.push(this.rolesUser.AGENT);
    }
    return rolesArray;
  }

  public modalDeleteUser(user: IUser) {
    const deleteId = user.id;
    const options = [
      { label: this._translateSrv.translate("user_deleteSoft-txt"), value: KIND.SOFT },
    ];
    if (this._authSrv.getRolesCurrent().indexOf(ROLES.SUPER_ADMIN) !== -1) {
      options.push({ label: this._translateSrv.translate("user_deleteHard-txt"), value: KIND.HARD });
    }

    this._modalSrv.openModal(ModalConfirmComponent,
      {
        title: this._translateSrv.translate("user_deleteAreYouSure-txt"),
        type: MODAL_TYPE.CONFIRM,
        data: {
          // tslint:disable-next-line: no-unused-expression
          message: `${this._translateSrv.translate("user_deleteConfirm-txt")} <b>${user.firstName}</b>?`,
          actions: [this._translateSrv.translate("user_deleteDefinitively-txt")],
          formGroup: [
            {
              type: "radio",
              name: "deleteUser",
              id: "deleteUser",
              options: options
            }
          ]
        },
        params: { deleteId },
        confirmCallback: this.deleteUser
      }
    );
  }

  public deleteUser = (params: { deleteId: number, formGroupValue: any }) => {
    this._userQueriesSrv.deleteUser(+ params.deleteId, params.formGroupValue.deleteUser).subscribe(result => {
      const { userPagination } = this._userQueriesSrv;
      if (userPagination.page > 1 && this.contentTable.length === 1 && userPagination.page === this.paginationFilter.totalPage) {
        this._userQueriesSrv.userPagination = {
          ...this._userQueriesSrv.userPagination,
          page: this._userQueriesSrv.userPagination.page - 1
        };
        this.listUsers();
      }
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate("user_deleteUserSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
    }, error => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("user_deleteUserFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

  public action(event: ITableActionData) {
    switch (event.action) {
      case EnumTableAction.EDIT:
        this.loadUserData(event.content.id);
        break;
      case EnumTableAction.DELETE:
        this.modalDeleteUser(event.content);
        break;
      case EnumTableAction.FILTER:
        this.processFilters(event.content);
        break;
      case EnumTableAction.SORT:
        this.sort(event.content);
        break;
    }
  }

  private sort(sortObject: any) {
    let { order } = this._userQueriesSrv.userPagination;

    if (!order) {
      order = {};
      this.headersTable.forEach(header => {
        if (header.sortable && header.key !== "Action") { order[header.key] = null; }
      });
    }
    Object.keys(order).forEach(key => {
      if (key === sortObject.key) {
        order[key] = order[key] === "ASC" ? "DESC" : "ASC";
      } else {
        order[key] = null;
      }
    });
    this._userQueriesSrv.userPagination = {
      ...this._userQueriesSrv.userPagination,
      order
    };
    this.listUsers();
  }

  public loadUserData(userId: number) {
    this._userQueriesSrv.getUser(userId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.user) {
        this.addUser(resultData.user, false);
      }
    }, error => {
      console.log("ERROR LOADING USER DATA", { error });
    });
  }

  public setPagination(currentPage) {
    this._userQueriesSrv.userPagination = {
      ...this._userQueriesSrv.userPagination,
      page: currentPage
    };
    this.listUsers();
  }

  public countItemsChanged(nbItemsToDisplay: number) {
    this._userQueriesSrv.userPagination = {
      ...this._userQueriesSrv.userPagination,
      limit: nbItemsToDisplay,
      page: 1
    };
    this.listUsers();
  }

  private processFilters(filterInputValues: any): void {
    const appliedFilters = {};
    Object.keys(filterInputValues).forEach(key => {
      if (this.filtersMapping.get(key)) {
        if (this.filtersMapping.get(key) == "createdAt" && filterInputValues[key] && (typeof filterInputValues[key] === 'string' || filterInputValues[key] instanceof String) ) {
          appliedFilters[this.filtersMapping.get(key)] = null;
        } else {
          appliedFilters[this.filtersMapping.get(key)] = filterInputValues[key];
        }
      }
    });
    this._userQueriesSrv.appliedFilters = appliedFilters;
    this._userQueriesSrv.resetPagination();
    this.listUsers();
  }


  public generateExcel() {
    this.loadingExport = true;
    const headers = this.headersTable.filter(header => header.key != "Action").map(header => ({
      key: header.key,
      translate: header.translate
    }));
    this._userQueriesSrv.getUsersXls(headers).subscribe(result => {
      const exportXls = result.data['usersXls'];
      this._xlsSrv.downloadXls(exportXls);
      this.loadingExport = false;
    }, error => {
        console.log("ERROR GETTING USER XLS LIST", { error });
        this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("user_generateExcelFail-txt"),
            STATUS_ALERTS.DANGER,
            0);
    });
  }


  public async initFilterOptions(): Promise<void> {
    for (const filter of this.filterOptions) {
        switch (filter.type) {
            case EnumTableDataType.selectcheckbox:
            case EnumTableDataType.selectSearchMultiple:
                filter.options = await this.getFilterSelectOptions(filter.key);
                break;
        }
    }
  }


  private async getFilterSelectOptions(fieldName: string, filter: any = null): Promise<ISelectOption[]> {
    switch (fieldName) {
      case "organisationId":
        return await this._orgaSrv.getOrganisationListQuery(filter)
          .toPromise()
          .then(res => {
            return res.data["organisations"].map(orga => ({ label: orga.name, value: orga.id }));
          })
          .catch(e => { throw e; });
      case "groupId":
        return await this._groupQueriesSrv.getListGroupUsers({limit: 0, page: 1}, filter)
          .toPromise()
          .then(res => {
            return res.data["groups"].map(group => ({ label: group.name, value: group.id }));
          })
          .catch(e => { throw e; });
      default:
        return [];
    }
  }

  public async search(event){
    const filterToChange = this.filterOptions.find(item => item.key == event.key);
    let filter = {
      [this.searchFieldMap.get(event.key)]: event.search && event.search != ""? event.search : null
    }

    if(filterToChange) filterToChange.options = await this.getFilterSelectOptions(event.key, filter);
  }

  public searchFieldMap = new Map([
    ["organisationId", "name"],
    ["groupId", "name"],
  ])
}
