import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { InputModule } from '../input/input.module';
import { PipeModule } from 'src/app/facades/pipes/pipe.module';
import { SharedDirectivesModule } from 'src/app/facades/directives/directives.modules';



@NgModule({
  declarations: [TableComponent],
  imports: [
    CommonModule,
    InputModule,
    PipeModule,
    SharedDirectivesModule
  ],
  exports : [ TableComponent]
})
export class TableModule { }
