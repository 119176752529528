import { Injectable } from "@angular/core";
import { ICategory } from "src/app/facades/interfaces/category.interface";

@Injectable()
export class CategoryService {

    public openedCateg = [];

    constructor() {
    }

    public checkShowChildren(category: ICategory) {
        let currentItem = this.openedCateg.find(opened => opened.id === category.id);
        if (!currentItem) {
            currentItem = {id: category.id, showChildren: false};
            this.openedCateg.push(currentItem);
        }
        return currentItem;
    }

    public setShowChildren(categoryId: number) {
        this.openedCateg = this.openedCateg.map(categ => {
            return {
                id: categ.id,
                showChildren: categ.id == categoryId ? !categ.showChildren : categ.showChildren
            };
        });
    }

    public reset() {
        this.openedCateg = [];
    }

}
