import gql from "graphql-tag";

export const SET_CATEGORY_DISPATCHING = gql`
    mutation setCategoryDispatching($categoryDispatching: CategoryDispatchingInput!) {
        categoryDispatching(categoryDispatching: $categoryDispatching){
            organisation{
                id
                name
            }
            category{
                id
                name
            }
            groups{
                id
                name
            }
        }
    }
`;

export const GET_CATEGORY_DISPATCHING = gql`
    query categoryDispatching($categoryId: Int, $organisationId: Int) {
        categoryDispatching(categoryId: $categoryId, organisationId: $organisationId){
            organisation{
                id
                name
            }
            category{
                id
                name
            }
            group{
                id
                name
            }
        }
    }
`;

export const LIST_CATEGORY_DISPATCHINGS = gql`
    query listCategoryDispatchings($pagination: Pagination, $filter: CategoryDispatchingFilter) {
        categoryDispatchings(pagination: $pagination, filter: $filter){
            organisation{
                id
                name
            }
            category{
                id
                name
            }
            group{
                id
                name
            }
            groups{
                id
                name
            }
        }
    }
`;