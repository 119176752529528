import gql from "graphql-tag";

const paginationGroups: string = `
  pagination {
    total
    page
    hasNext
    limit
    totalPage
    hasPrevious
  }
`;
export const GET_LIST_GROUP_USERS = gql`
query getUsersGroupList($filter: FilterGroups, $pagination: Pagination){
  groups(filter: $filter,pagination:$pagination){
    id
    name
    email
    phone
    isActive
    organisationId
    organisation{
      id
      name
    }
  }
  ${paginationGroups}
}`;

export const GET_GROUPS_XLS = gql`
query getGroupsXls($filter: FilterGroups, $pagination: Pagination, $tableHeaders: [TableHeader!]!) {
  groupsXls(filter: $filter,pagination:$pagination, tableHeaders: $tableHeaders) {
    filename,
    content
  }
}`;

export const GET_LIST_GROUP_BY_ORGANISATION = gql`
  query getListGroupByOrganisation($filter: FilterGroups,  $pagination: Pagination) {
    groups(filter: $filter, pagination:$pagination){
      id
      name
      isActive
    }
  }
`;

export const GET_TRANSFERABLE_GROUP_LIST = gql`
  query getTransferableGroupList {
    transferableGroups{
      id,
      name
      agents {
        id
        lastName
        firstName
      }
      ascertainingAgents {
        id
        lastName
        firstName
      }
    }
  }
`;

export const GET_USER_GROUP = gql`
  query getUserGroup($id: Int!){
    group(id: $id){
      id
      name
      email
      phone
      isActive
      notifyGroup
      notifyMembers
      organisationId
      managerIds
      agentIds
      ascertainingAgentIds
      dailyRepport
      reopenIncident
      newIncident
      incidentNews
      solvedIncident
      translation {
        fr
        nl
        de
        en
      }
      reminderReport
      frequences
    }
  }
`;

export const SET_USER_GROUP = gql`
  mutation setUserGroup($group: GroupInput!) {
    group(group: $group){
      id
      name
    }
  }
`;

export const DELETE_USER_GROUP = gql`
  mutation deleteUserGroup($id: Int!){
    deleteGroup(id: $id)
  }
`;

export const GET_MY_GROUPS = gql`
  query getMyGroups($managerOnly: Boolean, $agentOnly: Boolean){
    myGroups(managerOnly: $managerOnly, agentOnly: $agentOnly){
      id,
      name
      agents {
        id
        lastName
        firstName
      }
      ascertainingAgents {
        id
        lastName
        firstName
      }
    }
  }
`;

export const GET_TRANSFERABLE_GROUPS = gql`
  query getTransferableGroups($incidentId: Int!){
    transferableGroups(incidentId: $incidentId){
      id,
      name
    }
  }
`;
export const GET_NO_GROUPS_USER = gql`
  query findDeletedUser($groupId: Int!){
    findDeletedUser(groupId: $groupId){
      lastName,
      firstName
    }
  }
`;
