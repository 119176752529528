import gql from "graphql-tag";

export const GET_AN_ORGANISATION_ARCHIVING = gql `
  query getOrga {
    getOrga {
      id
      config
      organisationId
      organisation {
        name
        translation {
          fr
          nl
          en
          de
        }
      }
      prevArchiving
    }
  }
`
export const GET_BEWAPP = gql `
  query getBeWapp {
    getBeWapp{
      id
      config
      organisationId
      organisation {
        name
        translation {
          fr
          nl
          en
          de
        }
      }
      prevArchiving
    }
  }
`
export const GET_ALL_ORGANISATIONS_ARCHIVING = gql `
  query getAllOrga {
    getAllOrga {
      id
      config
      organisationId
      organisation {
        name
        translation{
          fr
          nl
          en
          de
        }
      }
      prevArchiving
    }
  }
`

export const SET_MANUAL_ARCHIVING = gql `
  mutation manualArchiving($orgaId: Int, $date: Date) {
    manualArchiving(orgaId: $orgaId, date: $date)
  }
`;

export const SET_GENERAL_CONFIG = gql `
  mutation configGeneralArchiving($time: Int) {
    configGeneralArchiving(time: $time)
  }
`;

export const SET_ORGA_CONFIG = gql `
  mutation configOrgaArchiving($orgaId: Int, $time: Int) {
    configOrgaArchiving(orgaId: $orgaId, time: $time)
  }
`

export const GET_ALL_ORGA_ARCHIVES = gql `
  query getAllOrgaArchives {
    getAllOrgaArchives {
      id
      wastype
      categoryId
      category {
        name
        id
      }
      categoryDetails {
        name
        id
      }
      categoryChain
      address
      complementAddress
      addressNumber
      city
      country
      street
      zipCode
      lambertY
      lambertX
      reporterSource
      reporter {
        id
        lastName
        firstName
      }
      priority
      status
      plannedDate
      isPrivateLocation
      isThirdPartyResponsability
      isMultipleLocations
      isPublic
      isMasked
      createdAt
      attachments {
        id
        filePath
        comment
        isPublic
        wastype
        user {
          id
          lastName
          firstName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
      }
      comments {
        id
        comment
        isPublic
        wastype
        user {
          id
          lastName
          firstName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
      }
      histories {
        id
        action
        wasType
        user {
          id
          lastName
          firstName
          organisation {
            name
            id
          }
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        content
        isPublic
        createdAt
        tl_message
      }
      assignment {
        organisation {
          id
          name
        }
        group {
          id
          name
        }
        agent {
          id
          lastName
          firstName
        }
        acceptableOrganisationIds
      }
      potentialDuplicates{
        id
        address
        complementAddress
        addressNumber
        city
        country
        street
        zipCode
        duplicatePercentage
        status
        createdAt
        categoryChain
        attachments {
          id
          filePath
          comment
          isPublic
          user {
            id
            lastName
            firstName
          }
          citizen {
            id
            firstName
            lastName
            email
            phone
          }
          createdAt
        }
        comments {
          id
          comment
          isPublic
          user {
            id
            lastName
            firstName
          }
          citizen {
            id
            firstName
            lastName
            email
            phone
          }
          createdAt
        }
      }
      duplicateId
    }
  }
`