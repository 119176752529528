import { Injectable } from "@angular/core";
import * as moment from "moment";
import { ISnack } from "../../interfaces/snackbar.interface";
import { STATUS_ALERTS } from "../../enum/status.enum";

@Injectable()
export class SnackService {

  private _snackBars: ISnack[] = [];
  public get snackBars(): ISnack[] { return this._snackBars; }

  constructor() { }

  public open(title: string, content: string, status: STATUS_ALERTS = STATUS_ALERTS.DEFAULT, delay: number) {
    const snack: ISnack = { title: title, content: content, delay: delay, status: status };
    snack.uuid = moment().unix(); // Give unique id (number of second since january..)
    this._snackBars.push(snack);

    // Automaticly close the snack after the given delay if different of null or 0
    if ( delay != null && delay != 0 ) {
      setTimeout(() => { this.remove(snack.uuid); }, snack.delay);
    }
  }

  public remove(snackUuid) {
    this._snackBars = this._snackBars.filter(sn => sn.uuid != snackUuid);
  }



}
