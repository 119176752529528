import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "../services/translate.service";

@Pipe({
  name: "translate",
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(private _translateSrv: TranslateService) {}

  transform(key: any, params?: any): any {
    return this._translateSrv.translate(key, params);
  }

}
