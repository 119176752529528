import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';


import { IHeaderOptions } from 'src/app/facades/interfaces/header.interface';
import * as moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridWeek from '@fullcalendar/timegrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { IncidentQueriesService } from 'src/app/facades/queries/incident/incident-queries.service';
import { ModalService } from 'src/app/facades/services/modal/modal.service';
import { TranslateService } from 'src/app/facades/services/translate.service';
import { ModalDetailIncidentComponent } from 'src/app/modal/component/modal-detail-incident/modal-detail-incident.component';
import { EnumIncidentStatuses, IIncident, IIncidentColumnFilter } from "src/app/facades/interfaces/incident.interface";
import { EnumUserRoles } from 'src/app/facades/interfaces/user.interface';
import { EnumIncidentListFilter } from "src/app/facades/enum/incident-list-filters.enum";
import { AuthService } from 'src/lib/auth/auth.service';
import { headerInputDefaultIncident, headerTableDefaultIncident } from 'src/app/facades/configs/incident-list-filters';
import { EnumTableAction, EnumTableDataType, ITableActionData, ITableFilter } from 'src/app/presentationnal/table/facades/interfaces/table.interface';
import { IFilterBarPagination } from 'src/app/presentationnal/bar/facades/interfaces/filter-bar.interface';
import { ISelectOption } from 'src/app/presentationnal/input/facades/interfaces/selectOption.interface';
import { UserQueriesService } from 'src/app/facades/queries/user/user-queries.service';
import { CategoryQueriesService } from 'src/app/facades/queries/categorie/category-queries.service';
import { ISelectMultipleOptions } from 'src/app/presentationnal/input/facades/interfaces/selectMultiple.interface';
import { ICategory } from 'src/app/facades/interfaces/category.interface';
export const CALENDAR_DIRECTION = {
  NEXT: "Next",
  PREVIOUS: "Previous"
}

@Component({
  selector: "app-planning-page",
  templateUrl: "./planning-page.component.html",
  styleUrls: ["./planning-page.component.css"]
})
export class PlanningPageComponent implements OnInit {
  public showed: boolean = false;
  public canManageIncidents: boolean = false;
  public optionsHeader: IHeaderOptions = { title: "planning_pageTitle-txt" };
  public calendarPlugins: any = [dayGridPlugin, timeGridWeek]; // important!
  public calendarOptions: any = null;
  public appointments: any = [];
  public ressources: any = [];
  public currentDate: any = moment().startOf('month');
  public __CALENDAR_DIRECTION = CALENDAR_DIRECTION;
  private _mapQueryType = new Map([
    [EnumIncidentListFilter.ALL_INCIDENTS, "allIncidents"],
    [EnumIncidentListFilter.MY_ATTRIBUTE_INCIDENTS, "myAttributedIncidents"],
    [EnumIncidentListFilter.MY_ASSIGNED_INCIDENTS, "myAssignedIncidents"],
  ]);
  @ViewChild('calendarComponent', { static: true }) calendarComponent: FullCalendarComponent;
  private appliedFilters: IIncidentColumnFilter;
  public headerTable = [
    { key: "id", type: "text", translate: "incident_id-th", size: "tiny", statusColor: true, sortable: true },
    { key: "categoryName", type: "text", translate: "incident_categoryName-th", size: "medium", sortable: false },
    { key: "completeCategory", type: "text", translate: "incident_completeCategory-th", sortable: false, fixedSize: "30", tooltip: true, tooltipKey: "completeCategory" },
    { key: "address", type: "text", translate: "incident_address-th", sortable: true },
    { key: "assignedTo", type: "text", translate: "incident_assignedTo-th", size: "medium", sortable: false },
    { key: "plannedDate", type: "text", translate: "incident_plannedDate-th", size: "medium", sortable: true },
    { key: "administrator", type: "text", translate: "incident_administrator-th", size: "medium", sortable: false },
  ];
  public contentTable = [];
  public incidentList = [];
  public pagination: IFilterBarPagination = {
    label: "incident_incidentsOnX-txt",
    listNumber: null,
    listTotalCounter: null,
    totalPage: null,
    currentPage: 1
  };
  public order: any = null;
  public filterOptions: ITableFilter[] = [
    { key: "id", type: EnumTableDataType.text },
    //{ key: "categoryName", type: EnumTableDataType.selectcheckbox },
    { key: "categoryName", type: EnumTableDataType.selectSearchMultiple },
    { key: "completeCategory", type: EnumTableDataType.selectMultiple, precision: true, precisionKey: "categoryDetails", precisionFilterKey: "categoryDetailIds", scrollable: true },
    { key: "address", type: EnumTableDataType.text },
    //{ key: "assignedTo", type: EnumTableDataType.selectcheckbox },
    { key: "assignedTo", type: EnumTableDataType.selectSearchMultiple },
    { key: "plannedDate", type: EnumTableDataType.date },
    { key: "administrator", type: EnumTableDataType.text, disabled: true },
  ];
  public filtersMapping: Map<string, string> = new Map([
    ["id", "id"],
    ["address", "address"],
    ["assignedTo", "assignedTo"],
    ["categoryName", "parentCategoryIds"],
    ["completeCategory", "categoryId"],
    ['plannedDate', 'atPlannedDate'],
    ["categoryDetailIds", "categoryDetailIds"],
  ]);

  constructor(private _incidentQueriesSrv: IncidentQueriesService,
    private _modalSrv: ModalService,
    private _translateSrv: TranslateService,
    private _authSrv: AuthService,
    private _userQueriesSrv: UserQueriesService,
    private _categoryQueriesSrv: CategoryQueriesService) { }

  ngOnInit() {
    this.appliedFilters = {};
    this.filtersMapping.forEach(value => {
      this.appliedFilters[value] = null;
    });
    this._initCalendarOptions();
    this.loadPlannedIncidents();
    if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN) || this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) || this._authSrv.getRolesCurrent().includes(EnumUserRoles.MANAGER)) {
      this.canManageIncidents = true;
      this.loadListIncidents();
      this.initFilterOptions();
    }
  }

  ngAfterViewInit() {
    this._initializeCalendarEvents();
  }

  /**
   * Initialize all the options for the calendar
   */
  private _initCalendarOptions(): void {
    this.calendarOptions = {
      header: {
        // left: 'dayGridMonth, timeGridWeek',
        left: '',
        center: 'title',
        right: ''
      },
      lang: this._translateSrv.activeLanguage.toLowerCase(),
      locale: this._translateSrv.activeLanguage.toLowerCase(),
      default: this._translateSrv.activeLanguage.toLowerCase(),
      defaultView: "dayGridMonth",
      axisFormat: "HH:mm",
      slotLabelFormat: "HH:mm",
      columnHeaderFormat: "ddd D/M",
      minTime: "07:00:00",
      maxTime: "22:00:00",
      editable: false,
      allDayDefault: true
    }
  }

  /**
   * Initialize all the events for the calendar
   */
  private _initializeCalendarEvents() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.on("eventClick", (clickInfo) => {
      if (clickInfo && clickInfo.event && clickInfo.event.id) this.openIncidentDetail(+clickInfo.event.id);
    })

    calendarApi.on("eventMouseEnter", (event, el, jsEvent, view) => {

    })

    calendarApi.on("eventRender", (data) => {
      const test: HTMLElement = data.el;
      test.innerHTML = `
        <div> 
          ${data.event.title} 
          ${data.event.extendedProps.agent ? " - <span style='font-weight: bold'>" + data.event.extendedProps.agent + "</span>" : ''} 
        </div>
        ${data.event.extendedProps.group ? "<div>" + data.event.extendedProps.group + "</div>" : ''} 
        `;
    });
  }

  /**
   * Load all the incidents to display on the calendar
   */
  public loadPlannedIncidents() {
    const queryType = this._getQueryByCurrentRole();
    this._incidentQueriesSrv.loadPlanningIncidents(queryType, this.currentDate.toISOString()).subscribe((res: any) => {
      if (res && res.data && res.data[this._mapQueryType.get(queryType)]) {
        this.appointments = res.data[this._mapQueryType.get(queryType)].filter(item => item.plannedDate != null).map(item => {
          return {
            ...item,
            start: new Date(item.plannedDate).toISOString(),
            end: new Date(item.plannedDate).toISOString(),
            title: this._getIncidentTitle(item),
            color: this._getIncidentStatusColor(item.status),
            agent: this._getAgent(item),
            group: this._getGroup(item)
          }
        });
        let calendarApi = this.calendarComponent.getApi();
        calendarApi.render();
      }
    }, error => {
      console.log(error);
    })
  }

  /**
   * Load the list of all incidents based on the current user role
   */
  public loadListIncidents() {
    const queryType = this._getQueryByCurrentRole();
    const pagination = {
      limit: 10,
      page: this.pagination.currentPage,
      order: this.order
    }
    this._incidentQueriesSrv.getIncidentList(queryType, null, this.appliedFilters, null, pagination).valueChanges.subscribe((res: any) => {
      const resultData = res && res.data ? res.data : null;
      if (resultData[this._mapQueryType.get(queryType)]) {
        this.incidentList = resultData[this._mapQueryType.get(queryType)];
        this.formatDatasForTable();
        if (resultData && resultData.pagination) {
          this.pagination = {
            ...this.pagination,
            listTotalCounter: resultData.pagination.total,
            totalPage: resultData.pagination.totalPage,
            currentPage: resultData.pagination.page
          };
        }
      }
    }, error => {
      console.log(error);
    })
  }

  /**
   * Format the title data to display on the calendar for an event
   * @param item the incident to format
   * @returns 
   */
  private _getIncidentTitle(item: IIncident) {
    let title = "";
    if (item) title += this._translateSrv.translate('incident_incidentIdX-txt', { id: item.id });
    return title;
  }

  /**
   * Format the agent data to display on the calendar for an event
   * @param item the incident to format
   * @returns 
   */
  private _getAgent(item: IIncident) {
    if (item && item.assignment && item.assignment.agent) return item.assignment.agent.firstName + " " + item.assignment.agent.lastName;
  }

  /**
   * Format the group data to display on the calendar for an event
   * @param item the incident to format
   * @returns 
   */
  private _getGroup(item: IIncident) {
    if (item && item.assignment && item.assignment.group) return item.assignment.group.name;
  }

  /**
   * Return the status color for the incident
   * @param {string} status status of the incident 
   * @returns {string} the color for the status of the incident
   */
  public _getIncidentStatusColor(status: string): string {
    switch (status) {
      case EnumIncidentStatuses.SOLVED:
        return "#73b076";
      case EnumIncidentStatuses.DISMISSED:
        return "#C43B5B";
      case EnumIncidentStatuses.IN_PROGRESS:
        return "#f9b233";
      case EnumIncidentStatuses.CREATED:
        return "#D1D1D1"
      case EnumIncidentStatuses.CLOSED:
        return "#626262";
      default:
        return "white";
    }
  }

  /**
   * Change the month to display on the calendar
   * @param direction Used to know if the month need to be the previous or the next from the current
   */
  public changeMonth(direction: string = CALENDAR_DIRECTION.NEXT) {
    let calendarApi = this.calendarComponent.getApi();
    this.currentDate = moment(calendarApi.getDate()).startOf("month");

    if (direction === CALENDAR_DIRECTION.NEXT) {
      this.currentDate = this.currentDate.add(1, "month");
      calendarApi.next();
    } else {
      this.currentDate = this.currentDate.add(-1, "month");
      calendarApi.prev()
    }

    this.loadPlannedIncidents();
  }

  /**
   * Open the incident detail modal
   * @param {number} incidentId Used to know which incident informations need to be load from the API 
   */
  private openIncidentDetail(incidentId: number) {
    const detailIncident = this._modalSrv.openModal(ModalDetailIncidentComponent,
      {
        title: this._translateSrv.translate("incident_detailIncidentNumberX-title", { incidentId }),
        type: null,
        data: {
          incidentId
        },
        modalStyle: {
          modalWidth: "custom",
        },
        customData: {
          displayCancelButton: false,
          displayConfirmButton: false,
        }
      }
    );
    // TODO Check if we can delete > Useless
    if (detailIncident) {
      detailIncident.afterClosed.subscribe(res => {
        if (res.confirm && res.data && res.data.reOpenId) {
          setTimeout(() => {
            this.openIncidentDetail(res.data.reOpenId);
          }, 500);
        }
        this.loadPlannedIncidents();
        if(this.canManageIncidents) this.loadListIncidents();
      });
    }
  }

  /**
   * Return the good query to use base on the user role
   * @returns 
   */
  private _getQueryByCurrentRole() {
    let query = null;
    if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN) || this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)) {
      query = EnumIncidentListFilter.ALL_INCIDENTS
    } else if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.MANAGER)) {
      query = EnumIncidentListFilter.MY_ATTRIBUTE_INCIDENTS;
    } else {
      query = EnumIncidentListFilter.MY_ASSIGNED_INCIDENTS
    }
    return query;
  }

  /**
   * Handle the action triggered by the user on table click
   * @param tableAction the action type
   */
  public incidentListAction(tableAction: ITableActionData) {
    switch (tableAction.action) {
      case EnumTableAction.EDIT:
        this.openIncidentDetail(tableAction.content.id);
        break;
      case EnumTableAction.FILTER:
        this.processFilters(tableAction.content);
        break;
      case EnumTableAction.SORT:
        this.sort(tableAction.content);
        break;
    }
  }

  /**
   * Format all the datas to display on incident list
   */
  private formatDatasForTable(): void {
    this.contentTable = this.incidentList.map(incident =>
      this.formatIncidentForTable(incident));
  }

  /**
   * Format the data for the display on incident list
   * @param incident the incident to format
   * @returns 
   */
  private formatIncidentForTable(incident: IIncident) {
    let completeCategory = this.formatCompleteCategoryName(incident.categoryChain);

    if (incident.categoryDetails && incident.categoryDetails.length > 0) {
      completeCategory += " ( " + incident.categoryDetails.map(prec => prec.name).join(", ") + " )";
    }

    return {
      id: incident.id,
      categoryName: incident.categoryChain.name,
      completeCategory: completeCategory,
      address: incident.address || "",
      createdAt: incident.createdAt ? moment(incident.createdAt).format("DD/MM/YYYY HH:mm") : "",
      assignedTo: incident.assignment && incident.assignment.agent ? `${incident.assignment.agent.lastName} ${incident.assignment.agent.firstName}` : "",
      plannedDate: incident.plannedDate ? moment(incident.plannedDate).format("DD/MM/YYYY") : "",
      administrator: incident.assignment && incident.assignment.group ? incident.assignment.group.name : "",
      statusEnum: incident.status,
    };
  }

  /**
   * Set the pagination with the current selected page
   * @param currentPage 
   */
  public setPagination(currentPage) {
    if(this.canManageIncidents){
      this.pagination = {
        ...this.pagination,
        currentPage: currentPage
      };
       this.loadListIncidents();
    }
  }

  /**
   * Process the filters to apply on the list
   * @param filterInputValues 
   */
  private processFilters(filterInputValues: any): void {
    if(this.canManageIncidents){
      Object.keys(filterInputValues).forEach(key => {
        if (this.filtersMapping.get(key)) {
          if(this.filtersMapping.get(key) == "atPlannedDate") this.appliedFilters[this.filtersMapping.get(key)] = moment(filterInputValues[key]).toISOString();
          else this.appliedFilters[this.filtersMapping.get(key)] = filterInputValues[key];
        }
      });
      this.loadListIncidents();
    }
  }



  /**
   * Display the incident list on the screen or not
   */
  public changeShowed() {
    this.showed = !this.showed;
  }

  /**
   * Initialize the filter options for all the select and select multiple
   */
  public async initFilterOptions(): Promise<void> {
    for (const filter of this.filterOptions) {
      switch (filter.type) {
        case EnumTableDataType.selectcheckbox:
        case EnumTableDataType.selectSearchMultiple:
          filter.options = await this.getFilterSelectOptions(filter.key);
          break;
      }
    }
  }

  /**
   * Set the options for all the select
   * @param fieldName the name of the select
   * @returns 
   */
  private async getFilterSelectOptions(fieldName: string, filter: any = null): Promise<ISelectOption[]> {
    switch (fieldName) {
      case "assignedTo":
        return await this._userQueriesSrv.getUsersList(filter)
          .toPromise()
          .then(res => res.data["users"].map(u => ({ label: `${u.lastName} ${u.firstName}`, value: u.id })))
          .catch(e => { throw e; });

          case "categoryName":
            let customFilter = { ...filter}
            return await this._categoryQueriesSrv.getCategories(customFilter, this._translateSrv.activeLanguage)
                .toPromise()
                .then(res => res.data["categories"].map(c => ({ label: c.name, value: c.id })))
                .catch(e => { throw e; });
      default:
        return [];
    }
  }

  /**
   * Get the options for all the select multiple
   * @param fieldName the name of the select multiple
   * @returns 
   */
  private async getFilterSelectMultipleOptions(fieldName: string): Promise<ISelectMultipleOptions[]> {
    switch (fieldName) {
      case "completeCategory":
        return await this._categoryQueriesSrv.getMappedCategories()
          .toPromise()
          .then(result => {
            const resultData: { mappedCategories: any[] } = <any>result.data;
            if (resultData && resultData.mappedCategories) {
              return [{
                id: 0,
                name: ""
              },
              ...resultData.mappedCategories];
            }
          })
          .catch(e => { throw e; });

      default:
        return [];
    }
  }

  /**
   * Format the category name
   * @param category 
   * @returns 
   */
  private formatCompleteCategoryName(category: ICategory): string {
    let categoryName = category.name;
    if (category.children.length !== 0) {
      categoryName += ` / ${this.formatCompleteCategoryName(category.children[0])}`;
    }
    return categoryName;
  }

  /**
   * Sort the incident list
   * @param sortObject 
   */
  private sort(sortObject: any) {
    if(this.canManageIncidents){
      if (!this.order) {
        this.order = {};
        this.headerTable.forEach(header => {
          if (header.sortable) { this.order[header.key] = null; }
        });
      }
      Object.keys(this.order).forEach(key => {
        if (key === sortObject.key) {
          this.order[key] = this.order[key] === "ASC" ? "DESC" : "ASC";
        } else {
          this.order[key] = null;
        }
      });
      this.pagination = {
        ...this.pagination,
      };
      this.loadListIncidents();
    }
  }

  public async search(event){
    const filterToChange = this.filterOptions.find(item => item.key == event.key);
    let filter = {
      [this.searchFieldMap.get(event.key)]: event.search && event.search != ""? event.search : null
    }
    
    if(filterToChange) filterToChange.options = await this.getFilterSelectOptions(event.key, filter);
}

public searchFieldMap = new Map([
    ["categoryName", "categoryName"],
    ["assignedTo", "userName"],
])


}
