import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AppComponent } from "./app.component";
import { TranslateService } from "./facades/services/translate.service";
import { AuthService } from "src/lib/auth/auth.service";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { PipeModule } from "./facades/pipes/pipe.module";
import { PagesModule } from "./container/pages/pages.module";
import { ModalModule } from "./modal/modal.module";
import { AppRoutingModule } from "./app-routing.module";
import { GraphQLModule } from "./graphql.module";
import { SnackbarModule } from "./presentationnal/snackbar/snackbar.module";

export function setupTranslateFactory(translateSrv: TranslateService): () => void  {
  return async () => await translateSrv.initLanguage();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    PipeModule,
    PagesModule,
    ModalModule,
    AppRoutingModule,
    GraphQLModule,
    SnackbarModule
  ],
  providers: [
    TranslateService,
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
