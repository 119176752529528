import { Component, OnInit, ViewChild } from "@angular/core";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { Router, ActivatedRoute } from "@angular/router";
import esri = __esri; // Esri TypeScript Types
import { loadModules } from "esri-loader";
import { Subscription, Subject, forkJoin } from "rxjs";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { ILayer, IGeoZoneInput, IGeoZone } from "src/app/facades/interfaces/dispatching.interface";
import { IQueryTaskDatas } from "src/app/presentationnal/maps/facades/interfaces/esri-map.interface";
import { TranslateService } from "src/app/facades/services/translate.service";
import { AuthService } from "src/lib/auth/auth.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { DispatchingQueriesService } from "src/app/facades/queries/dispatching/dispatching-queries.service";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { EnumUserRoles } from "src/app/facades/interfaces/user.interface";
import { ITableActionData, EnumTableAction } from "src/app/presentationnal/table/facades/interfaces/table.interface";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { ModalConfirmComponent } from "src/app/modal/component/modal-confirm/modal-confirm.component";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { UserGroupQueriesService } from "src/app/facades/queries/user-group/user-group-queries.service";
import { IGroup } from "src/app/facades/interfaces/group.interface";
import { ESRI_MAP_CONFIG } from 'src/app/presentationnal/maps/facades/configs/esri-map.config';
import { EsriMapGroupComponent } from 'src/app/presentationnal/maps/organisms/esri-map-group/esri-map-group.component';

@Component({
  selector: "app-dispatching-groupes-page",
  templateUrl: "./dispatching-groupes-page.component.html",
  styleUrls: ["./dispatching-groupes-page.component.css"]
})
export class DispatchingGroupesPageComponent implements OnInit {
  private _dispatchingQuerySub: Subscription;
  public optionsHeader: IHeaderOptions;
  public TYPE_BUTTON_ENUM = TYPE_BUTTON;
  public isLoading = false;
  public layersDatas: { formGroup: FormGroup, options: ISelectOption[] };
  public associationFormGroup: FormGroup;
  public groupsOptions: ISelectOption[] = [];
  private _layersList: ILayer[];
  public selectedLayer: ILayer;
  public selectedExistingGeoZone: IQueryTaskDatas[];
  public lastSelectedLayerZone: IGeoZoneInput;
  public headerLayerTable = [
    { key: "group", type: "text", translate: "dispatching_groupName-th" },
    { key: "polygone", type: "text", translate: "dispatching_polygonName-th" },
    { key: "objectId", type: "text", translate: "dispatching_polygonId-th" },
    { key: "Action", type: "action", translate: "dispatching_actionColumn-th" },
  ];
  public formatedLayerTableDatas: any[] = [];

  protected _organisationLayer: esri.GraphicsLayer;
  protected _selectedGeoZones: IGeoZoneInput[];
  public refreshMap: Subject<IGeoZoneInput[]>;

  @ViewChild(EsriMapGroupComponent, { static: false }) protected map: EsriMapGroupComponent;
  _checkGeozoneQuerySub: Subscription;

  constructor(
    private _router: Router,
    private _translateSrv: TranslateService,
    private _authSrv: AuthService,
    private _fb: FormBuilder,
    private _snackBarSrv: SnackService,
    private _dispatchingQueriesSrv: DispatchingQueriesService,
    private _organisationQueriesSrv: OrganisationQueriesService,
    private _userGroupQueriesSrv: UserGroupQueriesService,
    private _modalSrv: ModalService) {
    this.optionsHeader = {
      title: "dispatching_pageTitle-txt",
      buttons: [
        {
          name: this._translateSrv.translate("dispatching_toOrganisationBasedOnGeographicalZone-button"),
          datas: {
            link: "/dispatching"
          },
          disabled: !this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)
        },
        {
          name: this._translateSrv.translate("dispatching_toGroupBasedOnCategories-button"),
          datas: {
            link: "/dispatching/categories"
          },
          disabled: !this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN)
        },
        {
          name: this._translateSrv.translate("dispatching_toGroupBasedOnGeographicalZone-button"),
          datas: {
            link: "/dispatching/groupes"
          },
          disabled: !this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN)
        },
      ]
    };
    this._selectedGeoZones = [];
    this.refreshMap = new Subject();
  }

  ngOnInit() {
    this.initForm();
    this.loadLayers();
    this.loadOrganisationGeoZone();
    this.loadUserGroups();
    this.isLoading = false;
  }

  public get OrganisationLayer() {
    return this._organisationLayer;
  }

  // DONE
  public headerAction(datas: { link: string }) {
    this._router.navigateByUrl(datas.link);
  }

  // DONE
  private initForm() {
    this.associationFormGroup = this._fb.group({
      groupId: [null, Validators.required],
      layerUrl: null,
      objectId: [null, Validators.required],
      polygonName: [null, Validators.required]
    });
  }

  // DONE
  private loadLayers() {
    this._dispatchingQueriesSrv.getLayers().subscribe(result => {
      const resultData: { layers: ILayer[] } = <any>result.data;
      if (resultData && resultData.layers) {
        this._layersList = resultData.layers;
        this.layersDatas = {
          formGroup: this._fb.group({
            selectedLayers: resultData.layers[0].id
          }),
          options: resultData.layers.map(layer => {
            return {
              value: layer.id,
              label: layer.name
            };
          })
        };
      }
      this.loadGeoZone();
      this.selectedLayer = this._layersList.find(layer => layer.id === this.layersDatas.formGroup.get("selectedLayers").value);
      this.layersDatas.formGroup.get("selectedLayers").valueChanges.subscribe(value => {
        this.selectedLayer = this._layersList.find(layer => layer.id === value);
        this.resetAssociationFormGroupData();
        this.loadGeoZone();
      });
    }, error => {
      console.log("ERROR LOADING LAYERS", { error });
    });
  }
  // DONE
  private loadUserGroups() {
    this._userGroupQueriesSrv.getListGroupByOrganisation().subscribe(result => {
      const resultData: { groups: IGroup[] } = <any>result.data;
      if (resultData && resultData.groups && resultData.groups.length > 0) {
        this.groupsOptions = resultData.groups.filter(g => g.isActive).map(group => {
          return {
            value: group.id,
            label: group.name
          };
        });
        this.associationFormGroup.get("groupId").patchValue(resultData.groups[0].id);
      }
    }, error => {
      console.log("ERROR LOADING GROUP", { error });
    });
  }
  // DONE
  private async loadOrganisationGeoZone() {

    this._dispatchingQueriesSrv.geoZonesByOrganisation(+this._authSrv.getOrganisationId(), true)
      .subscribe(async result => {
        const [EsriGraphic, EsriGraphicLayer, EsriPolygon] = await loadModules([
          "esri/Graphic",
          "esri/layers/GraphicsLayer",
          "esri/geometry/Polygon",
        ]);
        const geozones = result.data["geoZonesByOrganisation"] || null;
        const graphics: esri.Graphic[] = [];
        for (const geozone of geozones) {
          if (geozone.polygons && geozones.length > 0) {
            for (const polygon of geozone.polygons) {
              graphics.push(new EsriGraphic({  // graphic with polygon geometry
                geometry: new EsriPolygon({
                  rings: polygon.coordinates,
                  spatialReference: { wkid: ESRI_MAP_CONFIG.wkid }
                }), // set geometry here
                symbol: {
                  type: "simple-fill",  // autocasts as new SimpleFillSymbol()
                  color: [140, 215, 144, 0],
                  style: "solid",
                  outline: {  // autocasts as new SimpleLineSymbol()
                    color: [0, 0, 0, 1],
                    width: 2
                  }
                },
                spatialReference: { wkid: ESRI_MAP_CONFIG.wkid }
              }));

            }
          }
        }
        this._organisationLayer = new EsriGraphicLayer({
          id: "organisationGeozones",
          graphics,
          spatialReference: { wkid: ESRI_MAP_CONFIG.wkid }
        });
      }, err => {
        console.error(err);
      });
  }

  private loadGeoZone() {
    if (this._dispatchingQuerySub) { this._dispatchingQuerySub.unsubscribe(); }
    const layerId: number = this.layersDatas.formGroup.get("selectedLayers").value;
    this._dispatchingQuerySub = this._dispatchingQueriesSrv.groupsGeoZonesByLayerId(layerId).valueChanges.subscribe(result => {
      const resultData: { geoZonesByLayerId: IGeoZone[] } = <any>result.data;
      if (resultData && resultData.geoZonesByLayerId) {
        const geoZoneAlreadySelected: number[] = this.formatedLayerTableDatas.filter(geoZone => geoZone.selectedLine).map(geoZone => geoZone.id);
        const geoZones = resultData.geoZonesByLayerId.filter(geoZone => geoZone.groups && geoZone.groups.length > 0);
        this.formatedLayerTableDatas = [];
        geoZones.forEach(geoZone => {
          geoZone.groups.filter(g => +g.organisationId === +this._authSrv.getOrganisationId()).forEach(group => {
            this.formatedLayerTableDatas.push(
              {
                id: geoZone.id,
                group: group.name,
                groupId: group.id,
                polygone: geoZone.name,
                objectId: geoZone.objectId,
                geoZoneData: { ...geoZone, groupId: group.id, group: group.name },
                selectedLine: geoZoneAlreadySelected.includes(geoZone.id)
              }
            );
          });
        });
        this.getExistingGraphsSelected();
      }
    }, error => {
      console.log("ERROR LOADING DISPATCHING DATAS", { error });
    });
  }

  public layerListAction(event: ITableActionData) {
    if (event.action === EnumTableAction.EDIT) {
      const layer: any = this.formatedLayerTableDatas.find(data => data.groupId === event.content.groupId);
      const selected = !layer.selectedLine;
      this.formatedLayerTableDatas
        .filter(data => data.groupId === layer.groupId)
        .forEach(z => z.selectedLine = selected)
      // layer.selectedLine = !layer.selectedLine;
      this.getExistingGraphsSelected();
    }
    if (event.action === EnumTableAction.DELETE) {
      this.beforeDeleteGeoZone(event.content.geoZoneData);
    }
  }

  private getExistingGraphsSelected() {
    const selectedGeoZone: IGeoZone[] = this.formatedLayerTableDatas.filter(layer => layer.selectedLine)
      .map(layer => layer.geoZoneData);
    const distinctLayer: Set<number> = new Set(selectedGeoZone.map(geoZone => geoZone.layerId));
    this.selectedExistingGeoZone = Array.from(distinctLayer).map(layerId => {
      const layerDatas: ILayer = selectedGeoZone.find(geoZone => geoZone.layerId === layerId).layer;
      return {
        url: `${layerDatas.url}/${layerDatas.mapServerId}`,
        objectIds: selectedGeoZone.filter(geoZone => geoZone.layerId === layerId).map(geoZone => geoZone.objectId)
      };
    });
  }

  public setDispatchingGeoZoneValues(geoZones: IGeoZoneInput[]) {
    this._selectedGeoZones = geoZones;
    if (geoZones.length !== 0) {
      if (this._checkGeozoneQuerySub) { this._checkGeozoneQuerySub.unsubscribe(); }
      this.isLoading = true;
      this._checkGeozoneQuerySub = this._dispatchingQueriesSrv.checkGeoZonesForGroup(geoZones[0].layerUrl, geoZones.map(gz => gz.objectId)).subscribe(result => {
        const resultData: { checkGeozonesForGroup: boolean[] } = <any>result.data;
        if (resultData.checkGeozonesForGroup) {
          resultData.checkGeozonesForGroup.forEach((r, i) => {
            if (!r && this._selectedGeoZones[i]) {
              this._selectedGeoZones.splice(i, 1);
            }
          });
          let objectIdKey = this.selectedLayer.url.indexOf("geoservices.wallonie.be") == -1? "objectid" : "OBJECTID";
          const objectIds: number[] = this._selectedGeoZones.map(gz => +gz.objectId);
          this.map._selectedGraphics = this.map._selectedGraphics.filter(g => objectIds.includes(+g.attributes[objectIdKey]));
          this.associationFormGroup.get("objectId").patchValue(this._selectedGeoZones.map(gz => gz.objectId).join(", "));
          this.associationFormGroup.get("polygonName").patchValue(this._selectedGeoZones.map(gz => gz.polygonName).join(", "));
          this.map.updateGraphicsSelected(this._selectedGeoZones);
        } else {
          this.resetAssociationFormGroupData();
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        console.log("ERROR CHECKING DISPATCHING GEOZONE", { error });
      });
    } else {
      this.associationFormGroup.get("objectId").patchValue(null);
      this.associationFormGroup.get("polygonName").patchValue(null);
    }
  }

  private resetAssociationFormGroupData() {
    this.associationFormGroup.get("layerUrl").patchValue(null);
    this.associationFormGroup.get("objectId").patchValue(null);
    this.associationFormGroup.get("polygonName").patchValue(null);
    this.lastSelectedLayerZone = null;
  }

  // DONE
  public saveGeoZone() {
    const formValue: any = { ...this.associationFormGroup.getRawValue() };
    delete formValue.polygonName;
    const geoZoneDatas: IGeoZoneInput[] = this._selectedGeoZones.map(sgz => ({
      layerUrl: sgz.layerUrl,
      objectId: sgz.objectId,
      groupId: formValue.groupId
    }))
    this.isLoading = true;
    if (Object.keys(geoZoneDatas).every(key => !!geoZoneDatas[key])) {
      forkJoin(geoZoneDatas.map(gz => this._dispatchingQueriesSrv.setGeoZone(gz))).subscribe(result => {
        this.resetAssociationFormGroupData();
        this._selectedGeoZones = [];
        this.map._selectedGraphics = [];
        this.map.updateGraphicsSelected();

        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("dispatching_setGeoZoneMessageSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
        this.isLoading = false;
      }, error => {
        console.log("ERROR SAVING GEOZONES", { error });
        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("dispatching_setGeoZoneMessageError-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
        this.isLoading = false;
      });
    } else {
      console.log("ERROR FORM");
    }
  }
  // DONE
  public beforeDeleteGeoZone(geoZone: any) {
    this._modalSrv.openModal(ModalConfirmComponent,
      {
        title: this._translateSrv.translate("dispatching_deleteAreYouSure-txt"),
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `${this._translateSrv.translate("dispatching_deleteConfirmFirstPart-txt")} <b>${geoZone.name}</b> ${this._translateSrv.translate("dispatching_deleteConfirmGroupSecondPart-txt")} <b>${geoZone.group}</b>?`,
          // actions: [
          //   "A DEFINIR",
          // ],
        },
        params: { geoZoneId: geoZone.id, groupId: geoZone.groupId },
        confirmCallback: this.deleteGeoZone
      }
    );
  }

  // DONE
  private deleteGeoZone = (params: any) => {
    this._dispatchingQueriesSrv.deleteGroupGeoZone(params.geoZoneId, params.groupId).subscribe(result => {
      const resultData: { deleteGeoZone: boolean } = <any>result.data;
      if (resultData && resultData.deleteGeoZone) {
        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("dispatching_deleteGeoZoneMessageSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }
    }, error => {
      console.log("ERROR DELETING DISPATCHING", { error });
      this._snackBarSrv.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("dispatching_deleteGeoZoneMessageError-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
    });
  }

}
