import { IUserPoint } from './userPoint.interface';
import { IOrganisation } from "./organisation.interface";
import { INotificationConfig } from "./notification.interface";
import { IGroup } from "./group.interface";

export interface IUser {
  userRoles: any;
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  busitIAM?: string;
  isActive: boolean;
  organisationId: number;
  password: string;
  lastLogin: number;
  deletedAt: number;
  organisation: IOrganisation;
  readonlyOrganisations: IOrganisation[];
  managerGroups: IGroup[];
  agentGroups: IGroup[];
  ascertainingAgentGroups: IGroup[];
  higherRole: string;
  roles: EnumUserRoles[];
  notificationConfig: INotificationConfig;
  group: IGroup;
  point?: IUserPoint;
}

export interface IFilterUsers {
  lastName?: string;
  firstName?: string;
  email?: string;
  phone?: string;
  organisationId?: number;
  organisationName?: string;
  roles?: EnumUserRoles[];
}

export interface IUserInput {
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  busitIAM?: string;
  isActive?: boolean;
  organisationId?: number;
  password?: string;
  lastLogin?: number;
  managerGroupIds?: number[];
  agentGroupIds?: number[];
  readonlyOrganisationIds?: number[];
  roles?: EnumUserRoles[];
  notificationConfig?: boolean; // INotificationConfigInput
}

export interface IOptionsModalCreateUser {
  user: IUser;
  createAdmin: boolean;
  createAgent: boolean;
  createManager: boolean;
  createAscertainingAgent: boolean;
  createReadonlyAdmin: boolean;
}

export enum EnumUserRoles {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  AGENT = "AGENT",
  ASCERTAINING_AGENT = "ASCERTAINING_AGENT",
  READONLY_ADMIN = "READONLY_ADMIN"
}

