import { IIncident } from "./incident.interface";
import { IUser } from "./user.interface";
import { ICitizen } from "./citizen.interface";

export enum EnumIncidentActions {
  CREATED = "CREATED",
  STATUS = "STATUS",
  CATEGORY = "CATEGORY",
  PLANNED_DATE = "PLANNED_DATE",
  PRIORITY = "PRIORITY",
  VISIBILITY = "VISIBILITY",
  MASKED = "MASKED",
  THIRD_PARTY_RESPONSABILITY = "THIRD_PARTY_RESPONSABILITY",
  PRIVATE_LOCATION = "PRIVATE_LOCATION",
  MULTIPLE_LOCATIONS = "MULTIPLE_LOCATIONS",
  SET_ALL_PUBLIC = "SET_ALL_PUBLIC",
  TRANSFER_ORGANISATION = "TRANSFER_ORGANISATION",
  TRANSFER_GROUP = "TRANSFER_GROUP",
  ASSIGN_AGENT = "ASSIGN_AGENT",
  ACCEPTED = "ACCEPTED",
  TRANSFER_ORGANISATION_MAIL = "TRANSFER_ORGANISATION_MAIL"
}

export interface IHistory {
  id: number;
  action: EnumIncidentActions;
  incidentId: number;
  incident: IIncident;
  userId: number;
  user: IUser;
  citizenId: number;
  citizen: ICitizen;
  content: string; // JSON
  isPublic: boolean;
  createdAt: number;
  tl_message: string;
  external:External;
}