import { Component, OnInit, Input } from "@angular/core";
import { IExpandContent } from "../../facades/interfaces/expand-content.interface";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: "app-expand",
  templateUrl: "./expand.component.html",
  styleUrls: ["./expand.component.css"]
})
export class ExpandComponent implements OnInit {
  @Input() public expandContent: IExpandContent;
  public editMode: boolean = false;
  private _oldFormData: any;

  constructor() {}

  ngOnInit() {
  }

  public showChildren() {
    this.expandContent.showChildren = !this.expandContent.showChildren;
  }

  public setEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.saveOldData();
    } else {
      this.recoverOldData();
    }
  }

  private saveOldData() {
    this._oldFormData = {...this.expandContent.formDatas.formGroup.value};
  }

  private recoverOldData() {
    Object.keys(this._oldFormData).forEach( key => {
      this.expandContent.formDatas.formGroup.get(key).patchValue(this._oldFormData[key]);
    });
    this._oldFormData = null;
  }

  public saveData() {
    if (this.expandContent.formDatas.formGroup.valid) {
      this.editMode = false;
      this.expandContent.button.action({
        expandData: this.expandContent,
        oldData: this._oldFormData
      });
      this._oldFormData = null;
    } else {
      this.markFormGroupTouched(this.expandContent.formDatas.formGroup);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
        (control as FormControl).updateValueAndValidity();
        control.markAsTouched();
        if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
    });
  }

}
