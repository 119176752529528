import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TabsComponent } from "./organisms/tabs/tabs.component";
import { TabsBarComponent } from "./organisms/tabs-bar/tabs-bar.component";



@NgModule({
  declarations: [
    TabsComponent,
    TabsBarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TabsComponent,
    TabsBarComponent
  ]
})
export class TabsModule { }
