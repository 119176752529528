import { Component, OnInit, Input, OnChanges, SimpleChanges, Renderer2 } from "@angular/core";

import { AInputs } from "../../facades/inputs";
import { ISelectOption } from "../../facades/interfaces/selectOption.interface";
import { IClickOutsideComponent } from "src/app/facades/directives/click-outside.directive";
import { ErrorMessageService } from "../../facades/services/error-message.service";


@Component({
  selector: "app-select-input",
  templateUrl: "./select-input.component.html",
  styleUrls: ["./select-input.component.scss"]
})
export class SelectInputComponent extends AInputs implements OnInit, OnChanges, IClickOutsideComponent {
  @Input() public options: ISelectOption[];
  @Input() public autoSelectFirst = false;
  @Input() public canReset = false;

  public valueSelect: any = null;
  public isClosed = true;

  constructor(public renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
  }

  ngOnInit() {
    const tmp = this.options.filter((d) => d.value === this.group.get(this.name).value);
    this.valueSelect = tmp[0];
    if (tmp.length === 0 && this.autoSelectFirst && this.options[0]) {
      this.valueSelect = this.options[0];
      this.group.get(this.name).patchValue(this.options[0].value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.options) {
      const tmp = this.options.filter((d) => d.value === this.group.get(this.name).value);
      this.valueSelect = tmp[0];
    }
    if (changes.group) {
      this.validationCheck();
    }
  }

  public showInfo() { this.close(null); }

  public open() {
    if (!this.disable) {
      // this.inputReference.nativeElement.classList.add("select_active");
      this.isClosed = !this.isClosed;
    }
  }

  close(option: any): void {
    if (option) {
      this.group.get(this.name).patchValue(option.value);
      this.group.get(this.name).markAsTouched();
      this.valueSelect = option;
    }
    // this.inputReference.nativeElement.classList.remove("select_active");
    this.isClosed = true;
  }

  reset(): void {
    this.group.get(this.name).reset();
    this.valueSelect = null;
  }

  protected validationCheck() {
    this.valueSelect = null;
    this.group.get(this.name).valueChanges.subscribe(result => {
      // Use to change the selected value label
      if ((!this.valueSelect && result) || (this.valueSelect && this.valueSelect.value !== result)) {
        this.valueSelect = this.options.find( option => option.value === result);
      }
      this.valueSelect = result ? this.valueSelect : null;
      let msg = "";
      if (!this.group.get(this.name).errors) {
        this.renderer.removeClass(this.inputReference.nativeElement, "select__error");
      } else {
        this.renderer.addClass(this.inputReference.nativeElement, "select__error");
        msg = this._errorMessageSrv.getInputErrors(this.group.get(this.name).errors);
      }
      this.errorMessage = msg.length > 0 ? msg : null;
    });
  }
}
