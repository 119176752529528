import { ICategory } from "./category.interface";
import { IUser } from "./user.interface";
import { IAttachment } from "./attachment.interface";
import { IComment } from "./comment.interface";
import { IHistory } from "./history.interface";
import { IAssignment } from "./assignment.interface";
import { IContainerComment } from "src/app/presentationnal/comment/facades/interfaces/container-comment.interface";
import esri = __esri; // Esri TypeScript Types
import { ICitizen } from "./citizen.interface";
import { REPORTER_SOURCES, REPORTER_SOURCES_PRECISIONS } from "src/app/container/pages/translations/translation-page/translation-page.component";

export enum EnumIncidentPriorities {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
    HIGHEST = "HIGHEST"
}

export enum EnumIncidentStatuses {
    CREATED = "CREATED",
    DISMISSED = "DISMISSED",
    IN_PROGRESS = "IN_PROGRESS",
    CLOSED = "CLOSED",
    SOLVED = "SOLVED",
    FUSION = "FUSION",
    // REOPEN_INCIDENT = "REOPEN_INCIDENT",
}

export interface IIncident {
    id: number;
    generatedid: string;
    orgacode: string;
    categoryId: number;
    category: ICategory;
    address: string;
    addressNumber: string;
    complementAddress: string;
    zipCode: string;
    city: string;
    country: string;
    municipality?: string;
    street: string;
    latitude: number;
    lambertY: number;
    longitude: number;
    lambertX: number;
    reporter: IUser;
    priority: EnumIncidentPriorities;
    status: EnumIncidentStatuses;
    plannedDate: number;
    isPrivateLocation: boolean;
    isThirdPartyResponsability: boolean;
    isMultipleLocations: boolean;
    isPublic: boolean;
    isMasked: boolean;
    reporterType: REPORTER_TYPES;
    reporterSource: REPORTER_SOURCES;
    reporterSourcePrecision: REPORTER_SOURCES_PRECISIONS;
    createdAt: number;
    assignment: IAssignment;
    attachments: IAttachment[];
    comments: IComment[];
    histories: IHistory[];
    categoryChain: ICategory;
    lastActionDate: number;
    duplicatePercentage?: number;
    potentialDuplicates?: IIncident[];
    duplicateId?: number;
    categoryDetails?: ICategory[];
    citizenId: number;
    citizen: ICitizen;
    avatar_url: string;
    isExtern: boolean;
}

export interface IIncidentDetail {
    categoryId: number;
    address: string;
    isSeveralPlaces: boolean;
}

export interface IIncidentPhotoAndComment {
    comment: string;
    autoSubscribe: boolean;
}

export interface TEMPIIncidentDuplicate {
    id: number;
    risquePourcent: number;
    address: string;
    postalCode: string;
    town: string;
    municipality: string;
    categorie: string;
    status: string;
    comments: TEMPIIncidentComment[];
}

export interface IIncidentDuplicate {
    id?: number;
    generatedid?: string;
    orgacode?: string;
    duplicatePercentage: number;
    address?: string;
    complementAddress?: string;
    zipCode?: string;
    city?: string;
    organisation?: string;
    group?: string;
    municipality?: string;
    agent?: string;
    priority?: string;
    status?: string;
    visibility?: string;
    category?: string;
    isMasked?: boolean;
    isMultipleLocations?: boolean;
    isPrivateLocation?: boolean;
    isThirdPartyResponsability?: boolean;
    attachments?: IAttachment[];
    commentDatas?: IContainerComment[];
    createdAt?: string | Date;
    plannedDate?: string | Date;
    isAddedItem?: boolean;
    statusColor?: string;
}

export interface TEMPIIncidentComment {
    id: number;
    imagePath?: string;
    comment: string;
    user: any;
    isPublic: boolean;
    createdAt: string | Date;
}

// TRUE INTERFACES
export interface IIncidentInput {
    latitude?: number;
    longitude?: number;
    lambertY: number;
    lambertX: number;
    categoryId: number;
    attachments?: File[];
    status?: EnumIncidentStatuses;
    address: string;
    addressNumber?: string;
    zipCode: string;
    city?: string;
    municipality?: string;
    country?: string;
    street?: string;
    comment?: string;
    isMultipleLocations?: boolean;
    autoSubscribe?: boolean;
    categoryDetailIds?: number[];
}

export interface IIncidentUpdate {
    status?: EnumIncidentStatuses;
    complementAddress?: string;
    address?: string;
    addressNumber?: string;
    zipCode?: string;
    city?: string;
    municipality?: string;
    country?: string;
    street?: string;
    isPublic?: boolean;
    plannedDate?: number;
    priority?: EnumIncidentPriorities;
    categoryId?: number;
    isThirdPartyResponsability?: boolean;
    isMasked?: boolean;
    isPrivateLocation?: boolean;
    isMultipleLocation?: boolean;
}

export enum REPORTER_TYPES {
    CITIZEN = "CITIZEN",
    PROFESSIONAL = "PROFESSIONAL",
}

export interface IInputRange {
    start?: Date;
    end?: Date;
}

export interface IIncidentColumnFilter {
    id?: number;
    ids?: number[];
    generatedid?: string;
    address?: string;
    createdAt?: Date;
    createdAtRange?: IInputRange;
    priorities?: EnumIncidentPriorities[];
    statuses?: EnumIncidentStatuses[];
    municipalities?: string[];
    isPublic?: boolean;
    isMasked?: boolean;
    isMultipleLocations?: boolean;
    isPrivateLocation?: boolean;
    isThirdPartyResponsability?: boolean;
    lastActionDate?: Date;
    reportedBy?: REPORTER_TYPES;
    plannedDate?: Date;
    isPlanned?: boolean;
    hasPhoto?: boolean;
    isSolved?: boolean;
    assignedTo?: number[];
    attributedTo?: number[];
    organisationIds?: number[];
    categoryId?: number;
    parentCategoryIds?: number[];
    reporterFirstName?: string;
    reporterLastName?: string;
    reporterPhone?: string;
    reporterEmail?: string;
    reporterSource?: REPORTER_SOURCES;
    INS_Code?: string;
}

export interface IIncidentMap {
    id: number;
    status: EnumIncidentStatuses;
    x: number;
    y: number;
    avatar_url: string;
}

export interface IIncidentMarker {
    incidentId: number;
    graphic: esri.Graphic;

}
