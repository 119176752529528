import { IDispatchLayerList } from "../interfaces/esri-map.interface";

export const ESRI_MAP_CONFIG = {
  wkid: 31370,
  baseTileLayers: [
    {
      key: "orthoLast",
      json: "esri-map-ortho-last",
      url: "http://geoservices.wallonie.be/arcgis/rest/services/IMAGERIE/ORTHO_LAST/MapServer",
      display: true,
      displayInDispatching: true
    },
    // {
    //   key: "annotation",
    //   json: "esri-map-layer-annotation",
    //   url: "https://geoservices.wallonie.be/arcgis/rest/services/DONNEES_BASE/FOND_PLAN_ANNOTATIONS_RW/MapServer",
    //   // url: "http://geoservices.wallonie.be/arcgis/rest/services/DONNEES_BASE/FDC_SPW_ANNOTATIONS/MapServer/",
    //   display: true,
    //   displayInDispatching: true
    // },
    {
      key: "roadNetwork",
      json: "esri-map-layer-road-network",
      url: "https://geoservices.wallonie.be/arcgis/rest/services/DONNEES_BASE/FOND_PLAN_CARTE_RESEAUX_EU_NB/MapServer",
      display: false,
      displayInDispatching: false
    },
  ],
  defaultCenter: {
    x: 164505.383900002,
    y: 91385.1402999982
  }
};

export const LAYERS_LIST: IDispatchLayerList[] = [
  {
    id: 1,
    name: "Province",
    url: "https://geoservices.wallonie.be/arcgis/rest/services/DONNEES_BASE/FOND_PLAN_ANNOTATIONS_RW/MapServer",
    mapServerId: 0,
    attributeKey: "POLYGON_NM",
    symbol: { type: "simple-fill", color: [153, 249, 51, 0.5] },
    used: false
  },
  {
    id: 2,
    name: "Communes",
    url: "https://geoservices.wallonie.be/arcgis/rest/services/IGN/LIMITES_ADMINISTRATIVES/MapServer",
    mapServerId: 0,
    attributeKey: "NAME",
    symbol: { type: "simple-fill", color: [51, 247, 249, 0.5]},
    used: true
  },
  {
    id: 21,
    name: "Arrondissements",
    url: "https://geoservices.wallonie.be/arcgis/rest/services/IGN/LIMITES_ADMINISTRATIVES/MapServer",
    mapServerId: 1,
    attributeKey: "ARRONDISSE",
    symbol: { type: "simple-fill", color: [51, 247, 249, 0.5]},
    used: true
  },
  {
    id: 22,
    name: "Provinces",
    url: "https://geoservices.wallonie.be/arcgis/rest/services/IGN/LIMITES_ADMINISTRATIVES/MapServer",
    mapServerId: 2,
    attributeKey: "PROVINCE",
    symbol: { type: "simple-fill", color: [51, 247, 249, 0.5]},
    used: true
  },
  {
    id: 23,
    name: "Région",
    url: "https://geoservices.wallonie.be/arcgis/rest/services/IGN/LIMITES_ADMINISTRATIVES/MapServer",
    mapServerId: 3,
    attributeKey: "REGION",
    symbol: { type: "simple-fill", color: [51, 247, 249, 0.5]},
    used: true
  },
  {
    id: 3,
    name: "Intercommunale",
    url: "https://geoservices.wallonie.be/arcgis/rest/services/INDUSTRIES_SERVICES/DECHETS/MapServer",
    mapServerId: 13,
    attributeKey: "IC",
    symbol: { type: "simple-fill", color: [227, 139, 79, 0.5]},
    used: true
  },
  // { // ERROR ON THIS LAYER
  //   name: "Route",
  //   url: "https://geoservices.wallonie.be/arcgis/rest/services/MOBILITE/RES_ROUTIER_REGIONAL_THEMATIQUE/MapServer/0",
  //   symbol: { type: "simple-fill", color: [120, 51, 249, 0.5]},
  //   used: false
  // },
  {
    id: 5,
    name: "Faune & Flore",
    url: "https://geoservices.wallonie.be/arcgis/rest/services/FAUNE_FLORE/LIMITES_DNF/MapServer",
    mapServerId: 0,
    attributeKey: "PROPRIETE",
    symbol: { type: "simple-fill", color: [249, 51, 95, 0.5]},
    used: false
  },
];

export const LAYER_IDS: any = {
  geoZoneSelected: "geoZoneSelectedFromTable"
};