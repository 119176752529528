import { Injectable } from "@angular/core";
import { IChartIncident, IChartDataIncident, IDataSetChart } from "../../interfaces/chart.interface";
import * as moment from "moment";
import { EnumIncidentStatuses } from "../../interfaces/incident.interface";
import { chartColor } from "src/app/presentationnal/chart/organisms/chart-colors";
import { TranslateService } from "../translate.service";

@Injectable({
  providedIn: "root"
})
export class ChartService {
  public incidentStatus = EnumIncidentStatuses;
  public dataSetChart: IDataSetChart ;
  public colors = chartColor;

  constructor(private translateSrv: TranslateService) {
    this.dataSetChart = {datasets: [], labels: []};
    moment.locale("fr");
   }

  formatDataIncident(incidentArray: IChartIncident[]) {
    const tmpData: IChartDataIncident  = {date : [], IN_PROGRESS : [], SOLVED : [], CREATED : [], DISMISSED: [], CLOSED : [], FUSION: []};
    if (incidentArray) {
      incidentArray.map(element => {
        tmpData.date.push(moment(element.date).format("l"));
        Object.keys(this.incidentStatus).map(result => {
          tmpData[result].push(element.quantities[result]);
        });
      });
      return this.buildDataIncidentToChart(tmpData);
    }

  }

  public buildDataIncidentToChart(data: IChartDataIncident) {
    if (data) {
      if (this.dataSetChart.datasets.length > 0) {
        this.dataSetChart = {datasets: [], labels: []};
      }
      this.dataSetChart.labels = data.date,
      Object.keys(this.incidentStatus).map((result) => {
        const tmp: any  = {
          label : this.translateSrv.translate(`incident_${this.incidentStatus[result].toLowerCase()}Status`),
          fill : true,
          backgroundColor : this.colors[result.toLowerCase()].fill,
          pointBackgroundColor : this.colors[result.toLowerCase()].stroke,
          borderColor : this.colors[result.toLowerCase()].stroke,
          pointHighlightStroke : this.colors[result.toLowerCase()].stroke,
          borderCapStyle : "butt",
          data : data[result]
        };

        this.dataSetChart.datasets.push(tmp);
      });
      return this.dataSetChart;
    }

  }

  public formatObjectToChartData(obj: Object){
    const resultObj = []
    Object.keys(obj).forEach(k => {
      resultObj.push({ x: k, y: obj[k]})
    })
    return resultObj
  }
}
