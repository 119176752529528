import gql from "graphql-tag";

const paginationOrganisation: string = `
pagination {
  total
  page
  hasNext
  limit
  totalPage
  hasPrevious
}
`;

export const GET_LIST_ORGANISATIONS = gql`
query getOrganisationsList($filter: FilterOrganisations, $pagination: Pagination){
  organisations(filter: $filter,pagination:$pagination){
    id
    name
    code
    isActive
    type
    stage
    email
    phone
    admins{
      id
      lastName
      firstName
    }
    externals{
      id
      name
    }
    INS_Code
    createdAt
  }
  ${paginationOrganisation}
}`;

export const GET_ORGANISATIONS_XLS = gql`
query getOrganisationsXls($filter: FilterOrganisations, $pagination: Pagination, $tableHeaders: [TableHeader!]!){
  organisationsXls(filter: $filter, pagination: $pagination, tableHeaders: $tableHeaders) {
    filename,
    content
  }
}`;

export const GET_LIST_ORGANISATIONS_TEST = gql`
query getOrganisationsList($filter: FilterOrganisations, $pagination: Pagination){
  organisations(filter: $filter,pagination:$pagination){
    id
    name
    code
    isActive
    type
    email
    phone
    admins{
      id
      lastName
      firstName
    }
  }
}`;

export const SET_ORGANISATION = gql`
  mutation setOrganisation($organisation: OrganisationInput!) {
    organisation(organisation: $organisation) {
      id
      name
      code
      type
      email
      phone
      adminIds
      admins {
        id
        lastName
        firstName
      }
      isActive
    }
  }
`;

export const GET_ORGANISATION = gql`
  query getOrganisation($id: Int!){
    organisation(id: $id){
      id
      name
      code
      type
      stage
      email
      phone
      adminIds
      imageUrl
      admins {
        id
        lastName
        firstName
        email
      }
      externals {
        id
        name
      }
      isActive
      translation {
        fr
        en
        nl
        de
      }
    }
  }
`;

export const DELETE_ORGANISATION = gql`
  mutation deleteOrganisation($id: Int!) {
    deleteOrganisation(id: $id)
  }
`;

export const GET_TRANSFERABLE_ORGANISATIONS = gql`
  query getTransferableOrganisations{
    transferableOrganisations {
      id
      name
    }
  }
`;


export const GET_EXISTING_ORGANISATION_CODE = gql`
  query getExistingOrganisationCode($code: String!, $id: Int) {
    existingOrganisationCode(code: $code, id: $id) {
      exist
    }
  }
`;
