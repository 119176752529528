import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ICitizenInput } from "../../interfaces/citizen.interface";
import { LOGIN, SEND_RESET_PASSWORD_MAIL, RESET_PASSWORD, SEND_RESET_PASSWORD_MAIL_CODE, CONFIRM_PASSWORD_CODE, RESEND_CODE, CONFIRM_ACCOUNT, CREATE_CITIZEN, CHANGE_UNCONFIRMED_MAIL, CHECK_EMAIL, LOGIN_EXTERNAL, CREATE_WEP_CITIZEN, SEND_USER_TOKEN_TO_EXTERNAL, VERIFY_TOKEN } from "./login.queries";

@Injectable({
  providedIn: "root"
})
export class LoginQueriesService {

  constructor(private _apollo: Apollo) { }

  public login(user: string, password: string) {
    return this._apollo.query({
      query: LOGIN,
      variables: { user, password },
      fetchPolicy: "network-only"
    });
  }

  public loginExternal(user: string, password) {
    return this._apollo.query({
      query: LOGIN_EXTERNAL,
      variables: { user, password },
      fetchPolicy: "network-only"
    });
  }

  public sendResetPasswordMail(email: string) {
    return this._apollo.query({
      query: SEND_RESET_PASSWORD_MAIL,
      variables: { email },
      fetchPolicy: "no-cache"
    });
  }

  public resetPassword(password: string) {
    return this._apollo.query({
      query: RESET_PASSWORD,
      variables: { password },
      fetchPolicy: "no-cache"
    });
  }

  public sendResetPasswordMailCode(email: string) {
    return this._apollo.query({
      query: SEND_RESET_PASSWORD_MAIL_CODE,
      variables: { email },
      fetchPolicy: "no-cache"
    });
  }

  public confirmPasswordCode(email: string, code: string) {
    return this._apollo.query({
      query: CONFIRM_PASSWORD_CODE,
      variables: { email, code },
      fetchPolicy: "no-cache"
    });
  }

  public verifyToken(){
    return this._apollo.query({
      query: VERIFY_TOKEN,
      fetchPolicy: "no-cache"
    })
  }

  public resendCode(email: string) {
    return this._apollo.query({
      query: RESEND_CODE,
      variables: { email },
      fetchPolicy: "no-cache"
    });
  }

  public confirmAccount(email: string, code: string) {
    return this._apollo.query({
      query: CONFIRM_ACCOUNT,
      variables: { email, code },
      fetchPolicy: "no-cache"
    });
  }

  public createCitizen(citizen: ICitizenInput) {
    return this._apollo.mutate({
      mutation: CREATE_CITIZEN,
      variables: {
        citizen
      }
    });
  }

  public createWepCitizen(citizen: ICitizenInput) {
    return this._apollo.mutate({
      mutation: CREATE_WEP_CITIZEN,
      variables: {
        citizen
      }
    });
  }

  public changeUnconfirmedEmail(oldEmail: string, newEmail: string) {
    return this._apollo.mutate({
      mutation: CHANGE_UNCONFIRMED_MAIL,
      variables: {
        old: oldEmail,
        new: newEmail
      }
    });
  }

  public sendUserTokenToExternal(userMail: string, userToken: string) {
    return this._apollo.mutate({
      mutation: SEND_USER_TOKEN_TO_EXTERNAL,
      variables: {
        userMail,
        userToken
      }
    });
  }

  public checkEmail(email: string) {
    return this._apollo.query({
      query: CHECK_EMAIL,
      variables: {
        email: email
      }
    });
  }
}
