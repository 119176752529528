import { Component, Renderer2, ElementRef } from "@angular/core";
import { Tabs } from "../../facades/tabs";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.css"]
})
export class TabsComponent extends Tabs {

  constructor(
    private _renderer: Renderer2,
    private _elRef: ElementRef) {
      super();
      // this._renderer.addClass(this._elRef.nativeElement, "main-header");
      // this._renderer.addClass(this._elRef.nativeElement, "main-header_sub");
      this._renderer.addClass(this._elRef.nativeElement, "main-header_sub__small-margin");
  }

}
