import gql from "graphql-tag";

export const CREATE_INCIDENT = gql`
  mutation createIncident($incident: IncidentInput!){
    incident(incident: $incident){
      id
      categoryId
      lambertY
      lambertX
      address
      zipCode
      attachments {
        filePath
      }
      comments {
        id
        incidentId
        comment
      }
      isMultipleLocations
    }
  }
`;

export const GET_INCIDENTS = gql`
  query getIncidents($latitude: Float, $longitude: Float, $altitude: Float, $filter: FilterIncidents, $pagination: Pagination) {
    incidents(latitude: $latitude, longitude: $longitude, altitude: $altitude, filter: $filter, pagination: $pagination) {
      id
      categoryId
      category {
        name
      }
      address
      zipCode
      lambertY
      lambertX
      addressNumber
      city
      municipality
      country
      street
      status
      createdAt
    }
  }
`;

export const CHECK_INCIDENT_ID = gql`
  query checkIncidentId($id: Int!){
    incident(id: $id){
      id
    }
  }
`;

export const CHECK_INCIDENT_BY_CUSTOM_ID = gql`
  query checkIncidentByCustomId($id: String!){
    incidentByCustomId(id: $id){
      id
    }
  }
`

export const GET_INCIDENT = gql`
  query getIncident($id: Int!){
    incident(id: $id){
      id
      avatar_url
      generatedid
      orgacode
      wastype
      categoryId
      category {
        name
        id
      }
      categoryDetails {
        name
        id
      }
      categoryChain
      address
      complementAddress
      addressNumber
      city
      municipality
      country
      street
      zipCode
      lambertY
      lambertX
      reporterSource
      citizen{
        id
        lastName
        firstName
        isAmbassador
        user_point{
          item_used{
            name
          }
        }
      }
      reporter {
        id
        lastName
        firstName
        higherRole
        point{
          item_used{
            name
          }
        }
      }
      priority
      status
      plannedDate
      isPrivateLocation
      isThirdPartyResponsability
      isMultipleLocations
      isPublic
      isMasked
      createdAt
      attachments {
        id
        filePath
        comment
        isPublic
        wastype
        external{
          name
        }
        user {
          id
          lastName
          firstName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
          isAmbassador
          user_point{
            item_used{
              name
            }
          }
        }
        createdAt
      }
      comments {
        id
        comment
        isPublic
        wastype
        external{
          name
        }
        user {
          id
          lastName
          firstName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
      }
      histories {
        id
        action
        wasType
        external{
          name
        }
        user {
          id
          lastName
          firstName
          organisation {
            name
            id
          }
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        content
        isPublic
        createdAt
        tl_message
      }
      assignment {
        organisation {
          id
          name
        }
        group {
          id
          name
        }
        agent {
          id
          lastName
          firstName
        }
        acceptableOrganisationIds
      }
      potentialDuplicates{
        id
        generatedid
        orgacode
        address
        complementAddress
        addressNumber
        municipality
        city
        country
        street
        zipCode
        duplicatePercentage
        status
        createdAt
        categoryChain
        attachments {
          id
          filePath
          comment
          isPublic
          user {
            id
            lastName
            firstName
          }
          citizen {
            id
            firstName
            lastName
            email
            phone
          }
          createdAt
        }
        comments {
          id
          comment
          isPublic
          user {
            id
            lastName
            firstName
          }
          citizen {
            id
            firstName
            lastName
            email
            phone
          }
          createdAt
        }
      }
      duplicateId
    }
  }
`;

const updateIncidentDataReturn: string = `
  id
  address
`;

export const UPDATE_INCIDENT = gql`
  mutation updateIncident($id: ID!, $incident: IncidentUpdate!){
    updateIncident(id: $id, incident: $incident){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_ALL_PUBLIC = gql`
  mutation setIncidentAllPublic($id: ID!){
    setIncidentAllPublic(id: $id){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_STATUS = gql`
  mutation setIncidentStatus($id: ID!, $status: INCIDENT_STATUSES!){
    setIncidentStatus(id: $id, status: $status){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_CATEGORY = gql`
  mutation setIncidentCategory($id: ID!, $categoryId: ID!, $categoryDetailIds: [ID!]){
    setIncidentCategory(id: $id, categoryId: $categoryId, categoryDetailIds: $categoryDetailIds){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_COMPLEMENTARY_ADDRESS = gql`
  mutation setIncidentComplementaryAddress($id: ID!, $complementAddress: String!){
    setIncidentComplementaryAddress(id: $id, complementAddress: $complementAddress){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_PLANNED_DATE = gql`
  mutation setIncidentPlannedDate($id: ID!, $plannedDate: Date){
    setIncidentPlannedDate(id: $id, plannedDate: $plannedDate){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_PRIORITY = gql`
  mutation setIncidentPriority($id: ID!, $priority: INCIDENT_PRIORITIES!){
    setIncidentPriority(id: $id, priority: $priority){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_VISIBILITY = gql`
  mutation setIncidentVisibility($id: ID!, $isPublic: Boolean!){
    setIncidentVisibility(id: $id, isPublic: $isPublic){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_MASKED = gql`
  mutation setIncidentMasked($id: ID!, $isMasked: Boolean!){
    setIncidentMasked(id: $id, isMasked: $isMasked){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_THIRD_PARTY_RESPONSABILITY = gql`
  mutation setIncidentThirdPartyResponsability($id: ID!, $isThirdPartyResponsability: Boolean!){
    setIncidentThirdPartyResponsability(id: $id, isThirdPartyResponsability: $isThirdPartyResponsability){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_PRIVATE_LOCATION = gql`
  mutation setIncidentPrivateLocation($id: ID!, $isPrivateLocation: Boolean!){
    setIncidentPrivateLocation(id: $id, isPrivateLocation: $isPrivateLocation){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_INCIDENT_MULTIPLE_LOCATIONS = gql`
  mutation setIncidentMultipleLocations($id: ID!, $isMultipleLocations: Boolean!){
    setIncidentMultipleLocations(id: $id, isMultipleLocations: $isMultipleLocations){
      ${updateIncidentDataReturn}
    }
  }
`;

export const SET_ALL_INCIDENTS_MASKED_BY_ORGANISATION = gql`
  mutation setIncidentsMaskedByOrganisation($isMasked: Boolean!, $incidentIds: [ID]){
    setIncidentsMaskedByOrganisation(isMasked: $isMasked, incidentIds: $incidentIds){
      id
    }
  }
`

/* FILTERED INCIDENTS QUERIES */

const incidentsQueryDatas: string = `
  id
  generatedid
  orgacode
  categoryId
  category {
    name
    id
    categoryDetails{
      id
      name
    }
  }
  categoryDetails{
    id
    name
  }
  address
  zipCode
  lambertY
  lambertX
  longitude
  latitude
  addressNumber
  city
  municipality
  country
  street
  createdAt
  lastActionDate
  categoryChain
  avatar_url
  assignment{
    agent {
      lastName
      firstName
      id
    }
    group{
      name
      id
    }
  }
  status
  plannedDate
  isPublic
  isThirdPartyResponsability
  isMasked
  isPrivateLocation
  priority
  reporterType
  reporterSource
  reporterSourcePrecision
  isExtern
  reporter{
    lastName
    firstName
    phone
    email
    id
    roles
    higherRole
    point{
      item_used{
        name
      }
    }
  }
  citizen{
    id
    firstName
    lastName
    phone
    email
    isAmbassador
    user_point{
      item_used{
        name
      }
    }
  }
  comments{
    id
    comment
    user{
      id
      firstName
      lastName
    }
    citizen {
      id
      firstName
      lastName
      email
      phone
    }
    createdAt
  }
`;

const paginationIncident: string = `
pagination {
  total
  page
  hasNext
  limit
  totalPage
  hasPrevious
}
`;


export const ALL_INCIDENTS = gql`
  query allIncidents($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    allIncidents(filter: $filter, pagination: $pagination, year: $year){
      ${incidentsQueryDatas}
    }
    ${paginationIncident}
  }
`;

export const MY_ATTRIBUTED_INCIDENTS = gql`
  query myAttributedIncidents($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    myAttributedIncidents(filter: $filter, pagination: $pagination, year: $year){
      ${incidentsQueryDatas}
    }
    ${paginationIncident}
  }
`;

export const MY_ASSIGNED_INCIDENTS = gql`
  query myAssignedIncidents($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    myAssignedIncidents(filter: $filter, pagination: $pagination, year: $year){
      ${incidentsQueryDatas}
    }
    ${paginationIncident}
  }
`;

export const MY_SUBSCRIBED_INCIDENTS = gql`
  query mySubscribedIncidents($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    mySubscribedIncidents(filter: $filter, pagination: $pagination, year: $year){
      ${incidentsQueryDatas}
    }
    ${paginationIncident}
  }
`;

export const INCIDENTS_BY_IDS = gql`
  query incidentByIds($ids: [ID!]!, $filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    incidentByIds(ids: $ids, filter: $filter, pagination: $pagination, year: $year){
      ${incidentsQueryDatas}
    }
    ${paginationIncident}
  }
`;

export const MY_REPORTED_INCIDENTS = gql`
  query myReportedIncidents($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    myReportedIncidents(filter: $filter, pagination: $pagination, year: $year){
      ${incidentsQueryDatas}
    }
    ${paginationIncident}
  }
`;

export const MY_TRANSFERRED_INCIDENTS = gql`
  query myTransferredIncidents($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    myTransferredIncidents(filter: $filter, pagination: $pagination, year: $year){
      ${incidentsQueryDatas}
    }
    ${paginationIncident}
  }
`;


export const ALL_INCIDENTS_FOR_MAP = gql`
  query allIncidentsForMap($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    allIncidentsForMap(filter: $filter, pagination: $pagination, year: $year)
    ${paginationIncident}
  }
`;

export const MY_ATTRIBUTED_INCIDENTS_FOR_MAP = gql`
  query myAttributedIncidentsForMap($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    myAttributedIncidentsForMap(filter: $filter, pagination: $pagination, year: $year)
    ${paginationIncident}
  }
`;

export const MY_ASSIGNED_INCIDENTS_FOR_MAP = gql`
  query myAssignedIncidentsForMap($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    myAssignedIncidentsForMap(filter: $filter, pagination: $pagination, year: $year)
    ${paginationIncident}
  }
`;

export const MY_SUBSCRIBED_INCIDENTS_FOR_MAP = gql`
  query mySubscribedIncidentsForMap($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    mySubscribedIncidentsForMap(filter: $filter, pagination: $pagination, year: $year)
    ${paginationIncident}
  }
`;

export const INCIDENTS_BY_IDS_FOR_MAP = gql`
  query incidentByIdsForMap($ids: [ID!]!, $filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    incidentByIdsForMap(ids: $ids, filter: $filter, pagination: $pagination, year: $year)
    ${paginationIncident}
  }
`;

export const MY_REPORTED_INCIDENTS_FOR_MAP = gql`
  query myReportedIncidentsForMap($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    myReportedIncidentsForMap(filter: $filter, pagination: $pagination, year: $year)
    ${paginationIncident}
  }
`;

export const MY_TRANSFERRED_INCIDENTS_FOR_MAP = gql`
  query myTransferredIncidentsForMap($filter: IncidentColumnFilter, $pagination: Pagination, $year: Int){
    myTransferredIncidentsForMap(filter: $filter, pagination: $pagination, year: $year)
    ${paginationIncident}
  }
`;

// MANAGMENT QUERIES
const updateStatusDatas = `
  id
  status
  isMasked
  assignment{
    organisation{
      id
    }
  }
`;

export const ACCEPT_INCIDENT = gql`
  mutation acceptIncident($incidentId: Int!){
    acceptIncident(incidentId: $incidentId){
      ${updateStatusDatas}
    }
  }
`;

export const CLOSE_INCIDENT = gql`
  mutation closeIncident($incidentId: Int!, $reason: String!){
    closeIncident(incidentId: $incidentId, reason: $reason){
      ${updateStatusDatas}
    }
  }
`;

export const REOPEN_INCIDENT = gql`
  mutation reopenIncident($incidentId: Int!, $reason: String){
    reopenIncident(incidentId: $incidentId, reason: $reason){
      ${updateStatusDatas}
    }
  }
`;

export const DISMISS_INCIDENT = gql`
  mutation dismissIncident($incidentId: Int!, $reason: String!){
    dismissIncident(incidentId: $incidentId, reason: $reason){
      ${updateStatusDatas}
    }
  }
`;

export const SOLVE_INCIDENT = gql`
  mutation solveIncident($incidentId: Int!, $infos: String){
    solveIncident(incidentId: $incidentId, infos: $infos){
      ${updateStatusDatas}
    }
  }
`;

export const ASSIGN_INCIDENT = gql`
  mutation assignIncident($incidentId: Int!, $groupId: Int, $agentId: Int!){
    assignIncident(incidentId: $incidentId, groupId: $groupId, agentId: $agentId){
      id
      assignment {
        organisation {
          name
        }
        group {
          name
        }
        agent {
          lastName
          firstName
        }
      }
    }
  }
`;

export const TRANSFERT_INCIDENT_TO_GROUP = gql`
  mutation transfertIncidentToGroup($incidentId: Int!, $groupId: Int!) {
    transfertIncidentToGroup(incidentId: $incidentId, groupId: $groupId){
      id
      status
      assignment {
        group {
          name
        }
        agent {
          lastName
          firstName
        }
      }
    }
  }
`;

export const TRANSFERT_INCIDENT_TO_ORGANISATION = gql`
  mutation transfertIncidentToOrganisation($incidentId: Int!, $organisationId: Int!) {
    transfertIncidentToOrganisation(incidentId: $incidentId, organisationId: $organisationId){
      id
      generatedid
      orgacode
      status
      assignment {
        organisation {
          name
        }
        group {
          name
        }
        agent {
          lastName
          firstName
        }
      }
    }
  }
`;

export const CAN_MODIFY_INCIDENT = gql`
  query canModifyIncident($id: ID!) {
    canModifyIncident(id: $id)
  }
`;

export const GET_POSSIBLE_DUPLICATES = gql`
  query possibleDuplicates($position: PositionInput!, $categoryId: ID) {
    possibleDuplicates(position: $position, categoryId: $categoryId) {
      id
      orgacode
      generatedid
      categoryId
      category {
        name
        children{
          id
          name
        }
        categoryDetails{
          id
          name
        }
      }
      categoryDetails{
        id
        name
      }
      categoryChain
      addressNumber
      address
      zipCode
      city
      municipality
      complementAddress
      assignment{
        organisation{
          name
        }
        group{
          name
        }
        agent{
          lastName
          firstName
        }
      }
      status
      priority
      createdAt
      isPublic
      isMultipleLocations
      plannedDate
      isPrivateLocation
      isMasked
      duplicatePercentage
      isThirdPartyResponsability
      attachments{
        id
        filePath
        user{
          lastName
          firstName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
      }
      comments{
        id
        comment
        user{
          firstName
          lastName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
      }
    }
  }
`;

export const ADD_COMMENTS_AND_ATTACHMENTS = gql`
  mutation addCommentsAndAttachments($incidentId: ID!, $files: [Upload], $comments: [String], $autoSubscribe: Boolean) {
    addCommentsAndAttachments(incidentId: $incidentId, files: $files, comments: $comments, autoSubscribe: $autoSubscribe){
      id
    }
  }
`;

export const MUNICIPALITY_LIST = gql`
  query municipalityList($filter: FilterMunicipality) {
    municipalityList(filter: $filter)
  }
`;

export const MERGE_INCIDENTS = gql`
  mutation mergeIncidents($fromIncidentId: ID!, $toIncidentId: ID!){
    mergeIncidents(fromIncidentId: $fromIncidentId, toIncidentId: $toIncidentId){
      id
    }
  }
`;

export const GET_DUPLICATE = gql`
  query getDuplicate($baseIncidentId: ID!, $duplicateId: ID!) {
    getDuplicate(baseIncidentId: $baseIncidentId, duplicateId: $duplicateId) {
      id
      generatedid
      orgacode
      categoryChain
      addressNumber
      address
      zipCode
      city
      municipality
      complementAddress
      status
      createdAt
      attachments{
        id
        filePath
        user{
          lastName
          firstName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
      }
      comments{
        id
        comment
        user{
          firstName
          lastName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
      }
      duplicatePercentage
    }
  }
`;

export const GET_DUPLICATE_BY_CUSTOM_ID = gql`
  query getDuplicateByCustomId($baseIncidentId: String!, $duplicateId: String!) {
    getDuplicateByCustomId(baseIncidentId: $baseIncidentId, duplicateId: $duplicateId) {
      id
      generatedid
      orgacode
      categoryChain
      addressNumber
      address
      zipCode
      city
      municipality
      complementAddress
      status
      createdAt
      attachments{
        id
        filePath
        user{
          lastName
          firstName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
      }
      comments{
        id
        comment
        user{
          firstName
          lastName
        }
        citizen {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
      }
      duplicatePercentage
    }
  }
`;

export const SEND_PDFMAIL = gql`
  mutation sendPdfMail($incidentId: ID!, $addresses: [String!]!, $isPro:Boolean!, $isIntern:Boolean!, $subject:String, $message: String) {
    sendPdfMail(incidentId: $incidentId, addresses: $addresses, isPro:$isPro, isIntern:$isIntern, subject:$subject, message: $message)
}
`;

export const GET_EXISTING_YEARS = gql`
  query existingYearsList {
    existingYearsList
  }
`;

export const GET_STATISTICS_INCIDENT = gql`
  query dailyStatistics($filter : DailyStatisticFilter!) {
    dailyStatistics(filter : $filter){
      date
      quantities{
        CREATED
        IN_PROGRESS
        DISMISSED
        SOLVED
        CLOSED
      }
    }
  }
`;

export const GET_LAST_HISTORIES = gql`
  query lastHistories($filter : LastActivityFilter!) {
    lastHistories(filter : $filter){
     createdAt
     incidentId
     action
     tl_message
     incident{
       orgacode,
       generatedid
      status
      address
       assignment{
        organisation{
          name
        }
      }
     }
    }
  }
`;

export const CHECK_LOCATION = gql`
  query checkLambertLocation($x: Float!, $y: Float!, $categoryId: Int) {
    checkLambertLocation(x: $x, y: $y, categoryId: $categoryId)
  }
`;

export const EXPORT_XLS = gql`
  query exportXls($filter: IncidentColumnFilter,  $year: Int, $queryType: INCIDENT_LIST_FILTER!, $tableHeaders: [TableHeader!]!, $ids: [ID], $pagination: Pagination) {
    incidentsXls(filter: $filter, year: $year, queryType: $queryType, tableHeaders: $tableHeaders, ids: $ids, pagination: $pagination) {
      filename,
      content
    }
  }
`;

export const GET_MY_STATS = gql`
  query myStats($filter: IncidentStatsFilter) {
    myStats(filter: $filter)
}`;
