import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { IExpandContentCategory } from "../../facades/interfaces/expand-content-categories.interface";
import { CategoryService } from "src/app/container/pages/categories/facades/categories.service";
import { CategoryThemeService } from "src/app/facades/services/theme/categorytheme.service";
import { TranslateService } from "src/app/facades/services/translate.service";


@Component({
  selector: "app-expand-category",
  templateUrl: "./expand-category.component.html",
  styleUrls: ["./expand-category.component.css"]
})
export class ExpandCategoryComponent implements OnInit {
  @Input() public expandContent: IExpandContentCategory;
  @Input() isSuperAdmin: boolean = true;
  public editMode: boolean = false;
  private _oldFormgroupData: any;

  constructor(
    private _categorySrv: CategoryService,
    private _categoryThemeSrv: CategoryThemeService,
    private _translateSrv: TranslateService) {}

  ngOnInit() {
    console.log("expandContent.toggleDisabled", this.expandContent.toggleDisabled);
  }

  public showChildren() {
    this.expandContent.showChildren = !this.expandContent.showChildren;
    this._categorySrv.setShowChildren(this.expandContent.category.id);
  }

  public setEditMode() {
    this.editMode = !this.editMode;
    if (!this.expandContent.showChildren) {
      this.showChildren();
    }
    if (this.editMode) {
      this.saveOldData();
    } else {
      this.recoverOldData();
    }
  }

  private saveOldData() {
    this._oldFormgroupData = {...this.expandContent.formGroup.getRawValue()};
  }

  private recoverOldData() {
    Object.keys(this._oldFormgroupData).forEach( key => {
      this.expandContent.formGroup.get(key).patchValue(this._oldFormgroupData[key]);
    });
    this._oldFormgroupData = null;
  }

  public saveData(event) {
    if (this.expandContent.formGroup.valid) {
      this.editMode = false;
      this.expandContent.actions.edit.click({
        expandData: this.expandContent,
        oldData: this._oldFormgroupData
      });
      this._oldFormgroupData = null;
    } else {
      this.markFormGroupTouched(this.expandContent.formGroup);
    }
  }

  public deleteData(event) {
    this.expandContent.actions.delete.click(this.expandContent.category);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
        (control as FormControl).updateValueAndValidity();
        control.markAsTouched();
        if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
    });
  }

  public get themeOptions() {
    return this._categoryThemeSrv.themes && this._categoryThemeSrv.themes.map(theme => ({
      value: theme.id,
      label: theme.translation[this._translateSrv.activeLanguage.toLowerCase()].name

    }))
  }
}
