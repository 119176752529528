import { Component, Input, ViewChildren, QueryList, ElementRef, Renderer2, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { ISelectOption } from "../../facades/interfaces/selectOption.interface";
import { AInputs } from "../../facades/inputs";
import { ErrorMessageService } from "../../facades/services/error-message.service";

@Component({
  selector: "app-radio-input",
  templateUrl: "./radio-input.component.html",
  styleUrls: ["./radio-input.component.scss"]
})
export class RadioInputComponent extends AInputs implements OnInit, OnChanges {
  @Input() public options: ISelectOption[];
  @Input() public isInline: boolean = false;
  @Input() public isGrid: boolean = false;
  @ViewChildren("input") protected inputRadioReference: QueryList<ElementRef>;
  public selectedValue: string | number;

  constructor(protected renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService,
              protected _elementRef: ElementRef) {
    super(renderer, _errorMessageSrv);
  }

  ngOnInit(): void {
    if (this.isInline) {
      this.renderer.addClass(this._elementRef.nativeElement, "radio-inline");
    }
    if (this.isGrid) {
      this.renderer.addClass(this._elementRef.nativeElement, "radio-grid");
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.group && changes.options && this.options ) {
      if (this.group.get(this.name).value !== null && this.group.get(this.name).value !== undefined) {
        this.selectedValue = this.group.get(this.name).value;
      } else {
        this.selectedValue = this.options[0].value;
        this.group.get(this.name).patchValue(this.options[0].value);
        this.group.get(this.name).markAsTouched();
      }
    }
    this.validationCheck();
  }

  public selectCheckbox(checkboxValue, disable = false) {
    if(!disable){
      this.group.get(this.name).patchValue(checkboxValue);
      this.group.get(this.name).markAsTouched();
    }
  }

  protected validationCheck() {
    this.group.get(this.name).valueChanges.subscribe(result => {
      this.selectedValue = result;
      this.inputRadioReference.toArray().forEach(element => {
        if (!this.group.get(this.name).errors) {
        this.renderer.removeClass(element.nativeElement, "radio__error");
        } else {
          this.renderer.addClass(element.nativeElement, "radio__error");
        }
      });
    });
  }
}
