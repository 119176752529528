import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

enum DISPLAY_FORM {
  LOGIN = "LOGIN",
  ASK_RESET = "ASK_RESET",
  ENTER_RESET_CODE = "ENTER_RESET_CODE",
  RESET_PASSWORD = "RESET_PASSWORD"
}

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.css"]
})
export class LoginPageComponent implements OnInit {

  public displayedForm: DISPLAY_FORM;
  public token: string;
  public resetEmail: string;

  constructor (
    private _router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this._router.url === "/login") { this.displayedForm = DISPLAY_FORM.LOGIN; }
    if (this._router.url === "/login/askReset") { this.displayedForm = DISPLAY_FORM.ASK_RESET; }
    if (this._router.url.startsWith("/login/resetPassword/")) {
      this.displayedForm = DISPLAY_FORM.RESET_PASSWORD;
      this._route.firstChild.paramMap.subscribe(params => {
        this.token = params.get("token");
      });
    }
    this._router.navigate(["/login"]);
  }

  public goToAskReset() {
    this.displayedForm = DISPLAY_FORM.ASK_RESET;
  }

  public goToEnterResetCode(email: string) {
    this.resetEmail = email;
    this.displayedForm = DISPLAY_FORM.ENTER_RESET_CODE;
  }

  public goToResetPassword(token: string) {
    this.token = token;
    this.displayedForm = DISPLAY_FORM.RESET_PASSWORD;
  }

  public goToLogin() {
    this.resetEmail = null;
    this.displayedForm = DISPLAY_FORM.LOGIN;
  }

}
