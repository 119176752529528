import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, DoCheck } from "@angular/core";
import { IFilterBarPagination } from "src/app/presentationnal/bar/facades/interfaces/filter-bar.interface";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.css"]
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() paginationOptions: IFilterBarPagination;
  @Input() nbItemsBeforeAfter: number = 2; // number of items displayed after and before current page
  @Output() onCurrentPage = new EventEmitter<number>();

  public currentPage: number = 1;
  public paginationBuild = [];
  public currentTotalPage: number = null;
  constructor() { }

  ngOnInit() {
    this.currentTotalPage = this.paginationOptions.totalPage;
    this.buildPagination();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentPage != this.paginationOptions.currentPage) {
      this.currentPage = this.paginationOptions.currentPage;
      this.buildPagination();
    }
    if (this.currentTotalPage != this.paginationOptions.totalPage) {
      this.currentTotalPage = this.paginationOptions.totalPage;
      this.buildPagination();
    }
  }

  private buildPagination() {
    this.paginationBuild = [];

    if (this.currentPage > 1 && this.currentPage < this.paginationOptions.totalPage) {
      this.paginationBuild.push(this.currentPage);
    }

    let countItem: number = 0;
    let currentNumber: number = this.currentPage + 1;


    while (countItem < this.nbItemsBeforeAfter && currentNumber < this.paginationOptions.totalPage) {
      this.paginationBuild.push(currentNumber);
      currentNumber++;
      countItem++;
    }
    currentNumber = this.currentPage - 1;
    countItem = 0;
    while (countItem < this.nbItemsBeforeAfter && currentNumber > 1) {
      this.paginationBuild.unshift(currentNumber);
      currentNumber--;
      countItem++;
    }
  }


  /**
   * choosePage
   */
  public choosePage(page: number) {
    if (page > 0 && page <= this.paginationOptions.totalPage) {
      this.onCurrentPage.emit(page);
    }
  }

  /**
   * changePage
   */
  // public changePage(isNextPage) {
  //   const tmpPage = isNextPage ? this.currentPage + 1 : this.currentPage - 1;
  //   if (tmpPage > 0 && tmpPage <= this.paginationOptions.totalPage ) {
  //     this.choosePage(tmpPage);
  //     this.buildPagination();
  //   }
  // }
}
