import { Component, OnInit, Input } from "@angular/core";
import { EnumTagColor } from "../../facades/interfaces/enums/tag-color.enum";

@Component({
  selector: "app-tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.css"]
})
export class TagComponent implements OnInit {
  @Input() public color: EnumTagColor = EnumTagColor.WHITE;
  constructor() { }

  ngOnInit() {
  }

}
