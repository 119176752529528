import {Injectable} from "@angular/core";
import {Apollo} from "apollo-angular";
import {BUY_ITEMS, GET_ALL_ITEMS, GET_BUYED_ITEMS, SET_AVATAR_USED} from "./shop.queries";
import {IItemsByRoles} from "../../interfaces/item.interface";

@Injectable({
  providedIn: "root"
})
export class ShopQueriesService {

  constructor(private _apollo: Apollo) { }

  public getAllItems() {
    return this._apollo.query({
      query: GET_ALL_ITEMS,
      fetchPolicy: "no-cache"
    });
  }

  public buyItems(itemsToBought: IItemsByRoles) {
    return this._apollo.mutate({
      mutation: BUY_ITEMS,
      variables: {
        itemsBought: itemsToBought
      },
      fetchPolicy: "no-cache"
    })
  }

  public getBuyedItems() {
    return this._apollo.query({
      query: GET_BUYED_ITEMS,
      fetchPolicy: "no-cache"
    });
  }

  public setAvatarUsed(id: number) {
    return this._apollo.mutate({
      mutation: SET_AVATAR_USED,
      variables: {
        id: id
      },
      fetchPolicy: "no-cache"
    })
  }

}
