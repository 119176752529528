import { Component, OnInit, ViewChild, Input, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import * as Chart from 'chart.js';
import { chartColor } from "../chart-colors";
@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.css"]
})
export class ChartComponent implements OnInit, OnDestroy, OnChanges {
  public myChart: Chart;
  public colors = chartColor;
  @ViewChild("lineChart", { static: true }) private chartRef;
  @Input() diagramData: any;

  constructor() { }

  ngOnInit() {
    this.myChart = new Chart(this.chartRef.nativeElement, {
      type: "line",
      data: {
        labels: this.diagramData.labels,
        datasets: this.diagramData.datasets
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            stacked: true,
          }]
        },
        animation: {
          duration: 750,
        },
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.myChart) {
      this.myChart.destroy();
    }
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.myChart.destroy();
  }
}
