import gql from "graphql-tag";


export const updateTranslationsMutation = gql`
mutation translations($translations: JSON!){
    translations(translations: $translations)
}
`;

export const translationQuery = gql`
query translation{
    translation
}
`;

export const allTranslationsQuery = gql`
query translations{
    translations
}
`;