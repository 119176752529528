import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { EnumIncidentStatuses } from 'src/app/facades/interfaces/incident.interface';

@Component({
  selector: "app-signal-box",
  templateUrl: "./signal-box.component.html",
  styleUrls: ["./signal-box.component.css"]
})
export class SignalBoxComponent implements OnInit, OnChanges {

  @Input() icon: string = null;
  @Input() label: string = null;
  @Input() status: string = null;
  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }

}
