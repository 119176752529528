

import { OnDestroy } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { Subject } from "rxjs";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { MODAL_ACTION_TYPE } from "src/app/facades/enum/modal/modal-action-type.enum";

export abstract class ModalContent implements OnDestroy {

    // tslint:disable-next-line: variable-name
    private _buttonClickedSub: Subscription;
    public afterClosed: Subject<any>;

    constructor(protected modalSrv: ModalService) {
        this.afterClosed = new Subject<any>();
        this._buttonClickedSub = this.modalSrv.buttonClicked.subscribe(res => {
            if ( res.actionType === MODAL_ACTION_TYPE.CONFIRM ) {
                this.confirmModal();
            } else {
                this.cancelModal(res.isCloseCrossButton);
            }
        });
    }

    public abstract confirmModal(): void;
    public abstract cancelModal(isCloseCrossButton?: boolean): void;

    ngOnDestroy() {
        if ( this._buttonClickedSub ) {
            this.afterClosed.complete();
            this._buttonClickedSub.unsubscribe();
        }
    }
}
