import gql from "graphql-tag";

export const CREATE_ATTACHMENT = gql`
  mutation createAttachment($attachment: AttachmentInput, $file: Upload){
    attachment(attachment: $attachment, file: $file) {
      id
    }
  }
`;

export const UPDATE_ATTACHMENT = gql`
  mutation updateAttachment($id: ID!, $attachment: AttachmentUpdate!) {
    updateAttachment(id: $id, attachment: $attachment){
      id
      comment
      isPublic
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($id: ID!){
    deleteAttachment(id: $id)
  }
`;