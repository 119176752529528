import { Injectable } from "@angular/core";
import { TranslateService } from "src/app/facades/services/translate.service";
import { FormGroup } from "@angular/forms";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";

@Injectable()
export class ErrorMessageService {

    private _errorMsgConfig: Map<string, (data: any) => string> = new Map<string, (data: any) => string>([
        [ "maxlength", (data: any) => this._translateSrv.translate("general_maxLengthInputError-txt", {max: data.requiredLength}) ],
        [ "minlength", (data: any) => this._translateSrv.translate("general_minLengthInputError-txt", {min: data.requiredLength}) ],
        [ "required", (data: any) => this._translateSrv.translate("general_requiredInputError-txt") ],
        [ "number", (data: any) => this._translateSrv.translate("general_numberInputError-txt") ],
        [ "matchPassword", (data: any) => this._translateSrv.translate("general_matchPasswordInputError-txt") ],
        [ "max", (data: any) => this._translateSrv.translate("general_maxInputError-txt", {max: data.max})  ],
        [ "min", (data: any) => this._translateSrv.translate("general_minInputError-txt", {min: data.min})  ],
        [ "email", (data: any) => this._translateSrv.translate("general_emailInputError-txt")],
        [ "emailMatcher", (data: any) => this._translateSrv.translate("general_emailInputError-txt")],
        [ "emailAlreadyUse", (data: any) => this._translateSrv.translate("general_emailAlreadyUseInputError-txt") ],
        [ "pattern", (data: any) => this._translateSrv.translate("general_pattern_invalid-txt") ],
        [ "existingCode", (data: any) => this._translateSrv.translate("general_code_exist-txt") ],
    ]);

    constructor(private _translateSrv: TranslateService) {
    }

    /**
     * @description Get the message errors for an input
     * @author Kevin Palade
     * @param {*} errors
     * @returns
     * @memberof ErrorMessageService
     */
    public getInputErrors(errors) {
        let msg = "";
        Object.keys(errors).forEach(key => {
            msg += this._errorMsgConfig.get(key)(errors[key]);
        });
        return msg;
    }

    /**
     * @description Get the message errors for a formGroup
     * @author Kevin Palade
     * @param {FormGroup} form
     * @param {Map<string, string>} [controlLabelMap]
     * @param {boolean} [formatForInfoBox=false]
     * @returns
     * @memberof ErrorMessageService
     */
    public getFormErrors(form: FormGroup, controlLabelMap?: Map<string, string>, formatForInfoBox: boolean = false) {
        const messageErrors: Object = {};
        if (form) {
            Object.keys(form.controls).forEach(control => {
                if (form.controls[control].errors) {
                    let msg = "";
                    Object.keys(form.controls[control].errors).forEach(errorKey => {
                        msg = this.getInputErrors(form.controls[control].errors);
                    });
                    if ( controlLabelMap && controlLabelMap.get(control) ) {
                        messageErrors[control] = {label: controlLabelMap.get(control), msg: msg};
                    }
                }
            });
        }
        return formatForInfoBox ? this.formatForInfoBox(messageErrors) : messageErrors;
    }

    /**
     * @description Format the errors into the box info needed format
     * @author Kevin Palade
     * @param {*} errorsObject
     * @returns {Array<IBoxInfo>}
     * @memberof ErrorMessageService
     */
    public formatForInfoBox(errorsObject): Array<IBoxInfo> {
        const array = [];
        Object.keys(errorsObject).forEach(key => {
            array.push({
                message: `${errorsObject[key].label}: ${errorsObject[key].msg}`,
                type: BOX_INFO_TYPE.DANGER
            });
        });
        return array;
    }
}
