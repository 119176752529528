export const translationKeys: { [appName: string]: string[] } = {
    "BACK_OFFICE": [
        "general_refreshList-button",
        "general_exportExcel-button",
        "general_create-button",
        "general_cancel-button",
        "general_continue-button",
        "general_confirm-button",
        "general_return-button",
        "general_save-button",
        "general_delete-button",
        "general_edit-button",
        "general_maxLengthInputError-txt",
        "general_minLengthInputError-txt",
        "general_requiredInputError-txt",
        "general_numberInputError-txt",
        "general_matchPasswordInputError-txt",
        "general_maxInputError-txt",
        "general_minInputError-txt",
        "general_emailAlreadyUseInputError-txt",
        "general_snackbarTitleSuccess-txt",
        "general_snackbarTitleError-txt",
        "general_snackbarTitleWarning-txt",
        "general_snackbarTitleInfo-txt",
        "general_formNotCorrectlyFill-txt",
        "general_fileNeedToBePicture-txt",
        "general_availableColumnList-txt",
        "login_mail-input",
        "login_password-input",
        "login_login-button",
        "login_forgotPassword-txt",
        "login_retrievePassword-link",
        "login_serverIsUnreachable-error",
        "login_authentificationFailed-error",
        "login_password_confirm-input",
        "login_backToLogin-link",
        "login_mailHasBeenSent-txt",
        "login_notSamePassword-error",
        "login_notAnExistingEmail-error",
        "login_linkExpired-error",
        "login_code-input",
        "login_codeSentNotice-txt",
        "login_emailNotPending-error",
        "login_invalidCode-error",
        "login_expiredCode-error",
        "login_reSendCode-txt",
        "login_reSendCode-link",
        "login_codeSentAgainNotice-txt",
        "menu_dashboard-link",
        "menu_incidents-link",
        "menu_users-link",
        "menu_groups-link",
        "menu_reports-link",
        "menu_adminOptions-txt",
        "menu_dispatching-link",
        "menu_organisations-link",
        "menu_categories-link",
        "menu_translation-link",
        "menu_copywriting-link",
        "menu_logout-link",
        "incident_pageTitle-txt",
        "incident_displayMap-button",
        "incident_deselectedMapPoints-button",
        "incident_displayList-button",
        "incident_archive-filter",
        "incident_display-txt",
        "incident_incidentsOnX-txt",
        "incident_filter-txt",
        "incident_allIncidents-link",
        "incident_myResponsabilities-link",
        "incident_myAssignments-link",
        "incident_mySubscriptions-link",
        "incident_myIncidents-link",
        "incident_myTransferredncidents-link",
        "incident_incidentsSelectedFromMap-link",
        "incident_choiceColumnDisplay-txt",
        "incident_resetColumnChoice-button",
        "incident_id-th",
        "incident_status-th",
        "incident_categoryName-th",
        "incident_completeCategory-th",
        "incident_address-th",
        "incident_municipality-th",
        "incident_lastActionDate-th",
        "incident_assignedTo-th",
        "incident_isPublic-th",
        "incident_isMasked-th",
        "incident_isThirdPartyResponsability-th",
        "incident_isPrivateLocation-th",
        "incident_priority-th",
        "incident_plannedDate-th",
        "incident_signaledBy-th",
        "incident_administrator-th",
        "incident_createdAt-th",
        "incident_lambertx-th",
        "incident_lamberty-th",
        "incident_longitude-th",
        "incident_latitude-th",
        "incident_public-filter",
        "incident_private-filter",
        "incident_publicPrivate-filter",
        "incident_isMaskedAll-filter",
        "incident_isMaskedTrue-filter",
        "incident_isMaskedFalse-filter",
        "incident_isPrivateLocationAll-filter",
        "incident_isPrivateLocationTrue-filter",
        "incident_isPrivateLocationFalse-filter",
        "incident_isThirdPartyResponsabilityAll-filter",
        "incident_isThirdPartyResponsabilityTrue-filter",
        "incident_isThirdPartyResponsabilityFalse-filter",
        "incident_priority_low-filter",
        "incident_priority_medium-filter",
        "incident_priority_high-filter",
        "incident_priority_highest-filter",
        "incident_detail-stepper",
        "incident_duplicate-stepper",
        "incident_commentary-stepper",
        "incident_summary-stepper",
        "incident_createIncident-title",
        "incident_category-input",
        "incident_category-txt",
        "incident_address-input",
        "incident_address-txt",
        "incident_pointOnMapNotSelected-error",
        "incident_photoList-input",
        "incident_multipleLocation-input",
        "incident_multipleLocation-txt",
        "incident_place-txt",
        "incident_picture-input",
        "incident_picture-txt",
        "incident_commentary-input",
        "incident_commentary-txt",
        "incident_subscription-title",
        "incident_subscription-input",
        "incident_subscription-txt",
        "incident_locate-button",
        "incident_detailIncidentTitleX-txt",
        "incident_incidentIdX-txt",
        "incident_subscribeToIncident-button",
        "incident_unsubscribeToIncident-button",
        "incident_dismiss-button",
        "incident_accept-button",
        "incident_close-button",
        "incident_solve-button",
        "incident_dismiss-txt",
        "incident_close-txt",
        "incident_solve-txt",
        "incident_reopen-button",
        "incident_group-txt",
        "incident_assignedTo-txt",
        "incident_status-txt",
        "incident_priority-txt",
        "incident_lowPriority",
        "incident_mediumPriority",
        "incident_highPriority",
        "incident_highestPriority",
        "incident_creationDate-txt",
        "incident_visibility-txt",
        "incident_publicVisibility-txt",
        "incident_privateVisibility-txt",
        "incident_isHidden-txt",
        "incident_privateProperty-txt",
        "incident_plannedDate-txt",
        "incident_thirdPartyResponsability-txt",
        "incident_additionnalOptions-txt",
        "incident_categoryDetail-input",
        "incident_additionnalAddress-input",
        "incident_plannedDate-input",
        "incident_priority-input",
        "incident_visibility-input",
        "incident_publicVisibility-value",
        "incident_privateVisibility-value",
        "incident_isHidden-input",
        "incident_isHiddenTrue-value",
        "incident_isHiddenFalse-value",
        "incident_isThirdPartyResponsability-input",
        "incident_isThirdPartyResponsabilityTrue-value",
        "incident_isThirdPartyResponsabilityFalse-value",
        "incident_isMultipleLocation-input",
        "incident_isMultipleLocationTrue-value",
        "incident_isMultipleLocationFalse-value",
        "incident_isPrivateProperty-input",
        "incident_isPrivatePropertyTrue-value",
        "incident_isPrivatePropertyFalse-value",
        "incident_isPrivatePropertyTrue-txt",
        "incident_setEntireIncidentPublic-button",
        "incident_manageIncident-txt",
        "incident_assignIncident-input",
        "incident_assignIncident-txt",
        "incident_forwardGroupAndAssignIncident-txt",
        "incident_forwardIncidentToGroup-input",
        "incident_forwardIncidentToGroup-txt",
        "incident_forwardIncidentToOrganisation-input",
        "incident_forwardIncidentToOrganisation-txt",
        "incident_createdStatus",
        "incident_dismissedStatus",
        "incident_closedStatus",
        "incident_in_progressStatus",
        "incident_solvedStatus",
        "incident_priority_low-value",
        "incident_priority_medium-value",
        "incident_priority_high-value",
        "incident_priority_highest-value",
        "incident_tabDetail-txt",
        "incident_sourceBackOffice-txt",
        "incident_sourceWebsite-txt",
        "incident_sourceMobileApp-txt",
        "incident_detailIncidentNumberX-title",
        "incident_tabHistorical-txt",
        "incident_tabHistorical_public-txt",
        "incident_historyIncidentCreated-txt",
        "incident_historyIncidentCreated_public-txt",
        "incident_historyIncidentAccepted-txt",
        "incident_historyIncidentAccepted_public-txt",
        "incident_historyIncidentInProgress-txt",
        "incident_historyIncidentInProgress_public-txt",
        "incident_historyIncidentClosed-txt",
        "incident_historyIncidentClosed_public-txt",
        "incident_historyIncidentDismissed-txt",
        "incident_historyIncidentDismissed_public-txt",
        "incident_historyIncidentSolved-txt",
        "incident_historyIncidentSolved_public-txt",
        "incident_historyIncidentCategoryChanged-txt",
        "incident_historyIncidentCategoryChanged_public-txt",
        "incident_historyIncidentPlannedDateSet-txt",
        "incident_historyIncidentPlannedDateSet_public-txt",
        "incident_historyIncidentPriorityChanged-txt",
        "incident_historyIncidentPriorityChanged_public-txt",
        "incident_historyIncidentVisibilityChangedPublic-txt",
        "incident_historyIncidentVisibilityChangedPublic_public-txt",
        "incident_historyIncidentVisibilityChangedPrivate-txt",
        "incident_historyIncidentVisibilityChangedPrivate_public-txt",
        "incident_historyIncidentIsHiddenTrue-txt",
        "incident_historyIncidentIsHiddenTrue_public-txt",
        "incident_historyIncidentIsHiddenFalse-txt",
        "incident_historyIncidentIsHiddenFalse_public-txt",
        "incident_historyIncidentIsThirdPartyResponsabilityTrue-txt",
        "incident_historyIncidentIsThirdPartyResponsabilityTrue_public-txt",
        "incident_historyIncidentIsThirdPartyResponsabilityFalse-txt",
        "incident_historyIncidentIsThirdPartyResponsabilityFalse_public-txt",
        "incident_historyIncidentIsPrivatePropertyTrue-txt",
        "incident_historyIncidentIsPrivatePropertyTrue_public-txt",
        "incident_historyIncidentIsPrivatePropertyFalse-txt",
        "incident_historyIncidentIsPrivatePropertyFalse_public-txt",
        "incident_historyIncidentIsMultipleLocationTrue-txt",
        "incident_historyIncidentIsMultipleLocationTrue_public-txt",
        "incident_historyIncidentIsMultipleLocationFalse-txt",
        "incident_historyIncidentIsMultipleLocationFalse_public-txt",
        "incident_historyIncidentIsSetEntirelyPublic-txt",
        "incident_historyIncidentIsSetEntirelyPublic_public-txt",
        "incident_historyIncidentHasBeenTransferedToOrganisation-txt",
        "incident_historyIncidentHasBeenTransferedToOrganisation_public-txt",
        "incident_historyIncidentHasBeenTransferedToGroup-txt",
        "incident_historyIncidentHasBeenTransferedToGroup_public-txt",
        "incident_historyIncidentHasBeenAssigned-txt",
        "incident_historyIncidentHasBeenAssigned_public-txt",
        "incident_historyIncidentHasBeenMergedTo-txt",
        "incident_historyIncidentHasBeenMergedTo_public-txt",
        "incident_historyIncidentHasBeenMergedIn-txt",
        "incident_historyIncidentHasBeenMergedIn_public-txt",
        "incident_historyCitizen-txt",
        "incident_tabCommentary-txt",
        "incident_comments-title",
        "incident_comment-value",
        "incident_picture-value",
        "incident_commentsPictures-title",
        "incident_commentAdded-txt",
        "incident_commentBy-txt",
        "incident_commentNeightbour-txt",
        "incident_commentVisibility-input",
        "incident_commentPublicVisibility-value",
        "incident_commentPrivateVisibility-value",
        "incident_tabDuplicate-txt",
        "incident_xDuplicateFound-txt",
        "incident_xDuplicateFounds-txt",
        "incident_xDuplicateFoundDetail-txt",
        "incident_xDuplicateFoundsDetail-txt",
        "incident_noDuplicateFound-txt",
        "incident_numberX-txt",
        "incident_search-title",
        "incident_consultDetectedDuplicate-title",
        "incident_duplicateInX-button",
        "incident_duplicateRiskX",
        "incident_tabPdf-txt",
        "incident_downloadPdfIcident-txt",
        "incident_downloadPublicPdf-button",
        "incident_downloadProfessionalPdf-button",
        "incident_choiceOfVersion-txt",
        "incident_publicVersionOfPdf-value",
        "incident_externalTransfer-value",
        "incident_internalTransfer-value",
        "incident_professionalVersionOfPdf-value",
        "incident_receiverOfMail-input",
        "incident_objectOfMail-input",
        "incident_contentOfMail-input",
        "incident_sendMail-button",
        "incident_generateExcelFail-txt",
        "incident_createIncidentSuccess-txt",
        "incident_createIncidentFail-txt",
        "incident_searchIncidentFail-txt",
        "incident_loadIncidentFail-txt",
        "incident_updateIncidentFail-txt",
        "incident_assignIncidentSuccess-txt",
        "incident_assignIncidentFail-txt",
        "incident_transferIncidentSuccess-txt",
        "incident_transferIncidentFail-txt",
        "incident_updateVisibilityCommentSuccess-txt",
        "incident_updateVisibilityCommentFail-txt",
        "incident_deleteCommentSuccess-txt",
        "incident_deleteCommentFail-txt",
        "incident_createCommentSuccess-txt",
        "incident_createCommentFail-txt",
        "incident_updateVisibilityPictureSuccess-txt",
        "incident_updateVisibilityPictureFail-txt",
        "incident_deletePictureSuccess-txt",
        "incident_deletePictureFail-txt",
        "incident_createPictureSuccess-txt",
        "incident_createPictureFail-txt",
        "incident_sendPdfSuccess-txt",
        "incident_sendPdfFail-txt",
        "incident_goToPublicIncidentSuccess-txt",
        "incident_goToPublicIncidentFail-txt",
        "incident_updateStatusSuccess-txt",
        "incident_updateStatusFail-txt",
        "incident_updateSubscribeSuccess-txt",
        "incident_updateSubscribeFail-txt",
        "incident_updateUnsubscribeSuccess-txt",
        "incident_updateUnsubscribeFail-txt",
        "incident_updateIncidentMergeSuccess-txt",
        "incident_updateIncidentMergeFail-txt",
        "incident_searchIncidentNotExist-txt",
        "incident_searchIncidentDuplicateFail-txt",
        "incident_reporterFirstName-th",
        "incident_reporterLastName-th",
        "incident_reporterPhone-th",
        "incident_reporterEmail-th",
        "incident_typeAll-filter",
        "incident_typePro-txt",
        "incident_typeCitizen-txt",
        "incident_precisions-input",
        "incident_needPrecisionsError-txt",
        "pdf_incidentNumber-txt",
        "pdf_informtations-txt",
        "pdf_lambert72-txt",
        "pdf_wgs84-txt",
        "pdf_yAxis-txt",
        "pdf_xAxis-txt",
        "pdf_latitude-txt",
        "pdf_longitude-txt",
        "pdf_managedBy-txt",
        "pdf_map-txt",
        "pdf_picture-txt",
        "pdf_pictureFrom-txt",
        "pdf_phoneManagedBy-txt",
        "pdf_history-txt",
        "pdf_aCitizen-txt",
        "pdf_onDate-txt",
        "user_pageTitle-txt",
        "user_display-txt",
        "user_usersOnX-txt",
        "user_lastname-th",
        "user_firstname-th",
        "user_phone-th",
        "user_mail-th",
        "user_createdAt-th",
        "user_isActive-th",
        "user_actionColumn-th",
        "user-isActiveAll-filter",
        "user-isActiveTrue-filter",
        "user-isActiveFalse-filter",
        "user_lastname-input",
        "user_firstname-input",
        "user_phone-input",
        "user_mail-input",
        "user_organisation-input",
        "user_role-txt",
        "user_role-input",
        "user_superAdminRole-value",
        "user_adminRole-value",
        "user_managerRole-value",
        "user_agentRole-value",
        "user_managerRole-txt",
        "user_managerInFollowedGroups-txt",
        "user_availableGroups-txt",
        "user_agentRole-txt",
        "user_agentInFollowedGroups-txt",
        "user_create-title",
        "user_edit-title",
        "user_createUserSuccess-txt",
        "user_createUserFail-txt",
        "user_updateUserSuccess-txt",
        "user_updateUserFail-txt",
        "user_deleteAreYouSure-txt",
        "user_deleteConfirm-txt",
        "user_deleteUserSuccess-txt",
        "user_deleteUserFail-txt",
        "user_deleteDefinitively-txt",
        "user_roleError-txt",
        "group_pageTitle-txt",
        "group_display-txt",
        "group_groupsOnX-txt",
        "group_name-th",
        "group_mail-th",
        "group_phone-th",
        "group_isActive-th",
        "group_actionColumn-th",
        "group-isActiveAll-filter",
        "group-isActiveTrue-filter",
        "group-isActiveFalse-filter",
        "group_create-title",
        "group_edit-title",
        "group_nameFr-input",
        "group_nameNl-input",
        "group_nameEn-input",
        "group_nameDe-input",
        "group_mail-input",
        "group_phone-input",
        "group_organisation-input",
        "group_notificationSetting-txt",
        "group_notifyGroupMail-value",
        "group_notifyManagerMail-value",
        "group_notificationType-txt",
        "group_resumeOfAllActionOnceADay-value",
        "group_notificationWhenIncidentIsDispatchedToTheGroup-value",
        "group_notificationWhenIncidentIsReopenToTheGroup-value",
        "group_notificationWhenIncidentIsCommentsToTheGroup-value",
        "group_notificationWhenIncidentIsSolvedToTheGroup-value",
        "group_managers-txt",
        "group_managersList-txt",
        "group_managersAvailableList-txt",
        "group_agents-txt",
        "group_agentsList-txt",
        "group_agentsAvailableList-txt",
        "group_addUserSuccess-txt",
        "group_createGroupSuccess-txt",
        "group_createGroupFail-txt",
        "group_editGroupSuccess-txt",
        "group_editGroupFail-txt",
        "group_alreadyExistName-txt",
        "group_deleteAreYouSure-txt",
        "group_deleteConfirm-txt",
        "group_deleteSuccess-txt",
        "group_deleteFail-txt",
        "group_loadDataFail-txt",
        "dispatching_pageTitle-txt",
        "dispatching_toOrganisationBasedOnGeographicalZone-button",
        "dispatching_choiceOfMapLayer-txt",
        "dispatching_createAssociation-txt",
        "dispatching_organisationName-input",
        "dispatching_polygonName-input",
        "dispatching_polygonId-input",
        "dispatching_existingAssociation-txt",
        "dispatching_organisationName-th",
        "dispatching_polygonName-th",
        "dispatching_polygonId-th",
        "dispatching_actionColumn-th",
        "dispatching_toGroupBasedOnCategories-button",
        "dispatching_toGroupBasedOnGeographicalZone-button",
        "dispatching_defaultGroup-txt",
        "dispatching_toGroupBasedOnCategories-txt",
        "dispatching_updateGroupByDefaultSuccess-txt",
        "dispatching_updateGroupByDefaultFail-txt",
        "dispatching_setGeoZoneMessageSuccess-txt",
        "dispatching_setGeoZoneMessageError-txt",
        "dispatching_deleteGeoZoneMessageSuccess-txt",
        "dispatching_deleteGeoZoneMessageError-txt",
        "dispatching_deleteAreYouSure-txt",
        "dispatching_deleteConfirmFirstPart-txt",
        "dispatching_deleteConfirmSecondPart-txt",
        "dispatching_groupByDefaultError-txt",
        "dispatching_groupName-th",
        "dispatching_deleteConfirmFirstPart-txt",
        "dispatching_deleteConfirmGroupSecondPart-txt",
        "dispatching_checkGeoZoneMessageError-txt",
        "dispatching_checkGeoZoneTitleError-txt",
        "dispatching_groupName-input",
        "organisation_pageTitle-txt",
        "organisation_display-txt",
        "organisation_organisationsOnX-txt",
        "organisation_name-th",
        "organisation_type-th",
        "organisation_mail-th",
        "organisation_phone-th",
        "organisation_isActive-th",
        "organisation_actionColumn-th",
        "organisation-isActiveAll-filter",
        "organisation-isActiveTrue-filter",
        "organisation-isActiveFalse-filter",
        "organisation_create-title",
        "organisation_edit-title",
        "organisation_nameFr-input",
        "organisation_nameNl-input",
        "organisation_nameEn-input",
        "organisation_nameDe-input",
        "organisation_type-input",
        "organisation_isActive-input",
        "organisation_mail-input",
        "organisation_phone-input",
        "organisation_admin-txt",
        "organisation_adminLastname-th",
        "organisation_adminFirstname-th",
        "organisation_adminMail-th",
        "organisation_group-txt",
        "organisation_groupName-th",
        "organisation_town-filter",
        "organisation_institution-filter",
        "organisation_underground_worker-filter",
        "organisation_work_executor-filter",
        "organisation_town-value",
        "organisation_institution-value",
        "organisation_underground_worker-value",
        "organisation_work_executor-value",
        "organisation_createOrganisationSuccess-txt",
        "organisation_createOrganisationFail-txt",
        "organisation_updateOrganisationSuccess-txt",
        "organisation_updateOrganisationFail-txt",
        "organisation_loadOrganisationFail-txt",
        "organisation_confirmDelete-title",
        "organisation_confirmDelete-txt",
        "organisation_deleteSuccess-txt",
        "organisation_disable_users_warning-txt",
        "organisation_disable_assignments_warning-txt",
        "organisation_deleteFail-txt",
        "category_pageTitle-txt",
        "category_categoriesManagement-txt",
        "category_create-title",
        "category_enable-title",
        "category_isActive-input",
        "category_label-txt",
        "category_takenInCharge-input",
        "category_addCategory-button",
        "category_nameFr-input",
        "category_nameNl-input",
        "category_nameEn-input",
        "category_nameDe-input",
        "category_disabled-button",
        "category_confirmDisable-txt",
        "category_createFail-txt",
        "category_createSuccess-txt",
        "category_enableFail-txt",
        "category_enableSuccess-txt",
        "category_updateFail-txt",
        "category_updateSuccess-txt",
        "category_label-text",
        "category_detailTakenInCharge-input",
        "category_deleteAreYouSure-txt",
        "category_deleteConfirm-txt",
        "category_deleteSuccess-txt",
        "category_deleteFail-txt",
        "category_deleteDetailAreYouSure-txt",
        "category_deleteDetailSuccess-txt",
        "category_deleteDetailFail-txt",
        "category_addCategoryDetail-button",
        "category_createDetail-title",
        "category_createDetailSuccess-txt",
        "category_createDetailFail-txt",
        "category_updateDetailSuccess-txt",
        "category_updateDetailFail-txt",
        "category_enableDetail-title",
        "category_enableDetailSuccess-txt",
        "category_enableDetailFail-txt",
        "category_confirmDisableDetail-txt",
        "translation_pageTitle-txt",
        "translation_procedure-txt",
        "translation_howTo-txt",
        "translation_downloadTranslationFile-txt",
        "translation_downloadTranslationFile-button",
        "translation_uplaodTranslationFile-txt",
        "translation_uplaodTranslationFile-button",
        "translation_uploadFileSuccess-txt",
        "dashboard_pageTitle-txt",
        "dashboard_lastActivityIncident-txt",
        "dashboard_lastCommentIncident-txt",
        "copywriting_pageTitle-txt",
        "copywriting_saveEditableContentSuccess-txt",
        "copywriting_saveEditableContentError-txt",
        "copywriting_key-terms-of-service",
        "copywriting_key-unassignable-location",
        "copywriting_key-help-page",
        "copywriting_key-faq-page",
        "copywriting_key-about-page",
        "copywriting_key-mobile-welcome-message",
        "incident_needToSelectAtLeastOneIncident-txt",
        "incident_changeVisibilityAreYouSure-txt",
        "incident_changeVisibilityConfirm-txt",
        "incident_changeHideToVisible-txt",
        "incident_changeHideNotImpactVisibility-txt",
        "incident_visibilityNoIncidentAvailable-txt",
        "incident_getIncidentMaskedError-txt",
        "incident_setMaskedToFalseSuccess-txt",
        "incident_setMaskedToFalseFail-txt",
        "stage_options_test-txt",
        "stage_options_internal-txt",
        "stage_options_public-txt",
        "organisation_stage-input",
        "incident_changeVisibilityButton-txt",
        "citizen_createdAt-th",
        "citizen_isAmbassador-th",
        "citizen_isAmbassador_true-filter",
        "citizen_isAmbassador_false-filter"
    ],
    "MOBILE_APP": [
        "page_myIssues-title",
        "page_allIssues-title",
        "page_myAssignments-title",
        "aboutUs_modal-title",
        "help_modal-title",
        "faq_modal-title",
        "useConditions_modal-title",
        "version",
        "menu",
        "cancel-button",
        "back-button",
        "home_reportAnIssue-button",
        "report_reportAnIssue-title",
        "filters_xIssuesReportedInthisArea-txt",
        "filters_xIssueReportedInthisArea-txt",
        "filters_noIssueReportedInthisArea-txt",
        "filters_xIssuesReported-txt",
        "filters_xIssueReported-txt",
        "filters_noIssueReported-txt",
        "status_closed-txt",
        "issueClosed-txt",
        "issueClosedDetail-txt",
        "status_solved-txt",
        "status_inProgress-txt",
        "status_dismissed-txt",
        "incident_situation-txt",
        "incident_situationDescription-label",
        "incident_typeOfIssue-txt",
        "incident_typeOfIssueDescription-label",
        "incident_comment-txt",
        "incident_addComment-button",
        "incident_commentDescription-label",
        "incident_addPictures-txt",
        "incident_addPicturesDescription-label",
        "incident_reportThisIssue-button",
        "incident_thankYou-txt",
        "incident_issueSent-txt",
        "incident_followMyIssue-button",
        "backToHome-button",
        "incident_status-txt",
        "incident_noIssue-txt",
        "incident_noIssueYet-txt",
        "incident_issue-txt",
        "status_issued-txt",
        "incomplete",
        "loading-txt",
        "addToMyIssues",
        "removeFromMyIssues",
        "dateTime",
        "date",
        "startDate",
        "endDate",
        "connectToInternet-txt",
        "incident_issueNotSent-txt",
        "filters_seeFilters-txt",
        "filters_closeFilters-txt",
        "filters_filterByDates-txt",
        "filters_filterByStatus-txt",
        "currentlyOffline-txt",
        "agentConnect",
        "user",
        "userDescription",
        "password",
        "passwordDescription",
        "login_connect-button",
        "dontConnect",
        "report_howToConnect-title",
        "login_useUser-error",
        "login_usePassword-error",
        "login_connectError-error",
        "alert_welcomeX-title",
        "youAreConnectedAgent",
        "ok",
        "retryLaterServerError",
        "errorPermissionLocation-error",
        "incident_selectionTypeIssue-placeholder",
        "form_pleaseAddPicture-error",
        "form_pleaseAddComment-error",
        "form_pleaseAddEmail-error",
        "form_pleaseValidEmail-error",
        "form_pleaseAddType-error",
        "form_pleaseAddReason-error",
        "takeIssue",
        "refuseIssue",
        "incident_finalizeIssue-txt",
        "reopenIssue",
        "modifyIssue",
        "chooseStatus",
        "chooseStatusDescription",
        "incident_goodWork-txt",
        "incident_goodWorkDetail-txt",
        "incident_issueInProgress-txt",
        "incident_issueInProgressDetail-txt",
        "goOnSite-button",
        "incident-classifyWithoutContinuation-txt",
        "solveIncident",
        "disconnect-link",
        "home-link",
        "incident_reportCloseTo-txt",
        "validate-button",
        "error",
        "errorSessionExpired",
        "login_connectBeforeSubmitIncident-txt",
        "login_dontHaveAccount-txt",
        "login_signupNow-link",
        "login_modal-title",
        "login_forgotYourPassword-link",
        "subscription_modal-title",
        "subscription_confirmAlreadyExistEmail-txt",
        "subscription_confirmAlreadyExistEmailHere-txt",
        "subscriptionAlert_accountConfirmed-title",
        "subscription_createAccount-button",
        "form_lastName-label",
        "form_firstName-label",
        "form_phone-label",
        "form_emailAddress-label",
        "form_password-label",
        "form_confirmPassword-label",
        "form_profilType-label",
        "form_lastNameRequired-error",
        "form_firstNameRequired-error",
        "form_phoneRequired-error",
        "form_emailRequired-error",
        "form_passwordRequired-error",
        "form_confirmPasswordRequired-error",
        "form_typeRequired-error",
        "form_emailInvalid-error",
        "form_samePassword-error",
        "form_codeRequired-error",
        "form_youNeedToAcceptCgu-error",
        "code_verifyIdentityCodeSent-txt",
        "code_verifyCodeSent-txt",
        "code_enterCodeBelow-txt",
        "code_modify-link",
        "code_enterCode-label",
        "code_createYourAccount-button",
        "code_resendCode-link",
        "code_remarks-txt",
        "code_noEmailReceived-txt",
        "code_verifyCorrectEmail-txt",
        "code_verifySpamEmail-txt",
        "code_continue-button",
        "report_createAccountError-title",
        "report_codeResendSuccess-success",
        "report_sendCodeError-title",
        "report_changeEmail-title",
        "drawer_logIn-link",
        "options_citizen-label",
        "options_school-label",
        "options_association-label",
        "options_business-label",
        "options_publicAdministration-label",
        "report_saveError-title",
        "report_resetPassword-title",
        "email_continue-button",
        "email_enterYourFmxEmail-txt",
        "resetPassword_securePasswordAdvices-txt",
        "resetPassword_usePasswordCharacter-txt",
        "resetPassword_useDifferentPasswordThanBefore-txt",
        "resetPassword_avoidPasswordWords-txt",
        "resetPassword_useDifferentPasswordAccount-txt",
        "resetPassword_askPasswordEachTime-txt",
        "resetPassword_saveAndConnect-button",
        "resetPassword_modal-title",
        "resetPasswordAlert_changePassword-title",
        "resetPasswordAlert_passwordChangedAndConnect-txt",
        "myAccount_modal-title",
        "myAccount_save-button",
        "myAccount_changePassword-subtitle",
        "myAccount_editUserError-error",
        "myAccountAlert_editUser-title",
        "myAccountAlert_informationsSave-txt",
        "welcome_modal-title",
        "welcome_dontDisplayAgain-label",
        "emailAlreadyRegistered-error",
        "noEmailConfirmPendingForThisEmail-error",
        "email_enterYourNewEmail-txt",
        "alert_youAreConnected-txt",
        "myAccount_informations-subtitle",
        "informations_modal-title",
        "page_mySubscribedIssues-title",
        "incident_unsupported-location-error",
        "incident_subscribeToIncident-button",
        "incident_unsubscribeToIncident-button",
        "codeNotValid-error",
        "codeExpired-error",
        "passwordMustContainXCharacters-error",
        "resetPasswordError-error",
        "terms_iAcceptTerms-txt",
        "alert_disconnect-title",
        "alert_disconnect-txt",
        "dataNotLoadVerifyConnection-error",
        "incident_number-txt",
        "more_comments-txt",
        "less_comments-txt",
        "incident_dontForgetToSavePictures-txt",
        "incident_priority-input",
        "incident_visibility-input",
        "incident_publicVisibility-value",
        "incident_privateVisibility-value",
        "incident_isHidden-input",
        "incident_isHiddenTrue-value",
        "incident_isHiddenFalse-value",
        "incident_isThirdPartyResponsability-input",
        "incident_isThirdPartyResponsabilityTrue-value",
        "incident_isThirdPartyResponsabilityFalse-value",
        "incident_isMultipleLocation-input",
        "incident_isMultipleLocationTrue-value",
        "incident_isMultipleLocationFalse-value",
        "incident_isPrivateProperty-input",
        "incident_isPrivatePropertyTrue-value",
        "incident_isPrivatePropertyFalse-value",
        "incident_isPrivatePropertyTrue-txt",
        "incident_lowPriority",
        "incident_mediumPriority",
        "incident_highPriority",
        "incident_highestPriority",
        "incident_categoryDetail-input",
        "incident_additionnalAddress-input",
        "incident_plannedDate-input",
        "incident_assignIncident-txt",
        "incident_categorySaveSuccess-txt",
        "general_snackbarTitleError-txt",
        "incident_updateIncidentFail-txt",
        "incident_categoryIncomplete-txt",
        "incident_complementSaveSuccess-txt",
        "incident_plannedDateSaveSuccess-txt",
        "incident_goToPublicIncidentSuccess-txt",
        "incident_goToPublicIncidentFail-txt",
        "incident_setEntireIncidentPublic-txt",
        "incident_currentAgent-txt",
        "incident_transferIncidentSuccess-txt",
        "incident_transferIncidentFail-txt",
        "incident_currentGroup-txt",
        "incident-currentAgentNone-txt",
        "incident-currentGroupNone-txt",
        "incident-currentOrganisationNone-txt",
        "incident_currentOrganisation-txt",
        "confirm-button",
        "incident_setEntireIncidentPublic-button",
        "incident_manageIncident-txt",
        "incident_assignIncident-input",
        "incident_forwardGroupAndAssignIncident-txt",
        "incident_forwardIncidentToGroup-input",
        "incident_forwardIncidentToGroup-txt",
        "incident_forwardIncidentToOrganisation-input",
        "incident_forwardIncidentToOrganisation-txt",
        "incident_downloadPdfIcident-txt",
        "incident_downloadPublicPdf-button",
        "incident_downloadProfessionalPdf-button",
        "incident_choiceOfVersion-txt",
        "incident_publicVersionOfPdf-value",
        "incident_externalTransfer-value",
        "incident_internalTransfer-value",
        "incident_professionalVersionOfPdf-value",
        "incident_receiverOfMail-input",
        "incident_objectOfMail-input",
        "incident_contentOfMail-input",
        "incident_sendMail-button",
        "incident_Mail-input",
        "incident_mailSelectUser-txt",
        "incident_commentAdded-txt",
        "incident_commentBy-txt",
        "incident_commentNeightbour-txt",
        "incident_commentPublicVisibility-value",
        "incident_commentPrivateVisibility-value",
        "incident_duplicateRiskX",
        "incident_consultDetectedDuplicate-title",
        "incident_duplicateInX-button",
        "incident_category-txt",
        "incident_creationDate-txt",
        "incident_commentsPictures-title",
        "incident_noDuplicateFound-txt",
        "incident_systemHasDetectDuplicate-txt",
        "incident_systemSameDuplicate-txt",
        "informations",
        "comments",
        "duplicate",
        "pdf",
        "settings",
        "incident_commentProUser-txt",
        "incident_historyCitizen-txt",
        "incident_createdStatus",
        "incident_dismissedStatus",
        "incident_closedStatus",
        "incident_in_progressStatus",
        "incident_solvedStatus",
        "incident_reopen-button",
        "incident_dismiss-button",
        "incident_dismiss-txt",
        "incident_reopen-txt",
        "page_myAttributed-title",
        "incident_accept-button",
        "comment_thereIsNoCommentAndAttachment-txt",
        "errorSubmitIssue",
        "showMoreInfosError",
        "hideMoreInfosError",
        "sendMailError",
        "login_connectErrorGen-error",
        "error_codeValidation-error",
        "error_resendCodeValidation-error",
        "error_subscribe-error",
        "comment-removeError-txt",
        "comment-removeSuccess-txt",
        "comment-setPublicSuccess-txt",
        "comment-setPublicError-txt",
        "incident_updateIncidentMergeSuccess-txt",
        "incident_updateIncidentMergeFail-txt",
        "incident_sendPdfSuccess-txt",
        "incident_sendPdfFail-txt",
        "incident_modal_Error_Mail",
        "incident_sendPdfFailNoEmail-txt",

    ],
    "WEB_APP": [
        "header_homepage-link",
        "header_allIncidents-link",
        "header_about-link",
        "header_faq-link",
        "header_login-link",
        "header_logout-link",
        "header_reportIncident-button",
        "footer_homepage-link",
        "footer_allIncidents-link",
        "footer_about-link",
        "footer_faq-link",
        "footer_help-link",
        "footer_tos-link",
        "footer_copyright-txt",
        "hero_contentBlock-title",
        "hero_contentBlock1-txt",
        "hero_contentBlock2-txt",
        "incident_createdStatus",
        "incident_dismissedStatus",
        "incident_closedStatus",
        "incident_in_progressStatus",
        "incident_solvedStatus",
        "incident_cardId-txt",
        "incident_cardAlt-txt",
        "allIncidents_search-title",
        "allIncidents_searchById-input",
        "allIncidents_searchById-button-screenReader-label",
        "allIncidents_searchById-tooltip_error",
        "allIncidents_searchById-error",
        "allIncidents_searchStatus-input",
        "allIncidents_searchStatusAll-txt",
        "allIncidents_searchCategory-input",
        "allIncidents_searchCategoryAll-txt",
        "allIncidents_searchMunicipality-input",
        "allIncidents_searchMunicipalityAll-txt",
        "allIncidents_searchStartDate-input",
        "allIncidents_searchEndDate-input",
        "allIncidents_search-button",
        "allIncidents_moreResults-link",
        "general_numberInput-error",
        "homepage_heroTitleLine1-title",
        "homepage_heroTitleLine2-title",
        "homepage_heroTitleIncidentBox-title",
        "homepage_reportIncident-button",
        "homepage_displayMap-button",
        "homepage_lastIncidents-title",
        "homepage_allIncidents-link",
        "incident_create-title",
        "incident_detail-stepper",
        "incident_login-stepper",
        "incident_duplicate-stepper",
        "incident_summary-stepper",
        "incident_detail-title",
        "incident_detailWarning-txt",
        "incident_detailInformations-txt",
        "incident_address-label",
        "incident_address-input",
        "incident_address-input-placeholder",
        "incident_address-button-screenReader-label",
        "incident_mapInformations-txt",
        "incident_category-label",
        "incident_category-input",
        "incident_multipleLocation-label",
        "incident_photos-label",
        "incident_photoList-input",
        "incident_commentary-label",
        "incident_comment-input",
        "incident_pointOnMapNotSelected-error",
        "incident_detailCancelButton-txt",
        "incident_detailContinueButton-txt",
        "incident_duplicateContinueButton-txt",
        "incident_duplicateBackButton-txt",
        "incident_login-title",
        "incident_loginCancelButton-txt",
        "incident_duplicateAlreadyHave-txt",
        "incident_duplicateProblem-txt",
        "incident_duplicateSignaledInSector-txt",
        "incident_duplicateVerify-txt",
        "incident_duplicateIncidentNumber-txt",
        "incident_duplicateConsultButton-txt",
        "incident_summary-title",
        "incident_duplicateModalDetailIncident-txt",
        "incident_duplicateModalIncidentNumber-txt",
        "incident_duplicateModalAddressLabel-txt",
        "incident_duplicateModalMultipleZone-txt",
        "incident_duplicateModalCreated-txt",
        "incident_duplicateModalType-txt",
        "incident_duplicateModalByLabel-txt",
        "incident_duplicateModalPictureLabel-txt",
        "incident_duplicateModalCommentsLabel-txt",
        "incident_duplicateModalCloseButton-txt",
        "incident_duplicateModalValidateButton-txt",
        "incident_summaryCategory-txt",
        "incident_summaryPhotos-txt",
        "incident_summaryCommentary-txt",
        "incident_summaryCancelButton-txt",
        "incident_summaryContinueButton-txt",
        "incident_summaryAddress-txt",
        "incident_number-txt",
        "incident_address-txt",
        "incident_multipleLocations-txt",
        "incident_createdAt-txt",
        "incident_type-txt",
        "incident_treatedBy-txt",
        "incident_downloadButton-txt",
        "incident_subscribeButton-txt",
        "incident_subscribeButton-tooltip",
        "incident_unsubscribeButton-txt",
        "incident_unsubscribeButton-tooltip",
        "incident_pictures-txt",
        "incident_comments-txt",
        "incident_addCommentPictureButton-txt",
        "incident_history-txt",
        "incident_addCommentPictureForm-title",
        "incident_addCommentPictureSaveSuccess-txt",
        "incident_addCommentPictureSaveError-txt",
        "incident_successThanks-txt",
        "incident_successSent-txt",
        "incident_successFollowIncidentButton-txt",
        "incident_successBackToHome-link",
        "incident_commentBy-txt",
        "incident_pictureAdded-txt",
        "incident_pictureBy-txt",
        "incident_professionnalType-txt",
        "incident_citizenType-txt",
        "incident_sourceBackOffice-txt",
        "incident_sourceWebsite-txt",
        "incident_sourceMobileApp-txt",
        "incident_precisions-input",
        "incident_needPrecisionsError-txt",
        "incident_unsupported-location-error",
        "login_verifyEmail-txt",
        "login_verifyAboutYou-txt",
        "login_forYourSecurity-txt",
        "login_enterCode-txt",
        "login_verifyEmailCode-txt",
        "login_modifyEmail-txt",
        "login_enterReceivedCode-label",
        "login_createAccountButton-txt",
        "login_continueButton-txt",
        "login_resendCode-txt",
        "login_codePassRemarks-txt",
        "login_verifyEmailRemarks-txt",
        "login_verifySpamRemarks-txt",
        "login_validateCode-request",
        "login_resendCode-request",
        "login_resendCode-success",
        "login_confirmEmail-request",
        "login_helpWithPassword-txt",
        "login_enterAssociatedEmail-txt",
        "login_askChangedEmail-txt",
        "login_emailContactSupport-txt",
        "login_support-txt",
        "login_helpRestoreAccess-txt",
        "login_identify-txt",
        "login_code-input",
        "login_email-input",
        "login_password-input",
        "login_firstName-input",
        "login_lastName-input",
        "login_type-input",
        "login_phone-input",
        "login_confirmPassword-input",
        "login_acceptTos-label",
        "login_tos-link",
        "login_isTosAccepted-input",
        "login_passwordForgotten-txt",
        "login_rememberMe-txt",
        "login_needHelp-txt",
        "login_newOnFMX-txt",
        "login_newOnFMXCreateButton-txt",
        "login_modifyEmailTitle-txt",
        "login_enterNewEmail-txt",
        "login_modifyEmail-request",
        "login_createNewPassword-txt",
        "login_askPassEachConnection-txt",
        "login_newPassword-input",
        "login_saveAndConnectButton-txt",
        "login_passwordTips-txt",
        "login_passwordTipsCharacter-txt",
        "login_samePasswordTips-txt",
        "login_complicatedPasswordTips-txt",
        "login_samePassMultipleAccountTips-txt",
        "login_resetPassword-request",
        "login_notSamePassword-error",
        "login_createAccount-txt",
        "login_validateAccountButton-txt",
        "login_alreadyHaveAccount-txt",
        "login_logInButton-txt",
        "login_atLeast6Charachter-txt",
        "options_citizen-label",
        "options_school-label",
        "options_association-label",
        "options_business-label",
        "options_publicAdministration-label",
        "login_needCgu-error",
        "cookieBanner_content-txt",
        "cookieBanner_tos-link",
        "cookieBanner_validate-button",
        "ieNotice_title-txt",
        "ieNotice_content-txt",
        "ieNotice_updateBrowser-button",
        "ieNotice_continueWithIE-link",
        "errorOccured-error",
        "badRequest-error",
        "emailAlreadyRegistered-error",
        "noEmailConfirmPendingForThisEmail-error",
        "codeNotValid-error",
        "codeExpired-error",
        "passwordMustContainXCharacters-error",
        "noUserExist-error",
        "authenticationFail-error",
        "general_save-button",
        "general_cancel-button",
        "general_maxLengthInputError-txt",
        "general_minLengthInputError-txt",
        "general_requiredInputError-txt",
        "general_numberInputError-txt",
        "general_matchPasswordInputError-txt",
        "general_maxInputError-txt",
        "general_minInputError-txt",
        "general_emailAlreadyUseInputError-txt",
        "general_emailInputError-txt",
        "general_serverIsUnreachable-error",
        "general_contentNoLoad-error",
        "footer_bewapp-site-link-screenReader-label",
        "footer_instagram-link-screenReader-label",
        "footer_linkedin-link-screenReader-label",
        "footer_twitter-link-screenReader-label",
        "footer_facebook-link-screenReader-label",
    ]
};
