import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/lib/auth/auth.service";
import { Apollo } from "apollo-angular";
import { TranslateService } from "src/app/facades/services/translate.service";

// tslint:disable: variable-name
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {

  public formGroup: FormGroup;
  public errorMessage: string;
  @Output() askReset = new EventEmitter<boolean>();

  constructor(private _fb: FormBuilder,
              private _authService: AuthService,
              private _apollo: Apollo,
              private _translateSrv: TranslateService,
              private _router: Router) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.formGroup = this._fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  public login() {
    this.errorMessage = null;
    this._authService.login( this.formGroup.get("username").value, this.formGroup.get("password").value ).subscribe(
      result => {
        if (result && result.Error) {
          this.errorMessage = this._translateSrv.translate("login_authentificationFailed-error");
        } else {
          if (result.target) {
            this._router.navigate([result.target]);
            this._authService.clearTargetRoute();
          } else {
            this._router.navigate(["/dashboard"]);
          }
        }
      },
      error => {
        if (error) {
          this.errorMessage = this._translateSrv.translate("login_serverIsUnreachable-error");
        }
      }
      );

  }

  public goToAskReset() {
    this.askReset.emit(true);
  }

  private isLoginDisabled() {
    return !this.formGroup.get("username").value || !this.formGroup.get("password").value;
  }

  private logout() {
    return this._authService.logout();
  }

}
