import { ITableFilter, EnumTableDataType } from "src/app/presentationnal/table/facades/interfaces/table.interface";

export const  headerInputDefaultGroup: ITableFilter[] = [
    { key: "name", type: EnumTableDataType.text },
    { key: "email", type: EnumTableDataType.text },
    { key: "phone", type: EnumTableDataType.text },
    //{ key: "organisationIds", type: EnumTableDataType.selectcheckbox },
    { key: "organisationIds", type: EnumTableDataType.selectSearchMultiple },
    { key: "isActive", type: EnumTableDataType.select, options: [{value : null, label: "group-isActiveAll-filter"}, {value : true, label: "group-isActiveTrue-filter"}, {value : false, label: "group-isActiveFalse-filter"}] },
    // { key: "address", type: EnumTableDataType.text },
    // { key: "municipality", type: EnumTableDataType.selectcheckbox },
    // { key: "lastActionDate", type: EnumTableDataType.text, disabled: true },
    // { key: "assignedTo", type: EnumTableDataType.selectcheckbox },
    // { key: "isPublic", type: EnumTableDataType.select, options: [{value : null, label: "Publique & Privé"}, {value : true, label: "Publique"}, {value : false, label: "Privé"}]},
    // { key: "isMasked", type: EnumTableDataType.select, options: [{value : null, label: "Tout"}, {value : true, label: "Masqué"}, {value : false, label: "Non-Masqué"}]},
    // { key: "isThirdPartyResponsability", type: EnumTableDataType.select, options: [{value : null, label: "Tout"}, {value : true, label: "Oui"}, {value : false, label: "Non"}], },
    // { key: "isPrivateLocation", type: EnumTableDataType.select, options: [{value : null, label: "Tout"}, {value : true, label: "Terrain privé"}, {value : false, label: "Terrain publique"}], },
    // { key: "priority", type: EnumTableDataType.selectcheckbox },
    // { key: "signaledBy", type: EnumTableDataType.text, disabled: true },
    // { key: "plannedDate", type: EnumTableDataType.text, disabled: true },
    // { key: "administrator", type: EnumTableDataType.text, disabled: true },
    // { key: "createdAt", type: EnumTableDataType.text, disabled: true },
  ];