import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilterBarComponent } from "./filter-bar/filter-bar.component";
import { InputModule } from "../input/input.module";
import { ButtonModule } from "../button/button.module";
import { PipeModule } from "src/app/facades/pipes/pipe.module";
import { StepperComponent } from "./stepper/stepper.component";


@NgModule({
  declarations: [FilterBarComponent, StepperComponent],
  imports: [
    CommonModule,
    InputModule,
    ButtonModule,
    PipeModule
  ],
  exports: [
    FilterBarComponent,
    StepperComponent
  ]
})
export class BarModule { }
