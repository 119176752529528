import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "src/lib/auth/auth.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"]
})
export class ResetPasswordComponent implements OnInit {

  @Output() back = new EventEmitter<boolean>();
  @Input() token: string;
  public formGroup: FormGroup;
  public displayedMessage: string;

  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _translateSrv: TranslateService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.formGroup = this._fb.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    });
  }

  public goBackToLogin() {
    this.back.emit(true);
  }

  public resetPassword() {
    this.displayedMessage = null;
    if (this.formGroup.valid) {
      if (this.formGroup.get("password").value === this.formGroup.get("confirmPassword").value) {
        this._authService.resetPassword(this.formGroup.get("password").value, this.token).subscribe(
          result => {
            if (result && result.Error && result.Error.length > 0) {
              if (result.Error[0].code == 40100) {
                this.displayedMessage = this._translateSrv.translate("login_linkExpired-error");
              } else {
                this.displayedMessage = result.Error[0].infos;
              }
            } else {
              if (result.target) {
                this._router.navigate([result.target]);
                this._authService.clearTargetRoute();
                this.back.emit(true);
              } else {
                this._router.navigate(["/incident"]);
              }
            }
          },
          error => {
            if (error) {
              this.displayedMessage = this._translateSrv.translate("login_serverIsUnreachable-error");
            }
          }
        );
      } else {
        this.displayedMessage = this._translateSrv.translate("login_notSamePassword-error");
      }
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        control.updateValueAndValidity();
      });
    }
  }
}
