import gql from "graphql-tag";

export const GET_ALL_ITEMS = gql`
  query {
    getItemsByRoles{
      items{
        id
        name
        img_url
        price
        bought
      }
      credit
    }
  }
`;

export const BUY_ITEMS = gql `
  mutation buyItems($itemsBought: InputUserShopItems!) {
    buyItems(itemsBought: $itemsBought) {
      items{
        id
        name
        img_url
        price
        bought
      }
      credit
    }
  }
`;

export const GET_BUYED_ITEMS = gql `
  query {
    getBuyedItems{
      item_used_id,
      item{
        id,
        name,
        price,
        img_url
      },
      item_used{
        name
      },
      exp
      level{
        expForNextLevel,
        level
      }
    }
  }
`;

export const SET_AVATAR_USED = gql`
  mutation setAvatarUsed($id: Int!){
    setAvatarUsed(id: $id){
      item_used_id,
      item{
        id,
        name,
        price,
        img_url
      },
      item_used{
        name
      }
    }
  }
`
