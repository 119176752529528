import { AbstractControl, FormGroup } from "@angular/forms";

export const CUSTOM_VALIDATOR = {
    phone: new RegExp( /^(00|\+)[1-9]{1}[0-9]{8,17}$/),
    hours: new RegExp(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
// tslint:disable-next-line: max-line-length
    email: new  RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    decimal_number: new RegExp(/^-?\d+(?:[.]\d{2})?$/),
    number: new RegExp(/^\d+$/),
    belgianPhone: new RegExp(/^(((\+|00)32(?:\(0\)[ ]?)?)|0){1}(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/)
};

export const numberMatcher = (control: AbstractControl): {[key: string]: boolean} => {
    if (!control.value) { return null; }
    return control.value.toString().match(CUSTOM_VALIDATOR.number) ? null : { number : false};
};

export const emailAlreadyUse = (control: AbstractControl): {[key: string]: boolean} => {
  return { emailAlreadyUse: true };
};

export const emailMatcher = (control: AbstractControl): { [key: string]: boolean } => {
    if (!control.value) { return null; }
    return control.value.toString().match(CUSTOM_VALIDATOR.email) ? null : { email: false };
};

export const checkSamePassword = (group: FormGroup): { [key: string]: boolean } => {
    const pass = group.get("password").value;
    const confirmPass = group.get("confirmPassword").value;
    return pass === confirmPass ? null : { notSamePassword: true };
};