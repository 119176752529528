import gql from "graphql-tag";

export const LOGIN = gql`
query authenticate($user:String!, $password:String!) {
  authenticateProWithSSO(user:$user, password:$password) {
    token
    roles
    username
    id
    organisationId
    type
  }
}`;

export const LOGIN_EXTERNAL = gql`
query wepAuthenticate($user:String!, $password:String!) {
  wepAuthenticate(user:$user, password:$password) {
    id
    token
    username
    type
    userToken
  }
}`;


export const SEND_RESET_PASSWORD_MAIL = gql`
  mutation sendResetMail($email: String!) {
    sendResetPasswordMail(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPasswordWithSSO($password:String!) {
    resetPasswordWithSSO(password:$password) {
      token
      roles
      username
      id
      organisationId
      type
      userToken
    }
  }
`;

export const SEND_RESET_PASSWORD_MAIL_CODE = gql`
  mutation sendResetPasswordMailCode($email: String!) {
    sendResetPasswordMailCode(email: $email)
  }
`;

export const CONFIRM_PASSWORD_CODE = gql`
  mutation confirmPasswordCode($email: String!, $code: String!) {
    confirmPasswordCode(email: $email, code: $code)
  }
`;

export const RESEND_CODE = gql`
  mutation resendCode($email: String!) {
    resendCode(email: $email)
  }
`;

export const CONFIRM_ACCOUNT = gql`
    mutation ConfirmAccount($email: String!, $code: String!) {
        confirmAccount(email: $email, code: $code){
            token
            username
            id
            type
            userToken
        }
    }
`;

export const CREATE_CITIZEN = gql`
  mutation CreateCitizen($citizen: CitizenInput!) {
    createCitizen(citizen: $citizen) {
        id,
        firstName,
        lastName,
        phone,
        type
    }
  }
`;

export const CREATE_WEP_CITIZEN = gql`
  mutation CreateWepCitizen($citizen: CitizenInput!) {
    createWepCitizen(citizen: $citizen) {
        id,
        firstName,
        lastName,
        phone,
        type
    }
  }
`;

export const CHANGE_UNCONFIRMED_MAIL = gql`
    mutation ChangeUnconfirmedMail($old: String!, $new: String!) {
        changeUnconfirmedMail(old: $old, new: $new)
    }
`;

export const CHECK_EMAIL = gql`
  query CheckEmail($email: String!) {
    checkEmail(email: $email)
  }
`;

export const SEND_USER_TOKEN_TO_EXTERNAL = gql`
  mutation sendUserToExternal($userMail: String!, $userToken: String!) {
    sendUserToExternal(userMail: $userMail, userToken: $userToken)
  }
`;

export const UPDATE_USER = gql`
  mutation User($user: UserInput!){
    user(user: $user){
      id
      lastName
      firstName
      email
      phone
    }
  }
`;

export const VERIFY_TOKEN = gql`
  query VerifyToken{
    verifyToken
  }
`
