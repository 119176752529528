import { FormGroup } from "@angular/forms";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";

export interface IExpandContent {
  id: number;
  title: string;
  showChildren: boolean;
  children: IExpandContent[];
  level: number;
  button: IExpandContentButton;
  formDatas: IExpandContentForm;
}

export interface IExpandContentButton {
  label: string;
  icon: string;
  action: any;
}

export interface IExpandContentForm {
  isInlineInput: boolean;
  formGroup: FormGroup;
  configInline?: IExpandContentFormConfig;
}

export interface IExpandContentFormConfig {
  type: EnumExpandContentFormConfigType;
  key: string;
  options?: ISelectOption[];
}

export enum EnumExpandContentFormConfigType {
  text = "text",
  select = "select",
  selectCheckbox = "select-checkbox"
}
