import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/lib/auth/auth.service";
import { TranslateService } from "src/app/facades/services/translate.service";

@Component({
  selector: "app-ask-reset",
  templateUrl: "./ask-reset.component.html",
  styleUrls: ["./ask-reset.component.css"]
})
export class AskResetComponent implements OnInit {

  @Output() back = new EventEmitter<boolean>();
  @Output() next = new EventEmitter<string>();
  public formGroup: FormGroup;
  public displayedMessage: string;
  public displayForm: boolean;

  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _translateSrv: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.formGroup = this._fb.group({
      email: [null, Validators.required]
    });
    this.displayForm = true;
  }

  public askReset() {
    if (this.formGroup.valid) {
      this.displayedMessage = null;
      const foundEmail: string = this.formGroup.get("email").value;
      this._authService.askPasswordReset(foundEmail).subscribe(
        result => {
          if (result.errors && result.errors.length > 0) {
            if (result.errors[0].code == 40103) {
              this.displayedMessage = this._translateSrv.translate("login_notAnExistingEmail-error");
            } else {
              this.displayedMessage = result.errors[0].infos;
            }
          } else {
            this.next.emit(foundEmail);
          }
        },
        error => {
          if (error) {
            this.displayedMessage = this._translateSrv.translate("login_serverIsUnreachable-error");
          }
        }
      );
    } else {
      this.formGroup.controls["email"].updateValueAndValidity();
    }
  }

  public goBackToLogin() {
    this.back.emit(true);
  }

}
