import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContainerCommentComponent } from "./organisms/container-comment/container-comment.component";
import { InputModule } from "../input/input.module";
import { CommentComponent } from "./organisms/comment/comment.component";



@NgModule({
  declarations: [
    ContainerCommentComponent,
    CommentComponent
  ],
  imports: [
    CommonModule,
    InputModule
  ],
  exports: [
    ContainerCommentComponent,
    CommentComponent
  ]
})
export class CommentModule { }
