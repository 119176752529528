import { EnumTableDataType, ITableFilter } from 'src/app/presentationnal/table/facades/interfaces/table.interface';

export const headerInputDefaultCitizen: ITableFilter[] = [
    { key: "lastName", type: EnumTableDataType.text },
    { key: "firstName", type: EnumTableDataType.text },
    { key: "phone", type: EnumTableDataType.text },
    { key: "email", type: EnumTableDataType.text },
    { key: "type", type: EnumTableDataType.select },
    { key: "createdAt", type: EnumTableDataType.date },
    { key: "isAmbassador", type: EnumTableDataType.select},
    // { key: "lang", type: EnumTableDataType.select },
]
