import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { LoginQueriesService } from "src/app/facades/queries/login/login-queries.service";
import { EnumLoginForms, ExternalService } from "src/app/facades/services/external/external.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { AuthService } from "src/lib/auth/auth.service";
import { LOGIN_ERRORS_MAP } from "../facades/login-errors-map";


@Component({
    selector: "app-login-confirm-code-external",
    templateUrl: "./login-confirm-code-external.component.html",
    styleUrls: ["./login-confirm-code-external.component.css"]
})
export class LoginConfirmCodeExternalComponent implements OnInit {

    public formGroup: FormGroup;
    public _EnumLoginForms = EnumLoginForms;
    public inputErrorsLabel: Array<IBoxInfo> | Object = [];
    public resendSuccessMessage: IBoxInfo = null;
    public inputErrorsLabelMap: Map<string, string> = new Map<string, string>([
        ["code", this._translateSrv.translate("login_code-input")],
    ]);
    public resendCodeSuccess: boolean = false;
    public disableCreateButton: boolean = false;

    constructor(private _fb: FormBuilder,
        public externalSrv: ExternalService,
        private _translateSrv: TranslateService,
        private _errorMessageSrv: ErrorMessageService,
        private _authSrv: AuthService,
        private _loginSrv: LoginQueriesService) { }

    ngOnInit(): void {
        this.initFormGroup();
    }

    /**
     * @description Init the form group
     * @author Kevin Palade
     * @private
     * @memberof LoginConfirmCodeComponent
     */
    private initFormGroup() {
        this.formGroup = this._fb.group({
            code: [null, Validators.required],
        });
    }

    /**
     * @description Go to form
     * @author Kevin Palade
     * @param {EnumLoginForms} form
     * @memberof LoginConfirmCodeComponent
     */
    public goToModifyEmail() {
        this.externalSrv.currentForm = EnumLoginForms.CHANGE_UNCONFIRMED_MAIL;
    }

    /**
     * @description Send the reset password code
     * @author Kevin Palade
     * @memberof LoginConfirmCodeComponent
     */
    public confirmPasswordCode() {
        if (!this.disableCreateButton) {
            this.disableCreateButton = true;
            if (this.formGroup.valid) {
                this.inputErrorsLabel = [];
                this._loginSrv.confirmPasswordCode(this.externalSrv.wepUserEmail, this.formGroup.get("code").value).subscribe(result => {
                    if (result.errors && result.errors[0]) {
                        const code = (<any>result.errors[0]).code;
                        this.getErrors(code);
                    } else {
                        this.setKeysInStorage((<any>result.data).confirmPasswordCode);
                        this.externalSrv.currentForm = EnumLoginForms.RESET_PASSWORD;
                    }
                    this.disableCreateButton = false;
                }, error => {
                    if (error.graphQLErrors && error.graphQLErrors[0]) {
                        const code = error.graphQLErrors[0].code;
                        this.getErrors(code);
                    }
                    this.disableCreateButton = false;
                });
            } else {
                this.markFormGroupTouched(this.formGroup);
                this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.formGroup, this.inputErrorsLabelMap, true);
                this.disableCreateButton = false;
            }
        }
    }

    /**
     * @description Resend a code after subscription or asking for reset password
     * @author Kevin Palade
     * @memberof LoginConfirmCodeComponent
     */
    public resendCode() {
        this.resendSuccessMessage = null;
        this._loginSrv.resendCode(this.externalSrv.wepUserEmail).subscribe(result => {
            if (result.errors && result.errors[0]) {
                const code = (<any>result.errors[0]).code;
                this.getErrors(code);
            } else {
                this.resendCodeSuccess = true;
                this.resendSuccessMessage = {
                    message: `${this._translateSrv.translate("login_resendCode-success")}`,
                    type: BOX_INFO_TYPE.SUCCESS
                };
            }
        }, error => {
            if (error.graphQLErrors && error.graphQLErrors[0]) {
                const code = error.graphQLErrors[0].code;
                this.getErrors(code);
            }
        });
    }

    /**
     * @description Confirm account with code and email after subscription
     * @author Kevin Palade
     * @memberof LoginConfirmCodeComponent
     */
    public confirmAccount() {
        if (!this.disableCreateButton) {
            this.disableCreateButton = true;
            if (this.formGroup.valid) {
                this.inputErrorsLabel = [];
                this._loginSrv.confirmAccount(this.externalSrv.wepUserEmail, this.formGroup.get("code").value).subscribe((result: any) => {
                    if (result.errors && result.errors[0]) {
                        const code = (<any>result.errors[0]).code;
                        this.getErrors(code);
                    } else {
                        // this._authSrv.setKeysInStorage(result.data, "confirmAccount");
                        // Log in and go to next step incident
                        const confirmAccount = result && result.data? result.data.confirmAccount : null;
                        const userToken = confirmAccount? confirmAccount.userToken : null;
                        if(userToken){

                            this._loginSrv.sendUserTokenToExternal(this.externalSrv.wepUserEmail, userToken).subscribe(res => {
                                if (res.errors && res.errors[0]) {
                                    const code = (<any>res.errors[0]).code;
                                    this.getErrors(code);
                                    this.disableCreateButton = false;
                                } else {
                                    window.open(this.externalSrv.wepUrl, '_self')
                                }
                            }, err => {
                                if (err.graphQLErrors && err.graphQLErrors[0]) {
                                    const code = err.graphQLErrors[0].code;
                                    this.getErrors(code);
                                }
                                this.disableCreateButton = false;
                            })
                        } else {
                            this.getErrors(40102);
                            this.disableCreateButton = false;
                        }
                    }
                }, error => {
                    if (error.graphQLErrors && error.graphQLErrors[0]) {
                        const code = error.graphQLErrors[0].code;
                        this.getErrors(code);
                    }
                    this.disableCreateButton = false;
                });
            } else {
                this.markFormGroupTouched(this.formGroup);
                this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.formGroup, this.inputErrorsLabelMap, true);
                this.disableCreateButton = false;
            }
        }
    }

    public getErrors(errorCode) {
        if (errorCode) {
            const errorKey = LOGIN_ERRORS_MAP.get(errorCode);
            if (errorKey) {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate(errorKey)}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            } else {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate("errorOccured-error")}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            }
        } else {
            (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                message: `${this._translateSrv.translate("errorOccured-error")}`,
                type: BOX_INFO_TYPE.DANGER
            });
        }
    }

    public markFormGroupTouched = (formGroup: FormGroup) => {
        (Object as any).values(formGroup.controls).forEach(control => {
            (control as FormControl).updateValueAndValidity();
            control.markAsTouched();
            if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
        });
    };

    private setKeysInStorage(token: string) {
        if (token) {
          localStorage.setItem("token", token);
        }
      }

}
