import { IDropListLabels } from "src/app/presentationnal/drop-list/facades/interfaces/drop-list.interface";

export const MANAGER_GROUPS_DROP_LIST_LABELS: IDropListLabels = {
  linked: "user_managerInFollowedGroups-txt",
  available: "user_availableGroups-txt",
  actionButton: "general_create-button"
};

export const AGENT_GROUPS_DROP_LIST_LABELS: IDropListLabels = {
  linked: "user_agentInFollowedGroups-txt",
  available: "user_availableGroups-txt",
  actionButton: "general_create-button"
};

export const ASCERTAINING_AGENT_GROUPS_DROP_LIST_LABELS: IDropListLabels = {
  linked: "user_ascertainingAgentInFollowedGroups-txt",
  available: "user_availableGroups-txt",
  actionButton: "general_create-button"
};

export const ORGANISATIONS_DROP_LIST_LABELS: IDropListLabels = {
  linked: "user_organisationInFollowedGroups-txt",
  available: "user_availableOrganisations-txt",
  actionButton: "general_create-button"
};
