import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ICreateInscription, IFilterInscription } from "../../interfaces/inscription.interface";
import { CREATE_INSCRIPTION, GET_INSCRIPTIONS, DELETE_INSCRIPTION } from "./inscription.queries";
import { IPagination } from "../../interfaces/pagination.interface";
import { IncidentQueriesService } from "../incident/incident-queries.service";

@Injectable({
  providedIn: "root"
})
export class InscriptionQueriesService {
  private _savedPagination: IPagination = null;
  private _savedFilter: IFilterInscription = null;

  constructor(private _apollo: Apollo, private _incidentQueriesSrv: IncidentQueriesService) { }

  public createInscription(inscription: ICreateInscription) {
    return this._apollo.mutate({
      mutation: CREATE_INSCRIPTION,
      variables: {inscription},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_INSCRIPTIONS,
        variables: {
          filter: this._savedFilter,
          pagination: this._savedPagination
        },
      },
      this._incidentQueriesSrv.lastIncidentListLoaded
    ]
    });
  }

  public getInscriptions(filter?: IFilterInscription, pagination?: IPagination ) {
    this._savedFilter = filter;
    this._savedPagination = pagination;
    return this._apollo.watchQuery({
      query: GET_INSCRIPTIONS,
      variables: {filter, pagination},
      fetchPolicy: "network-only"
    });
  }

  public deleteInscription(data: {email?: string, incidentId?: number}) {
    return this._apollo.mutate({
      mutation: DELETE_INSCRIPTION,
      variables: data,
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_INSCRIPTIONS,
        variables: {
          filter: this._savedFilter,
          pagination: this._savedPagination
        },
      },
      this._incidentQueriesSrv.lastIncidentListLoaded
    ]
    });
  }
}
