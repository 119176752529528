import { Component, Input, Renderer2, SimpleChanges, OnChanges, ElementRef } from "@angular/core";
import { AInputs } from "../../facades/inputs";
import { ErrorMessageService } from "../../facades/services/error-message.service";

@Component({
  selector: "app-checkbox-input",
  templateUrl: "./checkbox-input.component.html",
  styleUrls: ["./checkbox-input.component.scss"]
})
export class CheckboxInputComponent extends AInputs implements OnChanges {
  @Input() label: string;
  @Input() small = false;
  @Input() isCheckboxValid: boolean = true;
  @Input() isToggle: boolean = false;
  @Input() toggleDisabled: boolean = false;
  @Input() htmlLabel: string = null;
  public valueCheckbox: boolean;
  constructor(private _elRef: ElementRef, public renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
    this.errorClass = "checkbox__error";
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.isCheckboxValid) {
      if ( this.isCheckboxValid) {
        this.renderer.removeClass(this._elRef.nativeElement, this.errorClass);
      } else {
        this.renderer.addClass(this._elRef.nativeElement, this.errorClass);
      }
    }
  }

  /**
   * checkValue
   */
  public checkValue() {
    if (!this.disable) {
      this.group.get(this.name).patchValue( this.group.get(this.name).value ? false : true);
      this.group.get(this.name).markAsTouched();
    }
  }
}
