import gql from "graphql-tag";

const paginationHistory: string = `
    pagination {
        total
        page
        hasNext
        limit
        totalPage
        hasPrevious
    }
`;

export const GET_ORGANISATION_STAGE_HISTORY = gql`
query organisationsStageHistory($filter: FilterOrganisationStageHistory, $pagination: Pagination){
    organisationsStageHistory(filter: $filter, pagination: $pagination){
        id
        organisation{
            id
            name
        }
        user {
            id
            firstName
            lastName
        }
        changeDate
        fromStage
        toStage
    }
    ${paginationHistory}
}`;

export const GET_ORGANISATION_STAGE_HISTORY_XLS = gql`
query organisationsStageHistoryXls($filter: FilterOrganisationStageHistory, $pagination: Pagination, $tableHeaders: [TableHeader!]!) {
  organisationsStageHistoryXls(filter: $filter, pagination: $pagination, tableHeaders: $tableHeaders) {
      filename,
      content
    }
}`;

export const GET_CATEGORY_STATE_HISTORY = gql`
query categoryStateHistory($filter: FilterCategoryStateHistory, $pagination: Pagination) {
	categoryStateHistory(filter: $filter, pagination: $pagination) {
		id
		organisation {
			id
			name
		}
		user {
			id
			firstName
			lastName
		}
		category {
			name
		}
		categoryDetail {
			name
		}
		changeDate
		oldState
		newState
	}
    ${paginationHistory}
}`;

export const GET_CATEGORY_STATE_HISTORY_XLS = gql`
query categoryStateHistoryXls($filter: FilterCategoryStateHistory, $pagination: Pagination, $tableHeaders: [TableHeader!]!) {
	categoryStateHistoryXls(filter: $filter, pagination: $pagination, tableHeaders: $tableHeaders) {
		filename,
    content
	}
}`;

