import {EnumTableDataType, ITableFilter} from "src/app/presentationnal/table/facades/interfaces/table.interface";
import {ROLES} from "../enum/roles.enum";

export const  headerInputDefaultUser: ITableFilter[] = [
    { key: "lastName", type: EnumTableDataType.text },
    { key: "firstName", type: EnumTableDataType.text },
    { key: "phone", type: EnumTableDataType.text },
    { key: "email", type: EnumTableDataType.text },
    // { key: "organisationId", type: EnumTableDataType.selectcheckbox },
    { key: "organisationId", type: EnumTableDataType.selectSearchMultiple },
    // { key: "groupId", type: EnumTableDataType.selectcheckbox },
    { key: "groupId", type: EnumTableDataType.selectSearchMultiple },
    { key: "isOrgaAdmin", type: EnumTableDataType.select, options: [{value : null, label: "user-isActiveAll-filter"}, {value : [ROLES.ADMIN], label: "user_isOrgaAdmin_admin-value"}] },
    { key: "createdAt", type: EnumTableDataType.date},
    { key: "isActive", type: EnumTableDataType.select, options: [{value : null, label: "user-isActiveAll-filter"}, {value : true, label: "user-isActiveTrue-filter"}, {value : false, label: "user-isActiveFalse-filter"}] },
];

export const userHeaderTableOptionsDefault: string[] = [
    "lastName",
    "firstName",
    "phone",
    "email",
    "organisation",
    "group",
    "isOrgaAdmin",
    "isActive"
  ];
