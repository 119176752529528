import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// tslint:disable-next-line: quotemark
import { ChartComponent } from './organisms/chart/chart.component';
import { BarChartComponent } from "./organisms/barchart/barchart.component";



@NgModule({
  declarations: [ChartComponent, BarChartComponent],
  imports: [
    CommonModule
  ],
  exports : [
    ChartComponent,
    BarChartComponent
  ]
})
export class ChartModule { }
