export const getFileFromUrl = (url: string): Promise<File> => {
    const splittedUrl = url.split('/')
    const fileName = splittedUrl[splittedUrl.length - 1]
    return fetch(url)
        .then(async response => {
            const type = response.headers.get('content-type')
            const blob = await response.blob()
            const file = new File([blob], fileName, { type })
            //@ts-ignore
            file.previewUrl = url
            return file
        })
}