import { Input, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";


@Component({
  selector: 'app-modal-photo',
  templateUrl: './modal-photo.component.html',
  styleUrls: ['./modal-photo.component.scss']
})

export class ModalPhotoComponent {
  
  @Input() public photoData: any[] = [];
  @Input() public display: boolean = false;
  @Input() public currentDisplayIndex: number = null;
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter();

  public selectedItem: any = null;
  public nextButtonType: TYPE_BUTTON = TYPE_BUTTON.NEXT;
  public previousButtonType: TYPE_BUTTON = TYPE_BUTTON.PREVIOUS


  constructor() { }

  ngOnInit(){
    this.selectPhoto();
  }

  ngOnChanges(){
    this.selectPhoto();
  }

  public selectPhoto(){
    if(this.photoData && this.photoData.length > 0){
      this.selectedItem = this.photoData[this.currentDisplayIndex? this.currentDisplayIndex : 0];
    }
  }

  public onPress(){
    this.display = !this.display;
    this.onClose.emit(true);
  }

  public changePhoto(orientation: string = TYPE_BUTTON.PREVIOUS){
    if(orientation == TYPE_BUTTON.PREVIOUS) this.currentDisplayIndex = (this.currentDisplayIndex - 1 < 0 )? this.photoData.length -1 : this.currentDisplayIndex - 1;
    else this.currentDisplayIndex = (this.currentDisplayIndex + 1 > this.photoData.length -1 )? 0 : this.currentDisplayIndex + 1;
    this.selectedItem = this.photoData[this.currentDisplayIndex];
  }
}


