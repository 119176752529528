import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { ModalCreateOrganisationComponent } from "src/app/modal/component/modal-create-organisation/modal-create-organisation.component";
import { IOrganisation, IOrganisationInpdate, IFilterOrganisations } from "src/app/facades/interfaces/organisation.interface";
import { IUserInput, EnumUserRoles} from "src/app/facades/interfaces/user.interface";
import { ModalCreateUserComponent } from "src/app/modal/component/modal-create-user/modal-create-user.component";
import { Subscription } from "rxjs";
import { ITableActionData, EnumTableAction, ITableFilter, EnumTableDataType } from "src/app/presentationnal/table/facades/interfaces/table.interface";
import { UserQueriesService } from "src/app/facades/queries/user/user-queries.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { ModalConfirmComponent } from "src/app/modal/component/modal-confirm/modal-confirm.component";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { AuthService } from "src/lib/auth/auth.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { IFilterBarPagination } from "src/app/presentationnal/bar/facades/interfaces/filter-bar.interface";
import { headerInputDefaultOrganisation } from "src/app/facades/configs/organisation-list-filters";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { ORGANISATION_TYPES } from "src/app/facades/enum/organisation-types.enum";
import { STAGE_TYPES } from "src/app/facades/enum/stage-types.enum";
import { XlsService } from "src/app/facades/services/xls/xls.service";
import * as moment from "moment";

@Component({
  selector: "app-organisation-list",
  templateUrl: "./organisation-list.component.html",
  styleUrls: ["./organisation-list.component.css"]
})
export class OrganisationListComponent implements OnInit, OnDestroy {
  private _getOrganisationsListSub: Subscription;
  private _setOrganisationSub: Subscription;
  private _getOrganisationSub: Subscription;
  public formGroup: FormGroup;
  public contentTable = [];
  public optionsHeader: IHeaderOptions = {
    title : "organisation_pageTitle-txt"
  };
  public headerTable = [
    { key: "code", type: "text", translate: "organisation_code-th", sortable: true },
    { key: "name", type: "text", translate: "organisation_name-th", sortable: true },
    { key: "type", type: "text", translate: "organisation_type-th", sortable: true },
    { key: "email", type: "text", translate: "organisation_mail-th", sortable: true },
    { key: "phone", type: "text", translate: "organisation_phone-th", sortable: true },
    { key: "INS_Code", type: "text", translate: "organisation_INS_Code-th", sortable: true },
    { key: "stage", type: "text", translate: "organisation_stage-th", sortable: true },
    { key: "createdAt", type: "text", translate: "organisation_createdAt-th", sortable: true },
    { key: "isActive", type: "boolean", translate: "organisation_isActive-th", sortable: true },
    { key: "Action", type: "action", translate: "organisation_actionColumn-th", sortable: false },
  ];

  public headerTableOptionsDefault = [
    "code",
    "name",
    'type',
    'email',
    "phone",
    "INS_Code",
    "stage",
    "createdAt"
  ];

  public filtersMapping: Map<string, string> = new Map([
    ["code", "code"],
    ["name", "name"],
    ["type", "types"],
    ["email", "email"],
    ["phone", "phone"],
    ["isActive", "isActive"],
    ["INS_Code", "INS_Code"],
    ["stage", "stages"],
    ["createdAt", "createdAt"],
  ]);

  public filterOptions: ITableFilter[] = headerInputDefaultOrganisation;

  public pagination: IFilterBarPagination = {
    label: "organisation_organisationsOnX-txt",
    listNumber: null,
    listTotalCounter: null,
    totalPage : null,
    currentPage: 1
  };
  loadingExport = false;

  constructor(private _fb: FormBuilder,
              private _organisationQueriesSrv: OrganisationQueriesService,
              private _userQueriesSrv: UserQueriesService,
              private _translateSrv: TranslateService,
              private _modalSrv: ModalService,
              private _snackBar: SnackService,
              private _authSrv: AuthService,
              private _xlsSrv: XlsService,
              ) {
    if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)) {
      this.optionsHeader.buttonsOption = {
        size: "small",
        name: "general_create-button"
      };
    }
  }

  ngOnInit() {
    const appliedFilters = {};
    this.filtersMapping.forEach(value => {
      appliedFilters[value] = null;
    });
    this._organisationQueriesSrv.appliedFilters = appliedFilters;
    this._organisationQueriesSrv.organisationFilters = null;
    this._organisationQueriesSrv.resetPagination();
    this.pagination.listNumber = this._organisationQueriesSrv.organisationPaginations.limit;
    this.listOrganisations();
    this.initFilterOptions();
  }

  ngOnDestroy(): void {
    if (this._getOrganisationsListSub) { this._getOrganisationsListSub.unsubscribe(); }
    if (this._setOrganisationSub) { this._setOrganisationSub.unsubscribe(); }
    if (this._getOrganisationSub) { this._getOrganisationSub.unsubscribe(); }
  }

  searchElement(event) {
    // TODO Mette query pour filtrer le tableau
  }

  public initFilter() {
    this.formGroup = this._fb.group({
      searching: null,
    });
  }

  public listOrganisations() {
    if (this._getOrganisationsListSub) { this._getOrganisationsListSub.unsubscribe(); }
    // this._appliedFilters
    this._getOrganisationsListSub = this._organisationQueriesSrv.listOrganisations().valueChanges.subscribe(result => {
      const resultData: any = result.data;
      if ( resultData && resultData.organisations) {
        this.contentTable = resultData.organisations;
        this.contentTable = this.contentTable.map(item => this.formatOrganisation(item));
      }
      if (resultData && resultData.pagination) {
        this.pagination = {
          ...this.pagination,
          listTotalCounter: resultData.pagination.total,
          totalPage: resultData.pagination.totalPage,
          currentPage: resultData.pagination.page
        };
      }
    }, error => {
      console.error("ERROR LOADING ORGANISATION LIST", {error});
    });
  }

  private formatOrganisation(item) {

    return {
      ...item,
      type: this._translateSrv.translate(`organisation_${item.type.toLowerCase()}-value`),
      stage: this._translateSrv.translate(`organisation_stage_${item.stage.toLowerCase()}-value`),
      createdAt: item.createdAt? moment(item.createdAt).format("DD/MM/YYYY HH:mm") : "",
    };
  }

  public addOrganisation(organisation?: IOrganisation | IOrganisationInpdate, isAddingForm: boolean = true, addedUser?: IUserInput) {
    const data: { organisation: IOrganisation | IOrganisationInpdate, isAddingForm: boolean, addedUser: IUserInput} = {
      organisation, isAddingForm, addedUser
    };
    const modalTitle: string = isAddingForm ? this._translateSrv.translate("organisation_create-title") : this._translateSrv.translate("organisation_edit-title");
    const createOrganisation = this._modalSrv.openModal(ModalCreateOrganisationComponent,
      {
        title: modalTitle,
        type: isAddingForm ? MODAL_TYPE.CREATE : MODAL_TYPE.EDIT,
        data,
        modalStyle: {
          modalWidth: "x-large"
        }
      });
    if (createOrganisation) {
      createOrganisation.afterClosed.subscribe(res => {
        if (res.confirm) {
          if (res.needAddingUser) {
            setTimeout(() => {
              this.addTempUserAdmin(res.data);
            }, 0);
          } else {
            this.saveOrganisationData(res.data);
          }
        }
      });
    }
  }

  public addTempUserAdmin(organisationData: IOrganisationInpdate) {
    const addUser = this._modalSrv.openModal(ModalCreateUserComponent,
      {
        title: this._translateSrv.translate("user_create-title"),
        type: MODAL_TYPE.CREATE,
        data: {
          user: {
            organisationId: organisationData.id,
            roles: [EnumUserRoles.ADMIN]
          },
          createAdmin: true
        },
        modalStyle: {
          modalWidth: "x-large"
        },
        customData: {
          cancelButtonName: this._translateSrv.translate("general_return-button"),
        }
      });
    if (addUser) {
      addUser.afterClosed.subscribe(res => {
        let userData: IUserInput = null;
        if (res.data) {
          userData = res.data;
          this.saveUserDatas(userData, organisationData);
        } else {
          setTimeout(() => {
            this.addOrganisation(organisationData);
          }, 0);
        }
      });
    }
  }

  private saveUserDatas(userData: IUserInput, organisationData: IOrganisationInpdate) {
    if ( organisationData.id) {
      this._userQueriesSrv.createUser(userData).subscribe( result => {
        const resultData: any = result.data;
        if (resultData && resultData.userWithSSO) {
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
            this._translateSrv.translate("user_createUserSuccess-txt"),
            STATUS_ALERTS.SUCCESS,
            5000
          );
          organisationData.admins.push(resultData.user);
          this.addOrganisation(organisationData, false);
        } else {
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("user_createUserFail-txt"),
            STATUS_ALERTS.DANGER,
            0
          );
          this.addOrganisation(organisationData, false);
        }
      }, error => {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("user_createUserFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
        console.log("ERROR ADDING NEW ADMINISTRATOR", {error});
        this.addOrganisation(organisationData, false);
      });
    } else {
      setTimeout(() => {
        this.addOrganisation(organisationData, true, userData);
      }, 0);
    }
  }

  public setRoles(data): EnumUserRoles[] {
    const rolesArray = [EnumUserRoles.ADMIN];
    if (data.isSuperAdmin) {
      rolesArray.push(EnumUserRoles.SUPER_ADMIN);
    }
    if (data.isManager) {
      rolesArray.push(EnumUserRoles.MANAGER);
    }
    if (data.isAgent) {
      rolesArray.push(EnumUserRoles.AGENT);
    }
    return rolesArray;
  }

  public saveOrganisationData(organisationData: IOrganisationInpdate) {
    this._setOrganisationSub = this._organisationQueriesSrv.setOrganisation(organisationData).subscribe( result => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
        this._translateSrv.translate(!organisationData.id ? "organisation_createOrganisationSuccess-txt" : "organisation_updateOrganisationSuccess-txt"),
        STATUS_ALERTS.SUCCESS,
        5000
      );
    }, error => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate(!organisationData.id ? "organisation_createOrganisationFail-txt" : "organisation_updateOrganisationFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
      console.log("ERROR ON SAVING ORGANISATION DATA", {error});
    });
  }

  public action(tableAction: ITableActionData) {
    switch (tableAction.action) {
      case EnumTableAction.EDIT:
        this.loadOrganisationData(tableAction.content.id);
        break;
      case EnumTableAction.DELETE:
        this.beforeDeleteOrganisation(tableAction.content);
        break;
      case EnumTableAction.FILTER:
        this.processFilters(tableAction.content);
        break;
      case EnumTableAction.SORT:
        this.sort(tableAction.content);
        break;
    }
  }

  private loadOrganisationData(organisationId: number) {
    this._getOrganisationSub = this._organisationQueriesSrv.getOrganisation(organisationId).subscribe( result => {
      const resultData: any = result.data;
      if (resultData && resultData.organisation) {
        this.addOrganisation(resultData.organisation, false);
      }
    }, error => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("organisation_loadOrganisationFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
      console.log("ERROR ON GETTING ORGANISATION DATA", {error});
    });
  }

  private beforeDeleteOrganisation(organisation: IOrganisation) {
    this._modalSrv.openModal(ModalConfirmComponent,
      {
        title: this._translateSrv.translate("organisation_confirmDelete-title"),
        type: MODAL_TYPE.CONFIRM,
        data: {
          message: `${this._translateSrv.translate("organisation_confirmDelete-txt")} <b>${organisation.name}</b>?`,
          // actions: [
          //   "A DEFINIR",
          // ],
        },
        params: { organisationId: organisation.id },
        confirmCallback: this.deleteOrganisation
      }
    );
  }

  private deleteOrganisation = (params: any) => {
    this._organisationQueriesSrv.deleteOrganisation(params.organisationId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.deleteOrganisation) {
        const { organisationPaginations } = this._organisationQueriesSrv;
        if (organisationPaginations.page > 1 && this.contentTable.length === 1 && organisationPaginations.page === this.pagination.totalPage) {
          this._organisationQueriesSrv.organisationPaginations = {
            ...this._organisationQueriesSrv.organisationPaginations,
            page: this._organisationQueriesSrv.organisationPaginations.page - 1
          };
          this.listOrganisations();
        }
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("organisation_deleteSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      } else {
        console.log("ERROR DELETING ORGANISATION", {result});
      }
    }, error => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("organisation_deleteFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
      console.log("ERROR DELETING ORGANISATION", {error});
    });
  }

  private filteredOrganisationList(filters: IFilterOrganisations) {
    this._organisationQueriesSrv.organisationFilters = {
      name: filters.name
    };
    this.listOrganisations();
  }
  public setPagination( currentPage ) {
    this._organisationQueriesSrv.organisationPaginations = {
      ...this._organisationQueriesSrv.organisationPaginations,
      page: currentPage
    };
    this.listOrganisations();
  }

  public countItemsChanged(nbItemsToDisplay: number) {
    this._organisationQueriesSrv.organisationPaginations = {
        ...this._organisationQueriesSrv.organisationPaginations,
        limit: nbItemsToDisplay,
        page: 1
    };
    this.listOrganisations();
  }

  private sort(sortObject: any) {
    let { order } =  this._organisationQueriesSrv.organisationPaginations;

    if (!order) {
        order = {};
        this.headerTable.forEach(header => {
            if (header.sortable && header.key !== "Action") { order[header.key] = null; }
        });
    }
    Object.keys(order).forEach(key =>  {
        if (key === sortObject.key) {
            order[key] = order[key] === "ASC" ? "DESC" : "ASC";
        } else {
            order[key] = null;
        }
    });
    this._organisationQueriesSrv.organisationPaginations = {
        ...this._organisationQueriesSrv.organisationPaginations,
        order
    };
    this.listOrganisations();
  }

  private processFilters(filterInputValues: any): void {
    const appliedFilters = {};
    Object.keys(filterInputValues).forEach(key => {
      if (this.filtersMapping.get(key)) {
        if (this.filtersMapping.get(key) == "createdAt" && filterInputValues[key] && (typeof filterInputValues[key] === 'string' || filterInputValues[key] instanceof String) ) {
          appliedFilters[this.filtersMapping.get(key)] = null;
        } else {
          appliedFilters[this.filtersMapping.get(key)] = filterInputValues[key];
        }
      }
    });
    this._organisationQueriesSrv.appliedFilters = appliedFilters;
    this._organisationQueriesSrv.resetPagination();
    this.listOrganisations();
  }

  public async initFilterOptions(): Promise<void> {
    for (const filter of this.filterOptions) {
      switch (filter.type) {
        case EnumTableDataType.selectcheckbox:
          filter.options = await this.getFilterSelectOptions(filter.key);
          break;
      }
    }
  }

  private async getFilterSelectOptions(fieldName: string): Promise<ISelectOption[]> {
    switch (fieldName) {
      case "type":
        return await Object.keys(ORGANISATION_TYPES).map( type => {
          return {
            value: type,
            label: this._translateSrv.translate(`organisation_${type.toLowerCase()}-filter`)
            // label: ORGANISATION_TYPES[type],
          };
        });
      case "stage":
        return await Object.keys(STAGE_TYPES).map( type => {
          return {
            value: type,
            label: this._translateSrv.translate(`organisation_${type.toLowerCase()}-filter`)
          };
        });
      default:
        return [];
    }
  }

  public generateExcel() {
    this.loadingExport = true;
    const headers = this.headerTable.filter(header => header.key != "Action").map(header => ({
      key: header.key,
      translate: header.translate
    }));
    this._organisationQueriesSrv.getOrganisationsXls(headers).subscribe(result => {
      const exportXls = result.data['organisationsXls'];
      this._xlsSrv.downloadXls(exportXls);
      this.loadingExport = false;
    }, error => {
        console.log("ERROR GETTING ORGANISATION XLS LIST", { error });
        this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("organisation_generateExcelFail-txt"),
            STATUS_ALERTS.DANGER,
            0);
    });
  }
}
