import { EnumIncidentStatuses } from "src/app/facades/interfaces/incident.interface";

export const SelectedPinMap = new Map([
    [EnumIncidentStatuses.CREATED, {
        type: "picture-marker",
        url: "../../../../assets/img/pins/created_selected.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }],
    [EnumIncidentStatuses.IN_PROGRESS, {
        type: "picture-marker",
        url: "../../../../assets/img/pins/in_progress_selected.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }],
    [EnumIncidentStatuses.CLOSED, {
        type: "picture-marker",
        url: "../../../../assets/img/pins/closed_selected.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }],
    [EnumIncidentStatuses.DISMISSED, {
        type: "picture-marker",
        url: "../../../../assets/img/pins/dismissed_selected.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }],
    [EnumIncidentStatuses.SOLVED, {
        type: "picture-marker",
        url: "../../../../assets/img/pins/solved_selected.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }]
]);