import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModalComponent } from "./modal/modal.component";
import { ModalCreateUserComponent } from "./component/modal-create-user/modal-create-user.component";
import { ModalService } from "../facades/services/modal/modal.service";
import { ModalConfirmComponent } from "./component/modal-confirm/modal-confirm.component";
import { PipeModule } from "../facades/pipes/pipe.module";
import { ModalCreateGroupUserComponent } from "./component/modal-create-group-user/modal-create-group-user.component";
import { PresentationnalModule } from "../presentationnal/presentationnal.module";
import { ModalCreateOrganisationComponent } from "./component/modal-create-organisation/modal-create-organisation.component";
import { ModalCreateIncidentComponent } from "./component/modal-create-incident/modal-create-incident.component";
import { ModalDetailIncidentComponent } from "./component/modal-detail-incident/modal-detail-incident.component";
import { ErrorMessageService } from "../presentationnal/input/facades/services/error-message.service";
import { ModalChoiceHeaderComponent } from "./component/modal-choice-header/modal-choice-header.component";
import { ModalCreateCategoryComponent } from "./component/modal-detail-category/modal-create-category.component";
import { ModalPhotoComponent } from './component/modal-photo/modal-photo.component';
import { ModalCreateThemeComponent } from "./component/modal-create-theme/modal-create-theme.component";
import { SharedDirectivesModule } from "../facades/directives/directives.modules";

const MODAL_COMPONENT = [
  ModalComponent,
  ModalCreateUserComponent,
  ModalConfirmComponent,
  ModalCreateGroupUserComponent,
  ModalCreateOrganisationComponent,
  ModalCreateIncidentComponent,
  ModalChoiceHeaderComponent,
  ModalDetailIncidentComponent,
  ModalCreateCategoryComponent,
  ModalCreateThemeComponent
];

@NgModule({
  declarations: [
    ...MODAL_COMPONENT,
    ModalPhotoComponent,
  ],
  imports: [
    CommonModule,
    PipeModule,
    PresentationnalModule,
    SharedDirectivesModule
  ],
  exports: [
    ...MODAL_COMPONENT
  ],
  entryComponents: [
    ...MODAL_COMPONENT
  ],
  providers: [
    ModalService,
    ErrorMessageService
  ]
})
export class ModalModule { }
