import gql from "graphql-tag";

export const EDITABLE_CONTENT = gql`
  query getEditableContent($key: String!) {
  editableContent(key: $key) {
    id
    key
    value
    translation{
      id
      fr
      nl
      en
      de
    }
  }
}
`;

export const EDITABLE_CONTENTS = gql`
  query getEditableContents {
  editableContents {
    id
    key
    value
    translation{
      id
      fr
      nl
      en
      de
    }
  }
}
`;

export const SAVE_EDITABLE_CONTENT = gql`
  mutation saveEditableContent($key: String!, $value: String!, $lang: LANGS) {
  editableContent(key: $key, value: $value, lang: $lang) {
    id
    key
    value
    translation{
      id
      fr
      nl
      en
      de
    }
  }
}
`;
