import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslatePipe } from "./translate.pipe";
import { NullPipe } from "./null.pipe";
import { AbsoluteValuePipe } from "./abs.pipe";


@NgModule({
  declarations: [
    TranslatePipe,
    NullPipe,
    AbsoluteValuePipe
  ],
  imports: [
    CommonModule
  ],
  exports : [
    TranslatePipe,
    NullPipe,
    AbsoluteValuePipe
  ]
})
export class PipeModule { }
