import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { OrgaArchivingQueriesService } from "../../../facades/queries/archiving/archiving.queries.service"

import { AuthService } from "src/lib/auth/auth.service";
import { ActivatedRoute } from "@angular/router";

import { ModalService } from "src/app/facades/services/modal/modal.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { EnumUserRoles } from "src/app/facades/interfaces/user.interface";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { EnumExpandContentFormConfigType } from '../../../presentationnal/expand/facades/interfaces/expand-content.interface';
import { ARCHIVING } from '../../../facades/enum/archiveConfig-type.enum';
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import * as moment from "moment";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { IOrganisation } from "src/app/facades/interfaces/organisation.interface";

@Component({
  selector: "app-archiving-page",
  templateUrl: "./archiving-page.component.html",
  styleUrls: ["./archiving-page.component.css"]
})
export class ArchivingPageComponent implements OnInit {
  /** Used for define the button type */ public TYPE_BUTTON_ENUM = TYPE_BUTTON;
  /** FormGroup used for the the configuration */ public _formGroup: FormGroup;
  /** Page header configuration */ public optionsHeader: IHeaderOptions;
  /** List of organisations that need to be configured */ public orgas = [];
  /** Orga used for bewapp and set all default archiving */ public orgaBeWapp = null;
  /** Is the current logged user super admin */ public isSuperAdmin = false;
  /** Options to use for the archiving */ public archivingOptions: ISelectOption[] = [];
  /** Options to use for the organisations */ public organisationOptions: ISelectOption[] = [];
  /** Show error for super admin if manual orga not set */ public showErrorManualOrga: boolean = false;
  public __BOX_INFO_TYPE = BOX_INFO_TYPE;
  public editDate: boolean = false;

  public currentEditableOrgaId: number = null;
  public currentEditableOrgaValue: any = null;
  public currentEditableDateValue: any = null;

  public filter: string;
  public organisations;
  public associationFormGroup: FormGroup;

  public inputErrorsLabel: Array<IBoxInfo> | Object;
  public inputErrorsLabelMap = new Map<string, string>([
    ["OrgaId", this._translateSrv.translate("archiving_groupByDefaultError-txt")],
  ]);

  constructor(
    private _orgaArchivingSrv: OrgaArchivingQueriesService,
    private _organisationQueriesSrv: OrganisationQueriesService,
    private _authSrv: AuthService,
    private _route: ActivatedRoute,
    private _modalSrv: ModalService,
    private _snackBarSrv: SnackService,
    private _translateSrv: TranslateService,
    private _fb: FormBuilder,
    private _errorMessageSrv: ErrorMessageService) {
    this.optionsHeader = { title: "archiving_pageTitle-txt", };
  }

  ngOnInit() {
    this.initForm();
    this.isSuperAdmin = this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN);
    this.loadOrganisationDatas();
  }

  private initArchivingOptions() {
    this.archivingOptions = Object.keys(ARCHIVING).map((archItem, index) => {
      return {
        value: ARCHIVING[archItem],
        label: ARCHIVING[archItem] + " mois"
      }
    })
  }

  private initOrganisationsOptions(organisations: any[]){
    if(organisations){
      this.organisationOptions = organisations.map(orga => {
        return {
          value: orga.organisationId,
          label: orga.organisation.name
        }
      });
      this.organisationOptions.unshift({value: -1, label: "Toutes les organisations"})
    }
  }

  private initForm() {
    const orgaId = this._authSrv.getOrganisationId() && this._authSrv.getOrganisationId() != "null"? this._authSrv.getOrganisationId() : 1;
    this._formGroup = this._fb.group({
      date: [moment(), Validators.required],
      orgaId: [orgaId, Validators.required],
      manualOrgaId: [null]
    });

    this._formGroup.get("manualOrgaId").valueChanges.subscribe(res => {
      if(!res) this.showErrorManualOrga = true;
      else this.showErrorManualOrga = false;
    })
  }

  private loadOrganisationDatas() {
    this.initArchivingOptions();
    
    if(this.isSuperAdmin) {
      this._orgaArchivingSrv.getBeWapp().subscribe((res: any) => {
        this.orgaBeWapp = res.data.getBeWapp;
        this._formGroup.addControl("config_" + this.orgaBeWapp.organisationId, this._fb.control(this.orgaBeWapp && this.orgaBeWapp.config ? this.orgaBeWapp.config.toString() : null))
      })
    }

    this._orgaArchivingSrv.getAllOrga(this.isSuperAdmin).subscribe((res: any) => {
      if(res && res.data){
        this.initOrganisationsOptions(res.data.getAllOrga);
        this.orgas = this.isSuperAdmin? res.data.getAllOrga.filter(orgaConfig => orgaConfig.organisationId != 1) : [res.data.getOrga];

        this.orgas.forEach(orga => {
          this._formGroup.addControl("config_" + orga.organisationId, this._fb.control(orga && orga.config ? orga.config.toString() : null))
        })
      }
    })
  }
  
  public setEditMode(id: number): void {
    this.currentEditableOrgaValue = this._formGroup.get("config_"+id).value;
    this.currentEditableOrgaId = id;
  }


  public saveForOrga(id, isGeneralConfig: boolean = false) {
    if (this._formGroup.valid) {
      this._orgaArchivingSrv.configOrgaArchiving(id, +(this._formGroup.get("config_"+id).value), isGeneralConfig).subscribe(result => {
        this.currentEditableOrgaId = null;
        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("archiving_period_updateForAnOrgaSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, error => {
        this._snackBarSrv.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("archiving_period_updateForAnOrgaFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      });
    } else {
      this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
    }
  }

  public cancelDefaultGroupEdit(id: number): void {
    this.currentEditableOrgaId = null;
    this._formGroup.get("config_" + id).setValue(this.currentEditableOrgaValue);
  }

  public async saveDate() {
    if (this._formGroup && this._formGroup.valid) {
      const orgaIdToArchive = this.isSuperAdmin? +this._formGroup.value.manualOrgaId : +this._formGroup.value.orgaId;
      if(orgaIdToArchive){
        this._orgaArchivingSrv.manualArchiving(orgaIdToArchive, new Date(this._formGroup.value.date)).subscribe(result => {
          this._snackBarSrv.open(
            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
            this._translateSrv.translate("archiving_updateOrgaByManualSuccess-txt"),
            STATUS_ALERTS.SUCCESS,
            5000
          );
          this.editDate = false;
        }, error => {
          this._snackBarSrv.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("archiving_updateOrgaByManualFail-txt"),
            STATUS_ALERTS.DANGER,
            0
          );
        });
      } else {
        this.showErrorManualOrga = true;
      }
    } else {
      this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
    }
  }

  public search(event){
    this.filter = event;
    this.organisations = this._organisationQueriesSrv.getOrganisationListQuery({name: this.filter? this.filter: null}).subscribe(result => {
      const resultData: {organisations: IOrganisation[]} = <any>result.data;
      if (resultData && resultData.organisations && resultData.organisations.length > 0) {
        this.organisationOptions = resultData.organisations.filter(o => o.isActive).map( organisation => {
          return {
            value: organisation.id,
            label: organisation.name
          };
        });
        this.associationFormGroup.get("organisationId").patchValue(resultData.organisations[0].id);
      }
    }, error => {
      console.log("ERROR LOADING ORGANISATION", {error});
    })
  }

  public searchFieldMap = new Map([
      ["organisationsIds", "name"],
  ])
}
