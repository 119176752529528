import { IUser, IUserInput } from "./user.interface";
import { IGroup } from "./group.interface";
import { IExternal } from "./external.interface";

export interface IOrganisation {
  id: number;
  name: string;
  type: EnumOrganisationType;
  email: string;
  phone: string;
  code: string;
  adminIds: number[];
  admins: IUser[];
  isActive: boolean;
  users: IUser[];
  groups: IGroup[];
  translation?: IOrganisationTranslation;
  stage?: EnumOrganisationStage;
  externals?: IExternal[];
  INS_Code?: string;
  createdAt?: string;
  imageUrl?: string;
}

export interface IOrganisationTranslation {
  fr?: any;
  nl?: any;
  en?: any;
  de?: any;
}

export interface IFilterOrganisations {
  name: string;
}

export interface IOrganisationCode {
  id: number;
  code: string;
}

export interface IOrganisationInpdate {
  id: number;
  name: string;
  type: EnumOrganisationType;
  email: string;
  phone: string;
  admins?: IUserInput[];
  adminIds?: number[];
  isActive?: boolean;
  nameFr?: string;
  nameNl?: string;
  nameEn?: string;
  nameDe?: string;
  code?: string;
  translation?: IOrganisationTranslation;
  stage?: EnumOrganisationStage;
  imageUrl?: string;
}

export enum EnumOrganisationType {
  TOWN = "TOWN",
  INSTITUTION = "INSTITUTION",
  UNDERGROUND_WORKER = "UNDERGROUND_WORKER",
  WORK_EXECUTOR = "WORK_EXECUTOR"
}

export enum EnumOrganisationStage {
  TEST = "TEST",
  INTERNAL = "INTERNAL",
  PUBLIC = "PUBLIC",
}