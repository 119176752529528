import { ISelectMultipleOptions } from "../../../facades/interfaces/selectMultiple.interface";
import { ICategory } from "src/app/facades/interfaces/category.interface";

/**
 *  Reconstruct the array of ICategory for the given element and it's parent
 * @param options
 * @param id
 */
export const recursiveBreadcrumbs = (options: ISelectMultipleOptions[], id: number): ICategory[] => {
    let resultat = [];
    options.forEach(result => {
      if (result.id === id) {
          resultat = [{ id: result.id, name: result.name, children: result.children, categoryDetails: result.categoryDetails }];
      } else if (result.children && result.children.length > 0) {
          const resultRecursive = recursiveBreadcrumbs(result.children, id);
          if (resultRecursive.length > 0) {
          resultat = [{ id: result.id, name: result.name, children: result.children, categoryDetails: result.categoryDetails }, ...resultRecursive];
          }
      }
    });
    return resultat;
};

/**
 * Return the categories breadcrumb formatted
 * @param breadCrumb
 */
export const formatBreacrumb = (breadCrumb): string => {
    let tmp = "";
    breadCrumb.forEach((element, index) => {
        tmp += index == 0 ? element.name : ` / ${element.name}`;
    });
    return tmp;
};