// tslint:disable: variable-name
// tslint:disable: no-inferrable-types
import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { LoginQueriesService } from "src/app/facades/queries/login/login-queries.service";
import { HttpLink } from "apollo-angular-link-http";
import { ROLES } from "src/app/facades/enum/roles.enum";
import { Apollo } from "apollo-angular";
import { LOGIN_TYPE } from "src/app/facades/enum/login-type.enum";
import { HttpClient } from "@angular/common/http";
export const TOKEN_STORAGE_KEY = "token";
export const REFRESH_TOKEN_STORAGE_KEY = "token-refresh";
export const ROLES_KEY = "roles";
export const USER_ID = "userId";
export const ORGANISATION_ID = "organisationId";
export const TOKEN_COOKIE_NAME: string = "jwt";
export const REFRESH_TOKEN_COOKIE_NAME: string = "refreshjwt";
export const AUTH_HEADER_KEY: string = "Authorization";
export const COOKIE_PATH: string = "/";
export const COOKIE_SECURE: boolean = false;
export const USERNAME_KEY: string = "username";
const jwt_decode = require("jwt-decode");

@Injectable()
export class AuthService implements CanActivate {
    public roles = ROLES;
    private _targetRoute: string = null;
    public constructor(private _httpLink: HttpLink,
                       private _apollo: Apollo,
                       private httpCli: HttpClient,
                       private _loginSrv: LoginQueriesService,
                       private _router: Router) {
                        const http = _httpLink.create({uri: "/graphql"});
                       }


    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.isLoggedIn().subscribe(res => {
            if(!res){
                this._targetRoute = state.url;
                this._router.navigate(["/login"]);
            }
        })
        return this.isLoggedIn();
        // if (!this.isLoggedIn()) {
        //     this._targetRoute = state.url;
        //     this._router.navigate(["/login"]);
        //     return of(false);
        // }
        // return of(true);
    }

    public clearTargetRoute() {
        this._targetRoute = null;
    }
    public login(username: string, password: string) {
        return this._loginSrv.login(username, password).pipe(map (reponse => {
            if (reponse.errors) {
                this._apollo.getClient().resetStore();
                return {Error : reponse.errors};
            } else {
                return this.setKeysInStorage(reponse.data, "authenticateProWithSSO");
            }
        }));
    }

    public loginExternal(username: string, password: string){
        return this._loginSrv.loginExternal(username, password).pipe(map (reponse => {
            if (reponse.errors) {
                this._apollo.getClient().resetStore();
                return {Error : reponse.errors};
            } else {
                return reponse.data;
            }
        }));
    }
    public logout() {
        // Clearing front-related informations
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        localStorage.removeItem("roles");

        this._router.navigate(["/login"]);
    }

    public isLoggedIn(): Observable<boolean> {
        const token = localStorage.getItem(TOKEN_STORAGE_KEY);
        let agent = null;
        if (token) {
            agent = jwt_decode(token);
            if (new Date().getTime() >= ( agent.exp * 1000 )) {
                return of(false);
            } else {
                // return of(true);
                return this._loginSrv.verifyToken().pipe(map((res: any) => res.data.verifyToken));
            }
        } else {
            return of(false);
        }
        // return !!localStorage.getItem(TOKEN_STORAGE_KEY);
    }

    public getToken() {
        return localStorage.getItem(TOKEN_STORAGE_KEY);
    }
    public getRolesCurrent () {
      return localStorage.getItem(ROLES_KEY).split(",");
    }

    public getOrganisationId() {
      return localStorage.getItem(ORGANISATION_ID);
    }

    public getUserId() {
      return localStorage.getItem(USER_ID);
    }

    public askPasswordReset(email: string) {
        return this._loginSrv.sendResetPasswordMailCode(email);
    }

    public resendCode(email: string) {
        return this._loginSrv.resendCode(email);
    }

    public verifyCode(email: string, code: string) {
        return this._loginSrv.confirmPasswordCode(email, code);
    }

    public resetPassword(password: string, token: string) {
        localStorage.setItem(TOKEN_STORAGE_KEY, token);
        return this._loginSrv.resetPassword(password).pipe(map (reponse => {
            if (reponse.errors) {
                this._apollo.getClient().resetStore();
                return {Error : reponse.errors};
            } else {
                return this.setKeysInStorage(reponse.data, "resetPasswordWithSSO");
            }
        }));
    }

    /**
     * @description Set all necessary keys in LocalStorage after login
     * @author Quentin Wolfs
     * @private
     * @param {*} data
     * @param {string} queryName
     * @memberof AuthService
     */
    private setKeysInStorage(data: any, queryName: string): any {
        if (data[queryName].type == LOGIN_TYPE.CITIZEN) {
            // If by any chance a citizen logged in on the back-office, don't log it and go back to login
            return { target: "/login" };
        }
        localStorage.setItem(TOKEN_STORAGE_KEY, data[queryName].token);
        localStorage.setItem(ROLES_KEY, data[queryName].roles);
        localStorage.setItem(USERNAME_KEY, data[queryName].username);
        localStorage.setItem(ORGANISATION_ID, data[queryName].organisationId);
        localStorage.setItem(USER_ID, data[queryName].id);
        return { target: this._targetRoute };
    }
}
