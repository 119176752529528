import { Component, OnInit, Input, OnChanges, Renderer2, SimpleChanges, Output } from "@angular/core";
import { AInputs } from "../../facades/inputs";
import { IClickOutsideComponent } from "src/app/facades/directives/click-outside.directive";
import { ErrorMessageService } from "../../facades/services/error-message.service";
import { ISelectCheckboxOption, ISelectCheckboxFormData } from "../../facades/interfaces/selectCheckboxOption.interface";
import { FormArray, FormGroup, FormBuilder } from "@angular/forms";
import { Subscription, Subject } from "rxjs";

@Component({
    selector: "app-select-checkbox-input",
    templateUrl: "./select-checkbox-input.component.html",
    styleUrls: ["./select-checkbox-input.component.css"]
})
export class SelectCheckboxInputComponent extends AInputs implements OnInit, OnChanges, IClickOutsideComponent {
    private checkboxFormSub: Subscription;
    @Input() public options: ISelectCheckboxOption[] = [];
    @Input() public autoSelectFirst = false;
    @Input() public dataKeyToGet = "email";
    @Input() public razValues$: Subject<boolean>;
    public checkboxForm: FormArray;

    public valueSelect: string = "";
    public selectLabel: string = "";
    public isClosed = true;

    constructor(public renderer: Renderer2,
        protected _errorMessageSrv: ErrorMessageService,
        private _fb: FormBuilder) {
        super(renderer, _errorMessageSrv);
    }

    ngOnInit() {
        if (this.razValues$) {
            this.razValues$.subscribe(() => this.razValues());
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setOptionCheckbox();
        if (changes && changes["disable"] && changes["disable"].currentValue) {
            this.isClosed = true;
        }
    }

    private setOptionCheckbox() {
        if (this.checkboxFormSub) { this.checkboxFormSub.unsubscribe(); }
        this.checkboxForm = new FormArray([]);
        let formGroup: FormGroup;
        const formData: ISelectCheckboxFormData[] = [];
        this.options.forEach(option => {
            const data: ISelectCheckboxFormData = {
                isSelected: !!option.isSelected,
                value: option.value,
                label: option.label
            };
            formGroup = this._fb.group(data);
            this.checkboxForm.push(formGroup);
            formData.push(data);
        });
        this.updateSelectedValues(formData);
        this.checkboxFormSub = this.checkboxForm.valueChanges.subscribe(result => {
            this.updateSelectedValues(result);
        });
    }

    private updateSelectedValues(datas: ISelectCheckboxFormData[]) {
        const selectedDatas: any[] = datas.filter(data => data.isSelected).map(data => data.value);
        this.selectLabel = this.options
            .filter(option => selectedDatas.includes(option.value))
            .map(option => option.datas ? option.datas[this.dataKeyToGet] : option.label)
            .join("; ");
        this.group.get(this.name).patchValue(
            this.checkboxForm.controls
                .filter(c => c.get("isSelected").value)
                .map(c => c.get("value").value)
        );
    }

    public showInfo() { this.open(false); }

    /**
     * @description reset checkboxes values
     * @author Gaetan Geenens
     * @memberof SelectCheckboxInputComponent
     */
    public razValues() {
        this.checkboxForm.controls.forEach(c => c.get("isSelected").setValue(false));
    }


    public open(value: boolean = true) {
        if (!this.disable) {
            this.isClosed = value ? !this.isClosed : !value;
        }
    }

    protected validationCheck() {
        // this.valueSelect = null;
        // this.group.get(this.name).valueChanges.subscribe(result => {
        //   // Use to change the selected value label
        //   this.valueSelect = result ? this.valueSelect : null;
        //   let msg = "";
        //   if (!this.group.get(this.name).errors) {
        //     this.renderer.removeClass(this.inputReference.nativeElement, "select__error");
        //   } else {
        //     this.renderer.addClass(this.inputReference.nativeElement, "select__error");
        //     msg = this._errorMessageSrv.getInputErrors(this.group.get(this.name).errors);
        //   }
        //   this.errorMessage = msg.length > 0 ? msg : null;
        // });
    }

}
