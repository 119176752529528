import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IDropListItem, IDropListLabels } from "../../facades/interfaces/drop-list.interface";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { FormBuilder, FormGroup } from "@angular/forms";

export enum ORDER_TYPE {
  ID = "ID",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

@Component({
  selector: "app-drop-list",
  templateUrl: "./drop-list.component.html",
  styleUrls: ["./drop-list.component.css"]
})
export class DropListComponent implements OnInit {

  @Input() public availableArray: IDropListItem[] = [];
  @Input() public linkedArray: IDropListItem[] = [];
  @Input() public requiredLinkedItem: number = 0;
  @Input() public dropListLabel: IDropListLabels = {
    linked: "Liés",
    available: "Disponibles",
    actionButton: "Ajouter"
  };
  @Input() public order: ORDER_TYPE = ORDER_TYPE.ID;
  @Input() public canAddData: boolean = true;
  @Input() public canSearch: boolean = false;
  @Output() public onAddLinkedData: EventEmitter<any> = new EventEmitter<any>();

  public buttonType = TYPE_BUTTON;
  public waitingData = null;
  public waitingDataCounter = 0;
  public searchFormGroup: FormGroup;

  constructor(
    protected fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initSearchForm();
    this.waitingData = setInterval(() => {
      if (this.availableArray.length || this.linkedArray.length || this.waitingDataCounter > 20) {
        clearInterval(this.waitingData);
        this.waitingData = null;
        this.dataLoaded();
        return;
      }
      this.waitingDataCounter++;
    }, 100)
  }

  public dataLoaded() {
    this.orderTable();
  }

  public changeList(item, type) {
    let index: number;
    if (type === "availableArray") {
      this.linkedArray.push(item);
      index = this.availableArray.indexOf(item);
      if (index !== -1) {this.availableArray.splice(index, 1); }
    } else {
      if (this.linkedArray.length > this.requiredLinkedItem ) {
        this.availableArray.push(item);
        index = this.linkedArray.indexOf(item);
        if (index !== -1) {this.linkedArray.splice(index, 1); }
      }
    }

    this.searchFormGroup.reset();
    this.search();
    this.orderTable();
  }

  public orderById(a: IDropListItem, b: IDropListItem) {
    return a.id - b.id;
  }
  public orderByNameAsc(a: IDropListItem, b: IDropListItem) {
    if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;
    return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
  }
  public orderByNameDesc(a: IDropListItem, b: IDropListItem) {
    if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;
    return (a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1);
  }

  public orderTable() {
    let orderFct;
    switch (this.order) {
      case ORDER_TYPE.ID: orderFct = this.orderById; break;
      case ORDER_TYPE.NAME_ASC: orderFct = this.orderByNameAsc; break;
      case ORDER_TYPE.NAME_DESC: orderFct = this.orderByNameDesc; break;
    }
    this.linkedArray.sort(orderFct);
    this.availableArray.sort(orderFct);
  }

  public addLinkedData() {
    this.onAddLinkedData.emit();
  }

  public linkedArrayFiltered(): IDropListItem[] {
    return this.linkedArray.filter((item: IDropListItem) => !item.hidden)
  }
  public availableArrayFiltered(): IDropListItem[] {
    return this.availableArray.filter((item: IDropListItem) => !item.hidden)
  }

  public search(key?: string) {
    if (!key) {
      this.search('linked');
      this.search('available');
      return;
    }
    let value = this.searchFormGroup.get(key).value;
    let array = key === 'linked' ? this.linkedArray : this.availableArray;
    array.map((item: IDropListItem) => {
      item.hidden = value && value.length ? item.name.toLowerCase().indexOf(value.toLowerCase()) === -1 : false;
    });
  }

  public initSearchForm() {
    this.searchFormGroup = this.fb.group({
      linked: null,
      available: null,
    });
  }
}
