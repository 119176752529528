import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./organisms/login/login.component";
import { InputModule } from "../input/input.module";
import { LabelModule } from "../label/LabelModule";
import { PipeModule } from "src/app/facades/pipes/pipe.module";
import { ButtonModule } from "../button/button.module";
import { AskResetComponent } from "./organisms/ask-reset/ask-reset.component";
import { ResetPasswordComponent } from "./organisms/reset-password/reset-password.component";
import { EnterResetCodeComponent } from "./organisms/enter-reset-code/enter-reset-code.component";

@NgModule({
  declarations: [
    LoginComponent,
    AskResetComponent,
    ResetPasswordComponent,
    EnterResetCodeComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    InputModule,
    LabelModule,
    ButtonModule
  ],
  exports: [
    LoginComponent,
    AskResetComponent,
    ResetPasswordComponent,
    EnterResetCodeComponent
  ]
})
export class LoginModule { }
