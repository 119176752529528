import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { FormGroup, FormBuilder } from "@angular/forms";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  @Input() optionHeader: IHeaderOptions;
  @Input() printSearchField: boolean = false;
  @Output() addItem: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSearchData: EventEmitter<any> = new EventEmitter<any>();
  @Output() onButtonClickAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() additionnalButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  public buttonType = TYPE_BUTTON;
  public formGroup: FormGroup;
  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  public initForm() {
    this.formGroup = this._fb.group({
      searching: null,
    });
  }

  public buttonClick(): void {
    this.addItem.emit(true);
  }

  public additionnalButtonClick(): void {
    this.additionnalButtonClicked.emit(true);
  }

  public buttonClickActions(datas: any) {
    this.onButtonClickAction.emit(datas);
  }

  public sendSearch(event: any) {
    this.onSearchData.emit(event);
  }
}
