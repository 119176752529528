import { async } from '@angular/core/testing';
import { ApplicationConfig } from 'src/app/app.config';
import {Component, Input, OnInit} from '@angular/core';
import {IHeaderOptions} from "../../../../facades/interfaces/header.interface";
import {IBuyedItems, IItem, IItemsByRoles} from "../../../../facades/interfaces/item.interface";
import {Subscription} from "rxjs";
import {ShopQueriesService} from "../../../../facades/queries/shop/shop-queries.service";
import {UserQueriesService} from "../../../../facades/queries/user/user-queries.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EnumUserRoles, IUser, IUserInput} from "../../../../facades/interfaces/user.interface";
import {ROLES} from "../../../../facades/enum/roles.enum";
import {AuthService, ROLES_KEY} from "../../../../../lib/auth/auth.service";
import {TranslateService} from "../../../../facades/services/translate.service";
import { TYPE_BUTTON } from 'src/app/presentationnal/button/type-button.enum';
import { STATUS_ALERTS } from 'src/app/facades/enum/status.enum';
import { SnackService } from 'src/app/facades/services/snackbar/snackbar.service';
import { BOX_INFO_TYPE } from 'src/app/presentationnal/box-info/facades/enums/box-info-type.enum';
import { CUSTOM_VALIDATOR } from 'src/app/presentationnal/input/facades/custom-validator';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.css']
})

export class AccountPageComponent implements OnInit {
  private token: string = null;
  public _formGroup: FormGroup = null;
  public optionsHeader: IHeaderOptions;
  public items : IItemsByRoles = {};
  public buyedItems: IBuyedItems = {};
  private itemsToBought: IItemsByRoles = {items: [], credit: 0};
  public userId: string = localStorage.getItem('userId');
  public itemToUSedId: number = null;
  public user: IUserInput = null;
  public isAvatarMenuOpen: boolean = false;
  public displayedMessage: string;
  public img_url: string = null;
  private higherRole: string = null;
  public itemsIdBuyed: number[] = [];
  private appConfigUrl: string = '';
  public menuChoice: string = 'avatar';
  public creditForBuy: number = 0;
  public isButtonDisabled: boolean = true;
  public disableSave: boolean = false;
  public displayedMessageAvatarChoice: string;
  public displayedMessageBuyAvatar: string;
  public displayedMessageProfil: string;
  public __TYPE_BUTTON = TYPE_BUTTON;
  public __BOX_INFO_TYPE = BOX_INFO_TYPE;

  /**
   * @param _shopQueriesSrv
   * @param _userQueriesSrv
   * @param _fb
   * @param _authSrv
   * @param _translateSrv
   */
  constructor(private _shopQueriesSrv: ShopQueriesService,
              private _userQueriesSrv: UserQueriesService,
              private _fb: FormBuilder,
              private _authSrv: AuthService,
              private _translateSrv: TranslateService,
              private _snackBar: SnackService,) {
    this.optionsHeader = {
      title: "menu_account-link",
    };
  }

  /**
   * @description get items and user
   */
  ngOnInit() {

    this.initFormGroup();
    if(ApplicationConfig.Url.indexOf('/graphql') > -1) {
      this.appConfigUrl = ApplicationConfig.Url.replace('/graphql', '');
    } else {
      this.appConfigUrl = ApplicationConfig.Url;
    };
    
    this._userQueriesSrv.getUser(+this.userId).subscribe((res: any) => {
      this.user = res.data.user;
      this.higherRole = this.checkHigherRole(this.user.roles);
      this.getItemsByRoles();
      this.fillForm();
      this.setBuyedItems();
    }, err => {
      console.log(err);
    });
  }

  public checkHigherRole(roles){
    if(roles.indexOf(EnumUserRoles.SUPER_ADMIN) != -1) return "superadmin";
    else if(roles.indexOf(EnumUserRoles.ADMIN) != -1) return "admin";
    else if (roles.indexOf(EnumUserRoles.MANAGER) != -1) return "manageur";
    else if (roles.indexOf(EnumUserRoles.AGENT) != -1) return "agent";
    else if (roles.indexOf(EnumUserRoles.ASCERTAINING_AGENT) != -1) return "agentconstatateur";
    else return "agentconstatateur";
  }

  private setBuyedItems() {
    this.getBuyedItems().then((res: any) => {
      this.buyedItems = res.data.getBuyedItems;
      this.itemToUSedId = this.buyedItems.item_used_id;
      this.buyedItems.item.forEach((res) => {
        res.img_url = `${this.appConfigUrl}/img/avatars/${res.name}/${res.name}_${this.higherRole}_closed.png`;        
        this.itemsIdBuyed.push(res.id);
      });
      this.img_url = `${this.appConfigUrl}/img/avatars/${this.buyedItems.item_used.name}/${this.buyedItems.item_used.name}_${this.higherRole}_closed.png`;
    });
  }

  private fillForm() {
    this._formGroup.setValue({
      id: this.user.id,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      phone: this.user.phone,
      password: null,
      confirmPassword: null
    })
  }
  private initFormGroup() {
    this._formGroup = this._fb.group({
      id: null,
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.required],
      phone: ["", Validators.pattern(CUSTOM_VALIDATOR.belgianPhone)],
      password: null,
      confirmPassword: null,
    });
  }

  /**
   * @description getItems for the shop
   * @private
   */
  private async getItemsByRoles() {
    this._shopQueriesSrv.getAllItems().subscribe((res: any) => {
      if(res && res.data && res.data.getItemsByRoles) {
        this.items = res.data.getItemsByRoles;
         this.items.items.map((item) => {
            item.img_url = `${this.appConfigUrl}/img/avatars/${item.name}/${item.name}_${this.higherRole}_closed.png`;
        });
      }
    }, err => {
      console.log("error", err)
    })
  }

  /**
   * @description get buyed items
   * @private
   */
  private getBuyedItems() {
    return this._shopQueriesSrv.getBuyedItems().toPromise();
  }

  /**
   * @description builds the table of items to buy
   * @param event
   * @param item
   */
  public checkValue(event: any, item: IItem){
    switch (event.target.checked) {
      case true:
        this.itemsToBought.items.push({id: item.id, name: item.name, img_url: item.img_url, price: item.price, bought: true});
        this.creditForBuy = this.creditForBuy + item.price;
        break;

      case false:
        this.itemsToBought.items.forEach((obj) => {
          if(obj.id === item.id) {
            let index = this.itemsToBought.items.indexOf(obj);
            this.itemsToBought.items.splice(index, 1);
            this.creditForBuy = this.creditForBuy - obj.price;
          }
        })
    }
    (this.itemsToBought.items.length > 0)? (this.creditForBuy > this.items.credit? this.isButtonDisabled = true : this.isButtonDisabled = false) : this.isButtonDisabled = true;
  }

  /**
   * @description Buy items request
   */
   public buyItems() {
    if(this.creditForBuy <= this.items.credit) {
      this._shopQueriesSrv.buyItems(this.itemsToBought).toPromise().then((res: any) => {
        this.items = res.data.buyItems;
        this.items.items.forEach((item) => {
          item.img_url = `${this.appConfigUrl}/img/avatars/${item.name}/${item.name}_${this.higherRole}_closed.png`;
        })
        this.isButtonDisabled = true;
        this.itemsIdBuyed = [];
        this.creditForBuy = 0;
        this.setBuyedItems();
        this.displayedMessageBuyAvatar = null;
      }).catch(() => {
        this.displayedMessageBuyAvatar = this._translateSrv.translate('shop_errorBuyAvatar-error');
      });
    }
  };

  /**
   * @description method to open and close menu
   */
  public openAndCLoseAvatarMenu() {
    this.isAvatarMenuOpen = !this.isAvatarMenuOpen;
  }

  /**
   * @description set the id to use when the user chooses an avatar
   * @param id
   */
   public setItemsToUseId(id:number) {
    this.itemToUSedId = id;
    this.setAvatarUsed();
  }

  /**
   * @description set avatar used request
   */
   public setAvatarUsed() {
    this._shopQueriesSrv.setAvatarUsed(this.itemToUSedId).toPromise().then((res: any) => {
      this.buyedItems.item_used = res.data.setAvatarUsed.item_used;
      this.buyedItems.item_used_id = res.data.setAvatarUsed.item_used_id;
      this.itemToUSedId = this.buyedItems.item_used_id;
      this.img_url = `${this.appConfigUrl}/img/avatars/${this.buyedItems.item_used.name}/${this.buyedItems.item_used.name}_${this.higherRole}_closed.png`;
      this.displayedMessageAvatarChoice = null;
    }).catch(() => {
      this.displayedMessageAvatarChoice = this._translateSrv.translate('account_canNotChangeAvatar-error');
    });
  }

  /**
   * @description update User request
   */
  public updateUser() {
    if (this._formGroup.valid) {
      this.disableSave = true;
      const userToSave = this.formatUserDataToSave();

      const password = this._formGroup.get("password").value;
      if(password){
        if(password !== this._formGroup.get('confirmPassword').value) {
          this.displayedMessageProfil = this._translateSrv.translate("login_notSamePassword-error");
          this.disableSave = false;
          return;
        } else userToSave['password'] = password;
      }

      const roles = localStorage.getItem(ROLES_KEY);
      this._userQueriesSrv.updateUser(userToSave).subscribe((res: any) => {
        this.user= res.data.user;
        localStorage.setItem(ROLES_KEY, roles);
        // this._authSrv.login(this.user.email, this._formGroup.get("password").value);
        this.disableSave = false;
        this.displayedMessageProfil = null;
        this._snackBar.open( this._translateSrv.translate("general_snackbarTitleSuccess-txt"), this._translateSrv.translate("user_updateSuccess-txt"), STATUS_ALERTS.SUCCESS, 5000 );
      }, (err) => {
        this.disableSave = false;
        this._snackBar.open( this._translateSrv.translate("general_snackbarTitleError-txt"), this._translateSrv.translate("user_updateFail-txt"), STATUS_ALERTS.DANGER, 0 );
      });
    }

  }

  /**
   * @description return an IUserInput built with form data
   * @private
   */
  private formatUserDataToSave() {
    return {
      id: this._formGroup.get("id").value,
      agentGroupIds: this.user.agentGroupIds,
      managerGroupIds: this.user.managerGroupIds,
      roles: this.user.roles,
      email: this._formGroup.get("email").value,
      firstName: this._formGroup.get("firstName").value,
      lastName: this._formGroup.get("lastName").value,
      phone: this._formGroup.get("phone").value,
    };
  }

  public setMenuUsed(value: string) {
    this.menuChoice = value;
  }

  public checkIfDisabled(id: number) {
    return this.itemsIdBuyed.includes(id);
  }
}
