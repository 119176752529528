import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginPageComponent } from "./login-page/login-page.component";
import { TranslatePipe } from "src/app/facades/pipes/translate.pipe";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PresentationnalModule } from "src/app/presentationnal/presentationnal.module";
import { PipeModule } from "src/app/facades/pipes/pipe.module";
import { UserPageComponent } from "./users/user-page/user-page.component";
import { RouterModule } from "@angular/router";
import { UserGroupListComponent } from "./users-groups/user-group-list/user-group-list.component";
import { OrganisationListComponent } from "./organisation/organisation-list/organisation-list.component";
import { IncidentListComponent } from "./incident/incident-list/incident-list.component";
import { DispatchingPage } from "./dispatching/dispatching-page.component";
import { DispatchingCategoriesPageComponent } from "./dispatching-categories-page/dispatching-categories-page.component";
import { DispatchingGroupesPageComponent } from "./dispatching-groupes-page/dispatching-groupes-page.component";
import { CategoriesPageComponent } from "./categories/categories-page.component";
import { CategoryService } from "./categories/facades/categories.service";
import { DashboardPageComponent } from "./dashboard/dashboard-page/dashboard-page.component";
import { TranslationPageComponent } from "./translations/translation-page/translation-page.component";
import { CopywritingPage } from './copywriting/copywriting.component';
import { CitizenPageComponent } from './citizens/citizens-page.component';
import { PlanningPageComponent } from "./planning/planning-page/planning-page.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { ArchivingPageComponent } from "./archiving/archiving-page.component";
import { ExternalLoginComponent } from "./login-page-wep/login-page-external.component";
import { LoginForgottenPasswordExternalComponent } from "./login-page-wep/login-forgotten-password-external/login-forgotten-password-external.component";
import { LoginFormExternalComponent } from "./login-page-wep/login-form-external/login-form-external.component";
import { LoginConfirmCodeExternalComponent } from "./login-page-wep/login-confirm-code-external/login-confirm-code-external.component";
import { LoginResetPasswordExternalComponent } from "./login-page-wep/login-reset-password-external/login-reset-password-external.component";
import { LoginSubscriptionFormExternalComponent } from "./login-page-wep/login-subscription-form-external/login-subscription-form-external.component";
import { LoginModifyEmailExternalComponent } from "./login-page-wep/login-modify-email-external/login-modify-email-external.component";
import { AccountPageComponent } from './account/account-page/account-page.component';
import {FormsModule} from "@angular/forms";
import { BoxInfoModule } from "src/app/presentationnal/box-info/box-info.module";
import { HistoryComponent } from './history/history.component';
import { ReportingSearch } from "./reporting/reporting-search-form/reporting-search.component";
import { ReportingPageComponent } from "./reporting/reporting-page/reporting-page.component";
import { ThemesPageComponent } from "./themes/themes-page.component";
import { SharedDirectivesModule } from "src/app/facades/directives/directives.modules";

@NgModule({
  declarations: [
    ExternalLoginComponent,
    LoginPageComponent,
    UserPageComponent,
    UserGroupListComponent,
    OrganisationListComponent,
    IncidentListComponent,
    DispatchingPage,
    DispatchingCategoriesPageComponent,
    DispatchingGroupesPageComponent,
    ThemesPageComponent,
    CategoriesPageComponent,
    DashboardPageComponent,
    TranslationPageComponent,
    CopywritingPage,
    CitizenPageComponent,
    PlanningPageComponent,
    ArchivingPageComponent,
    LoginFormExternalComponent,
    LoginForgottenPasswordExternalComponent,
    LoginConfirmCodeExternalComponent,
    LoginResetPasswordExternalComponent,
    LoginSubscriptionFormExternalComponent,
    LoginModifyEmailExternalComponent,
    AccountPageComponent,
    HistoryComponent,
    ReportingSearch,
    ReportingPageComponent
  ],
    imports: [
      CommonModule,
      PresentationnalModule,
      RouterModule,
      CKEditorModule,
      PipeModule,
      FullCalendarModule,
      FormsModule,
      BoxInfoModule,
      SharedDirectivesModule
    ],
  exports : [
    LoginPageComponent,
    UserPageComponent,
    DispatchingPage,
    CopywritingPage
  ],
  providers: [
    CategoryService
  ]
})
export class PagesModule { }
