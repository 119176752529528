import { IIncident } from "./incident.interface";
import { IUser } from "./user.interface";
import { ICitizen } from "./citizen.interface";
import { IExternal } from "./external.interface";

export enum WAS_TYPE {
  CITIZEN = "Citizen",
  USER = "User"
}

export interface IComment {
  id: number;
  comment: string;
  isPublic: boolean;
  incidentId: number;
  incident: IIncident;
  userId: number;
  user: IUser;
  citizenId: number;
  citizen: ICitizen;
  createdAt: number;
  deletedAt: number;
  wastype: WAS_TYPE;
  externalId: number;
  external: IExternal;
}

export interface ICommentInput {
  comment: string;
  isPublic: boolean;
  incidentId: number;
}

export interface ICommentUpdate {
  comment?: string;
  isPublic?: boolean;
}