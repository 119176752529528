import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { IContainerComment } from "../../facades/interfaces/container-comment.interface";
import { Subscription } from "rxjs";

@Component({
  selector: "app-container-comment",
  templateUrl: "./container-comment.component.html",
  styleUrls: ["./container-comment.component.css"]
})
export class ContainerCommentComponent implements OnInit, OnChanges {
  private _selectCommentSub: Subscription[] = [];
  @Input() public commentDatas: IContainerComment[] = [];
  @Input() public displayActions: boolean = true;
  @Output() public onUpdateSelectField: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDeleteComment: EventEmitter<IContainerComment> = new EventEmitter<IContainerComment>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.commentDatas ) {
      this.listenValueChanges();
    }
  }

  // TODO Check when reload the list if no duplicate subscription
  private listenValueChanges() {
    this._selectCommentSub.forEach( sub => sub.unsubscribe());
    this._selectCommentSub = [];
    if (this.displayActions) {
      this.commentDatas.forEach( comment => {
        this._selectCommentSub.push( comment.inputSelectConfig.formGroup.valueChanges.subscribe( value => {
          this.onUpdateSelectField.emit(value);
        }));
      });
    }
  }

  public deleteComment(data: IContainerComment) {
    this.onDeleteComment.emit(data);
  }
}
