import { EnumTableDataType, ITableFilter } from 'src/app/presentationnal/table/facades/interfaces/table.interface';

export const headerInputDefaultHistory: ITableFilter[] = [
    { key: "organisation", type: EnumTableDataType.selectSearchMultiple },
    { key: "changeDate", type: EnumTableDataType.date },
    { key: "fromStage", type: EnumTableDataType.selectcheckbox },
    { key: "toStage", type: EnumTableDataType.selectcheckbox},
    { key: "user", type: EnumTableDataType.text },

];

export const categoryHeaderInputDefaultHistory: ITableFilter[] = [
    { key: "organisation", type: EnumTableDataType.selectSearchMultiple },
    { key: "changeDate", type: EnumTableDataType.date },
    { key: "oldState", type: EnumTableDataType.selectcheckbox },
    { key: "newState", type: EnumTableDataType.selectcheckbox},
    { key: "category", type: EnumTableDataType.selectSearchMultiple},
    { key: "categoryDetail", type: EnumTableDataType.selectSearchMultiple},
    { key: "user", type: EnumTableDataType.text },
];

