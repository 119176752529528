import { IFilterBarFilter } from "src/app/presentationnal/bar/facades/interfaces/filter-bar.interface";
import { EnumIncidentListFilter } from "../enum/incident-list-filters.enum";
import { EnumTableDataType, ITableFilter } from "src/app/presentationnal/table/facades/interfaces/table.interface";
import { REPORTER_TYPES } from "../interfaces/incident.interface";
import {REPORTER_SOURCES, REPORTER_SOURCES_PRECISIONS} from "../../container/pages/translations/translation-page/translation-page.component";

export const incidentListFilters: IFilterBarFilter[] = [
  {
    type: EnumIncidentListFilter.ALL_INCIDENTS,
    queryResult: "allIncidents", label: "incident_allIncidents-link", selected: true
  },
  {
    type: EnumIncidentListFilter.MY_ATTRIBUTE_INCIDENTS,
    queryResult: "myAttributedIncidents", label: "incident_myResponsabilities-link", selected: false
  },
  {
    type: EnumIncidentListFilter.MY_ASSIGNED_INCIDENTS,
    queryResult: "myAssignedIncidents", label: "incident_myAssignments-link", selected: false
  },
  {
    type: EnumIncidentListFilter.MY_SUBSCRIBED_INCIDENTS,
    queryResult: "mySubscribedIncidents", label: "incident_mySubscriptions-link", selected: false
  },
  {
    type: EnumIncidentListFilter.MY_REPORTED_INCIDENTS,
    queryResult: "myReportedIncidents", label: "incident_myIncidents-link", selected: false
  },
  {
    type: EnumIncidentListFilter.MY_TRANSFERRED_INCIDENTS,
    queryResult: "myTransferredIncidents", label: "incident_myTransferredncidents-link", selected: false
  },
  {
    type: EnumIncidentListFilter.INCIDENT_BY_IDS,
    queryResult: "incidentByIds", label: "incident_incidentsSelectedFromMap-link", selected: false
  },
];

export const headerTableDefaultIncident = [
  { key: "id", type: "text", translate: "incident_id-th", size: "tiny", statusColor: true, sortable: true },
  { key: "generatedid", type: "text", translate: "incident_genid-th", size: "tiny", statusColor: true, sortable: true },
  { key: "status", type: "text", translate: "incident_status-th", size: "medium", sortable: true },
  { key: "categoryName", type: "text", translate: "incident_categoryName-th", size: "medium", sortable: false },
  { key: "completeCategory", type: "text", translate: "incident_completeCategory-th", sortable: false, fixedSize: "40", tooltip: true, tooltipKey: "completeCategory" },
  { key: "address", type: "text", translate: "incident_address-th", sortable: true },
  { key: "municipality", type: "text", translate: "incident_municipality-th", sortable: true },
  { key: "lambertX", type: "text", translate: "incident_lambertx-th", sortable: false },
  { key: "lambertY", type: "text", translate: "incident_lamberty-th", sortable: false },
  { key: "latitude", type: "text", translate: "incident_latitude-th", sortable: false },
  { key: "longitude", type: "text", translate: "incident_longitude-th", sortable: false },
  { key: "lastActionDate", type: "text", translate: "incident_lastActionDate-th", sortable: true },
  { key: "assignedTo", type: "text", translate: "incident_assignedTo-th", size: "medium", sortable: false },
  { key: "isPublic", type: "boolean", translate: "incident_isPublic-th", size: "medium", sortable: true },
  { key: "isMasked", type: "boolean", translate: "incident_isMasked-th", size: "medium", sortable: true },
  { key: "isThirdPartyResponsability", type: "boolean", translate: "incident_isThirdPartyResponsability-th", size: "medium", sortable: true },
  { key: "isPrivateLocation", type: "boolean", translate: "incident_isPrivateLocation-th", size: "medium", sortable: true },
  { key: "priority", type: "text", translate: "incident_priority-th", size: "medium", sortable: true },
  { key: "signaledBy", type: "text", translate: "incident_signaledBy-th", size: "medium", sortable: false },
  { key: "plannedDate", type: "text", translate: "incident_plannedDate-th", size: "medium", sortable: true },
  { key: "administrator", type: "text", translate: "incident_administrator-th", size: "medium", sortable: true },
  { key: "createdAt", type: "text", translate: "incident_createdAt-th", sortable: true },
  { key: "reporterFirstName", type: "text", translate: "incident_reporterFirstName-th", sortable: false },
  { key: "reporterLastName", type: "text", translate: "incident_reporterLastName-th", sortable: false },
  { key: "reporterPhone", type: "text", translate: "incident_reporterPhone-th", sortable: false },
  { key: "reporterEmail", type: "text", translate: "incident_reporterEmail-th", sortable: false },
  { key: "reporterSource", type: "text", translate: "incident_reporterSource-th", sortable: false },
  { key: "reporterSourcePrecision", type: "text", translate: "incident_reporterSourcePrecision-th", sortable: false },
  { key: "formattedComments", type: "text", translate: "incident_comments-th", sortable: false, fixedSize: "40", tooltip: true, useTooltipKey: true, tooltipKey: "formattedCommentsTooltip", tooltipCustomStyle: {'white-space': 'pre'} },
  { key: "isExtern", type: "boolean", translate: "incident_is_extern-th", size: "medium", sortable: false }

];

export const headerInputDefaultIncident: ITableFilter[] = [
  { key: "id", type: EnumTableDataType.text },
  { key: "generatedid", type: EnumTableDataType.text },
  { key: "status", type: EnumTableDataType.selectcheckbox },
  { key: "categoryName", type: EnumTableDataType.selectSearchMultiple },
  { key: "completeCategory", type: EnumTableDataType.selectMultiple, precision: true, precisionKey: "categoryDetails", precisionFilterKey: "categoryDetailIds" },
  { key: "address", type: EnumTableDataType.text },
  { key: "municipality", type: EnumTableDataType.selectSearchMultiple },
  { key: "lambertX", type: EnumTableDataType.number },
  { key: "lambertY", type: EnumTableDataType.number },
  { key: "latitude", type: EnumTableDataType.number },
  { key: "longitude", type: EnumTableDataType.number },
  { key: "lastActionDate", type: EnumTableDataType.date },
  { key: "assignedTo", type: EnumTableDataType.selectSearchMultiple },
  { key: "isPublic", type: EnumTableDataType.select, options: [{ value: null, label: "incident_publicPrivate-filter" }, { value: true, label: "incident_public-filter" }, { value: false, label: "incident_private-filter" }] },
  { key: "isMasked", type: EnumTableDataType.select, options: [{ value: null, label: "incident_isMaskedAll-filter" }, { value: true, label: "incident_isMaskedTrue-filter" }, { value: false, label: "incident_isMaskedFalse-filter" }] },
  { key: "isThirdPartyResponsability", type: EnumTableDataType.select, options: [{ value: null, label: "incident_isThirdPartyResponsabilityAll-filter" }, { value: true, label: "incident_isThirdPartyResponsabilityTrue-filter" }, { value: false, label: "incident_isThirdPartyResponsabilityFalse-filter" }], },
  { key: "isPrivateLocation", type: EnumTableDataType.select, options: [{ value: null, label: "incident_isPrivateLocationAll-filter" }, { value: true, label: "incident_isPrivateLocationTrue-filter" }, { value: false, label: "incident_isPrivateLocationFalse-filter" }], },
  { key: "priority", type: EnumTableDataType.selectcheckbox },
  { key: "signaledBy", type: EnumTableDataType.select, options: [{ value: null, label: "incident_typeAll-filter" }, { value: REPORTER_TYPES.PROFESSIONAL, label: "incident_typePro-txt" }, { value: REPORTER_TYPES.CITIZEN, label: "incident_typeCitizen-txt" }] },
  { key: "plannedDate", type: EnumTableDataType.date },
  { key: "administrator", type: EnumTableDataType.text, disabled: true },
  { key: "createdAt", type: EnumTableDataType.date },
  { key: "reporterFirstName", type: EnumTableDataType.text },
  { key: "reporterLastName", type: EnumTableDataType.text },
  { key: "reporterPhone", type: EnumTableDataType.text },
  { key: "reporterEmail", type: EnumTableDataType.text },
  { key: "reporterSource", type: EnumTableDataType.select, options: [{ value: null, label: "incident_reporterSourceAll-txt" }, { value: REPORTER_SOURCES.BACK_OFFICE, label: "incident_back_office_reporterSource" }, { value: REPORTER_SOURCES.MOBILE_APP, label: "incident_mobile_app_reporterSource" }, {value: REPORTER_SOURCES.WEB_APP, label: "incident_web_app_reporterSource"}] },
  { key: "reporterSourcePrecision", type: EnumTableDataType.select, options: [{ value: null, label: "incident_reporterSourcePrecisionAll-txt" }, { value: REPORTER_SOURCES_PRECISIONS.ANDROID, label: "incident_reporterSourcePrecision-android" }, { value: REPORTER_SOURCES_PRECISIONS.IOS, label: "incident_reporterSourcePrecision-ios" }] },
  { key: "formattedComments", type: EnumTableDataType.text, disabled: true },
  { key: "isExtern", type: EnumTableDataType.select, options: [{ value: null, label: "incident_externeinterne-filter" }, { value: true, label: "incident_externe-filter" }, { value: false, label: "incident_interne-filter" }] }
];

export const headerTableOptionsDefault: string[] = [
  "id",
  "generatedid",
  "status",
  "categoryName",
  "completeCategory",
  "address",
  "municipality",
  "lastActionDate",
  "assignedTo",
  "lambertX",
  "lambertY",
  "latitude",
  "longitude",
  "createdAt",
  "isPublic",
  "isMasked",
  "isThirdPartyResponsability",
  "isPrivateLocation",
  "priority",
  "plannedDate",
  "signaledBy",
  "administrator",
  "reporterFirstName",
  "reporterLastName",
  "reporterPhone",
  "reporterEmail",
  "reporterSource",
  "reporterSourcePrecision",
  "formattedComments",
  "isExtern"
];
