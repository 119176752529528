import gql from "graphql-tag";

const paginationUsers: string = `
  pagination {
    total
    page
    hasNext
    limit
    totalPage
    hasPrevious
  }
`;

export const GET_LIST_USERS = gql`
query getUsersList($filter: FilterUsers, $pagination: Pagination){
  users(filter: $filter,pagination:$pagination){
    id
    lastName
    firstName
    email
    phone
    isActive
    organisationId
    organisation {
      id
      name
    }
    groupsWithRole {
      id
      groupId
      userId
      onRole
      group {
        id
        name
      }
    }
    roles
    loginInfo {
      createdAt
    }
  }
  ${paginationUsers}
}`;


export const GET_USERS_XLS = gql`
  query getUsersXls($filter: FilterUsers, $pagination: Pagination, $tableHeaders: [TableHeader!]!) {
    usersXls(filter: $filter, pagination: $pagination, tableHeaders: $tableHeaders) {
      filename,
      content
    }
}`;

export const CREATE_USER = gql`
  mutation userWithSSO($user: UserInput!){
    userWithSSO(user: $user){
      id
      lastName
      firstName
      email
      isActive
    }
  }
`;

export const GET_USER = gql`
query getUser($id: Int!){
  user(id:$id){
    id
    lastName
    firstName
    email
    phone
    isActive
    organisationId
    higherRole
    point{
      item_used{
        name
        id
      }
    }
    managerGroups{
      id
      name
    }
    agentGroups{
      id
      name
    }
    ascertainingAgentGroups{
      id
      name
    }
    readonlyOrganisations{
      id
      name
    }
    roles
  }
}`;

export const GET_GROUPS_AVAILABLE = gql`
query getGroupAvailable($userId: Int!,$role:ROLES){
  groupsAvailables(userId:$userId,role:$role){
    id
    name
  }
}`;

export const DELETE_USER = gql`
mutation deleteUserWithSSO($id: Int!, $kind: KIND){
  deleteUserWithSSO(id:$id, kind: $kind)
}`;

export const GET_ALL_GROUPS_FOR_USER = gql`
query getUser($id: Int!){
  user(id:$id){
    id
    managerGroups{
      id
      name
    }
    agentGroups{
      id
      name
    }
  }
}`;

export const IS_EMAIL_FREE = gql`
  query isEmailFree($email: String!) {
    isEmailFree(email: $email)
  }
`;

export const USER_UPDATE_IMPACTS = gql`
  query userUpdateImpacts($userUpdate: UserInput!) {
    userUpdateImpacts(userUpdate: $userUpdate){
      message
      infos
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UserWithSSO($user: UserInput!){
    userWithSSO(user: $user){
      id
      lastName
      firstName
      email
      phone
      roles
    }
  }
`;


