/*
  Copyright 2019 Esri
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
*/

import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, ɵɵi18nAttributes } from "@angular/core";
import { loadModules } from "esri-loader";
import esri = __esri; // Esri TypeScript Types
import { ESRI_MAP_CONFIG, LAYER_IDS } from "../../facades/configs/esri-map.config";
import { GeoservicesWallonieService } from "src/app/facades/services/geoservices-wallonie/geoservices-wallonie.service";
import { IMarkerData, IQueryTaskDatas } from "../../facades/interfaces/esri-map.interface";
import { ConversionQueriesService } from "src/app/facades/queries/conversion/conversion-queries.service";
import { ILatLong } from "src/app/facades/interfaces/conversion.interface";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { IGeoZoneInput, ILayer, IGeoZone } from "src/app/facades/interfaces/dispatching.interface";
import { EnumIncidentStatuses, IIncidentMap, IIncidentMarker } from "src/app/facades/interfaces/incident.interface";

@Component({
  selector: "app-esri-map-group",
  templateUrl: "./esri-map-group.component.html",
  styleUrls: ["./esri-map-group.component.scss"]
})
export class EsriMapGroupComponent implements OnInit, OnChanges {

  private _orthoLastConfig: any = require("../../facades/configs/esri-map-ortho-last.config.json");
  private _esriMapConfig: any = ESRI_MAP_CONFIG;
  private _view: esri.MapView;
  private _graphicsLayer: esri.GraphicsLayer;
  private _debouncer: Subject<any> = new Subject();

  @Input() additionnalLayer: esri.GraphicsLayer;
  @Output() mapLoadedEvent = new EventEmitter<boolean>();
  @Input() isSelectPointMap: boolean = false;
  @Output() onClickMap: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickMarker: EventEmitter<any> = new EventEmitter<any>();
  @Input() heightMap: string = "50vh";
  @Input() isDispatchingMap: boolean = false;

  @Input() displayIncidentsMap: Array<IIncidentMap> = [];
  @Input() selectedIncidentIds: Array<number> = [];

  @Input() dispatchLayerToUse: ILayer;
  @Input() selectedLayerZone: IGeoZoneInput;
  @Input() geoZoneForGraphicLayer: IQueryTaskDatas[];
  @Output() onChangeGraphicList: EventEmitter<IGeoZoneInput[]> = new EventEmitter<IGeoZoneInput[]>();

  @ViewChild("mapViewNode", { static: true }) private mapViewNode: ElementRef;

  private _markerGraphics: any[] = [];

  private _zoom = 12;
  private _center: Array<number> = [0.1278, 51.5074];
  private _basemap = "streets";
  private _loaded = false;
  private _scale = 1000;
  private _markerClickInitialized: boolean = false;
  public _selectedGraphics: esri.Graphic[];

  get mapLoaded(): boolean { return this._loaded; }

  @Input() set zoom(zoom: number) { this._zoom = zoom; }
  get zoom(): number { return this._zoom; }

  @Input() set center(center: Array<number>) { this._center = center; }
  get center(): Array<number> { return this._center; }

  @Input() set basemap(basemap: string) { this._basemap = basemap; }
  get basemap(): string { return this._basemap; }

  @Input() canClickOnMap: boolean = true;
  @Input() markerData: IMarkerData = null;
  @Input() refreshMap: Subject<IGeoZoneInput[]>;

  constructor(
    private _geoserviceWallonieSrv: GeoservicesWallonieService,
    private _conversionQueriesSrv: ConversionQueriesService) {
    this._debouncer.pipe(debounceTime(400)).subscribe((value) => this.convertPointToLatLong(value));
    this._selectedGraphics = [];
  }

  ngOnInit() {
    this.initializeMapLayerTransformation().then((mapView) => {
      this._view = mapView;
      if (this.isDispatchingMap && this.dispatchLayerToUse) { this.setDispatchLayerToUse(); }

      this.getMapCenter();
      this.houseKeeping(mapView);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dispatchLayerToUse && this._view) {
      this.setDispatchLayerToUse();
    }

    if (changes.selectedLayerZone && this._view && !this.selectedLayerZone) {
      this.updateGraphicsSelected();
    }

    if (changes.geoZoneForGraphicLayer && this._view) {
      this.getGraphicsDataFromSelectedGeoZone();
    }
    if (this.additionnalLayer && this._view) {
      this._view.map.add(this.additionnalLayer);
    }
  }

  // Finalize a few things once the MapView has been loaded
  private houseKeeping(mapView) {
    mapView.when(() => {
      // console.log("mapView ready: ", mapView.ready);
      this._loaded = mapView.ready;
      // this.getMapCenter();
      this.mapLoadedEvent.emit(true);
    });
  }

  private displayCoordinate(view: esri.MapView) {
    // Add div element to show coordates
    const coordsWidget = document.createElement("div");
    coordsWidget.id = "coordsWidget";
    coordsWidget.className = "esri-widget esri-component";
    coordsWidget.style.padding = "7px 15px 5px";
    view.ui.add(coordsWidget, "bottom-right");

    const showCoordinatesStationary = () => {
      this._debouncer.next({ coordsWidget, mapPoint: { x: view.center.x, y: view.center.y } });
    };
    view.watch(["stationary"], showCoordinatesStationary.bind(this));

    // Add event to show mouse coordinates on click and move >> TODO CHECK IF NEEDED OR IF WE JUST NEED THE MAP CENTER
    // view.on(["pointer-down", "pointer-move"], showCoordinatesPointerMove.bind(this));
  }

  private async convertPointToLatLong(data: { coordsWidget: any, mapPoint: any }) {
    const { coordsWidget, mapPoint } = data;
    this._conversionQueriesSrv.convertLambertToLatLong(mapPoint.x, mapPoint.y).subscribe(result => {
      const resultData: { convertLambertToLatlong: ILatLong } = <any>result.data;
      if (resultData && resultData.convertLambertToLatlong) {
        const coords = "Lat/Lon " + resultData.convertLambertToLatlong.latitude + ", " + resultData.convertLambertToLatlong.longitude;
        coordsWidget.innerHTML = coords;
        return resultData.convertLambertToLatlong;
      }
    }, error => {
      console.log(error);
    });
  }

  async initializeMapLayerTransformation() {
    try {
      // Load the modules for the ArcGIS API for JavaScript
      const [EsriMap, EsriMapView, EsriMapImageLayer, EsriTileLayer, EsriBasemap, EsriWebTileLayer, EsriSpatialReference, EsriExtent, EsriTileInfo] = await loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/MapImageLayer",
        "esri/layers/TileLayer",
        "esri/Basemap",
        "esri/layers/WebTileLayer",
        "esri/geometry/SpatialReference",
        "esri/geometry/Extent",
        "esri/layers/support/TileInfo"
      ]);

      // Définit le type de référence spatial. Les layers et la map doivent avoir la même
      const spatialReference: esri.SpatialReference = new EsriSpatialReference({
        wkid: this._esriMapConfig.wkid
      });

      const map: esri.Map = new EsriMap({
        basemap: new EsriBasemap({
          baseLayers: this.getBaseLayers(spatialReference, EsriTileInfo, EsriWebTileLayer, EsriMapImageLayer),
          // thumbnailUrl: "http://geoservices.wallonie.be/arcgis/rest/services/IMAGERIE/ORTHO_LAST/MapServer/tile/0/203/176"
        }),
        layers: this.buildLayers(EsriTileLayer, EsriMapImageLayer)
      });

      // Initialize the MapView
      const mapViewProperties: esri.MapViewProperties = {
        container: this.mapViewNode.nativeElement,
        map: map
      };

      const view: esri.MapView = new EsriMapView(mapViewProperties);
      // Redéfinit la référence spatial pour matcher avec les tuiles
      view.extent = new EsriExtent({
        xmax: this._orthoLastConfig.initialExtent.xmax,
        xmin: this._orthoLastConfig.initialExtent.xmin,
        ymax: this._orthoLastConfig.initialExtent.ymax,
        ymin: this._orthoLastConfig.initialExtent.ymin,
        spatialReference: spatialReference
      });

      // this.displayLayerList(view, EsriLayerList);
      if (this.isDispatchingMap) {
        view.on("click", this.getAnnotationData.bind(this));
      } else {
        this.displayCoordinate(view);
      }

      return view;

    } catch (error) {
      console.log("EsriLoader: ", error);
    }
  }

  private async setDispatchLayerToUse() {
    const [EsriMapImageLayer] = await loadModules([
      "esri/layers/MapImageLayer",
    ]);
    const mapImageLayerProperties: esri.MapImageLayerProperties = {
      url: `${this.dispatchLayerToUse.url}`,
      sublayers: [
        {
          id: +this.dispatchLayerToUse.mapServerId
        }
      ]
    };
    this._view.map.removeAll();
    this.updateGraphicsSelected();
    this._view.map.add(new EsriMapImageLayer(mapImageLayerProperties));
  }

  public updateGraphicsSelected(graphics: IGeoZoneInput[] = null) {
    // TODO Remove only graphics added and not already save >> Create a layer with all graphic already saved > see graphicLayer 'https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-GraphicsLayer.html'
    // this._selectedGraphics = this._selectedGraphics.filter(sg => graphics.map(g => g.objectId).includes(sg.attributes.OBJECTID));
    let objectIdKey = this.dispatchLayerToUse.url.indexOf("geoservices.wallonie.be") == -1? "objectid" : "OBJECTID";
    this._view.graphics.removeAll();
    if (graphics) {
      const symbol: any = { type: "simple-fill", color: [249, 178, 51, 0.5] };
      this._selectedGraphics.forEach((graphic, i) => {
        const foundGraphic = graphics.find(g => g.objectId === graphic.attributes[objectIdKey]);
        if (foundGraphic) {
          graphic.symbol = symbol;
          this._view.graphics.add(graphic);
        }
      });
    }
  }

  private async getGraphicsDataFromSelectedGeoZone() {
    const symbol: any = { type: "simple-fill", color: [32, 130, 181, 0.5] };
    const [EsriQueryTask, EsriQuery] = await loadModules([
      "esri/tasks/QueryTask",
      "esri/tasks/support/Query",
    ]);
    let queryTask: esri.QueryTask;
    let query: esri.Query;
    let graphics: esri.Graphic[] = [];
    for (const queryTaskDatas of this.geoZoneForGraphicLayer) {
      queryTask = new EsriQueryTask({
        url: queryTaskDatas.url
      });
      query = new EsriQuery();
      query.returnGeometry = true;
      query.outFields = ["*"];
      query.objectIds = queryTaskDatas.objectIds;

      const results = await queryTask.execute(query);
      graphics = [...graphics, ...(<esri.Graphic[]>results.features)];
    }
    graphics.forEach(graphic => {
      graphic.symbol = symbol;
    });
    this.createGraphicLayerOfSelectedGeoZone(graphics);
  }

  private async createGraphicLayerOfSelectedGeoZone(graphics: esri.Graphic[]) {
    const [EsriGraphicsLayer] = await loadModules([
      "esri/layers/GraphicsLayer"
    ]);
    const layer: esri.Layer = this._view.map.findLayerById(LAYER_IDS.geoZoneSelected);
    if (layer) {
      (<esri.GraphicsLayer>layer).graphics.removeAll();
      (<esri.GraphicsLayer>layer).graphics.addMany(graphics);
    } else {
      const graphicsLayer: esri.GraphicsLayer = new EsriGraphicsLayer({ id: LAYER_IDS.geoZoneSelected, graphics });
      this._view.map.add(graphicsLayer);
    }
  }

  private async getAnnotationData(event: esri.MapViewClickEvent) {
    const [EsriPoint, EsriQueryTask, EsriQuery] = await loadModules([
      "esri/geometry/Point",
      "esri/tasks/QueryTask",
      "esri/tasks/support/Query",
    ]);
    const symbol: any = { type: "simple-fill", color: [249, 178, 51, 0.5] };
    const layerUrlToQuery: string = `${this.dispatchLayerToUse.url}/${this.dispatchLayerToUse.mapServerId}`;
    let objectIdKey = this.dispatchLayerToUse.url.indexOf("geoservices.wallonie.be") == -1? "objectid" : "OBJECTID";
    const queryTask = new EsriQueryTask({
      url: layerUrlToQuery
    });
    const query: esri.Query = new EsriQuery();
    query.returnGeometry = true;
    query.outFields = ["*"];
    query.geometry = new EsriPoint({
      x: event.mapPoint.x,
      y: event.mapPoint.y,
      spatialReference: { wkid: 31370 }
    });
    queryTask.execute(query).then(results => {
      const currentGraphs: esri.Graphic[] = this._selectedGraphics;
      const graphicFound: esri.Graphic[] = <esri.Graphic[]>results.features;
      graphicFound.forEach(graphic => {
        // const graphIndex = this._view.graphics.findIndex( graph => {
        //   return Object.keys(graph.attributes).every( key => graph.attributes[key] === graphic.attributes[key]);
        // });
        // if (graphIndex  === -1) {
        //   graphic.symbol = this.dispatchLayerToUse.symbol;
        //   this._view.graphics.add(graphic);
        // } else {
        //   this._view.graphics.removeAt(graphIndex);
        // }
        graphic.symbol = symbol;

        const existsIdx = currentGraphs.findIndex(sg => sg.attributes[objectIdKey] === graphic.attributes[objectIdKey])
        if (existsIdx === -1) {
          currentGraphs.push(graphic);
        } else {
          currentGraphs.splice(existsIdx, 1);
        }
      });
      
      this.updateGraphicsSelected(currentGraphs.map(graph => {
        return {
          layerUrl: `${layerUrlToQuery}/query`,
          objectId: graph.attributes[objectIdKey],
          polygonName: graph.attributes[this.dispatchLayerToUse.attributeKey]
        };
      }));

      this.onChangeGraphicList.emit(currentGraphs.map(graph => {
        return {
          layerUrl: `${layerUrlToQuery}/query`,
          objectId: graph.attributes[objectIdKey],
          polygonName: graph.attributes[this.dispatchLayerToUse.attributeKey]
        };
      }));
    }).catch(error => {
      console.log({ ...this.dispatchLayerToUse, error });
    });
  }

  private async getMapCenter() {
    const [EsriPoint] = await loadModules([
      "esri/geometry/Point",
    ]);
    const mapPoint: esri.Point = new EsriPoint({
      ...ESRI_MAP_CONFIG.defaultCenter,
      spatialReference: { wkid: ESRI_MAP_CONFIG.wkid }
    });
    this._view.goTo(mapPoint);
  }

  // Définit les layers qu'on va utiliser comme base map. L'attribut fullExtent permet de délimiter les tuiles qu'on va aller chercher sur le webservice
  private getBaseLayers(spatialReference: esri.SpatialReference, EsriTileInfo: any, EsriWebTileLayer: any, EsriMapImageLayer: any): esri.TileLayer[] {
    const layers: esri.TileLayer[] = [];
    let config: any;
    let tileInfo: esri.TileInfo;
    let layerData: any;
    const baseTileLayer: any = this._esriMapConfig.baseTileLayers.filter(tileData => (tileData.display && (!this.isDispatchingMap || tileData.displayInDispatching)));
    baseTileLayer.forEach(tileData => {
      config = require(`../../facades/configs/${tileData.json}.config.json`);
      tileInfo = new EsriTileInfo(this.setTileInfo(config));
      layerData = {
        urlTemplate: `${tileData.url}/tile/{level}/{row}/{col}`,
        subDomains: ["level", "col", "row"],
        tileInfo: tileInfo,
        spatialReference: spatialReference,
        fullExtent: {
          xmax: config.fullExtent.xmax,
          xmin: config.fullExtent.xmin,
          ymax: config.fullExtent.ymax,
          ymin: config.fullExtent.ymin,
        }
      };
      switch (tileData.key) {
        case "orthoLast":
          layerData.copyright = "";
          break;
        case "annotation":
          layerData.maxScale = (config.maxScale * 2) + 1;
          break;
        default:
          break;
      }
      layers.push(new EsriWebTileLayer(layerData));
      //Add layer for annotation 
      layers.push(new EsriMapImageLayer({
        url: "http://geoservices.wallonie.be/arcgis/rest/services/DONNEES_BASE/FDC_SPW_ANNOTATIONS/MapServer/",
        bbox: "EsriTileLayer.fullExtent.xmin, EsriTileLayer.fullExtent.ymin, EsriTileLayer.fullExtent.xmax, EsriTileLayer.fullExtent.ymax ",
        bboxSR: 31370,
        imageSR: 31370,
        f:"image",
        show: "2,4,6,8,9,10,12,13,17"
      }));
    });

    return layers;
  }
  // Représente chaque tuile. ATTENTION: les tuiles de la wallonie ne sont pas de la même taille (512) que celles par défaut (256)
  private setTileInfo(config: any) {
    return {
      ...config.tileInfo,
      size: [config.tileInfo.rows, config.tileInfo.cols]
    };
  }

  private buildLayers(EsriTileLayer, EsriMapImageLayer): esri.Layer[] {
    const layers: esri.Layer[] = [];
    // layers.push(new EsriTileLayer({
    //   url: "https://geoservices.wallonie.be/arcgis/rest/services/DONNEES_BASE/FOND_PLAN_ANNOTATIONS_RW/MapServer",
    //   maxScale: (this._layersConfig.maxScale * 2) + 1, // Loading error if we put 250
    // }));

    // TODO: DEFINE Max Scale
    // layers.push(new EsriMapImageLayer({
    //   url: "https://geoservices.wallonie.be/arcgis/rest/services/PLAN_REGLEMENT/CADMAP_PARCELLES/MapServer",
    // }));
    // layers.push(new EsriMapImageLayer({
    //   url: "http://geoservices.wallonie.be/arcgis/rest/services/TOPOGRAPHIE/PICC_VDIFF/MapServer",
    //   minScale: 5000
    // }));
    return layers;
  }

}
