import gql from "graphql-tag";


export const CREATE_COMMENT = gql`
  mutation createComment($comment: InputComment!){
    comment(comment: $comment){
      id
      comment
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation updateComment($id: Int!, $comment: UpdateComment!) {
    updateComment(id: $id, comment: $comment){
      id
      comment
      isPublic
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($id: Int!){
    deleteComment(id: $id)
  }
`;

export const LAST_COMMENTS_AND_ATTACHMENTS = gql`
query lastCommentsAndAttachments($filter: LastActivityFilter!){
  lastCommentsAndAttachments(filter: $filter){
    comments{
      external{
        id
        name
      }
      externalId
      comment
      wastype
      createdAt
      id
      user{
        firstName
        lastName
      }
      citizen{
        firstName
        lastName
        phone
        email
      }
      incidentId
      incident{
        generatedid
        orgacode
      status
      address
       assignment{
        organisation{
          name
        }
      }
    }
    }
    attachments{
      id
      isPublic
      filePath
      user{
        lastName
        firstName
      }
      citizen{
        lastName
        firstName
        phone
        email
      }
      externalId
      external{
        name
      }
      createdAt
      createdAt
      incidentId
      incident{
        generatedid
        orgacode
      status
      address
       assignment{
        organisation{
          name
        }
      }
    }
  }
  }
}
`;
