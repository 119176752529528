import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ITimelineVertical } from "../../facades/interfaces/timeline-vertical.interface";
import { TranslateService } from "src/app/facades/services/translate.service";

@Component({
  selector: "app-timeline-vertical",
  templateUrl: "./timeline-vertical.component.html",
  styleUrls: ["./timeline-vertical.component.css"]
})
export class TimelineVerticalComponent implements OnInit, OnChanges {
  @Input() public historyDatas: ITimelineVertical[] = [];
  public formattedHistoryData: ITimelineVertical[] = [];
  constructor(private translateSrv: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputObject && !changes.inputObject.firstChange) {
      this.formatHistory()
    }
  }

  ngOnInit() {
    this.formatHistory()
    // for (let index = 0; index < 10; index++) {
    //   this.historyDatas.push({
    //     date: "23/08/2019",
    //     user: "BEWAPP",
    //     content: "Incident assigné à l'éxécuteur de travaux : Agent"
    //   });
    // }
  }

  public translate(str: string) {
    return this.translateSrv.smartTranslate(str)
  }

  private formatHistory() {
    this.formattedHistoryData = this.historyDatas  ? this.historyDatas.map(h => ({
      ...h,
      content: h.content && this.translate(h.content.replace(/\n/g, '<br>'))
    })) : []
  }
}
