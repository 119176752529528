import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EsriMapComponent } from "./organisms/esri-map/esri-map.component";
import { EsriMapGroupComponent } from './organisms/esri-map-group/esri-map-group.component';



@NgModule({
  declarations: [
    EsriMapComponent,
    EsriMapGroupComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EsriMapComponent,
    EsriMapGroupComponent
  ]
})
export class MapsModule { }
