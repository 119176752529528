import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import { ModalContentForm } from "../../../facades/model/modal-content-form";
import { ModalService, DATA } from "src/app/facades/services/modal/modal.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ROLES } from "src/app/facades/enum/roles.enum";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { ISelectInput } from "src/app/facades/interfaces/input.interface";
import { UserGroupQueriesService } from "src/app/facades/queries/user-group/user-group-queries.service";
import { UserQueriesService } from "src/app/facades/queries/user/user-queries.service";
import { IUser, IOptionsModalCreateUser, EnumUserRoles, IUserInput } from "src/app/facades/interfaces/user.interface";
import { AuthService, ORGANISATION_ID } from "src/lib/auth/auth.service";
import { IDropListLabels, IDropListData, IDropListItem } from "src/app/presentationnal/drop-list/facades/interfaces/drop-list.interface";
import {
  AGENT_GROUPS_DROP_LIST_LABELS,
  ASCERTAINING_AGENT_GROUPS_DROP_LIST_LABELS,
  MANAGER_GROUPS_DROP_LIST_LABELS,
  ORGANISATIONS_DROP_LIST_LABELS
} from "./modal-create-user.config";
import { IGroup, IFilterGroups } from "src/app/facades/interfaces/group.interface";
import { Subscription, Subject } from "rxjs";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { CUSTOM_VALIDATOR, emailAlreadyUse } from "src/app/presentationnal/input/facades/custom-validator";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { debounceTime } from "rxjs/operators";
import { TranslateService } from "src/app/facades/services/translate.service";
import { IOrganisation } from "src/app/facades/interfaces/organisation.interface";
import { ORDER_TYPE } from "../../../presentationnal/drop-list/organisms/drop-list/drop-list.component";

@Component({
  selector: "app-modal-create-user",
  templateUrl: "./modal-create-user.component.html",
  styleUrls: ["./modal-create-user.component.css"]
})
export class ModalCreateUserComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _updateOrganisationSub: Subscription;
  private _updateSuperAdminValueSub: Subscription;
  private _updateReadonlyAdminValueSub: Subscription;
  private _updateEmailValueSub: Subscription;
  private _rolesUser = ROLES;
  private _dataUser: IUser = null;
  private _dataModal: IOptionsModalCreateUser = null;
  private _oldMailValue: string = "";
  private _debouncerCheckImpact: Subject<any> = new Subject<any>();
  public hasOneRoleSelected: boolean = true;
  public formRole: FormGroup;
  public boxDatas: IBoxInfo[] = [];
  public filter: string;
  public organisations;
  public associationFormGroup: FormGroup;
  public dropListOrder = ORDER_TYPE;

  public organisationOptions: ISelectInput[] = [];
  public managerDropListData: IDropListData = {
    linked: [],
    available: []
  };
  public agentDropListData: IDropListData = {
    linked: [],
    available: []
  };
  public ascertainingAgentDropListData: IDropListData = {
    linked: [],
    available: []
  };
  public organisationsDropListData: IDropListData = {
    linked: [],
    available: []
  };
  public isCreateAdminModal: boolean = false;
  public isCreateManagerModal: boolean = false;
  public isCreateAgentModal: boolean = false;
  public isCreateAscertainingAgentModal: boolean = false;
  public isCreateReadonlyAdminModal: boolean = false;
  public isBaseModal: boolean = true;
  public displayOrganisation: boolean = true;
  public sendingForm: boolean = false;
  public managerGroupsListLabels: IDropListLabels = MANAGER_GROUPS_DROP_LIST_LABELS;
  public agentGroupsListLabels: IDropListLabels = AGENT_GROUPS_DROP_LIST_LABELS;
  public ascertainingAgentGroupsListLabels: IDropListLabels = ASCERTAINING_AGENT_GROUPS_DROP_LIST_LABELS;
  public organisationsListLabels: IDropListLabels = ORGANISATIONS_DROP_LIST_LABELS;

  constructor(protected modalSrv: ModalService,
    protected fb: FormBuilder,
    protected _snackBar: SnackService,
    private _organisationQueriesSrv: OrganisationQueriesService,
    private _authSrv: AuthService,
    private _groupQueriesSrv: UserGroupQueriesService,
    private _userQueriesSrv: UserQueriesService,
    protected _errorMessageSrv: ErrorMessageService,
    private _translateSrv: TranslateService,
    @Inject(DATA) private _data) {
      super(modalSrv, fb, _snackBar, _errorMessageSrv);
      this._dataModal = this._data.data;
      this._dataUser = this._dataModal && this._dataModal.user ? this._dataModal.user : null;
      this.isCreateAdminModal = this._dataModal && this._dataModal.createAdmin ? this._dataModal.createAdmin : false;
      this.isCreateManagerModal = this._dataModal && this._dataModal.createManager ? this._dataModal.createManager : false;
      this.isCreateAgentModal = this._dataModal && this._dataModal.createAgent ? this._dataModal.createAgent : false;
      this.isCreateAscertainingAgentModal = this._dataModal && this._dataModal.createAscertainingAgent ? this._dataModal.createAscertainingAgent : false;
      this.isCreateReadonlyAdminModal = this._dataModal && this._dataModal.createReadonlyAdmin ? this._dataModal.createReadonlyAdmin : false;
      this.isBaseModal = (!this.isCreateAdminModal && !this.isCreateManagerModal && !this.isCreateAgentModal && !this.isCreateAscertainingAgentModal && !this.isCreateReadonlyAdminModal);
      this.displayOrganisation = this.checkSuperAdmin() && (!this._dataUser || !!this._dataUser.organisationId);
      this.inputErrorsLabelMap = new Map<string, string>([
        ["lastName", this._translateSrv.translate("user_lastname-input")],
        ["firstName", this._translateSrv.translate("user_firstname-input")],
        ["email", this._translateSrv.translate("user_mail-input")],
        ["organisationId", this._translateSrv.translate("user_organisation-input")],
        ["organisationIds", this._translateSrv.translate("user_organisations-input")]
      ]);
      this._debouncerCheckImpact.pipe(debounceTime(100)).subscribe(result => {
        this.checkUserUpdateImpact();
      });
  }

  ngOnInit() {
    this.initForm();
    if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)) {
      this.getListOrganisation();
      this._updateOrganisationSub = this._formGroup.get("organisationId").valueChanges.subscribe(result => {
        this.getOrganisationGroups();
      });
    }
    if (this.isBaseModal) {
      this.getOrganisationGroups();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._updateOrganisationSub) { this._updateOrganisationSub.unsubscribe(); }
    if (this._updateSuperAdminValueSub) { this._updateSuperAdminValueSub.unsubscribe(); }
    if (this._updateEmailValueSub) { this._updateEmailValueSub.unsubscribe(); }
  }

  public checkIsOrganisationEditable(): boolean {
    return (this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) && (!this._dataUser || !this._dataUser.organisationId));
  }

  public initForm() {
    this._formGroup = this.fb.group({
      id: this._dataUser && this._dataUser.id ? this._dataUser.id : null,
      lastName: [this._dataUser && this._dataUser.lastName ? this._dataUser.lastName : null, Validators.required],
      firstName: [this._dataUser && this._dataUser.firstName ? this._dataUser.firstName : null, Validators.required],
      email: [this._dataUser && this._dataUser.email ? this._dataUser.email : null, Validators.required],
      phone: [this._dataUser && this._dataUser.phone ? this._dataUser.phone : null, Validators.pattern(CUSTOM_VALIDATOR.belgianPhone)],
      organisationId: this._dataUser && this._dataUser.organisationId ? this._dataUser.organisationId : null,
      // organisationIds: this._dataUser && this._dataUser.readonlyOrganisations ? this._dataUser.readonlyOrganisations.map(orga => orga.id) : null,
    });
    this._oldMailValue = this._formGroup.get("email").value;

    this.formRole = this.fb.group({
      isAdmin: [this._dataUser && this._dataUser.roles ? this.checkRoleValue("ADMIN") : false, Validators.required],
      isSuperAdmin: this._dataUser && this._dataUser.roles ? this.checkRoleValue("SUPER_ADMIN") : false,
      isReadonlyAdmin: this._dataUser && this._dataUser.roles ? this.checkRoleValue("READONLY_ADMIN") : false,
      isManager: this._dataUser && this._dataUser.roles ? this.checkRoleValue("MANAGER") : false,
      isAgent: this._dataUser && this._dataUser.roles ? this.checkRoleValue("AGENT") : false,
      isAscertainingAgent: this._dataUser && this._dataUser.roles ? this.checkRoleValue("ASCERTAINING_AGENT") : false,
    });

    setTimeout(() => {
      this._updateSuperAdminValueSub = this.formRole.valueChanges.subscribe( result => {
        this.checkRolesSelected();
        this._debouncerCheckImpact.next();
      });
    }, 100);

    this._updateSuperAdminValueSub = this.formRole.get("isSuperAdmin").valueChanges.subscribe(result => {
      if (result) {
        this._formGroup.get("organisationId").patchValue(null);
        this.formRole.get("isManager").patchValue(false);
        this.formRole.get("isAscertainingAgent").patchValue(false);
        this.formRole.get("isAgent").patchValue(false);
        this.formRole.get("isAdmin").patchValue(false);
        this.formRole.get("isReadonlyAdmin").patchValue(false);
        // Clear validator
        this._formGroup.get("organisationId").clearValidators();
        this._formGroup.get("organisationId").updateValueAndValidity();
      } else {
        if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) && !this.isCreateAdminModal && !this.isCreateReadonlyAdminModal) {
          this._formGroup.get("organisationId").setValidators(Validators.required);
          this._formGroup.get("organisationId").updateValueAndValidity();
        }

      }
    });

    this._updateReadonlyAdminValueSub = this.formRole.get("isReadonlyAdmin").valueChanges.subscribe(result => {
      if (result) {
        this._formGroup.get("organisationId").patchValue(null);
        this.formRole.get("isSuperAdmin").patchValue(false);
        this.formRole.get("isManager").patchValue(false);
        this.formRole.get("isAscertainingAgent").patchValue(false);
        this.formRole.get("isAgent").patchValue(false);
        this.formRole.get("isAdmin").patchValue(false);
        // Clear validator
        this._formGroup.get("organisationId").clearValidators();
        this._formGroup.get("organisationId").updateValueAndValidity();
        this.displayOrganisation = false;
      } else {
        if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) && !this.isCreateAdminModal) {
          this._formGroup.get("organisationId").setValidators(Validators.required);
          this._formGroup.get("organisationId").updateValueAndValidity();
          this.displayOrganisation = true;
        }
      }
    });

    this._updateEmailValueSub = this._formGroup.get("email").valueChanges.subscribe(result => {
      if (result !== this._oldMailValue) {
        this._formGroup.get("email").setValidators([Validators.required]);
        setTimeout(() => {
          this._formGroup.get("email").updateValueAndValidity();
        }, 0);
      }
      this._oldMailValue = result;
    });
  }

  private checkUserUpdateImpact() {
    this._userQueriesSrv.checkUserUpdateImpacts(this.formatUserDataToSave()).subscribe( result => {
      const resultData: {userUpdateImpacts: any} = <any>result.data;
      if (resultData && resultData.userUpdateImpacts) {
        this.boxDatas = resultData.userUpdateImpacts.map( impact => {
          return {
            type: BOX_INFO_TYPE.WARNING,
            message: `${impact.message} : ${(<[]>impact.infos).join(", ")}`
          };
        });
        if (this.boxDatas.length === 0 && this.sendingForm) {
          this.sendingForm = false;
          this.confirmModal();
        }
      }
      this.sendingForm = false;
    }, error => {
      this.sendingForm = false;
      console.log("ERROR CHECK USER UPDATE IMPACTS", {error});
    });
  }

  public checkRoleValue(role: string) {
    return this._dataUser.roles.includes(this._rolesUser[role]);
  }

  public getListOrganisation() {
    this._organisationQueriesSrv.getOrganisationListQuery().subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.organisations) {
        this.organisationOptions = resultData.organisations.map(organisation => {
          return {
            value: organisation.id,
            label: organisation.name
          };
        });
        this.setOrganisationDropListDatas(resultData.organisations);
      }
    });
  }

  private getOrganisationGroups() {
    let filter: IFilterGroups;
    let canDoQuery: boolean = !this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN);
    if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) && this._formGroup.get("organisationId").value) {
      filter = {
        organisationId: this._formGroup.get("organisationId").value
      };
      canDoQuery = true;
    }
    if (canDoQuery) {
      this._groupQueriesSrv.getListGroupByOrganisation(filter).subscribe(result => {
        const resultData: any = result.data;
        if (resultData && resultData.groups) {
          this.setDropListDatas(resultData.groups);
        }
      }, error => {
        console.log("ERROR GETTING ORGANISATION GROUPS", { error });
      });
    } else {
      this.setDropListDatas([]);
    }
  }

  private setDropListDatas(groups: IGroup[]) {
    if (this._dataUser) {
      const
        linkedManagerGroupIds: number[] = this._dataUser.managerGroups ?
          this._dataUser.managerGroups.map(group => group.id) : [],
        linkedAgentGroupIds: number[] = this._dataUser.agentGroups ?
          this._dataUser.agentGroups.map(group => group.id) : [],
        linkedAscertainingAgentGroupIds: number[] = this._dataUser.ascertainingAgentGroups ?
          this._dataUser.ascertainingAgentGroups.map(group => group.id) : [];

      this.managerDropListData = {
        linked: this.setDropListItem(groups.filter(group => linkedManagerGroupIds.includes(group.id))),
        available: this.setDropListItem(groups.filter(group => !linkedManagerGroupIds.includes(group.id)))
      };
      this.agentDropListData = {
        linked: this.setDropListItem(groups.filter(group => linkedAgentGroupIds.includes(group.id))),
        available: this.setDropListItem(groups.filter(group => !linkedAgentGroupIds.includes(group.id)))
      };
      this.ascertainingAgentDropListData = {
        linked: this.setDropListItem(groups.filter(group => linkedAscertainingAgentGroupIds.includes(group.id))),
        available: this.setDropListItem(groups.filter(group => !linkedAscertainingAgentGroupIds.includes(group.id)))
      };
    } else {
      this.managerDropListData.available = this.setDropListItem(groups);
      this.agentDropListData.available = this.setDropListItem(groups);
      this.ascertainingAgentDropListData.available = this.setDropListItem(groups);
    }
  }

  private setOrganisationDropListDatas(organisations: IOrganisation[]) {
    if (this._dataUser) {
      const linkedReadonlyOrganisationIds: number[] = this._dataUser.readonlyOrganisations ?
        this._dataUser.readonlyOrganisations.map(organisation => organisation.id) : [];

      this.organisationsDropListData = {
        linked: this.setDropListItem(organisations.filter(orga => linkedReadonlyOrganisationIds.includes(orga.id))),
        available: this.setDropListItem(organisations.filter(orga => !linkedReadonlyOrganisationIds.includes(orga.id)))
      };
    } else {
      this.organisationsDropListData.available = this.setDropListItem(organisations);
    }
  }

  private setDropListItem(items: {id: number, name: string}[]): IDropListItem[] {
    return items.map((item: {id: number, name: string}) => {
      return {
        id: item.id,
        name: item.name
      };
    });
  }

  private formatUserDataToSave(): IUserInput {
    return {
      ...this._formGroup.value,
      organisationId: this._formGroup.controls.organisationId.value,
      roles: this.setRoles(),
      managerGroupIds: this.formRole.get("isManager").value ?
        this.managerDropListData.linked.map(data => data.id) : [],
      agentGroupIds: this.formRole.get("isAgent").value ?
        this.agentDropListData.linked.map(data => data.id) : [],
      ascertainingAgentGroupIds: this.formRole.get("isAscertainingAgent").value ?
        this.ascertainingAgentDropListData.linked.map(data => data.id) : [],
      readonlyOrganisationIds: this.formRole.get("isReadonlyAdmin").value ?
        this.organisationsDropListData.linked.map(data => data.id) : [],

      // password: "Fmx@092B=7",
      // isActive: true
    };
  }

  public setRoles() {
    const data: any = this.formRole.getRawValue();
    const rolesArray = [];
    if (data.isSuperAdmin) {
      rolesArray.push(EnumUserRoles.SUPER_ADMIN);
    }
    if (data.isAdmin) {
      rolesArray.push(EnumUserRoles.ADMIN);
    }
    if (data.isManager) {
      rolesArray.push(EnumUserRoles.MANAGER);
    }
    if (data.isAgent) {
      rolesArray.push(EnumUserRoles.AGENT);
    }
    if (data.isAscertainingAgent) {
      rolesArray.push(EnumUserRoles.ASCERTAINING_AGENT);
    }
    if (data.isReadonlyAdmin) {
      rolesArray.push(EnumUserRoles.READONLY_ADMIN);
    }
    return rolesArray;
  }

  private checkRolesSelected() {
    const formValue = this.formRole.getRawValue();
    this.hasOneRoleSelected = (
      formValue.isSuperAdmin ||
      formValue.isManager ||
      formValue.isAdmin ||
      formValue.isAgent ||
      formValue.isAscertainingAgent ||
      formValue.isReadonlyAdmin
    );
  }

  public confirmModal() {
    if (!this._formGroup.get("id").value) {
      const email: string = this._formGroup.get("email").value ? this._formGroup.get("email").value : "";
      this._userQueriesSrv.isEmailFree(email).subscribe(result => {
        const resultData: any = result.data;
        if (resultData && !resultData.isEmailFree) {
          this._formGroup.get("email").setValidators([Validators.required, emailAlreadyUse]);
        } else {
          this._formGroup.get("email").setValidators([Validators.required]);
        }
        this._formGroup.get("email").updateValueAndValidity();
        this.checkFormValidity();
      }, error => {
        console.log("ERROR CHECK EMAIL FREE", { error });
      });
    } else {
      this.checkFormValidity();
    }
  }

  private checkFormValidity() {
    this.sendingForm = true;
    this.checkRolesSelected();
    if (this.hasOneRoleSelected && this._formGroup.valid) {
      this.sendingForm = false;
      // this.setCheckboxDisable();
      this.save();
      this.modalSrv.closeModal();
    } else {
      this.markFormGroupTouched(this._formGroup);
      this.markFormGroupTouched(this.formRole);
      this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
      if (!this.hasOneRoleSelected) {
        (<Array<IBoxInfo>>this.inputErrorsLabel).push({
          message: this._translateSrv.translate("user_role-input") + ": " + this._translateSrv.translate("user_roleError-txt"),
          type: BOX_INFO_TYPE.DANGER
        });
      }
    }
  }

  protected save(): void {
    const objData = {
      confirm: true,
      data: this.formatUserDataToSave(),
    };
    this.afterClosed.next(objData);
  }

  public cancelModal(): void {
    const objData = {
      confirm: false,
    };
    this.afterClosed.next(objData);
    this.modalSrv.closeModal();
  }

  public checkSuperAdmin() {
    return this._authSrv.getRolesCurrent().includes(ROLES.SUPER_ADMIN);
  }

  public checkAdmin() {
    return this._authSrv.getRolesCurrent().includes(ROLES.ADMIN) || this.checkSuperAdmin();
  }

  // private setCheckboxDisable() {
  //   this.formRole.get("isAdmin").enable();
  //   this.formRole.get("isSuperAdmin").enable();
  //   this.formRole.get("isManager").enable();
  //   this.formRole.get("isAgent").enable();
  //   this.formRole.get("isAscertainingAgent").enable();
  // }

  public search(event) {
    this.filter = event;
    this.organisations = this._organisationQueriesSrv.getOrganisationListQuery({name: this.filter ? this.filter : null}).subscribe(result => {
      const resultData: {organisations: IOrganisation[]} = <any>result.data;
      if (resultData && resultData.organisations && resultData.organisations.length > 0) {
        this.organisationOptions = resultData.organisations.filter(o => o.isActive).map( organisation => {
          return {
            value: organisation.id,
            label: organisation.name
          };
        });
        this._formGroup.get("organisationId").patchValue(resultData.organisations[0].id);
      }
    }, error => {
      console.log("ERROR LOADING ORGANISATION", {error});
    });
  }

  public searchFieldMap = new Map([
      ["organisationId", "name"],
  ]);

}
