import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputModule } from "./input/input.module";
import { LoginModule } from "./login/login.module";
import { LabelModule } from "./label/LabelModule";
import { ButtonModule } from "./button/button.module";
import { HeaderModule } from "./header/header.module";
import { MenuModule } from "./menu/menu.module";
import { TableModule } from "./table/table.module";
import { BarModule } from "./bar/bar.module";
import { DropListModule } from "./drop-list/drop-list.module";
import { BlockModule } from "./block/block.module";
import { SnackbarModule } from "./snackbar/snackbar.module";
import { TabsModule } from "./tabs/tabs.module";
import { MapsModule } from "./maps/maps.module";
import { TagsModule } from "./tags/tags.module";
import { TimelineModule } from "./timeline/timeline.module";
import { BoxInfoModule } from "./box-info/box-info.module";
import { CommentModule } from "./comment/comment.module";
import { PaginationModule } from "./pagination/pagination.module";
import { ExpandModule } from "./expand/expand.module";
import { ChartModule } from "./chart/chart.module";
import { StatsModule } from "./stats/stats.module"


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LabelModule,
    InputModule,
    LoginModule,
    ButtonModule,
    HeaderModule,
    MenuModule,
    TableModule,
    BarModule,
    DropListModule,
    BlockModule,
    SnackbarModule,
    TabsModule,
    MapsModule,
    TagsModule,
    TimelineModule,
    BoxInfoModule,
    CommentModule,
    PaginationModule,
    ExpandModule,
    ChartModule,
    StatsModule
  ],
  exports: [
    LabelModule,
    InputModule,
    LoginModule,
    ButtonModule,
    HeaderModule,
    MenuModule,
    TableModule,
    BarModule,
    DropListModule,
    BlockModule,
    SnackbarModule,
    TabsModule,
    MapsModule,
    TagsModule,
    TimelineModule,
    BoxInfoModule,
    CommentModule,
    PaginationModule,
    ExpandModule,
    ChartModule,
    StatsModule
  ]
})
export class PresentationnalModule { }
