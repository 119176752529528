import { Component, Input, Renderer2 } from "@angular/core";
import { AInputs } from "../../facades/inputs";
import { ErrorMessageService } from "../../facades/services/error-message.service";

@Component({
  selector: "app-textarea-input",
  templateUrl: "./textarea-input.component.html",
  styleUrls: ["./textarea-input.component.scss"]
})
export class TextareaInputComponent extends AInputs {
  @Input() public cols: number;
  @Input() public rows: number;
  @Input() public small = false;
  constructor(public renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
  }
}
