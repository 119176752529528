import { Component, ViewChild, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { FILES_BLOCK_ACTIONS_ENUM } from "../../facades/enums/files-block-actions.enum";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";

@Component({
  selector: "app-img-input",
  templateUrl: "./img-input.component.html",
  styleUrls: ["./img-input.component.css"]
})
export class ImgInputComponent {

  @Input() public disabled = false;
  @Input() public hiddenInput = false;
  // @Input() files: File[] = [];
  @Input() required: boolean = false;
  @Input() isFileError: boolean = false;
  @Output() public sendImg = new EventEmitter<any>();
  @ViewChild("imgInput", {static: false}) public imgInput: ElementRef;

  public FILES_BLOCK_ACTIONS_ENUM = FILES_BLOCK_ACTIONS_ENUM;
  constructor(private _snackBar: SnackService,
              private _translateSrv: TranslateService) { }


  public chooseFile() {
    if (!this.disabled) {
      this.imgInput.nativeElement.click();
    }
  }

  /**
   * @description Add the file in the filesList and read it for preview
   */
  public set(file: File) {
    if (file) {
      if (file.type.indexOf("image/") === -1) {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("general_fileNeedToBePicture-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      } else {
        // Read file for preview
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          file["previewUrl"] = reader.result;
          this.sendImg.emit(file);
          this.imgInput.nativeElement.value = "";
        };
      }
    }
  }

  public onDrop(ev: any) {
    ev.preventDefault();
    if (!!ev.dataTransfer.files[0]) { this.set(ev.dataTransfer.files[0]); }
  }

  public avoid(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();
  }
}
