import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, InjectionToken } from "@angular/core";
import { DomPortalHost, ComponentPortal, PortalInjector, ComponentType } from "@angular/cdk/portal";
import { Subject } from "rxjs";

import { IModalData } from "../../interfaces/modal-data.interface";
import { ModalContent } from "src/app/facades/model/modal-content";


export const DATA = new InjectionToken<{}>("DATA");

@Injectable()
export class ModalService {
  private portalHost: DomPortalHost;
  private portal: ComponentPortal<any>;
  public buttonClicked: Subject<any> = new Subject<any>();
  private modalData: IModalData;

  public get getModalData(): IModalData { return this.modalData; }
  public set setModalData(value: IModalData) { this.modalData = value; }

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private injector: Injector,
              private appRef: ApplicationRef) {
  }

  public openModal(component: ComponentType<ModalContent>, modalData: IModalData = null): ModalContent {
    if (!this.portalHost || !this.portalHost.hasAttached() ) {
      this.modalData = modalData;
      this.portal = new ComponentPortal(component, null, this.createInjector(modalData));
      document.querySelector("#appModal").removeAttribute("style");
      document.querySelector("#appModalTitle").innerHTML = modalData.title;
      this.portalHost = new DomPortalHost(document.querySelector("#portalModal"),
       this.componentFactoryResolver, this.appRef, this.injector);
      const componentRef = this.portalHost.attach<ModalContent>(this.portal);
      return componentRef.instance;
    } else {
      // console.log("----------------- SURCHARGE MODAL CONTENT -----------------");
      return null;
    }
  }

  public buttonClick(actionType: number, isCloseCrossButton: boolean = false, deleteCancelEvent: boolean = false): void {
    if (deleteCancelEvent) {
      delete this.modalData.cancelCallback;
    }
    this.buttonClicked.next({actionType: actionType, isCloseCrossButton: isCloseCrossButton}); // Subscribe by the ModalContent
  }


  public closeModal(): void {
    document.querySelector("#appModal").setAttribute("style", "display:none");
    this.portalHost.detach();
    this.modalData = null;
  }

  private createInjector(dataToPass: any): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(DATA, dataToPass);
    return new PortalInjector(this.injector, injectorTokens);
  }

}
