import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { SET_GEO_ZONE, GET_GEO_ZONES, DELETE_GEO_ZONE, GEO_ZONES_BY_ORGANISATION, GEO_ZONES_BY_LAYER_ID, GROUPS_GEO_ZONES_BY_LAYER_ID, CHECK_GEO_ZONE_FOR_GROUP, GEO_ZONES_BY_ORGANISATION_POLYGONS, CHECK_GEO_ZONES_FOR_GROUP } from "./dispatching.queries";
import { IGeoZoneInput } from "../../interfaces/dispatching.interface";
import { GET_LAYERS } from "./layer.queries";

@Injectable({
  providedIn: "root"
})
export class DispatchingQueriesService {
  private _queryDataForGeoZonesByLayerId: any;

  constructor( private _apollo: Apollo) { }

  public getLayers() {
    return this._apollo.query({
      query: GET_LAYERS,
      fetchPolicy: "no-cache"
    });
  }

  public geoZonesByOrganisation(organisationId?: number, queryPolygons?: boolean) {
    return this._apollo.query({
      query: !!queryPolygons ? GEO_ZONES_BY_ORGANISATION_POLYGONS : GEO_ZONES_BY_ORGANISATION,
      variables: {organisationId},
      fetchPolicy: "no-cache"
    });
  }

  public geoZonesByLayerId(layerId: number) {
    this._queryDataForGeoZonesByLayerId = {
      query: GEO_ZONES_BY_LAYER_ID,
      variables: {layerId}
    };
    return this._apollo.watchQuery({
      ...this._queryDataForGeoZonesByLayerId,
      fetchPolicy: "network-only"
    });
  }

  public getGeoZone(id: number) {
    return this._apollo.query({
      query: GET_GEO_ZONES,
      variables: {id},
      fetchPolicy: "no-cache"
    });
  }

  public setGeoZone(input: IGeoZoneInput) {
    return this._apollo.mutate({
      mutation: SET_GEO_ZONE,
      variables: {input},
      fetchPolicy: "no-cache",
      refetchQueries: [
        this._queryDataForGeoZonesByLayerId
      ]
    });
  }

  public deleteGeoZone(geozoneId: number, organisationId: number) {
    return this._apollo.mutate({
      mutation: DELETE_GEO_ZONE,
      variables: {
        geozoneId,
        organisationId
      },
      fetchPolicy: "no-cache",
      refetchQueries: [
        this._queryDataForGeoZonesByLayerId
      ]
    });
  }

  public deleteGroupGeoZone(geozoneId: number, groupId: number) {
    return this._apollo.mutate({
      mutation: DELETE_GEO_ZONE,
      variables: {
        geozoneId,
        groupId
      },
      fetchPolicy: "no-cache",
      refetchQueries: [
        this._queryDataForGeoZonesByLayerId
      ]
    });
  }

  // GROUP
  public groupsGeoZonesByLayerId(layerId: number) {
    this._queryDataForGeoZonesByLayerId = {
      query: GROUPS_GEO_ZONES_BY_LAYER_ID,
      variables: {layerId}
    };
    return this._apollo.watchQuery({
      ...this._queryDataForGeoZonesByLayerId,
      fetchPolicy: "network-only"
    });
  }

  public checkGeoZoneForGroup(layerUrl: string, objectId: number) {
    return this._apollo.query({
      query: CHECK_GEO_ZONE_FOR_GROUP,
      variables: {layerUrl, objectId},
      fetchPolicy: "no-cache"
    });
  }

  public checkGeoZonesForGroup(layerUrl: string, objectIds: number[]) {
    return this._apollo.query({
      query: CHECK_GEO_ZONES_FOR_GROUP,
      variables: {layerUrl, objectIds},
      fetchPolicy: "no-cache"
    });
  }
  
}
