import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ModalCreateGroupUserComponent } from "src/app/modal/component/modal-create-group-user/modal-create-group-user.component";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { UserGroupQueriesService } from "src/app/facades/queries/user-group/user-group-queries.service";
import { IGroupInptade, IGroup, IFilterGroups } from "src/app/facades/interfaces/group.interface";
import { EnumUserRoles, IUserInput } from "src/app/facades/interfaces/user.interface";
import { ModalCreateUserComponent } from "src/app/modal/component/modal-create-user/modal-create-user.component";
import { UserQueriesService } from "src/app/facades/queries/user/user-queries.service";
import { ITableActionData, EnumTableAction, ITableFilter, EnumTableDataType } from "src/app/presentationnal/table/facades/interfaces/table.interface";
import { ModalConfirmComponent } from "src/app/modal/component/modal-confirm/modal-confirm.component";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { IFilterBarPagination } from "src/app/presentationnal/bar/facades/interfaces/filter-bar.interface";
import { AuthService } from "src/lib/auth/auth.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { IIncidentColumnFilter } from "src/app/facades/interfaces/incident.interface";
import { headerInputDefaultGroup } from "src/app/facades/configs/group-list-filters";
import { ROLES } from "src/app/facades/enum/roles.enum";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { XlsService } from "src/app/facades/services/xls/xls.service";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";


@Component({
  selector: "app-user-group-list",
  templateUrl: "./user-group-list.component.html",
  styleUrls: ["./user-group-list.component.css"]
})
export class UserGroupListComponent implements OnInit {

  public formGroup: FormGroup;
  public contentTable = [];
  public listUserNoGroup : IUserInput;
  public optionsHeader: IHeaderOptions = {
    title: "group_pageTitle-txt",
  };
  public pagination: IFilterBarPagination = {
    label: "group_groupsOnX-txt",
    listNumber: null,
    listTotalCounter: null,
    totalPage: null,
    currentPage: null
  };

  public headerTable = [
    { key: "name", type: "text", translate: "group_name-th", sortable: true },
    { key: "email", type: "text", translate: "group_mail-th", sortable: true },
    { key: "phone", type: "text", translate: "group_phone-th", sortable: true },
    { key: "organisationName", type: "text", translate: "group_organisation-th", sortable: true},
    { key: "isActive", type: "boolean", translate: "group_isActive-th", sortable: true },
    { key: "Action", type: "action", translate: "group_actionColumn-th", sortable: false },
  ];
  public filterOptions: ITableFilter[] = headerInputDefaultGroup;

  public filtersMapping: Map<string, string> = new Map([
    ["name", "name"],
    ["email", "email"],
    ["phone", "phone"],
    ["organisationIds", "organisationIds"],
    ["isActive", "isActive"],
  ]);
  loadingExport = false;

  constructor(private _fb: FormBuilder,
    private _modalSrv: ModalService,
    private _userGroupQueriesSrv: UserGroupQueriesService,
    private _userQueriesSrv: UserQueriesService,
    private _authSrv: AuthService,
    private _translateSrv: TranslateService,
    private _xlsSrv: XlsService,
    private _orgaSrv: OrganisationQueriesService,
    private _snackBar: SnackService) {

    if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) || this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN)) {
      this.optionsHeader.buttonsOption = {
        size: "small",
        name: "general_create-button"
      };
    }
  }

  ngOnInit() {
    const appliedFilters = {};
    this.filtersMapping.forEach(value => {
      appliedFilters[value] = null;
    });
    this._userGroupQueriesSrv.appliedFilters = appliedFilters;
    this._userGroupQueriesSrv.resetPagination();
    this.pagination.listNumber = this._userGroupQueriesSrv.userGroupPagination.limit;
    this.initFilterOptions();
    this.listGroupUser();
    // this.addGroupUser();
  }

  ngOnDestroy(){
    const appliedFilters = {};
    this.filtersMapping.forEach(value => {
      appliedFilters[value] = null;
    });
    this._userGroupQueriesSrv.appliedFilters = appliedFilters;
  }

  searchElement(event) {
    // TODO Mette query pour filtrer le tableau
  }

  public initFilter() {
    this.formGroup = this._fb.group({
      searching: null,
    });
  }

  public listGroupUser() {
    this._userGroupQueriesSrv.listGroupUsers().valueChanges.subscribe(groupUsers => {
      const resultData: any = <any>groupUsers.data;
      if (resultData && resultData.groups) {
        this.contentTable = resultData["groups"]? resultData["groups"].map(item => this.formatDataForTable(item)) : null;
      }
      if (resultData && resultData.pagination) {
        this.pagination = {
          ...this.pagination,
          listTotalCounter: resultData.pagination.total,
          totalPage: resultData.pagination.totalPage,
          currentPage: resultData.pagination.page
        };
      }
    }, error => {
      console.log("ERROR LOADING GROUP LIST", { error });
    });
  }

  public addGroupUser(groupData?: IGroup | IGroupInptade, isAddingForm: boolean = true) {
    const addUser = this._modalSrv.openModal(ModalCreateGroupUserComponent,
      {
        title: isAddingForm ? this._translateSrv.translate("group_create-title") : this._translateSrv.translate("group_edit-title"),
        type: isAddingForm ? MODAL_TYPE.CREATE : MODAL_TYPE.EDIT,
        data: {
          group: groupData
        },
        modalStyle: {
          modalWidth: "custom"
        }
      });
    if (addUser) {
      addUser.afterClosed.subscribe(res => {
        if (res.confirm) {
          if (res.needAddingUser) {
            setTimeout(() => {
              this.addNewUser(res.data, res.userType);
            }, 0);
          } else {
            this.saveGroupData(res.data);
          }
        }
      });
    }
  }

  public addNewUser(groupData: IGroupInptade, userType: ROLES) {
    const addUser = this._modalSrv.openModal(ModalCreateUserComponent,
      {
        title: this._translateSrv.translate("user_create-title"),
        type: MODAL_TYPE.CREATE,
        data: {
          user: {
            organisationId: +groupData.organisationId,
            roles: [userType]
          },
          createAgent: userType == ROLES.AGENT,
          createAscertainingAgent: userType == ROLES.ASCERTAINING_AGENT,
          createManager: userType == ROLES.MANAGER
        },
        modalStyle: {
          modalWidth: "x-large"
        },
        customData: {
          cancelButtonName: this._translateSrv.translate("general_return-button"),
        }
      });
    if (addUser) {
      addUser.afterClosed.subscribe(res => {
        let userData: IUserInput = null;
        if (res.data) {
          userData = res.data;
          this.saveUserDatas(userData, groupData, userType);
        } else {
          setTimeout(() => {
            this.addGroupUser(groupData);
          }, 0);
        }
      });
    }
  }


  private saveUserDatas(userData: IUserInput, groupData: IGroupInptade, userType: ROLES) {
    this._userQueriesSrv.createUser(userData).subscribe( result => {
      const resultData: any = result.data;
      if (resultData && resultData.userWithSSO) {
        if (userType === ROLES.MANAGER) {
          groupData.managerIds.push(resultData.user.id);
        } else if( userType === ROLES.AGENT) {
          groupData.agentIds.push(resultData.user.id);
        } else {
          groupData.ascertainingAgentIds.push(resultData.user.id);
        }
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("group_addUserSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }
      this.addGroupUser(groupData);
    }, error => {
      console.log("ERROR ADDING NEW ADMINISTRATOR", { error });
      this.addGroupUser(groupData);
    });
  }

  public setRoles(data): EnumUserRoles[] {
    const rolesArray = [EnumUserRoles.ADMIN];
    if (data.isSuperAdmin) {
      rolesArray.push(EnumUserRoles.SUPER_ADMIN);
    }
    if (data.isManager) {
      rolesArray.push(EnumUserRoles.MANAGER);
    }
    if (data.isAgent) {
      rolesArray.push(EnumUserRoles.AGENT);
    }
    return rolesArray;
  }

  private saveGroupData(groupData: IGroupInptade) {
    this._userGroupQueriesSrv.saveUserGroup(groupData).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.group) {
        if (groupData.id) {
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
            this._translateSrv.translate("group_editGroupSuccess"),
            STATUS_ALERTS.SUCCESS,
            5000
          );
        } else {
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
            this._translateSrv.translate("group_createGroupSuccess-txt"),
            STATUS_ALERTS.SUCCESS,
            5000
          );
        }
      }
    }, error => {
      if (groupData.id) {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("group_editGroupFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      } else {
        if (error.graphQLErrors && error.graphQLErrors.some(err => err.code == 40022)) { // 40022 specific code from backend error
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("group_alreadyExistName-txt"),
            STATUS_ALERTS.DANGER,
            0
          );
        } else {
          this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("group_createGroupFail-txt"),
            STATUS_ALERTS.DANGER,
            0
          );
        }
      }
      console.log("ERROR SAVING USER GROUP DATA", { error });
    });
  }


  /**
   * Methods in the list
   *
   */
  public action(tableAction: ITableActionData) {
    switch (tableAction.action) {
      case EnumTableAction.EDIT:
        this.loadGroupData(tableAction.content.id);
        break;
      case EnumTableAction.DELETE:
        this.beforeDeleteUserGroup(tableAction.content);
        break;
      case EnumTableAction.FILTER:
        this.processFilters(tableAction.content);
        break;
      case EnumTableAction.SORT:
        this.sort(tableAction.content);
        break;
    }
  }

  private processFilters(filterInputValues: any): void {
    const appliedFilters = {};
    Object.keys(filterInputValues).forEach(key => {
      if (this.filtersMapping.get(key)) {
        appliedFilters[this.filtersMapping.get(key)] = filterInputValues[key];
      }
    });
    this._userGroupQueriesSrv.appliedFilters = appliedFilters;
    this._userGroupQueriesSrv.resetPagination();
    this.listGroupUser();
  }

  private sort(sortObject: any) {
    let { order } = this._userGroupQueriesSrv._userGroupPagination;

    if (!order) {
      order = {};
      this.headerTable.forEach(header => {
        if (header.sortable && header.key !== "Action") { order[header.key] = null; }
      });
    }
    Object.keys(order).forEach(key => {
      if (key === sortObject.key) {
        order[key] = order[key] === "ASC" ? "DESC" : "ASC";
      } else {
        order[key] = null;
      }
    });
    this._userGroupQueriesSrv._userGroupPagination = {
      ...this._userGroupQueriesSrv._userGroupPagination,
      order
    };
    this.listGroupUser();
  }


  private loadGroupData(groupId: number) {
    this._userGroupQueriesSrv.getUserGroup(groupId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.group) {
        this.addGroupUser(resultData.group, false);
      }
    }, error => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("group_loadDataFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
      console.log("ERROR LOADING GROUP DATA", { error });
    });
  }

  private beforeDeleteUserGroup(group: IGroup) {
    //execute service
    this._userGroupQueriesSrv.getNoGroupsUser(group.id).subscribe(result => {
      const resultData : any = result.data;
      if (resultData && resultData.findDeletedUser) {
        const findUser = resultData.findDeletedUser;
        const liModal = [];
        findUser.forEach(user => {
          liModal.push(`<li>${user.lastName} ${user.firstName}</li>`);
        });
        const message = findUser.length > 0 ? `<p>${this._translateSrv.translate("alert_modal_delete_group-txt")}<p>
                                               <ul>${liModal}</ul>
                                               </br>${this._translateSrv.translate("group_deleteConfirm-txt")} <b>${group.name}</b>?`
                                               :
                                               `${this._translateSrv.translate("group_deleteConfirm-txt")} <b>${group.name}</b>?`;
        this._modalSrv.openModal(ModalConfirmComponent,
          {
            title: this._translateSrv.translate("group_deleteAreYouSure-txt"),
            type: MODAL_TYPE.CONFIRM,
            data: {
              message: message
              // actions: [
              //   "A DEFINIR",
              // ],
            },
            params: { groupId: group.id },
            confirmCallback: this.deleteUserGroup
          }
        );
      } else {
        console.log("ERROR GET USER NO GROUP!");


      }


    });



  }

  private deleteUserGroup = (params: any) => {
    this._userGroupQueriesSrv.deleteUserGroup(params.groupId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.deleteGroup) {
        const { userGroupPagination } = this._userGroupQueriesSrv;
        if (userGroupPagination.page > 1 && this.contentTable.length === 1 && userGroupPagination.page === this.pagination.totalPage) {
          this._userGroupQueriesSrv.userGroupPagination = {
            ...this._userGroupQueriesSrv.userGroupPagination,
            page: this._userGroupQueriesSrv.userGroupPagination.page - 1
          };
          this.listGroupUser();
        }
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("group_deleteSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      } else {
        console.log("ERROR DELETING GROUP", { result });
      }
    }, error => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("group_deleteFailt-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
      console.log("ERROR DELETING GROUP", { error });
    });
  }

  public setPagination(currentPage) {
    this._userGroupQueriesSrv.userGroupPagination = {
      ...this._userGroupQueriesSrv.userGroupPagination,
      page: currentPage
    };
    this.listGroupUser();
  }

  public countItemsChanged(nbItemsToDisplay: number) {
    this._userGroupQueriesSrv.userGroupPagination = {
      ...this._userGroupQueriesSrv.userGroupPagination,
      limit: nbItemsToDisplay,
      page: 1
    };
    this.listGroupUser();
  }

  public generateExcel() {
    this.loadingExport = true;
    const headers = this.headerTable.filter(header => header.key != "Action").map(header => ({
      key: header.key,
      translate: header.translate
    }));
    this._userGroupQueriesSrv.getGroupsXls(headers).subscribe(result => {
      const exportXls = result.data['groupsXls'];
      this._xlsSrv.downloadXls(exportXls);
      this.loadingExport = false;
    }, error => {
        console.log("ERROR GETTING USER XLS LIST", { error });
        this._snackBar.open(
            this._translateSrv.translate("general_snackbarTitleError-txt"),
            this._translateSrv.translate("group_generateExcelFail-txt"),
            STATUS_ALERTS.DANGER,
            0);
    });
}

  private formatDataForTable(group: any) {
    return {
      ...group,
      organisationName: group.organisation? group.organisation.name : " - ",
    }
  }

  public async initFilterOptions(): Promise<void> {
    for (const filter of this.filterOptions) {
        switch (filter.type) {
            case EnumTableDataType.selectcheckbox:
              case EnumTableDataType.selectSearchMultiple:
                filter.options = await this.getFilterSelectOptions(filter.key);
                break;
        }
    }
}

  private async getFilterSelectOptions(fieldName: string, filter: any = null): Promise<ISelectOption[]> {
    switch (fieldName) {
      case "organisationIds":
        return await this._orgaSrv.getOrganisationListQuery(filter)
          .toPromise()
          .then(res => {
            return res.data["organisations"].map(orga => ({ label: orga.name, value: orga.id }), filter);
          })
          .catch(e => { throw e; });
      default:
        return [];
    }
  }

  public async search(event){
    const filterToChange = this.filterOptions.find(item => item.key == event.key);
    let filter = {
      [this.searchFieldMap.get(event.key)]: event.search && event.search != ""? event.search : null
    }

    if(filterToChange) filterToChange.options = await this.getFilterSelectOptions(event.key, filter);
  }

  public searchFieldMap = new Map([
    ["organisationIds", "name"],
  ])
}
