// tslint:disable: variable-name
// tslint:disable: no-input-rename

import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appLoading]'
})
export class LoadingDirective implements OnChanges {

  @Input('appLoading') public appLoading = false;

  constructor(private _el: ElementRef) { }

  /**
   * @description Apply the class 'loading' on the first element
   * @author Kevin Palade
   */
  ngOnChanges() {
    if ( this.appLoading ) {
      this._el.nativeElement.children[0].classList.add('loading');
    }
  }

}
