import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { TranslateService } from "src/app/facades/services/translate.service";
import { CategoryQueriesService } from "src/app/facades/queries/categorie/category-queries.service";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { IExpandContentCategory, IExpandContentCategoryActions } from "src/app/presentationnal/expand/facades/interfaces/expand-content-categories.interface";
import { ModalConfirmComponent } from "src/app/modal/component/modal-confirm/modal-confirm.component";
import { ICategoryTheme } from "../../../facades/interfaces/category-theme.interface";
import { ModalCreateThemeComponent } from "../../../modal/component/modal-create-theme/modal-create-theme.component";

@Component({
  selector: "app-themes-page",
  templateUrl: "./themes-page.component.html",
  styleUrls: ["./themes-page.component.css"]
})
export class ThemesPageComponent implements OnInit {
  public optionsHeader: IHeaderOptions;
  public expandContents: IExpandContentCategory[] = [];
  public themeIndex: number = 0;

  constructor(
    private _categoryQueriesSrv: CategoryQueriesService,
    private _translateSrv: TranslateService,
    private _modalSrv: ModalService,
    private _snackBar: SnackService,
    private _fb: FormBuilder
  ) {
    this.optionsHeader = {
      title: "theme_pageTitle-txt",
    };
  }

  ngOnInit() {
    this.loadCategoryThemes();
  }

  private async loadCategoryThemes() {
    this._categoryQueriesSrv.watchCategoryThemes().valueChanges.subscribe((result: any) => {
      if (result && result.data) {
        const { categoryThemes } = result.data;
        this.themeIndex = 0;
        this.expandContents = this.generateExpandContent(categoryThemes);
      }
    }, error => {
      console.log("ERROR LOADING THEMES", { error });
    });
  }


  private generateExpandContent(themes: ICategoryTheme[]): any[] {
    const expandData = themes.map((theme) => {
      const formGroup = this._fb.group({
        nameFr: [theme.translation && theme.translation.fr ? theme.translation.fr.name : null, Validators.compose([Validators.required, Validators.maxLength(100)])],
        nameNl: [theme.translation && theme.translation.nl ? theme.translation.nl.name : null, Validators.compose([Validators.required, Validators.maxLength(100)])],
        nameEn: [theme.translation && theme.translation.en ? theme.translation.en.name : null, Validators.compose([Validators.required, Validators.maxLength(100)])],
        nameDe: [theme.translation && theme.translation.de ? theme.translation.de.name : null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      });

      const itemToReturn = {
        category: theme,
        actions: this.generateActions(),
        formGroup,
        children: [],
        index: this.themeIndex,
        level: 1
      };

      this.themeIndex = this.themeIndex + 1;
      return itemToReturn;
    });
    return expandData;
  }


  private generateActions(): IExpandContentCategoryActions {
    const actions: IExpandContentCategoryActions = {
      edit: {
        label: this._translateSrv.translate("general_edit-button"),
        icon: "fas fa-edit",
        action: "EDIT",
        click: this.updateCategory
      },
      delete: {
        label: this._translateSrv.translate("general_delete-button"),
        icon: "fas fa-trash-alt",
        action: "DELETE",
        click: this.beforeDeleteTheme
      }
    };

    return actions;
  }


  public addTheme = () => {
    const createTheme = this._modalSrv.openModal(ModalCreateThemeComponent,
      {
        title: this._translateSrv.translate("theme_create-title"),
        type: MODAL_TYPE.CREATE,
        data: null,
        modalStyle: {
          modalWidth: "x-large",
        }
      }
    );

    if (createTheme) {
      createTheme.afterClosed.subscribe(res => {
        if (res.confirm && res.data) {
          const variables = {
            name: res.data.nameFr,
            translation: {
              fr: { name: res.data.nameFr },
              en: { name: res.data.nameEn },
              de: { name: res.data.nameDe },
              nl: { name: res.data.nameNl }
            }
          };

          this._categoryQueriesSrv.mutateCategoryTheme(variables).subscribe(result => {
            this._snackBar.open(
              this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
              this._translateSrv.translate("theme_createSuccess-txt"),
              STATUS_ALERTS.SUCCESS,
              5000
            );
          }, error => {
            console.log("ERROR CREATING CATEGORY", { error });
            this._snackBar.open(
              this._translateSrv.translate("general_snackbarTitleError-txt"),
              this._translateSrv.translate("theme_createFail-txt"),
              STATUS_ALERTS.DANGER,
              0
            );
          });
        }
      });
    }
  }

  private updateCategory = (datas: { expandData: IExpandContentCategory, oldData: any }) => {
    const { expandData: { category, formGroup }, oldData } = datas;
    if (formGroup.valid) {

      const categoryInput: any = {
        id: category.id,
        name: formGroup.value.nameFr,
        categoryThemeId: formGroup.value.categoryThemeId,
        translation: {
          fr: { name: formGroup.value.nameFr },
          en: { name: formGroup.value.nameEn },
          de: { name: formGroup.value.nameDe },
          nl: { name: formGroup.value.nameNl }
        }
      };

      this._categoryQueriesSrv.mutateCategoryTheme(categoryInput).subscribe(res => {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("theme_updateSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      }, err => {
        formGroup.get("nameFr").patchValue(oldData.nameFr);
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("theme_updateFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      });
    }

  }

  private beforeDeleteTheme = (theme: ICategoryTheme) => {
    this._modalSrv.openModal(ModalConfirmComponent, {
      title: this._translateSrv.translate("category_deleteAreYouSure-txt"),
      type: MODAL_TYPE.CONFIRM,
      data: {
        message: `${this._translateSrv.translate("theme_deleteConfirm-txt")} <b>${theme.name}</b>?`,
      },
      params: { themeId: theme.id },
      confirmCallback: this.deleteTheme
    });
  }


  private deleteTheme = (params: { themeId: number }) => {
    this._categoryQueriesSrv.deleteCategoryTheme(params.themeId).subscribe(result => {
      const resultData: any = result.data;
      if (resultData && resultData.deleteCategoryTheme) {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
          this._translateSrv.translate("theme_deleteSuccess-txt"),
          STATUS_ALERTS.SUCCESS,
          5000
        );
      } else {
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("theme_deleteFail-txt"),
          STATUS_ALERTS.DANGER,
          0
        );
      }
    }, err => {
      this._snackBar.open(
        this._translateSrv.translate("general_snackbarTitleError-txt"),
        this._translateSrv.translate("theme_deleteFail-txt"),
        STATUS_ALERTS.DANGER,
        0
      );
      console.log("ERROR DELETING CATEGORY", { err });
    });
  }

}
