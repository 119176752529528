import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ICitizenInput } from "src/app/facades/interfaces/citizen.interface";
import { LoginQueriesService } from "src/app/facades/queries/login/login-queries.service";
import { EnumLoginForms, ExternalService } from "src/app/facades/services/external/external.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";
import { checkSamePassword, emailMatcher } from "src/app/presentationnal/input/facades/custom-validator";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { LOGIN_ERRORS_MAP } from "../facades/login-errors-map";

@Component({
    selector: "app-login-subscription-form-external",
    templateUrl: "./login-subscription-form-external.component.html",
    styleUrls: ["./login-subscription-form-external.component.css"]
})
export class LoginSubscriptionFormExternalComponent implements OnInit {

    public formGroup: FormGroup;
    public _EnumLoginForms = EnumLoginForms;
    public inputErrorsLabel: Array<IBoxInfo> | Object = [];
    public displayConfirmLink: boolean = false;
    public linkToTos: string;
    public inputErrorsLabelMap: Map<string, string> = new Map<string, string>([
        ["firstName", this._translateSrv.translate("login_firstName-input")],
        ["lastName", this._translateSrv.translate("login_lastName-input")],
        ["type", this._translateSrv.translate("login_type-input")],
        ["email", this._translateSrv.translate("login_email-input")],
        ["phone", this._translateSrv.translate("login_phone-input")],
        ["password", this._translateSrv.translate("login_password-input")],
        ["confirmPassword", this._translateSrv.translate("login_confirmPassword-input")],
        ["isCguAccepted", this._translateSrv.translate("login_isTosAccepted-input")]
    ]);
    public typeOptions = [
        { value: "CITIZEN", label: "options_citizen-label" },
        { value: "SCHOOL", label: "options_school-label" },
        { value: "ASSOCIATION", label: "options_association-label" },
        { value: "BUSINESS", label: "options_business-label" },
        { value: "PUBLIC_ADMINISTRATION", label: "options_publicAdministration-label" },
    ];
    public disableCreateButton: boolean = false;

    constructor(
        private _fb: FormBuilder,
        public externalSrv: ExternalService,
        private _translateSrv: TranslateService,
        private _errorMessageSrv: ErrorMessageService,
        private _loginSrv: LoginQueriesService,
        // private _routingService: RoutingService
    ) { }

    ngOnInit(): void {
        this.initFormGroup();
        const baseLink: string = "https://www.fixmystreetwallonie.be/conditions-d-utilisation";
        const htmlLink: string = `<a href="${baseLink}" target="__blank" rel=opener>${this._translateSrv.translate("login_tos-link")}</a>`;
        this.linkToTos = this._translateSrv.translate("login_acceptTos-label", { tosLink: htmlLink });
    }

    /**
     * @description Init the form group
     * @author Kevin Palade
     * @private
     * @memberof LoginConfirmCodeComponent
     */
    private initFormGroup() {
        this.formGroup = this._fb.group({
            lastName: [this.externalSrv.wepUserLastName? this.externalSrv.wepUserLastName : null, Validators.required],
            firstName: [this.externalSrv.wepUserFirstName? this.externalSrv.wepUserFirstName : null, Validators.required],
            phone: [this.externalSrv.wepUserPhone? this.externalSrv.wepUserPhone : null, [Validators.required]],
            email: [this.externalSrv.wepUserEmail? this.externalSrv.wepUserEmail : null, [Validators.required, emailMatcher]],
            password: [null, [Validators.required, Validators.minLength(6)]],
            confirmPassword: [null, Validators.required],
            type: [null, Validators.required],
            isCguAccepted: false,
        }, { validator: checkSamePassword });
    }

    public createAccount() {
        if (!this.disableCreateButton) {
            this.disableCreateButton = true;
            if (this.formGroup.valid && this.formGroup.get("isCguAccepted").value) {
                this.inputErrorsLabel = [];
                this._loginSrv.createWepCitizen(this.getCitizenValue()).subscribe(result => {
                    if (result.errors && result.errors[0]) {
                        const code = (<any>result.errors[0]).code;
                        this.getErrors(code);
                    } else {
                        this.externalSrv.fromSubscription = true;
                        this.externalSrv.wepUserEmail = this.formGroup.get("email").value;
                        this.externalSrv.currentForm = EnumLoginForms.CONFIRM_CODE;
                    }
                    this.disableCreateButton = false;
                }, error => {
                    if (error.graphQLErrors && error.graphQLErrors[0]) {
                        const code = error.graphQLErrors[0].code;
                        this.getErrors(code);
                    }
                    this.disableCreateButton = false;
                });
            } else {
                this.markFormGroupTouched(this.formGroup);
                this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.formGroup, this.inputErrorsLabelMap, true);
                if (this.formGroup.errors && this.formGroup.errors.notSamePassword) {
                    (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                        message: `${this._translateSrv.translate("login_password-input")}: ${this._translateSrv.translate("login_notSamePassword-error")}`,
                        type: BOX_INFO_TYPE.DANGER
                    });
                }
                if (!this.formGroup.get("isCguAccepted").value) {
                    (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                        message: `${this._translateSrv.translate("login_isTosAccepted-input")}: ${this._translateSrv.translate("login_needCgu-error")}`,
                        type: BOX_INFO_TYPE.DANGER
                    });
                }
                this.disableCreateButton = false;
            }
        }
    }

    private getCitizenValue(): ICitizenInput {
        return {
            firstName: this.formGroup.get("firstName").value,
            lastName: this.formGroup.get("lastName").value,
            phone: this.formGroup.get("phone").value,
            email: this.formGroup.get("email").value,
            password: this.formGroup.get("password").value,
            type: this.formGroup.get("type").value
        };
    }

    public goToConfirmCode(event: Event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        // this.externalSrv.fromSubscription = true;
        this.externalSrv.wepUserEmail = this.formGroup.get("email").value;
        this.externalSrv.goTo(EnumLoginForms.CONFIRM_CODE);
    }

    public getErrors(errorCode) {
        if (errorCode) {
            const errorKey = LOGIN_ERRORS_MAP.get(errorCode);
            if (errorKey) {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate(errorKey)}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            } else {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate("errorOccured-error")}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            }
        } else {
            (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                message: `${this._translateSrv.translate("errorOccured-error")}`,
                type: BOX_INFO_TYPE.DANGER
            });
        }
    }

    public markFormGroupTouched = (formGroup: FormGroup) => {
        (Object as any).values(formGroup.controls).forEach(control => {
            (control as FormControl).updateValueAndValidity();
            control.markAsTouched();
            if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
        });
    };
}
