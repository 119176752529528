import { Component, OnInit, OnDestroy } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { ICategory } from "src/app/facades/interfaces/category.interface";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { TranslateService } from "src/app/facades/services/translate.service";
import { CategoryQueriesService } from "src/app/facades/queries/categorie/category-queries.service";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { ModalCreateCategoryComponent } from "src/app/modal/component/modal-detail-category/modal-create-category.component";
import { IExpandContentCategory, IExpandContentCategoryActions, IExpandContentCategoryDetail } from "src/app/presentationnal/expand/facades/interfaces/expand-content-categories.interface";
import { CategoryService } from "./facades/categories.service";
import { AuthService } from "src/lib/auth/auth.service";
import { EnumUserRoles } from "src/app/facades/interfaces/user.interface";
import { ModalConfirmComponent } from "src/app/modal/component/modal-confirm/modal-confirm.component";
import { ICategoryDetailInput } from "src/app/facades/interfaces/category-detail.interface";
import { CategoryThemeService } from "src/app/facades/services/theme/categorytheme.service";

@Component({
    selector: "app-categories-page",
    templateUrl: "./categories-page.component.html",
    styleUrls: ["./categories-page.component.css"]
})
export class CategoriesPageComponent implements OnInit, OnDestroy {

    private _mappedCategoriesSub: Subscription;

    public optionsHeader: IHeaderOptions;
    public expandContents: IExpandContentCategory[] = [];
    public groupOptions = [];
    public openedCateg = [];
    public isSuperAdmin: boolean = true;
    public categorieIndex: number = 0;

    constructor(private _categoryQueriesSrv: CategoryQueriesService,
                private _translateSrv: TranslateService,
                private _modalSrv: ModalService,
                private _snackBar: SnackService,
                private _categorySrv: CategoryService,
                private _authSrv: AuthService,
                private _categoryQuerySrv: CategoryQueriesService,
                private _categoryThemeSrv: CategoryThemeService,
                private _fb: FormBuilder) {

        this.optionsHeader = {
            title: "category_pageTitle-txt",
        };
    }

    ngOnInit() {
        this._categorySrv.reset();
        this.getCategoryThemes();
        if (!this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN )) {
            this.isSuperAdmin = false;
        }
        this.loadCategories();
    }

    ngOnDestroy(): void {
        if (this._mappedCategoriesSub) { this._mappedCategoriesSub.unsubscribe(); }
    }

    /**
     * @description Load the categories list with children
     * @author Kevin Palade
     * @private
     * @memberof CategoriesPageComponent
     */
    private async loadCategories() {
        if (this._mappedCategoriesSub) { this._mappedCategoriesSub.unsubscribe(); }
        this._mappedCategoriesSub = this._categoryQueriesSrv.watchMappedCategories(true).valueChanges.subscribe((result: any) => {
            if (result && result.data) {
                const { mappedCategories } = result.data;
                this.categorieIndex = 0;
                this.expandContents = this.generateExpandContent(mappedCategories);
            }
        }, error => {
            console.log("ERROR GETTING MAPPED CATEGORIES", { error });
        });
    }

    /**
     * @description Generate the expand items
     * @author Kevin Palade
     * @private
     * @param {ICategory[]} categories
     * @param {number} [level=1]
     * @param {ICategory} [parentCategory]
     * @returns {any[]}
     * @memberof CategoriesPageComponent
     */
    private generateExpandContent(categories: ICategory[], level: number = 1, parentCategory?: ICategory): any[] {
        const expandData: IExpandContentCategory[] = categories.map((cat, index) => {
            if (level > 6) { console.log("OUT OF LEVEL"); }

            const currentItem = this._categorySrv.checkShowChildren(cat);
            const hasTheme = !(cat.children && cat.children.length) && !(cat.categoryDetails && cat.categoryDetails.length) 
            const formGroup = this._fb.group({
                nameFr: [cat.translation && cat.translation.fr ? cat.translation.fr.name : null, Validators.compose([Validators.required, Validators.maxLength(100)])],
                nameNl: [cat.translation && cat.translation.nl ? cat.translation.nl.name : null, Validators.compose([Validators.required, Validators.maxLength(100)])],
                nameEn: [cat.translation && cat.translation.en ? cat.translation.en.name : null, Validators.compose([Validators.required, Validators.maxLength(100)])],
                nameDe: [cat.translation && cat.translation.de ? cat.translation.de.name : null, Validators.compose([Validators.required, Validators.maxLength(100)])],
                categoryThemeId: [hasTheme ? cat.categoryThemeId : undefined],
                ["active_" + this.categorieIndex]: [cat.isActive]
            });

            const itemToReturn: IExpandContentCategory = {
                category: cat,
                parentCategory,
                level,
                actions: this.generateActions(cat, level),
                showChildren: currentItem.showChildren,
                formGroup,
                children: null,
                index: this.categorieIndex,
                hasTheme: hasTheme,
                toggleDisabled: parentCategory && !parentCategory.isActive,
                toggleLabel: this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) ?
                    this._translateSrv.translate("category_isActive-input") : 
                    (cat.isActive? this._translateSrv.translate("category_takenInCharge-input") : this._translateSrv.translate("category_notTakenInCharge-input"))
            };
            formGroup.get("active_" + this.categorieIndex).valueChanges.subscribe(res => {
                if (itemToReturn.category.isActive !== res && (itemToReturn.parentCategory == null || itemToReturn.parentCategory.isActive)) {
                    this.beforeEnableCategory(itemToReturn);
                }
            });

            this.categorieIndex = this.categorieIndex + 1;
            itemToReturn["children"] = this.generateExpandContent(cat.children, level + 1, cat);
            itemToReturn.categoryDetails = cat.categoryDetails && cat.categoryDetails.length > 0 ? this.generateExpandDetailContent(cat, level + 1) : [];
            return itemToReturn;
        });
        return expandData;
    }

    /**
     * @description Generate actions for ExpandContents
     * @author Quentin Wolfs
     * @private
     * @param {ICategory} category
     * @param {number} level
     * @returns {IExpandContentCategoryActions}
     * @memberof CategoriesPageComponent
     */
    private generateActions(category: ICategory, level: number): IExpandContentCategoryActions {
        const actions: IExpandContentCategoryActions = {
            edit: {
                label: this._translateSrv.translate("general_edit-button"),
                icon: "fas fa-edit",
                action: "EDIT",
                click: this.updateCategory
            },
            delete: {
                label: this._translateSrv.translate("general_delete-button"),
                icon: "fas fa-trash-alt",
                action: "DELETE",
                click: this.beforeDeleteCategory
            }
        };
        // Can only add a categoryDetail IF there are no sub-categories
        if (category.children.length === 0) {
            actions.addDetail = {
                label: this._translateSrv.translate("category_addCategoryDetail-button"),
                icon: "fas fa-plus",
                action: "EDIT",
                click: this.addCategoryDetail
            };
        }
        // Can only add a sub-category IF there are no CategoryDetail
        if (category.categoryDetails.length === 0) {
            actions.add = {
                label: this._translateSrv.translate("category_addCategory-button", {level: level + 1}),
                icon: "fas fa-plus",
                action: "EDIT",
                click: this.addCategory
            };
        }

        return actions;
    }

    /**
     * @description Generate content required to init all ExpandCategoryDetail components for a single Category
     * @author Quentin Wolfs
     * @private
     * @param {ICategory} category
     * @param {number} level
     * @returns {IExpandContentCategoryDetail[]}
     * @memberof CategoriesPageComponent
     */
    private generateExpandDetailContent(category: ICategory, level: number): IExpandContentCategoryDetail[] {
        return category.categoryDetails.map(categoryDetail => {
            const formGroup = this._fb.group({
                nameFr: [
                    categoryDetail.translation && categoryDetail.translation.fr ? categoryDetail.translation.fr.name : null,
                    Validators.compose([Validators.required, Validators.maxLength(100)])
                ],
                nameNl: [
                    categoryDetail.translation && categoryDetail.translation.nl ? categoryDetail.translation.nl.name : null,
                    Validators.compose([Validators.required, Validators.maxLength(100)])
                ],
                nameEn: [
                    categoryDetail.translation && categoryDetail.translation.en ? categoryDetail.translation.en.name : null,
                    Validators.compose([Validators.required, Validators.maxLength(100)])
                ],
                nameDe: [
                    categoryDetail.translation && categoryDetail.translation.de ? categoryDetail.translation.de.name : null,
                    Validators.compose([Validators.required, Validators.maxLength(100)])
                ],
                categoryThemeId: [categoryDetail.categoryThemeId],
                [`active_detail_${this.categorieIndex}_${categoryDetail.id}`]: [categoryDetail.isActive]
            });

            const expandDetail: IExpandContentCategoryDetail = {
                categoryDetail,
                parentCategory: category,
                level,
                actions: {
                    edit: {
                        label: this._translateSrv.translate("general_edit-button"),
                        icon: "fas fa-edit",
                        action: "EDIT",
                        click: this.updateCategoryDetail
                    },
                    delete: {
                        label: this._translateSrv.translate("general_delete-button"),
                        icon: "fas fa-trash-alt",
                        action: "DELETE",
                        click: this.beforeDeleteCategoryDetail
                    }
                },
                showDetail: false,
                formGroup,
                index: this.categorieIndex,
                toggleDisabled: category && !category.isActive,
                toggleLabel: this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) ?
                    this._translateSrv.translate("category_isActive-input") : this._translateSrv.translate("category_detailTakenInCharge-input"),
            };
            formGroup.get(`active_detail_${this.categorieIndex}_${categoryDetail.id}`).valueChanges.subscribe(res => {
                if (expandDetail.categoryDetail.isActive !== res && (expandDetail.parentCategory == null || expandDetail.parentCategory.isActive)) {
                    this.beforeEnableCategoryDetail(expandDetail);
                }
            });

            return expandDetail;
        });
    }

    /**
   * @description Add a new category to it's parent if existing
   * @author Kevin Palade
   * @param {ICategory} parentCategory
   * @memberof ExpandCategoryComponent
   */
    public addCategory = (parentCategory: ICategory) => {
        const createCateg = this._modalSrv.openModal(ModalCreateCategoryComponent,
            {
                title: this._translateSrv.translate("category_create-title"),
                type: MODAL_TYPE.CREATE,
                data: null,
                modalStyle: {
                    modalWidth: "x-large",
                }
            }
        );

        if (createCateg) {
            createCateg.afterClosed.subscribe(res => {
                if (res.confirm && res.data) {
                    const variables = {
                        name: res.data.nameFr,
                        categoryThemeId: res.data.categoryThemeId,
                        translation: {
                            fr: { name: res.data.nameFr },
                            en: { name: res.data.nameEn },
                            de: { name: res.data.nameDe },
                            nl: { name: res.data.nameNl }
                        }
                    };
                    if (parentCategory) {
                        variables["parentId"] = +parentCategory.id;
                    }
                    this._categoryQueriesSrv.mutateCategory(variables).subscribe(result => {
                        this._snackBar.open(
                            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                            this._translateSrv.translate("category_createSuccess-txt"),
                            STATUS_ALERTS.SUCCESS,
                            5000
                        );
                    }, error => {
                        console.log("ERROR CREATING CATEGORY", { error });
                        this._snackBar.open(
                            this._translateSrv.translate("general_snackbarTitleError-txt"),
                            this._translateSrv.translate("category_createFail-txt"),
                            STATUS_ALERTS.DANGER,
                            0
                        );
                    });
                }
            });
        }
    }

    /**
     * @description Open a modal to add a new category detail to its parent
     * @private
     * @author Quentin Wolfs
     * @param {ICategory} parentCategory
     * @memberof CategoriesPageComponent
     */
    private addCategoryDetail = (parentCategory: ICategory) => {
        const createCateg = this._modalSrv.openModal(ModalCreateCategoryComponent,
            {
                title: this._translateSrv.translate("category_createDetail-title"),
                type: MODAL_TYPE.CREATE,
                data: null,
                modalStyle: {
                    modalWidth: "x-large",
                }
            }
        );

        if (createCateg) {
            createCateg.afterClosed.subscribe(res => {
                if (res.confirm && res.data) {
                    const categoryDetailInput: ICategoryDetailInput = {
                        name: res.data.nameFr,
                        categoryThemeId: res.data.categoryThemeId,
                        translation: {
                            fr: { name: res.data.nameFr },
                            en: { name: res.data.nameEn },
                            de: { name: res.data.nameDe },
                            nl: { name: res.data.nameNl }
                        },
                        categoryId: +parentCategory.id
                    };

                    this._categoryQueriesSrv.mutateCategoryDetail(categoryDetailInput).subscribe(result => {
                        this._snackBar.open(
                            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                            this._translateSrv.translate("category_createDetailSuccess-txt"),
                            STATUS_ALERTS.SUCCESS,
                            5000
                        );
                    }, error => {
                        console.log("ERROR CREATING CATEGORY DETAIL", { error });
                        this._snackBar.open(
                            this._translateSrv.translate("general_snackbarTitleError-txt"),
                            this._translateSrv.translate("category_createDetailFail-txt"),
                            STATUS_ALERTS.DANGER,
                            0
                        );
                    });
                }
            });
        }
    }

    /**
     * @description Opens a modal to confirm Category deletion
     * @private
     * @author Quentin Wolfs
     * @param {ICategory} category
     * @memberof CategoriesPageComponent
     */
    private beforeDeleteCategory = (category: ICategory) => {
        this._modalSrv.openModal(ModalConfirmComponent, {
              title: this._translateSrv.translate("category_deleteAreYouSure-txt"),
              type: MODAL_TYPE.CONFIRM,
              data: {
                message: `${this._translateSrv.translate("category_deleteConfirm-txt")} <b>${category.name}</b>?`,
              },
              params: { categoryId: category.id },
              confirmCallback: this.deleteCategory
        });
    }

    /**
     * @description Sends request to delete the selected Category
     * @private
     * @author Quentin Wolfs
     * @param {{ categoryId: number }} params
     * @memberof CategoriesPageComponent
     */
    private deleteCategory = (params: { categoryId: number }) => {
        this._categoryQueriesSrv.deleteCategory(params.categoryId).subscribe(result => {
            const resultData: any = result.data;
            if (resultData && resultData.deleteCategory) {
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                    this._translateSrv.translate("category_deleteSuccess-txt"),
                    STATUS_ALERTS.SUCCESS,
                    5000
                );
            } else {
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleError-txt"),
                    this._translateSrv.translate("category_deleteFail-txt"),
                    STATUS_ALERTS.DANGER,
                    0
                );
            }
        }, err => {
            this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleError-txt"),
                this._translateSrv.translate("category_deleteFail-txt"),
                STATUS_ALERTS.DANGER,
                0
            );
            console.log("ERROR DELETING CATEGORY", { err });
        });
    }

    public beforeEnableCategory = (datas: any) => {
        // if(!datas.parentCategory || datas.parentCategory.isActive)
        if (datas.category && datas.category.isActive) {
            this._modalSrv.openModal(ModalConfirmComponent, {
                title: this._translateSrv.translate("category_enable-title"),
                type: MODAL_TYPE.CONFIRM,
                data: {
                    message: `${this._translateSrv.translate("category_confirmDisable-txt")} <b>${datas.category.name}</b>`,
                  // actions: [
                  //   "A DEFINIR",
                  // ],
                },
                params: { datas },
                confirmCallback: this.enableCategory,
                cancelCallback: this.cancelEnableCategory,
                customData: {
                    deleteButtonName: this._translateSrv.translate("category_disabled-button"),
                }
            });
        } else {
            this.enableCategory({datas: datas});
        }
    }

    /**
     * @description Opens a modal to confirm the disabling of a CategoryDetail
     * @private
     * @author Quentin Wolfs
     * @param {IExpandContentCategoryDetail} expandContent
     * @memberof CategoriesPageComponent
     */
    private beforeEnableCategoryDetail = (expandContent: IExpandContentCategoryDetail) => {
        if (expandContent.categoryDetail && expandContent.categoryDetail.isActive) {
            this._modalSrv.openModal(ModalConfirmComponent, {
                title: this._translateSrv.translate("category_enableDetail-title"),
                type: MODAL_TYPE.CONFIRM,
                data: {
                    message: `${this._translateSrv.translate("category_confirmDisableDetail-txt")} <b>${expandContent.categoryDetail.name}</b>`
                },
                params: expandContent,
                confirmCallback: this.enableCategoryDetail,
                cancelCallback: this.cancelDisableCategoryDetail,
                customData: {
                    deleteButtonName: this._translateSrv.translate("category_disabled-button"),
                }
            });
        } else {
            this.enableCategoryDetail(expandContent);
        }
    }

    /**
     * @description Resets the value of "IsActive" for a Category
     * @private
     * @author Quentin Wolfs
     * @param {{ datas: IExpandContentCategory }} datas
     * @memberof CategoriesPageComponent
     */
    private cancelEnableCategory = (datas: { datas: IExpandContentCategory }) => {
        const control = datas.datas.formGroup.get(`active_${datas.datas.index}`);
        if (!!control) { control.patchValue(true); }
    }

    /**
     * @description  Resets the value of "IsActive" for a CategoryDetail
     * @private
     * @author Quentin Wolfs
     * @param {IExpandContentCategoryDetail} expandContent
     * @memberof CategoriesPageComponent
     */
    private cancelDisableCategoryDetail = (expandContent: IExpandContentCategoryDetail) => {
        const control = expandContent.formGroup.get(`active_detail_${expandContent.index}_${expandContent.categoryDetail.id}`);
        if (!!control) { control.patchValue(true); }
    }

    public enableCategory = (datasReceived: any) => {
        const datas = datasReceived.datas;
        if ( !datas.parentCategory || datas.parentCategory.isActive) {
            this._categoryQueriesSrv.enableCategory(datas.category.id, !datas.category.isActive).subscribe(res => {
                datas.category.isActive = !datas.category.isActive;
                // this.updateChildValue(datas, datas.category.isActive);
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                    this._translateSrv.translate("category_enableSuccess-txt"),
                    STATUS_ALERTS.SUCCESS,
                    5000
                );
            }, error => {
                console.log("ERROR ENABLE CATEGORY", { error });
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleError-txt"),
                    this._translateSrv.translate("category_enableFail-txt"),
                    STATUS_ALERTS.DANGER,
                    0
                );
            });
        }
    }

    /**
     * @description Enable/Disable a single CategoryDetail
     * @private
     * @author Quentin Wolfs
     * @param {IExpandContentCategoryDetail} expandContent
     * @memberof CategoriesPageComponent
     */
    private enableCategoryDetail = (expandContent: IExpandContentCategoryDetail) => {
        if (!expandContent.parentCategory || expandContent.parentCategory.isActive) {
            this._categoryQueriesSrv.enableCategoryDetail(expandContent.categoryDetail.id, !expandContent.categoryDetail.isActive).subscribe(res => {
                expandContent.categoryDetail.isActive = !expandContent.categoryDetail.isActive;
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                    this._translateSrv.translate("category_enableDetailSuccess-txt"),
                    STATUS_ALERTS.SUCCESS,
                    5000
                );
            }, error => {
                console.log("ERROR ENABLE CATEGORY DETAIL", { error });
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleError-txt"),
                    this._translateSrv.translate("category_enableDetailFail-txt"),
                    STATUS_ALERTS.DANGER,
                    0
                );
            });
        }
    }

    /**
     * @description Update the category
     * @private
     * @memberof CategoriesPageComponent
     */
    private updateCategory = (datas: {expandData: IExpandContentCategory, oldData: any}) => {
        const { expandData: { category, formGroup }, oldData } = datas;
        if (formGroup.valid) {

            const categoryInput: any = {
                id: category.id,
                name: formGroup.value.nameFr,
                categoryThemeId: formGroup.value.categoryThemeId,
                translation: {
                    fr: { name: formGroup.value.nameFr },
                    en: { name: formGroup.value.nameEn },
                    de: { name: formGroup.value.nameDe },
                    nl: { name: formGroup.value.nameNl }
                }
            };

            this._categoryQueriesSrv.mutateCategory(categoryInput).subscribe(res => {
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                    this._translateSrv.translate("category_updateSuccess-txt"),
                    STATUS_ALERTS.SUCCESS,
                    5000
                );
            }, err => {
                formGroup.get("nameFr").patchValue(oldData.nameFr);
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleError-txt"),
                    this._translateSrv.translate("category_updateFail-txt"),
                    STATUS_ALERTS.DANGER,
                    0
                );
            });
        }

    }

    /**
     * @description Update a single CategoryDetail
     * @private
     * @author Quentin Wolfs
     * @param {IExpandContentCategoryDetail} expandContent
     * @memberof CategoriesPageComponent
     */
    private updateCategoryDetail = (datas: { expandData: IExpandContentCategoryDetail, oldData: any }) => {
        const { expandData: { categoryDetail, formGroup }, oldData } = datas;
        if (formGroup.valid) {
            const categoryDetailInput: ICategoryDetailInput = {
                id: categoryDetail.id,
                name: formGroup.value.nameFr,
                categoryThemeId: formGroup.value.categoryThemeId,
                translation: {
                    fr: { name: formGroup.value.nameFr },
                    en: { name: formGroup.value.nameEn },
                    de: { name: formGroup.value.nameDe },
                    nl: { name: formGroup.value.nameNl }
                }
            };
            this._categoryQueriesSrv.mutateCategoryDetail(categoryDetailInput).subscribe(res => {
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                    this._translateSrv.translate("category_updateDetailSuccess-txt"),
                    STATUS_ALERTS.SUCCESS,
                    5000
                );
            }, err => {
                formGroup.get("nameFr").patchValue(oldData.nameFr);
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleError-txt"),
                    this._translateSrv.translate("category_updateDetailFail-txt"),
                    STATUS_ALERTS.DANGER,
                    0
                );
            });
        }
    }

    /**
     * @description Opens a modal to confirm CategoryDetail deletion
     * @private
     * @author Quentin Wolfs
     * @param {{ categoryId: number }} params
     * @memberof CategoriesPageComponent
     */
    private beforeDeleteCategoryDetail = (datas: { categoryId: number }) => {
        this._modalSrv.openModal(ModalConfirmComponent, {
              title: this._translateSrv.translate("category_deleteDetailAreYouSure-txt"),
              type: MODAL_TYPE.CONFIRM,
              data: {
                message: `${this._translateSrv.translate("group_deleteConfirm-txt")} <b>Meh</b>?`,
              },
              params: { categoryId: datas.categoryId },
              confirmCallback: this.deleteCategoryDetail
        });
    }

    /**
     * @description Sends request to delete the selected CategoryDetail
     * @private
     * @author Quentin Wolfs
     * @param {{ categoryId: number }} params
     * @memberof CategoriesPageComponent
     */
    private deleteCategoryDetail = (params: { categoryId: number }) => {
        this._categoryQueriesSrv.deleteCategoryDetail(params.categoryId).subscribe(result => {
            const resultData: any = result.data;
            if (resultData && resultData.deleteCategoryDetail) {
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                    this._translateSrv.translate("category_deleteDetailSuccess-txt"),
                    STATUS_ALERTS.SUCCESS,
                    5000
                );
            } else {
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleError-txt"),
                    this._translateSrv.translate("category_deleteDetailFail-txt"),
                    STATUS_ALERTS.DANGER,
                    0
                );
            }
        }, err => {
            this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleError-txt"),
                this._translateSrv.translate("category_deleteDetailFail-txt"),
                STATUS_ALERTS.DANGER,
                0
            );
            console.log("ERROR DELETING CATEGORY", { err });
        });
    }

    /**
     * @description Update child isActive
     * @author Kevin Palade
     * @private
     * @param {IExpandContentCategory} expandContent
     * @param {boolean} newValue
     * @memberof CategoriesPageComponent
     */
    private updateChildValue(expandContent: any, newValue: boolean) {
        if (expandContent.children.length > 0) {
            expandContent.children.forEach( child => {
                child.category.isActive = newValue;
                child.parentCategory.isActive = newValue;
                // expandContent.formGroup.get("active_" + expandContent.index).setValue(newValue);
                this.updateChildValue(child, newValue);
            });
        }
    }

    private getCategoryThemes() {
        this._categoryQuerySrv.getCategoryThemes(this._translateSrv.activeLanguage).subscribe(
          (result: any) => {
            this._categoryThemeSrv.themes = result.data && result.data.categoryThemes
          },
          error => {
            console.log("ERROR LOADING THEMES", {error});
          }
        )
      }
}
