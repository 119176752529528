import gql from "graphql-tag";

export const CREATE_INSCRIPTION = gql`
mutation createInscription($inscription: CreateInscription!){
  inscription(inscription: $inscription){
    id
  }
}
`;

export const GET_INSCRIPTIONS = gql`
  query getInscriptions($filter: FilterInscription, $pagination: Pagination) {
    inscriptions(filter: $filter, pagination: $pagination){
      id
      incidentId
      userId
    }
  }
`;

export const DELETE_INSCRIPTION = gql`
  mutation deleteInscriptions($email: String, $incidentId: Int!){
    deleteInscription(email: $email, incidentId: $incidentId)
  }
`;