import { IDropListLabels } from "src/app/presentationnal/drop-list/facades/interfaces/drop-list.interface";

export const MANAGER_DROP_LIST_LABELS: IDropListLabels = {
  linked: "group_managersList-txt",
  available: "group_managersAvailableList-txt",
  actionButton: "general_create-button"
};

export const AGENT_DROP_LIST_LABELS: IDropListLabels = {
  linked: "group_agentsList-txt",
  available: "group_agentsAvailableList-txt",
  actionButton: "general_create-button"
};

export const ASCERTAINING_AGENT_DROP_LIST_LABELS: IDropListLabels = {
  linked: "group_ascertainingAgentsList-txt",
  available: "group_ascertainingAgentsAvailableList-txt",
  actionButton: "general_create-button"
};