import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "src/app/facades/model/modal-content-form";
import { DATA, ModalService } from "src/app/facades/services/modal/modal.service";
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ORGANISATION_TYPES } from "src/app/facades/enum/organisation-types.enum";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { IOrganisation, IOrganisationCode, IOrganisationInpdate } from "src/app/facades/interfaces/organisation.interface";
import { IUserInput, EnumUserRoles } from "src/app/facades/interfaces/user.interface";
import { IBlockList, IBlockListHeader } from "src/app/presentationnal/block/facades/interfaces/block-list.interface";
import { UserGroupQueriesService } from "src/app/facades/queries/user-group/user-group-queries.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { STAGE_TYPES } from 'src/app/facades/enum/stage-types.enum';
import { AuthService, ROLES_KEY } from 'src/lib/auth/auth.service';
import { IExternal } from "src/app/facades/interfaces/external.interface";
import { ExternalQueriesService } from "src/app/facades/queries/external/external-queries.service";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { FILES_BLOCK_ACTIONS_ENUM } from "src/app/presentationnal/input/facades/enums/files-block-actions.enum";
import { getFileFromUrl } from "src/app/facades/utils/file";
import { CUSTOM_VALIDATOR } from "src/app/presentationnal/input/facades/custom-validator";

interface IOrganisationModalReceivedData {
  data: {
    organisation: IOrganisation;
    isAddingForm: boolean;
    addedUser: IUserInput;
  };
}

export const BEWAPP_ID: number = 1;

@Component({
  selector: "app-modal-create-organisation",
  templateUrl: "./modal-create-organisation.component.html",
  styleUrls: ["./modal-create-organisation.component.css"]
})
export class ModalCreateOrganisationComponent extends ModalContentForm implements OnInit {
  private _organisationData: IOrganisation;
  private _addedUser: IUserInput;
  private _needAddingUser: boolean = false;
  public isAddingForm: boolean;
  public organisationsType = ORGANISATION_TYPES;
  public __BOX_INFO_TYPE = BOX_INFO_TYPE;
  public organisationTypes: ISelectOption[] = [];
  public stageTypes: ISelectOption[] = [];
  public adminBlockList: IBlockList[] = [];
  public groupBlockList: IBlockList[] = [];
  public attachment?: File = undefined;
  public adminListHeader: IBlockListHeader[] = [
    {key: "lastName", translate: "organisation_adminLastname-th"},
    {key: "firstName", translate: "organisation_adminFirstname-th"},
    {key: "email", translate: "organisation_adminMail-th"}
  ];

  public externalFormGroup: FormGroup;
  public externals: IExternal[] = [];

  public groupListHeader: IBlockListHeader[] = [
    {key: "name", translate: "organisation_groupName-th"}
  ];
  public disableWarning: string;
  public __BEWAPP_ID = BEWAPP_ID;
  public isCurrentUserSuperAdmin: boolean = false;

  constructor(protected modalSrv: ModalService,
    private _userGroupQueriesSrv: UserGroupQueriesService,
    protected fb: FormBuilder,
    protected _snackBar: SnackService,
    protected _errorMessageSrv: ErrorMessageService,
    private _translateSrv: TranslateService,
    private _orgaSrv: OrganisationQueriesService,
    private _authSrv: AuthService,
    private _externalQueriesSrv: ExternalQueriesService,
    @Inject(DATA) public _data: IOrganisationModalReceivedData) {
      super(modalSrv, fb, _snackBar, _errorMessageSrv);
      this._organisationData = this._data.data.organisation;
      this._addedUser = this._data.data.addedUser;
      this.isAddingForm = this._data.data.isAddingForm;
      this.inputErrorsLabelMap = new Map<string, string>([
        [ "nameFr", this._translateSrv.translate("organisation_nameFr-input")],
        [ "nameNl", this._translateSrv.translate("organisation_nameNl-input")],
        [ "nameDe", this._translateSrv.translate("organisation_nameDe-input")],
        [ "nameEn", this._translateSrv.translate("organisation_nameEn-input")],
        [ "email", this._translateSrv.translate("organisation_mail-input")],
        [ "phone", this._translateSrv.translate("organisation_phone-input")],
        [ "code", this._translateSrv.translate("organisation_code-input")],
        [ "stage", this._translateSrv.translate("organisation_stage-input")]
      ]);
      this.disableWarning = this._translateSrv.translate("organisation_disable_warning-txt");
  }

  ngOnInit() {
    this.isCurrentUserSuperAdmin = this._authSrv.getRolesCurrent().indexOf(EnumUserRoles.SUPER_ADMIN) != -1;
    this.initForm();
    if (!this.isAddingForm) {
      this.loadOrganisationGroups();
    }
    this.loadExternals();
    this.initImage();
  }

  private initImage() {
    if(this._organisationData.imageUrl) {
      getFileFromUrl(this._organisationData.imageUrl).then((file) => {
      this.attachment = file
      })
    }
  }

  private initForm() {
    this.organisationTypes = Object.keys(ORGANISATION_TYPES).map( type => {
      return {
        value: type,
        label: ORGANISATION_TYPES[type]
      };
    });
    this.stageTypes = Object.keys(STAGE_TYPES).map( type => {
      return {
        value: type,
        label: this._translateSrv.translate("stage_options_"+type.toLocaleLowerCase()+"-txt")
      }
    })
    this._formGroup = this.fb.group(this.setOrganisationInpdateData());
    if (this._addedUser) {
      this.adminBlockList.push({
        id: null,
        name: `${this._addedUser.lastName} ${this._addedUser.firstName}`,
        completeData: this._addedUser
      });
    }
    if (this._organisationData && this._organisationData.admins) {
      this._organisationData.admins.forEach( admin => {
        this.adminBlockList.push({
          id: admin.id,
          name: `${admin.lastName} ${admin.firstName}`,
          completeData: admin
        });
      });
    }
  }



  private setOrganisationInpdateData(): IOrganisationInpdate | any {
    return {
      id: this._organisationData ? this._organisationData.id : null,
      nameFr: [this._organisationData && this._organisationData.translation && this._organisationData.translation.fr ?
        this._organisationData.translation.fr.name : null, Validators.required],
      nameNl: [this._organisationData && this._organisationData.translation && this._organisationData.translation.nl ?
        this._organisationData.translation.nl.name : null, Validators.required],
      nameEn: [this._organisationData && this._organisationData.translation && this._organisationData.translation.en ?
        this._organisationData.translation.en.name : null, Validators.required],
      nameDe: [this._organisationData && this._organisationData.translation && this._organisationData.translation.de ?
        this._organisationData.translation.de.name : null, Validators.required],
      type: this._organisationData ? this._organisationData.type : this.organisationTypes[0].value,
      email: [this._organisationData ? this._organisationData.email : null, Validators.required],
      phone: [this._organisationData ? this._organisationData.phone : null, [Validators.required, Validators.pattern(CUSTOM_VALIDATOR.belgianPhone)]],
      code: [this._organisationData ? this._organisationData.code : null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(/^[a-z]+$/i)
      ]],
      isActive: this._organisationData ? this._organisationData.isActive : true,
      stage: [this._organisationData ? this._organisationData.stage : null, Validators.required]
    };
  }

  public fileChanged(file) {
		if (file) {
			this.attachment = file;
			// this.isFileError = false;
		} else {
			this.attachment = undefined;
		}
	}

  
	public deleteFile() {
			this.attachment = undefined;
	}

  public fileTouched(event) {
		if (event.index != null) {
			switch (event.action) {
				case FILES_BLOCK_ACTIONS_ENUM.DELETE:
					// this.markAsTouched();
					this.deleteFile();
					break;
				case FILES_BLOCK_ACTIONS_ENUM.ZOOM:
					break;
				default:
			}
		}
	}

  private loadOrganisationGroups() {
    this._userGroupQueriesSrv.getListGroupByOrganisation({organisationId: this._organisationData.id}).subscribe( result => {
      const resultData: any = result.data;
      if (resultData && resultData.groups) {
        this.groupBlockList = resultData.groups.map( group => {
          return {
            id: group.id,
            name: `${group.name}`,
            completeData: group
          };
        });
      }
    }, error => {
      console.log("ERROR LOADING GROUPS", {error});
    });
  }

  public onAddLinkedData() {
    this._needAddingUser = true;
    this.confirmModal();
  }

  public confirmModal(): void {
    const id = this._formGroup.get('id');
    const idValue = id ? id.value : null;
    const code = this._formGroup.get('code');
    if (this._formGroup.valid || this._needAddingUser) {
      this._orgaSrv.checkExistingCode(code.value, idValue).subscribe((res: any) => {
        if(res.data.existingOrganisationCode.exist) {
          this.markFormGroupTouched(this._formGroup);
          if(!code.errors || !code.errors.existingCode) code.setErrors({existingCode: "Code exist"});
          this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
        } else {
          this.save();
          this.modalSrv.closeModal();
        }
      }, err => {
        this.markFormGroupTouched(this._formGroup);
        this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
      });
    } else {
        const hasErrorExistingCode = (code.errors && code.errors.existingCode);
        this.markFormGroupTouched(this._formGroup);
        if(hasErrorExistingCode) code.setErrors({existingCode: "Code exist"});
        this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
    }
  }

  private formatOrganisationData(): IOrganisationInpdate {
    let usersDatas: IBlockList[];
    if (this._needAddingUser) {
      usersDatas = [...this.adminBlockList];
    } else {
      usersDatas = this.adminBlockList.filter( user => !user.id );
    }
    const organisationData: IOrganisationInpdate = {
      ...this._formGroup.value,
      attachment: this.attachment,
      admins: usersDatas.map( user => {
        return user.completeData;
      }),
      adminIds: this.adminBlockList.filter( user => user.id ).map( user => {
        return user.id;
      }),
      name: this._formGroup.value.nameFr,
      translation: {
        fr: { name: this._formGroup.value.nameFr },
        en: { name: this._formGroup.value.nameEn },
        de: { name: this._formGroup.value.nameDe },
        nl: { name: this._formGroup.value.nameNl }
      }
    };
    // need attribut "admins" only on adding organisation BUT used to set the adminBlockList datas, so we keep it when we need to add an user
    if (!this.isAddingForm && !this._needAddingUser) {
      delete organisationData.admins;
    }
    delete organisationData.nameNl;
    delete organisationData.nameFr;
    delete organisationData.nameEn;
    delete organisationData.nameDe;
    organisationData['externalIds'] = [];
    Object.keys(this.externalFormGroup.value).forEach(key => {
      if(this.externalFormGroup.get(key).value){
        organisationData['externalIds'].push(parseInt(key.split('_')[1]));
      }
    })
    return organisationData;
  }

  public save() {
    const objData = {
      confirm: true,
      data: this.formatOrganisationData(),
      needAddingUser: this._needAddingUser
    };
    this.afterClosed.next(objData);
  }

  public getExternalFormKeys(): string[] {
    return Object.keys(this.externalFormGroup.controls);
  }

  /**
   * Load all the possible externals used for interoperability
   */
  private loadExternals(): void{
    this._externalQueriesSrv.getListExternals().subscribe(result => {
      const resultData: any = result.data;
      if(resultData && resultData.externals){
        this.externals = resultData.externals;
        //console.log(this.externals);
        this.initExternalForm();
      }
    }, error => {
      console.log("ERROR LOADING EXTERNALS", {error});
    }) 
  }

  /**
   * Init the form group for the external selection (used for interoperability)
   */
  private initExternalForm(){
    let objectForGroup = {};
    let externalIds = this._organisationData && this._organisationData.externals? this._organisationData.externals.map(item => item.id) : [];
    this.externals.forEach(external => {
      objectForGroup["external_"+external.id] = externalIds.indexOf(external.id) != -1? true : false;
    })
    this.externalFormGroup = this.fb.group(objectForGroup);
  }

}
