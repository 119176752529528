import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ITableActionData, EnumTableAction, ITableFilter, EnumTableDataType } from "../facades/interfaces/table.interface";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { EnumIncidentStatuses } from "src/app/facades/interfaces/incident.interface";

@Component({
    selector: "app-table",
    templateUrl: "./table.component.html",
    styleUrls: ["./table.component.css"]
})
export class TableComponent implements OnInit, OnChanges {
    public actionTable = EnumTableAction;
    private _debouncerValueChange: Subject<any> = new Subject<any>();
    public razValues$: Subject<boolean>;
    public incidentStatus = EnumIncidentStatuses;
    @Input() headers: any[] = [];
    @Input() contents: any = [];
    @Input() optionsFilter: ITableFilter[] = [];
    @Input() activateFilter: boolean = false;
    @Input() displayOnlyFilter: boolean = false;
    @Input() showSelectColumn: boolean = false;
    @Input() isGlobalSelected: boolean = false;
    @Input() checkedIds: number[] = [];
    @Input() showSelectColumnCondition: any;
    @Input() selectColumnIcon: string = null;
    @Output() tableAction: EventEmitter<ITableActionData> = new EventEmitter<ITableActionData>();
    @Output() OnModalHeader: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() filterSearch: EventEmitter<{key: string, search: string}> = new EventEmitter<{key: string, search: string}>();

    public filterGroup: FormGroup;

    constructor(
        private fb: FormBuilder
    ) {
        this._debouncerValueChange.pipe(debounceTime(500)).subscribe(next => this.updateTableList());
    }

    ngOnInit() {
        this.razValues$ = new Subject();
        this.initFormGroup();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["optionsFilter"] && !changes["optionsFilter"].firstChange) {
            this.initFormGroup();
        }
    }

    public initFormGroup(): void {
        // if (!this.filterGroup) {
            this.filterGroup = this.fb.group({});
        // }
        let value: string;
        this.optionsFilter.forEach(element => {
            value = element.type === EnumTableDataType.select && element.options && element.options[0] ?
                element.options[0].value : null;

            this.filterGroup.addControl(element.key, this.fb.control(value));
            this.filterGroup.get(element.key).valueChanges.subscribe(next => {
                this._debouncerValueChange.next();
            });
            if (element.precision && element.precisionFilterKey != null) {
                this.filterGroup.addControl(element.precisionFilterKey, this.fb.control(null));
                this.filterGroup.get(element.precisionFilterKey).valueChanges.subscribe(next => {
                    this._debouncerValueChange.next();
                });
            }
        });
    }

    public clickAction(event: MouseEvent, action: EnumTableAction, data: number | any) {
        event.stopPropagation();
        if (action == EnumTableAction.SORT) {
            if (data && data.sortable) {
                this.tableAction.emit({ action: action, content: data });
            }
        } else {
            this.tableAction.emit({ action: action, content: data });
        }
    }

    /**
     * @description Reset all filters inputs
     * @author Gaetan Geenens
     * @memberof TableComponent
     */
    public razFilters() {
        this.optionsFilter.forEach(el => {
            switch (el.type) {
                case EnumTableDataType.selectcheckbox:
                    this.razValues$.next(true);
                    break;


                default:
                    this.filterGroup.get(el.key).setValue(null);
                    break;
            }
            if (el.precision && el.precisionFilterKey != null) {
                this.filterGroup.get(el.precisionFilterKey).setValue(null);
            }
        });
    }

    public updateTableList() {
        this.tableAction.emit({
            action: EnumTableAction.FILTER,
            content: this.filterGroup.value
        });
    }

    public openModalHeader() {
        this.OnModalHeader.emit(true);
    }

    public precisionUpdate(precisions, precisionFilterKey) {
        this.filterGroup.get(precisionFilterKey).patchValue(precisions.map(pre => pre.id));
    }

    public callSearch(q: string, key: string){
        this.filterSearch.emit({key: key, search: q});
    }

}