import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CardStatsComponent } from "./organisms/cardstats/cardstats.component";
import { PipeModule } from "../../facades/pipes/pipe.module";

@NgModule({
  declarations: [CardStatsComponent],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [
    CardStatsComponent
  ],
})
export class StatsModule { }
