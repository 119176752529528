
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { MODAL_TYPE } from "src/app/facades/enum/modal/modal-type.enum";
import { ModalCreateIncidentComponent } from "src/app/modal/component/modal-create-incident/modal-create-incident.component";
import { ModalDetailIncidentComponent } from "src/app/modal/component/modal-detail-incident/modal-detail-incident.component";
import { IIncident, IIncidentInput, EnumIncidentStatuses, IIncidentColumnFilter, IIncidentMap, EnumIncidentPriorities, REPORTER_TYPES } from "src/app/facades/interfaces/incident.interface";
import { IncidentQueriesService } from "src/app/facades/queries/incident/incident-queries.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import * as moment from "moment";
import { ITableActionData, EnumTableAction, ITableFilter, EnumTableDataType } from "src/app/presentationnal/table/facades/interfaces/table.interface";
import { TranslateService } from "src/app/facades/services/translate.service";
import { Observable, Subscription } from "rxjs";
import { incidentListFilters, headerTableDefaultIncident, headerInputDefaultIncident, headerTableOptionsDefault } from "src/app/facades/configs/incident-list-filters";
import { IFilterBarFilter, IFilterBarPagination } from "src/app/presentationnal/bar/facades/interfaces/filter-bar.interface";
import { EnumIncidentListFilter } from "src/app/facades/enum/incident-list-filters.enum";
import { ICategory } from "src/app/facades/interfaces/category.interface";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { AuthService } from "src/lib/auth/auth.service";
import { EnumUserRoles } from "src/app/facades/interfaces/user.interface";
import { EnumUtil } from "src/lib/enum";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { UserQueriesService } from "src/app/facades/queries/user/user-queries.service";
import { CategoryQueriesService } from "src/app/facades/queries/categorie/category-queries.service";
import { ISelectMultipleOptions } from "src/app/presentationnal/input/facades/interfaces/selectMultiple.interface";
import { EsriMapComponent } from "src/app/presentationnal/maps/organisms/esri-map/esri-map.component";
import { ModalChoiceHeaderComponent } from "src/app/modal/component/modal-choice-header/modal-choice-header.component";
import { ActivatedRoute } from "@angular/router";
import { ModalConfirmComponent } from "src/app/modal/component/modal-confirm/modal-confirm.component";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { EnumOrganisationStage } from "src/app/facades/interfaces/organisation.interface";
import { IComment, WAS_TYPE } from 'src/app/facades/interfaces/comment.interface';
import { REPORTER_SOURCES_PRECISIONS } from '../../translations/translation-page/translation-page.component';
import { GeoservicesWallonieService } from "../../../../facades/services/geoservices-wallonie/geoservices-wallonie.service";
import { map, take, tap } from "rxjs/operators";
import { XlsService } from "../../../../facades/services/xls/xls.service";


const DISPLAYED_INCIDENT_HEADER_KEY: string = "displayed-incident-headers";

@Component({
    selector: "app-incident-list",
    templateUrl: "./incident-list.component.html",
    styleUrls: ["./incident-list.component.css"]
})
export class IncidentListComponent implements OnInit, OnDestroy {
  loadingExport = false;
    public displayMap: boolean = false;
    public incidentList: Array<IIncident> = [];
    public incidentPointsMap: Array<IIncidentMap> = [];
    private _filterByIdsDisplayed: boolean = false;
    private _incidentListSub: Subscription;
    private _getVisibilityIncidentSub: Subscription;
    public incidentListFilters: IFilterBarFilter[] = incidentListFilters;
    public contentTable: any[] = [];
    public __TYPE_BUTTON = TYPE_BUTTON;
    public headerTableOptionsDefault = [
        "generatedid",
        "status",
        "categoryName",
        "completeCategory",
        "address",
        "municipality",
        "lambertX",
        "lambertY",
        "latitude",
        "longitude",
        "lastActionDate",
        "assignedTo",
        "createdAt",
        "action",
        "reporterSource"
    ];
    public headerTableOptions = [];

    public archivesOptions: ISelectOption[] = [];
    public headerTable = headerTableDefaultIncident;
    public filterOptions: ITableFilter[] = headerInputDefaultIncident;
    public filtersMapping: Map<string, string> = new Map([
        ["id", "id"],
        ["generatedid", "generatedid"],
        ["address", "address"],
      // ["municipality", "municipalities"],
      ["municipality", "zipCodes"],
        ["latitude", "latitude"],
        ["lambertY", "lambertY"],
        ["longitude", "longitude"],
        ["lambertX", "lambertX"],
        ["status", "statuses"],
        ["assignedTo", "assignedTo"],
        ["categoryName", "parentCategoryIds"],
        ["completeCategory", "categoryId"],
        ['plannedDate', 'atPlannedDate'],
        ["isPublic", "isPublic"],
        ["isMasked", "isMasked"],
        ["isThirdPartyResponsability", "isThirdPartyResponsability"],
        ["isPrivateLocation", "isPrivateLocation"],
        ["priority", "priorities"],
        ["createdAt", "createdAt"],
        ["lastActionDate", "lastActionDate"],
        ["categoryDetailIds", "categoryDetailIds"],
        ["reporterFirstName", "reporterFirstName"],
        ["reporterLastName", "reporterLastName"],
        ["reporterPhone", "reporterPhone"],
        ["reporterEmail", "reporterEmail"],
        ["signaledBy", "reportedBy"],
        ["reporterSource", "reporterSource"],
        ["reporterSourcePrecision", "reporterSourcePrecision"],
        ["isExtern", "isExtern"]
    ]);
    private appliedFilters: IIncidentColumnFilter;
    private _archiveYear: number;

    public optionsHeader: IHeaderOptions = {
        title: "incident_pageTitle-txt",
        buttonsOption: {
            size: "small",
            name: "general_create-button"
        },
        additionButtonOption: {
            size: "small",
            name: "incident_displayMap-button"
        }
    };
    public pagination: IFilterBarPagination = {
        label: "incident_incidentsOnX-txt",
        listNumber: null,
        listTotalCounter: null,
        totalPage: null,
        currentPage: 1
    };
    public selectedIncidentFromMap: number[] = [];
    public checkIncidentIds: number[] = [];
    public isGlobalSelected: boolean = false;
    public currentUserOrgaId: string = null;
    public isCurrentUserOrgaPublic: boolean = false;
    @ViewChild("esriMap", { static: true }) private esriMap: EsriMapComponent;

    public specialActions: Array<{label: string, icon: string, action: () => any, isVisible: () => boolean }> = [];
    public isCurrentUserAdmin: boolean = false;
    public isCurrentUserReadonlyAdmin: boolean = false;
  municipalities: ISelectOption[];

    constructor(
      private _modalSrv: ModalService,
      private _route: ActivatedRoute,
      private _translateSrv: TranslateService,
      private _snackBar: SnackService,
      private _incidentQueriesSrv: IncidentQueriesService,
      private _userQueriesSrv: UserQueriesService,
      private _categoryQueriesSrv: CategoryQueriesService,
      private _orgaQueriesSrv: OrganisationQueriesService,
      private _authSrv: AuthService,
      private _xlsService: XlsService,
      private _geoserviceWallonieSrv: GeoservicesWallonieService,
    ) { }

    ngOnInit() {
        this.appliedFilters = {};
        this.filtersMapping.forEach(value => {
            this.appliedFilters[value] = null;
        });
        this._incidentQueriesSrv.resetIncidentPagination();
        this.pagination.listNumber = this._incidentQueriesSrv.incidentPagination.limit;
        const storedHeaders = localStorage.getItem(DISPLAYED_INCIDENT_HEADER_KEY);
        this.headerTableOptions = storedHeaders ? JSON.parse(storedHeaders) : this.headerTableOptionsDefault;
        this.buildTableColumn(this.headerTableOptions, false);
        this.checkDisplayFilters();
        this.getIncidentsList();
        this.initFilterOptions();
        this.getExistingYears();
        const incidentId = this._route.snapshot.paramMap.get("id");
        if (incidentId !== null && incidentId !== undefined) {
            this.openIncidentDetail(+incidentId, incidentId);
        }
        this.isCurrentUserAdmin = this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN);
        this.isCurrentUserReadonlyAdmin = this._authSrv.getRolesCurrent().includes(EnumUserRoles.READONLY_ADMIN);
        if (this.isCurrentUserReadonlyAdmin) delete this.optionsHeader.buttonsOption
        this.loadCurrentUserOrganisation();
        this.specialActions = [
            {label: this._translateSrv.translate("incident_changeVisibilityButton-txt"), icon: "far fa-eye", action: this.openModalChangeIsMaskedVisibility, isVisible: this.checkVisibilityButtonHide}
        ];
    }


    ngOnDestroy(): void {
        this._incidentListSub.unsubscribe();
    }

    private checkDisplayFilters() {
        const filterToHide: EnumIncidentListFilter[] = [];
        if (this.selectedIncidentFromMap.length === 0) {
            filterToHide.push(EnumIncidentListFilter.INCIDENT_BY_IDS);
        }

        // NOT ADMIN OR SUPER_ADMIN
        if (!(
          this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN)
          || this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)
          || this._authSrv.getRolesCurrent().includes(EnumUserRoles.READONLY_ADMIN)
        )) {
            filterToHide.push(EnumIncidentListFilter.ALL_INCIDENTS);
        }

        // NOT MANAGER
        if (!this._authSrv.getRolesCurrent().includes(EnumUserRoles.MANAGER)) {
            filterToHide.push(EnumIncidentListFilter.MY_ATTRIBUTE_INCIDENTS);
        }

        // NOT AGENT
        if (!this._authSrv.getRolesCurrent().includes(EnumUserRoles.AGENT) && !this._authSrv.getRolesCurrent().includes(EnumUserRoles.ASCERTAINING_AGENT)) {
            filterToHide.push(EnumIncidentListFilter.MY_ASSIGNED_INCIDENTS);
        }

        // NOT ADMIN OR MANAGER
        if (!(this._authSrv.getRolesCurrent().includes(EnumUserRoles.ADMIN) || this._authSrv.getRolesCurrent().includes(EnumUserRoles.MANAGER))) {
            filterToHide.push(EnumIncidentListFilter.MY_TRANSFERRED_INCIDENTS);
        }

        // READONLY_ADMIN
        if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.READONLY_ADMIN)) {
            filterToHide.push(EnumIncidentListFilter.MY_REPORTED_INCIDENTS);
            filterToHide.push(EnumIncidentListFilter.MY_SUBSCRIBED_INCIDENTS);
        }

        this.incidentListFilters = incidentListFilters.filter(filter => !filterToHide.includes(filter.type));
        if (!this.incidentListFilters.some(filter => filter.selected)) {
            this.incidentListFilters[0].selected = true;
        }
    }

    public getIncidentsList() {
        if (this._incidentListSub) { this._incidentListSub.unsubscribe(); }
        const queryType: IFilterBarFilter = this.incidentListFilters.find(filter => filter.selected);

        const ids = queryType.type === EnumIncidentListFilter.INCIDENT_BY_IDS ? this.selectedIncidentFromMap : null;

        // this.pagination.totalPage = null;

        this._incidentListSub = this._incidentQueriesSrv.getIncidentList(queryType.type, ids, this.appliedFilters, this._archiveYear, null, this.displayMap).valueChanges.subscribe(result => {
            const resultData: any = <any>result.data;
            const typeData: string = queryType.queryResult + (this.displayMap ? 'ForMap' : '');
            if (resultData && typeData) {
                this.incidentList = resultData[typeData];
                if (this.displayMap) {
                    this.formatDatasForMap();
                } else {
                    this.formatDatasForTable();
                }
            }
            if (resultData && resultData.pagination) {
                this.pagination = {
                    ...this.pagination,
                    listTotalCounter: resultData.pagination.total,
                    totalPage: resultData.pagination.totalPage,
                    currentPage: resultData.pagination.page
                };
            }
        }, error => {
            console.log("ERROR GETTING INCIDENT LIST", { error });
        });
    }

    public generateExcel() {
      this.loadingExport = true;
      const queryType: IFilterBarFilter = this.incidentListFilters.find(filter => filter.selected);
      const ids = queryType.type === EnumIncidentListFilter.INCIDENT_BY_IDS ? this.selectedIncidentFromMap : null;
      const headers = this.headerTable.map((header): any => {
        return {
          key: header.key,
          translate: header.translate,
          useTooltipKey: header.useTooltipKey,
          tooltipKey: header.tooltipKey,
        }
      });
      this._incidentQueriesSrv.exportXls(queryType.type, ids, headers, this.appliedFilters, this._archiveYear).subscribe(res => {
        const exportXls = res.data['incidentsXls'];
        this._xlsService.downloadXls(exportXls);
        this.loadingExport = false;
      }, error => {
        console.log("ERROR GETTING INCIDENT XLS LIST", { error });
        this._snackBar.open(
          this._translateSrv.translate("general_snackbarTitleError-txt"),
          this._translateSrv.translate("incident_generateExcelFail-txt"),
          STATUS_ALERTS.DANGER,
          0);
      });
    }

    private formatDatasForTable(): void {
        this.contentTable = this.incidentList.map(incident =>
            this.formatIncidentForTable(incident));
    }

    private formatIncidentForTable(incident: IIncident) {
        let completeCategory = this.formatCompleteCategoryName(incident.categoryChain);

        if (incident.categoryDetails && incident.categoryDetails.length > 0) {
            completeCategory += " ( " + incident.categoryDetails.map(prec => prec.name).join(", ") + " )";
        }

        return {
            id: incident.id,
            generatedid: incident.orgacode + "-" + incident.generatedid,
            orgacode: incident.orgacode,
            categoryName: incident.categoryChain.name,
            completeCategory: completeCategory,
            address: incident.address || "",
            createdAt: incident.createdAt ? moment(incident.createdAt).format("DD/MM/YYYY HH:mm") : "",
            municipality: incident.municipality,
            lambertX: incident.lambertX,
            lambertY: incident.lambertY,
            latitude: incident.latitude,
            longitude: incident.longitude,
            lastActionDate: incident.lastActionDate ? moment(incident.lastActionDate).format("DD/MM/YYYY HH:mm") : "",
            assignedTo: incident.assignment && incident.assignment.agent ? `${incident.assignment.agent.lastName} ${incident.assignment.agent.firstName}` : "",
            status: this._translateSrv.translate(`incident_${incident.status.toLowerCase()}Status`),
            statusEnum: incident.status,
            plannedDate: incident.plannedDate ? moment(incident.plannedDate).format("DD/MM/YYYY") : "",
            isPublic: incident.isPublic,
            priority: this._translateSrv.translate(`incident_priority_${incident.priority.toLowerCase()}-value`),
            isMasked: incident.isMasked,
            isPrivateLocation: incident.isPrivateLocation,
            isThirdPartyResponsability: incident.isThirdPartyResponsability,
            administrator: incident.assignment && incident.assignment.group ? incident.assignment.group.name : "",
            signaledBy: this.displayReporterType(incident),
            reporterFirstName: this.getReporterProp(incident, "firstName"),
            reporterLastName: this.getReporterProp(incident, "lastName"),
            reporterPhone: this.getReporterProp(incident, "phone"),
            reporterEmail: this.getReporterProp(incident, "email"),
            isChecked: this.checkIncidentIds.indexOf(incident.id) != -1,
            reporterSource: this._translateSrv.translate(`incident_${incident.reporterSource.toLowerCase()}_reporterSource`),
            reporterSourcePrecision: this.formatReporterSourcePrecision(incident.reporterSourcePrecision),
            formattedComments: incident.comments && incident.comments.length > 0? this.setCommentData(incident.comments[0]) : 0,
            formattedCommentsTooltip: this.formatIncidentComments(incident.comments),
            isExtern: incident.isExtern

        };
    }

    public formatReporterSourcePrecision(precision: string): string{
        if(!precision || (precision && precision === REPORTER_SOURCES_PRECISIONS.NONE)) return "-";
        else return precision === REPORTER_SOURCES_PRECISIONS.ANDROID? "Android" : "iOS";
    }

    public formatIncidentComments(comments): string{
        let value = this.formatCommentDatas(comments);
        return value.join("\n ");
    }

    private formatCommentDatas(comments: IComment[]): string[] {
        const allComments: IComment[] = comments.sort( (a, b) => {
          return ( a.createdAt > b.createdAt ) ? -1 : 1;
        });
        return allComments.map( comment => {
          return this.setCommentData(comment);
        });
    }

    private setCommentData(comment: IComment): string {
        return `${this._translateSrv.translate("incident_commentAdded-txt")} ${moment(comment.createdAt).format("DD/MM/YYYY HH:mm")} ${this._translateSrv.translate("incident_commentBy-txt")} ${this.formatAuthor(comment)}: ${comment.comment}`
    }

    private formatAuthor(comment: IComment): string {
        if (!!comment.user) {
            return `${comment.user.lastName} ${comment.user.firstName}`;
        } else if (!!comment.citizen) {
            return `${this._translateSrv.translate("incident_commentNeightbour-txt")} (${comment.citizen.lastName} ${comment.citizen.firstName}`
            + `${comment.citizen.phone ? " | " + comment.citizen.phone : ""}${comment.citizen.email ? " | " + comment.citizen.email : ""})`;
        } else if (!!comment.external) {
            return `${comment.external.name}`;
        }
        else {
            return comment.wastype == WAS_TYPE.USER ? this._translateSrv.translate("incident_commentProUser-txt") : this._translateSrv.translate("incident_historyCitizen-txt");
        }
    }

    /**
     * @description Get reporter property depending on the reporter type (user, citizen)
     * @author Quentin Wolfs
     * @private
     * @param {IIncident} incident
     * @param {string} property
     * @returns {string}
     * @memberof IncidentListComponent
     */
    private getReporterProp(incident: IIncident, property: string): string {
        if (!!incident.reporter) {
            return incident.reporter[property] ? incident.reporter[property] : "";
        } else if (!!incident.citizen) {
            return incident.citizen[property] ? incident.citizen[property] : "";
        } else {
            return "";
        }
    }

    /**
     * @description Translates & returns the correct string to display for the reporterType
     * @author Quentin Wolfs
     * @private
     * @param {IIncident} incident
     * @returns {string}
     * @memberof IncidentListComponent
     */
    private displayReporterType(incident: IIncident): string {
        switch (incident.reporterType) {
            case REPORTER_TYPES.PROFESSIONAL: return this._translateSrv.translate("incident_typePro-txt");
            case REPORTER_TYPES.CITIZEN: return this._translateSrv.translate("incident_typeCitizen-txt");
        }
    }

    public getHigherRole(roles){
        // if the user is user type
        if(roles) {
            if(roles.indexOf(EnumUserRoles.SUPER_ADMIN) != -1) return "superadmin";
            else if(roles.indexOf(EnumUserRoles.ADMIN) != -1) return "admin";
            else if (roles.indexOf(EnumUserRoles.MANAGER) != -1) return "manageur";
            else if (roles.indexOf(EnumUserRoles.AGENT) != -1) return "agent";
            else if (roles.indexOf(EnumUserRoles.ASCERTAINING_AGENT) != -1) return "agentconstatateur"
            else return "agentconstatateur";
        }
      }

    private formatDatasForMap(): void {
        this.incidentPointsMap = this.incidentList.map(incident => {
            const higherRole = incident.reporter? this.getHigherRole(incident.reporter.userRoles) : (incident.citizen.isAmbassador === true ? 'ambassadeur' : 'citoyen');
            const avatarName = incident.reporter? incident.reporter.point.item_used.name : incident.citizen.user_point.item_used.name;
            let status = '';
            switch(incident.status) {
                case 'CREATED':
                status = 'created';
                break;

                case 'DISMISSED':
                status = 'dismissed';
                break;

                case 'IN_PROGRESS':
                status = 'inprogress';
                break;

                case 'CLOSED':
                status = 'closed';
                break;

                case 'SOLVED':
                status = 'solved';
                break;

                case 'FUSION':
                status = 'fusion';
                break;
            };
            return {
                id: incident.id,
                status: incident.status,
                x: incident.lambertX,
                y: incident.lambertY,
                avatar_url: `/img/avatars/${avatarName}/${avatarName}_${higherRole}_${status}.png`
            };
        });
    }

    private formatCompleteCategoryName(category: ICategory): string {
        let categoryName = category.name;

        if (category.children.length !== 0) {
            categoryName += ` / ${this.formatCompleteCategoryName(category.children[0])}`;
        }
        return categoryName;
    }


    public createIncident() {
        const createIncident = this._modalSrv.openModal(ModalCreateIncidentComponent,
            {
                title: this._translateSrv.translate("incident_createIncident-title"),
                type: MODAL_TYPE.CREATE,
                data: null,
                modalStyle: {
                    modalWidth: "x-large",
                },
                customData: {
                    cancelButtonName: this._translateSrv.translate("general_cancel-button"),
                    confirmButtonName: this._translateSrv.translate("general_continue-button"),
                    confirmButtonType: TYPE_BUTTON.CONTINUE,
                    confirmButtonIcon: "far fa-arrow-alt-circle-right"
                }
            });


        if (createIncident) {
            createIncident.afterClosed.subscribe(res => {
                if (res.confirm && res.data) {
                    const { datas, merge } = res.data;
                    const variables: IIncidentInput | any = merge.needMerge ? {
                        incidentId: merge.incidentId,
                        files: datas.attachments,
                        comments: [datas.comment],
                        autoSubscribe: datas.autoSubscribe
                    } : datas;

                    const query = merge.needMerge ? this._incidentQueriesSrv.addCommentsAndAttachments(variables) : this._incidentQueriesSrv.createIncident(variables);
                    query.subscribe(result => {
                        this._snackBar.open(
                            this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                            this._translateSrv.translate("incident_createIncidentSuccess-txt"),
                            STATUS_ALERTS.SUCCESS,
                            5000
                        );
                    }, error => {
                        console.log("ERROR CREATING INCIDENT", { error });
                        this._snackBar.open(
                            this._translateSrv.translate("general_snackbarTitleError-txt"),
                            this._translateSrv.translate("incident_createIncidentFail-txt"),
                            STATUS_ALERTS.DANGER,
                            0);
                    });
                }
            });
        }
    }

    /* BEGIN LIST METHODS */
    public incidentListAction(tableAction: ITableActionData) {
        switch (tableAction.action) {
            case EnumTableAction.EDIT:
                this.openIncidentDetail(tableAction.content.id, tableAction.content.generatedid);
                break;
            case EnumTableAction.DELETE:
                // this.beforeDeleteOrganisation(tableAction.content);
                break;
            case EnumTableAction.FILTER:
                this.processFilters(tableAction.content);
                break;
            case EnumTableAction.SORT:
                this.sort(tableAction.content);
                break;
            case EnumTableAction.SELECT:
                const indexOf = this.checkIncidentIds.indexOf(tableAction.content.id);
                if (indexOf != -1) {
                    this.checkIncidentIds.splice(indexOf, 1);
                    this.isGlobalSelected = false;
                } else {
                    this.checkIncidentIds.push(tableAction.content.id);
                }
                this.isGlobalSelected = this.checkIncidentIds.length == this.pagination.listTotalCounter ? true : false;

                break;
            case EnumTableAction.SELECT_GLOBAL:
                const queryType: IFilterBarFilter = this.incidentListFilters.find(filter => filter.selected);
                const ids = queryType.type === EnumIncidentListFilter.INCIDENT_BY_IDS ? this.selectedIncidentFromMap : null;
                if (!this.isGlobalSelected) {
                    if (this._getVisibilityIncidentSub) { this._getVisibilityIncidentSub.unsubscribe(); }
                    this._getVisibilityIncidentSub = this._incidentQueriesSrv.getVisibilityIncident(queryType.type, ids, this.appliedFilters, this._archiveYear).subscribe(result => {
                        const incidents: IIncident[] = result.data[queryType.queryResult];
                        if (incidents.length > 0 ) {
                            this.checkIncidentIds = incidents.map(incident => incident.id);
                            this.isGlobalSelected = !this.isGlobalSelected;
                        } else {
                            this._snackBar.open(
                                this._translateSrv.translate("general_snackbarTitleInfo-txt"),
                                this._translateSrv.translate("incident_visibilityNoIncidentAvailable-txt"),
                                STATUS_ALERTS.INFO,
                                0);
                        }
                        this.getIncidentsList();
                    }, error => {
                        this._snackBar.open(
                            this._translateSrv.translate("general_snackbarTitleError-txt"),
                            this._translateSrv.translate("incident_getIncidentMaskedError-txt"),
                            STATUS_ALERTS.DANGER,
                            0);
                    });
                } else {
                    this.checkIncidentIds = [];
                    this.isGlobalSelected = false;
                }
                break;
        }
    }

    private openIncidentDetail(incidentId: number, displayId: string) {
        const detailIncident = this._modalSrv.openModal(ModalDetailIncidentComponent,
            {
                title: this._translateSrv.translate("incident_detailIncidentNumberX-title", { incidentId: displayId? displayId.toUpperCase() : "" }),
                type: null,
                data: {
                    incidentId
                },
                modalStyle: {
                    modalWidth: "custom",
                },
                customData: {
                    displayCancelButton: false,
                    displayConfirmButton: false,
                }
            }
        );
        // TODO Check if we can delete > Useless
        if (detailIncident) {
            detailIncident.afterClosed.subscribe(res => {
                if (res.confirm && res.data && res.data.reOpenId) {
                    setTimeout(() => {
                        // CARE HERE
                        this.openIncidentDetail(res.data.reOpenId, null);
                    }, 500);
                }
                this.getIncidentsList();
            });
        }
    }

    /**
     * @description Apply filters and refresh incidentList
     * @author Gaetan Geenens
     * @private
     * @param {*} filterInputValues
     * @memberof IncidentListComponent
     */
    private processFilters(filterInputValues: any): void {
        Object.keys(filterInputValues).forEach(key => {
            if (this.filtersMapping.get(key)) {
              if (this.filtersMapping.get(key) == "atPlannedDate") {
                this.appliedFilters[this.filtersMapping.get(key)] = moment(filterInputValues[key]).toISOString();
              } else if ((this.filtersMapping.get(key) == "createdAt" || this.filtersMapping.get(key) == "lastActionDate") && filterInputValues[key] && (typeof filterInputValues[key] === 'string' || filterInputValues[key] instanceof String) ) {
                this.appliedFilters[this.filtersMapping.get(key)] = null;
              } else if (this.filtersMapping.get(key) == "zipCodes" && filterInputValues[key].length) {
                const zipCodes = filterInputValues[key].map(value => value.split(',').map(Number));
                this.appliedFilters[this.filtersMapping.get(key)] = [].concat(...zipCodes);
              } else {
                this.appliedFilters[this.filtersMapping.get(key)] = filterInputValues[key];
              }
            }
        });
        this.getIncidentsList();
    }

    /**
     * @description Initialize column filters
     * @author Gaetan Geenens
     * @returns {Promise<void>}
     * @memberof IncidentListComponent
     */
    public async initFilterOptions(): Promise<void> {
        for (const filter of this.filterOptions) {
            switch (filter.type) {
                case EnumTableDataType.selectcheckbox:
                case EnumTableDataType.selectSearchMultiple:
                    filter.options = await this.getFilterSelectOptions(filter.key);
                    break;
                case EnumTableDataType.selectMultiple:
                    filter.selectMultipleOptions = await this.getFilterSelectMultipleOptions(filter.key);
                    break;
            }
        }
    }

    /**
     * @description get values for selects and selectCheckbox column filters
     * @author Gaetan Geenens
     * @private
     * @param {string} fieldName
     * @returns {Promise<ISelectOption[]>}
     * @memberof IncidentListComponent
     */
    private async getFilterSelectOptions(fieldName: string, filter: any = null): Promise<ISelectOption[]> {
        switch (fieldName) {
            case "status":
                return EnumUtil.getStringValues(EnumIncidentStatuses)
                    .map(status => ({
                        label: this._translateSrv.translate(`incident_${status.toLowerCase()}Status`),
                        value: status
                    }));

            case "municipality":
            // return await this._incidentQueriesSrv.getMunicipalityList(filter)
            //     .toPromise()
            //     .then(res => {
            //         return res.data["municipalityList"].map(r => ({ label: r, value: r }));
            //     })
            //     .catch(e => { throw e; });

            if (filter && filter.name) {
              return this.municipalities.filter((option) => option.label.toLowerCase().includes(filter.name.toLowerCase()));
            }
            return await this.loadMunicipalities()
                    .toPromise()
              .then(res => res)
                    .catch(e => { throw e; });

            case "assignedTo":
                return await this._userQueriesSrv.getUsersList(filter)
                    .toPromise()
                    .then(res => res.data["users"].map(u => ({ label: `${u.lastName} ${u.firstName}`, value: u.id })))
                    .catch(e => { throw e; });

            case "categoryName":
                let customFilter = { ...filter}
                return await this._categoryQueriesSrv.getCategories(customFilter, this._translateSrv.activeLanguage)
                    .toPromise()
                    .then(res => res.data["categories"].map(c => ({ label: c.name, value: c.id })))
                    .catch(e => { throw e; });
            case "priority":
                return EnumUtil.getStringValues(EnumIncidentPriorities)
                    .map(priority => ({
                        label: this._translateSrv.translate(`incident_priority_${priority.toLowerCase()}-filter`),
                        value: priority
                    }));
            default:
                return [];
        }
    }

    public async search(event){
        const filterToChange = this.filterOptions.find(item => item.key == event.key);
        let filter = {
          [this.searchFieldMap.get(event.key)]: event.search && event.search != ""? event.search : null
        }

        if(filterToChange) filterToChange.options = await this.getFilterSelectOptions(event.key, filter);
    }

    public searchFieldMap = new Map([
        ["categoryName", "categoryName"],
        ["municipality", "name"],
        ["assignedTo", "userName"],
    ])

    /**
     * @description Get values for selectMultipes filter options
     * @author Gaetan Geenens
     * @private
     * @param {string} fieldName
     * @returns {Promise<ISelectMultipleOptions[]>}
     * @memberof IncidentListComponent
     */
    private async getFilterSelectMultipleOptions(fieldName: string): Promise<ISelectMultipleOptions[]> {
        switch (fieldName) {
            case "completeCategory":
                return await this._categoryQueriesSrv.getMappedCategories()
                    .toPromise()
                    .then(result => {
                        const resultData: { mappedCategories: any[] } = <any>result.data;
                        if (resultData && resultData.mappedCategories) {
                            return [{
                                id: 0,
                                name: ""
                            },
                            ...resultData.mappedCategories];
                        }
                    })
                    .catch(e => { throw e; });

            default:
                return [];
        }
    }

    public getIncidentsListFiltered(type: EnumIncidentListFilter) {
        this.checkIncidentIds = [];
        this.isGlobalSelected = false;
        this.incidentListFilters = this.incidentListFilters.map(filter => {
            return {
                ...filter,
                selected: filter.type === type
            };
        });
        this._incidentQueriesSrv.switchPaginationTableMap();
        this.getIncidentsList();
    }

    public searchIncidentId(incidentId: string) {
        if (incidentId) {
            this._incidentQueriesSrv.checkIncidentByCustomId(incidentId.toString()).subscribe(result => {
                const resultData: any = result.data;
                if (resultData && resultData.incidentByCustomId) {
                    this.openIncidentDetail(resultData.incidentByCustomId.id, incidentId.toString());
                } else {
                    this._snackBar.open(
                        this._translateSrv.translate("general_snackbarTitleWarning-txt"),
                        this._translateSrv.translate("incident_searchIncidentFail-txt"),
                        STATUS_ALERTS.WARNING,
                        5000
                    );
                }
            }, error => {
                this._snackBar.open(
                    this._translateSrv.translate("general_snackbarTitleWarning-txt"),
                    this._translateSrv.translate("incident_searchIncidentFail-txt"),
                    STATUS_ALERTS.WARNING,
                    5000
                );
            });
        } else {
            this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleWarning-txt"),
                this._translateSrv.translate("incident_searchIncidentFail-txt"),
                STATUS_ALERTS.WARNING,
                5000
            );
        }
    }

    public mapLoadedEvent(status: boolean) {
        console.log("The map loaded: " + status);
    }

    public mapMarkerClickedEvent(incidentId) {
        if (this.selectedIncidentFromMap.indexOf(incidentId) === -1) {
            this.selectedIncidentFromMap.push(incidentId);
            // Check if need to add filter by ids
            if (!this._filterByIdsDisplayed) {
                this.incidentListFilters.push(incidentListFilters.filter(filter => filter.type === EnumIncidentListFilter.INCIDENT_BY_IDS)[0]);
                this._filterByIdsDisplayed = true;
            }
        } else {
            this.selectedIncidentFromMap = this.selectedIncidentFromMap.filter(id => id != incidentId);
            // Remove filter by ids
            if (this.selectedIncidentFromMap.length === 0) {
                this.incidentListFilters = this.incidentListFilters.filter(filter => filter.type !== EnumIncidentListFilter.INCIDENT_BY_IDS);
                if (this.incidentListFilters.find(filter => filter.selected) == null) {
                    this.incidentListFilters[0].selected = true;
                    this.getIncidentsList();
                }
                this._filterByIdsDisplayed = false;
            }
        }
    }

    /**
     * @description Set the page and get the datas
     * @author Kevin Palade
     * @param {*} currentPage
     * @memberof IncidentListComponent
     */
    public setPagination(currentPage) {
        this._incidentQueriesSrv.incidentPagination = {
            ...this._incidentQueriesSrv.incidentPagination,
            page: currentPage
        };
        this.getIncidentsList();
    }

    /**
     * @description Count items display changed
     * @author Kevin Palade
     * @param {number} nbItemsToDisplay
     * @memberof IncidentListComponent
     */
    public countItemsChanged(nbItemsToDisplay: number) {
        this._incidentQueriesSrv.baseListPagination = {
            ...this._incidentQueriesSrv.baseListPagination,
            limit: nbItemsToDisplay
        };
        this._incidentQueriesSrv.incidentPagination = {
            ...this._incidentQueriesSrv.incidentPagination,
            limit: nbItemsToDisplay,
            page: 1
        };
        this.getIncidentsList();
    }

    /**
     *  Set item order for the first time with all the key possible
     *  !!! the query is in network-only and take cache if one of the key is different !!!
     */
    private sort(sortObject: any) {
        let { order } = this._incidentQueriesSrv.incidentPagination;

        if (!order) {
            order = {};
            this.headerTable.forEach(header => {
                if (header.sortable) { order[header.key] = null; }
            });
        }
        Object.keys(order).forEach(key => {
            if (key === sortObject.key) {
                order[key] = order[key] === "ASC" ? "DESC" : "ASC";
            } else {
                order[key] = null;
            }
        });
        this._incidentQueriesSrv.incidentPagination = {
            ...this._incidentQueriesSrv.incidentPagination,
            order
        };
        this.getIncidentsList();
    }

    /**
     * @description Display map or list and reset the pagination (no limit for map)
     * @author Kevin Palade
     * @param {*} datas
     * @memberof IncidentListComponent
     */
    public additionnalButtonClicked(datas) {
        this.displayMap = !this.displayMap;
        this.optionsHeader.additionButtonOption.name = this.displayMap ? "incident_displayList-button" : "incident_displayMap-button";
        this._incidentQueriesSrv.switchPaginationTableMap(this.displayMap);
        this.getIncidentsList();
    }

    /**
     * @description Remove all selected point from map
     * @author Kevin Palade
     * @memberof IncidentListComponent
     */
    public resetSelectedIncidentFromMap() {
        this.esriMap.unSelectedDisplayedMarkers();
        this.selectedIncidentFromMap = [];
        this.incidentListFilters = this.incidentListFilters.filter(filter => filter.type !== EnumIncidentListFilter.INCIDENT_BY_IDS);
        if (this.incidentListFilters.find(filter => filter.selected) == null) {
            this.incidentListFilters[0].selected = true;
            this.getIncidentsList();
        }
        this._filterByIdsDisplayed = false;
    }

    public incidentHeaderModal(event: boolean) {
        if (event) {
            const filterModal = this._modalSrv.openModal(ModalChoiceHeaderComponent,
                {
                    title: this._translateSrv.translate("incident_choiceColumnDisplay-txt"),
                    type: null,
                    modalStyle: {
                        modalWidth: "x-large",
                    },
                    data: {
                        defaultHeader: headerTableOptionsDefault,
                        headerOptionsDefault: this.headerTableOptionsDefault,
                        currentHeader: this.headerTable,
                        resetColumnButtonTitle: "incident_resetColumnChoice-button"
                    }
                }
            );
            // TODO Check if we can delete > Useless
            if (filterModal) {
                filterModal.afterClosed.subscribe(res => {
                    this.headerTableOptions = [];
                    headerTableDefaultIncident.map(item => {
                        if (res.get(item.key).value) {
                            this.headerTableOptions.push(item.key);
                        }
                    });
                    localStorage.setItem(DISPLAYED_INCIDENT_HEADER_KEY, JSON.stringify(this.headerTableOptions));
                    this.buildTableColumn(this.headerTableOptions, false);
                    this.initFilterOptions();
                });
            }
        }
    }

    private buildTableColumn(arrayHeader, isFilter: boolean) {
        const tmp = [];
        if (!isFilter) {
            headerTableDefaultIncident.map(item => {
                if (arrayHeader.includes(item.key)) {
                    tmp.push(item);
                }
            });
            this.headerTable = tmp;
            this.buildTableColumn(arrayHeader, true);
        } else {
            headerInputDefaultIncident.map(item => {
                if (arrayHeader.includes(item.key)) {
                    tmp.push(item);
                }
            });
            this.filterOptions = tmp;
        }
    }

    /**
     * @description Get an array of all years when at least one incident has been reported
     * @author Quentin Wolfs
     * @private
     * @memberof IncidentListComponent
     */
    private getExistingYears() {
        this._incidentQueriesSrv.getExistingYears().subscribe(years => {
            const existingYears = years.data["existingYearsList"];
            this.archivesOptions = [
                { label: this._translateSrv.translate("incident_archive-filter"), value: null },
                ...existingYears.map(year => ({
                    label: year,
                    value: year
                }))
            ];
        }, err => {
            console.log("ERROR LOADING INCIDENT EXISTING YEARS", { err });
        });
    }

    /**
     * @description Change the selected archive year
     * @author Quentin Wolfs
     * @param {number} yearToDisplay
     * @memberof IncidentListComponent
     */
    public yearChanged(yearToDisplay: number) {
        this._archiveYear = yearToDisplay;
        this.getIncidentsList();
    }


    public changeIsMaskedVisibility = (params: {incidentIds: number[]}) =>  {
        this._incidentQueriesSrv.setAllIncidentsMaskedByOrganisation(false, params.incidentIds).subscribe(res => {
            this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleSuccess-txt"),
                this._translateSrv.translate("incident_setMaskedToFalseSuccess-txt"),
                STATUS_ALERTS.SUCCESS,
                5000
            );
            this.getIncidentsList();
            this.isGlobalSelected = false;
            this.checkIncidentIds = [];
        }, error => {
            console.log("ERROR CREATING INCIDENT", { error });
            this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleError-txt"),
                this._translateSrv.translate("incident_setMaskedToFalseFail-txt"),
                STATUS_ALERTS.DANGER,
                0);
            this.getIncidentsList();
            this.isGlobalSelected = false;
            this.checkIncidentIds = [];
        });
    }

    public openModalChangeIsMaskedVisibility = () => {
        if ((this.checkIncidentIds && this.checkIncidentIds.length > 0)) {
            this._modalSrv.openModal(ModalConfirmComponent,
                {
                    title: this._translateSrv.translate("incident_changeVisibilityAreYouSure-txt"),
                    type: MODAL_TYPE.CONFIRM,
                    data: {
                        // tslint:disable-next-line: no-unused-expression
                        message: `${this._translateSrv.translate("incident_changeVisibilityConfirm-txt")}`,
                        actions: [
                            this._translateSrv.translate("incident_changeHideToVisible-txt"),
                            this._translateSrv.translate("incident_changeHideNotImpactVisibility-txt")
                        ],
                    },
                    customData: {
                        deleteButtonName: this._translateSrv.translate("general_confirm-button"),
                        deleteButtonIcon: "fa fa-check",
                        deleteButtonType: TYPE_BUTTON.VALID,

                    },
                    params : {
                        incidentIds: this.checkIncidentIds,
                    },
                    confirmCallback: this.changeIsMaskedVisibility
                    }
            );
        } else {
            this._snackBar.open(
                this._translateSrv.translate("general_snackbarTitleError-txt"),
                this._translateSrv.translate("incident_needToSelectAtLeastOneIncident-txt"),
                STATUS_ALERTS.DANGER,
                0);
        }
    }

    public showSelectCondition = (item: any) => {
        return item.isMasked == true;
    }

    public loadCurrentUserOrganisation(): void {
        this.currentUserOrgaId = this._authSrv.getOrganisationId();
        if (this.currentUserOrgaId && this.currentUserOrgaId != "" && this.currentUserOrgaId != "null") {
            this._orgaQueriesSrv.getOrganisation(+this.currentUserOrgaId).subscribe(res => {
                const resultData: any = res.data;
                if (resultData && resultData.organisation) {
                    this.isCurrentUserOrgaPublic = resultData.organisation.stage && resultData.organisation.stage == EnumOrganisationStage.PUBLIC;
                }
            }, err => {
                console.log("ERROR GETTING USER ORGANISATION", { err });
            });
        }
    }

    public checkVisibilityButtonHide = () => {
        return this.isCurrentUserAdmin && this.isCurrentUserOrgaPublic && this.checkIncidentIds && this.checkIncidentIds.length > 0;
    }

  private loadMunicipalities(): Observable<ISelectOption[]> {
    return this._geoserviceWallonieSrv.getCommunesList().pipe(
      take(1),
      map((res) => res["communes"].map(c => ({ label: c.nom, value: c.cps.join() }))),
      tap((municipalities) => this.municipalities = municipalities)
    );
  }
}
