import gql from "graphql-tag";

export const GET_CATEGORY_THEMES = gql`
  query getCategoryThemes( $usedLanguage: String) {
    categoryThemes(usedLanguage: $usedLanguage) {
      id
      name
      description
      translation {
        fr
        nl
        en
        de
      }
    }
  }
`;

export const MUTATE_CATEGORY_THEME = gql`
  mutation categoryTheme($categoryTheme: CategoryThemeInput) {
    categoryTheme(categoryTheme: $categoryTheme) {
      id
      name
      translation {
        fr
        nl
        en
        de
      }
    }
  }
`;

export const DELETE_CATEGORY_THEME = gql`
  mutation deleteCategoryTheme($id: Int!){
    deleteCategoryTheme(id: $id)
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories($parentId: Int, $isPublic: Boolean, $pagination: Pagination, $filter: CategoryFilter, $usedLanguage: String) {
    categories(parentId: $parentId, isPublic: $isPublic, pagination: $pagination, filter: $filter, usedLanguage: $usedLanguage) {
      id
      name
      parentId
      hasChildren
      categoryDetails{
        id
        name
        categoryId
      }
    }
  }
`;

export const GET_CATEGORY_DETAILS = gql`
  query getCategoryDetails( $filter: FilterCategoryDetails, $pagination: Pagination, $usedLanguage: String) {
    categoryDetails(pagination: $pagination, filter: $filter, usedLanguage: $usedLanguage) {
      id
      name
    }
  }
`;

export const GET_MAPPED_CATEGORIES = gql`
  query getMappedCategories($includeDisabled: Boolean) {
    mappedCategories(includeDisabled: $includeDisabled)
  }
`;

export const MUTATE_CATEGORY = gql `
  mutation category($category: CategoryInput) {
    category(category: $category) {
      id
      name
      isActive
      categoryThemeId
      translation {
        fr
        nl
        en
        de
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: Int!){
    deleteCategory(id: $id)
  }
`;

export const ENABLE_CATEGORY = gql`
  mutation enableCategory($id: Int!, $enabled: Boolean!){
    enableCategory(id: $id, enabled: $enabled){
      id
    }
  }
`;

export const MUTATE_CATEGORY_DETAIL = gql `
  mutation categoryDetail($categoryDetail: CategoryDetailInput) {
    categoryDetail(categoryDetail: $categoryDetail) {
      id
      name
      isActive
      categoryThemeId
      translation {
        fr
        nl
        en
        de
      }
    }
  }
`;

export const DELETE_CATEGORY_DETAIL = gql`
  mutation deleteCategoryDetail($id: Int!){
    deleteCategoryDetail(id: $id)
  }
`;

export const ENABLE_CATEGORY_DETAIL = gql`
  mutation enableCategoryDetail($id: Int!, $enabled: Boolean!){
    enableCategoryDetail(id: $id, enabled: $enabled){
      id
    }
  }
`;
