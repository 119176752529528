import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_LIST_GROUP_USERS, GET_LIST_GROUP_BY_ORGANISATION, GET_USER_GROUP, SET_USER_GROUP, DELETE_USER_GROUP, GET_TRANSFERABLE_GROUPS, GET_MY_GROUPS, GET_TRANSFERABLE_GROUP_LIST, GET_NO_GROUPS_USER, GET_GROUPS_XLS } from "./user-group.queries";
import { IGroupInptade, IFilterGroups } from "../../interfaces/group.interface";
import { IPagination } from "../../interfaces/pagination.interface";
import { IIncidentColumnFilter } from "../../interfaces/incident.interface";

@Injectable({
  providedIn: "root"
})
export class UserGroupQueriesService {
  public _userGroupPagination: IPagination = {limit: 20, page: 1};
  private _appliedFilters: IIncidentColumnFilter = null;

  constructor(private _apollo: Apollo) { }

  public set userGroupPagination(pagination: IPagination ) { this._userGroupPagination = pagination; }
  public get userGroupPagination(): IPagination { return this._userGroupPagination; }

  public get appliedFilters(): IIncidentColumnFilter { return this._appliedFilters; }
  public set appliedFilters(appliedFilters: IIncidentColumnFilter) { this._appliedFilters = appliedFilters; }

  public resetPagination() {
    this._userGroupPagination = {
      ...this._userGroupPagination,
      page: 1
    };
  }

  public listGroupUsers(pagination?: any) {
    if (this._appliedFilters) {
      Object.keys(this._appliedFilters).forEach(key => {
        if (this._appliedFilters[key] === "") {
          this._appliedFilters[key] = null;
        }
      });
    }
    return this._apollo.watchQuery({
      query: GET_LIST_GROUP_USERS,
      variables: {
        filter: this._appliedFilters,
        pagination: this._userGroupPagination
      },
      fetchPolicy: "cache-and-network",
    });
  }

  public getListGroupUsers(pagination?: any, filter: any = null) {
    return this._apollo.query({
      query: GET_LIST_GROUP_USERS,
      variables: {
        filter: filter? filter : this._appliedFilters,
        pagination: pagination? pagination : this._userGroupPagination
      },
      fetchPolicy: "no-cache",
    });
  }

  public getUserGroup(id: number) {
    return this._apollo.query({
      query: GET_USER_GROUP,
      variables: {id},
      fetchPolicy: "no-cache"
    });
  }

  public getListGroupByOrganisation(filter?: IFilterGroups) {
    return this._apollo.query({
      query: GET_LIST_GROUP_BY_ORGANISATION,
      fetchPolicy: "no-cache",
      variables: { filter }
    });
  }

  public getTransferableGroupList() {
    return this._apollo.query({
      query: GET_TRANSFERABLE_GROUP_LIST,
      fetchPolicy: "no-cache",
    });
  }

  public getMyGroups(managerOnly: boolean, agentOnly: boolean) {
    return this._apollo.query({
      query: GET_MY_GROUPS,
      variables: {managerOnly, agentOnly},
      fetchPolicy: "no-cache"
    });
  }

  public saveUserGroup( group: IGroupInptade) {
    return this._apollo.mutate({
      mutation: SET_USER_GROUP,
      variables: {group},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_LIST_GROUP_USERS,
        variables: {
          pagination: this._userGroupPagination,
          filter: this._appliedFilters,
        },
      }]
    });
  }

  public deleteUserGroup( id: number) {
    return this._apollo.mutate({
      mutation: DELETE_USER_GROUP,
      variables: {id},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_LIST_GROUP_USERS,
        variables: {
          pagination: this._userGroupPagination,
          filter: this._appliedFilters,
        },
      }]
    });
  }


  public getGroupsXls(tableHeaders: any[]) {
    if (this._appliedFilters) {
      Object.keys(this._appliedFilters).forEach(key => {
        if (this._appliedFilters[key] === "") {
          this._appliedFilters[key] = null;
        }
      });
    }

    const pagination = {
      ...this._userGroupPagination,
      limit: 0,
      page: 1
    };

    return this._apollo.query({
      query: GET_GROUPS_XLS,
      variables: {
        filter: this._appliedFilters,
        pagination: pagination,
        tableHeaders
      },
      fetchPolicy: "no-cache"
    });
  }

  public getNoGroupsUser(groupId: number) {
    return this._apollo.query({
      query: GET_NO_GROUPS_USER,
      variables: {groupId},
      fetchPolicy: "no-cache"
    });
  }
}
