import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.css"]
})
export class StepperComponent implements OnInit {
  @Input() public stepperOptions: {value: number, label: string} [] = [];
  @Input() public currentValue = null;
  @Output() getValueStepper = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    // console.table(this.stepperOptions);
  }

  public changeStep(value) {
    this.getValueStepper.emit(value);
  }

}
