import { Component, OnInit } from "@angular/core";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { log } from "util";

@Component({
  selector: "app-snack-bar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.css"]
})
export class SnackBarComponent  implements OnInit {
  public snackBars: any[];

  constructor(public snackService: SnackService) {
   }

   ngOnInit(): void {
   }
}
