import { Component, Renderer2, Input, Output, EventEmitter } from "@angular/core";
import { AInputs } from "../../facades/inputs";
import { ErrorMessageService } from "../../facades/services/error-message.service";


@Component({
  selector: "app-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"]
})
export class TextInputComponent extends AInputs {

  @Input() isSearch: boolean = false;
  @Input() placeholder: string = '';
  @Output() public onSubmitData: EventEmitter<any> = new EventEmitter<any>();

  constructor(public renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
  }

  public onKeydown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.onSubmitData.emit(this.group.get(this.name).value);
    }
  }
}
