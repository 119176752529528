import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { ROLES } from "src/app/facades/enum/roles.enum";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { UserGroupQueriesService } from "src/app/facades/queries/user-group/user-group-queries.service";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { AuthService } from "src/lib/auth/auth.service";

@Component({
  selector: "app-reporting-search",
  templateUrl: "./reporting-search.component.html",
  styleUrls: ["./reporting-search.component.css"]
})
export class ReportingSearch implements OnInit {
  private roles = ROLES;
  public formGroup: FormGroup;
  public isSuperAdmin: Boolean = false;
  public organisationOptions: any[] = [];
  public groupOptions: any[] = [];
  private _organisationId: number = null;

  @Output() filterEvent = new EventEmitter<any>();
  @Input() loading: boolean;
  @Input() filterDefaultDates: any;

  constructor(
    private _fb: FormBuilder,
    private authSrv: AuthService,
    private _organisationQueriesSrv: OrganisationQueriesService,
    private _userGroupQueriesSrv: UserGroupQueriesService,
    private _snackBar: SnackService,
    private _translateSrv: TranslateService
  ) { }

  ngOnInit(): void {
    this.initFormGroup()
    this.searchOrganisations(null);
  }

  public filter() {
    if(this.formGroup.value.startDate && this.formGroup.value.startDate != "" && this.formGroup.value.endDate && this.formGroup.value.endDate != ""){
      this.filterEvent.emit({
        createdAtRange: {
          start: this.formGroup.value.startDate && moment(this.formGroup.value.startDate).format('YYYY-MM-DD'),
          end: this.formGroup.value.endDate && moment(this.formGroup.value.endDate).format('YYYY-MM-DD'),
        },
        organisationId: this.formGroup.value.organisationId,
        groupId: this.formGroup.value.groupId
      })
    } else {
      this._snackBar.open( this._translateSrv.translate("general_snackbarTitleError-txt"), this._translateSrv.translate("report_date_required-txt"), STATUS_ALERTS.DANGER, 0 );
    }
  }

  private initFormGroup() {
    this.formGroup = this._fb.group({
      startDate: [moment(this.filterDefaultDates.start).valueOf()],
      endDate: [moment(this.filterDefaultDates.end).valueOf()],
      organisationId: null,
      groupId: null
    });
    const userRoles = this.authSrv.getRolesCurrent()
    if (userRoles.includes(this.roles.SUPER_ADMIN)) {
      this.isSuperAdmin = true;
      this.formGroup.get('organisationId').valueChanges.subscribe(organisationId => {
        this.formGroup.get('groupId').patchValue(null);
        if (!organisationId) {
        } else {
          this._organisationId = organisationId;
          this.searchGroups(null);
        }
      })
    } else {
      this.searchGroups(null);
    }
  }

  private _organisationsSubscription: Subscription;
  public searchOrganisations(event) {
    if (this._organisationsSubscription) this._organisationsSubscription.unsubscribe()
    this._organisationsSubscription = this._organisationQueriesSrv.listOrganisations({ limit: 0, page: 1 }, {name: event? event : null}).valueChanges.subscribe((result: any) => {
      this.organisationOptions = result && result.data && result.data.organisations? result.data.organisations.map(o => ({ label: o.name, value: o.id })) : [];
    })
  }

  private _groupsSubscription: Subscription;
  public searchGroups(event?: number) {
    if (this._groupsSubscription) this._groupsSubscription.unsubscribe()
    this._groupsSubscription = this._userGroupQueriesSrv.getListGroupUsers({ limit: 0, page: 1 }, { organisationIds: this._organisationId ? [this._organisationId] : null, name: event? event : null }).subscribe((result: any) => {
      this.groupOptions = result.data && result.data.groups? result.data.groups.map(g => ({label: g.name, value: g.id})) : [];
    })
  }

  public test(){
    this.formGroup.get('groupId').patchValue(null);
  }

}
