export enum ROLES {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  READONLY_ADMIN = "READONLY_ADMIN",
  MANAGER = "MANAGER",
  AGENT = "AGENT",
  ASCERTAINING_AGENT = "ASCERTAINING_AGENT"
}

export interface IRolesAccepted {
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isManager: boolean;
  isAgent: boolean;
  isAscertainingAgent: boolean;
  isReadonlyAdmin: boolean;
}