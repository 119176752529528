import { EnvironmentMode } from "src/lib/environments.enum";
import { ENV } from "./environment.constant";

export class ApplicationConfig {

    private constructor() {}
    public static readonly AppName: string = "FMX";

    /* Webservice graphql url */
    public static get EndPointURI(): string {
        switch (ENV) {
            case EnvironmentMode.Development:
                return "https://dev.ws.fmx.ssii.be/graphql";
                // return "http://127.0.0.1:3000/graphql";
            case EnvironmentMode.LocalDevelopment:
                return "//localhost:3000/graphql";
            case EnvironmentMode.Stage:
               return  "https://ws.stage.fmx.ssii.be/graphql";
            case EnvironmentMode.Production:
                return "https://ws.fixmystreetwallonie.be/graphql";
            default:
                throw new Error("This environment is not defined");
}
    }

    /* Webservice base url */
    public static get Url(): string {
        switch (ENV) {
            case EnvironmentMode.Development:
                return "https://dev.ws.fmx.ssii.be";
                // return "http://127.0.0.1:3000"
            case EnvironmentMode.LocalDevelopment:
                return "http://localhost:3000";
            case EnvironmentMode.Stage:
                return "https://ws.stage.fmx.ssii.be";
            case EnvironmentMode.Production:
                return "https://ws.fixmystreetwallonie.be";
            default:
                throw new Error("This environment is not defined");
        }
    }

    /* Webservice graphql url */
    public static getDomain(): string {
        switch (ENV) {
            case EnvironmentMode.Development:
                return "https://dev.ws.fmx.ssii.be/graphql";
                // return 'http://192.168.1.53:3000/graphql'
            case EnvironmentMode.LocalDevelopment:
                return "http://localhost:3000/graphql";
            case EnvironmentMode.Stage:
                return "https://ws.stage.fmx.ssii.be/graphql";
            case EnvironmentMode.Production:
                return "https://ws.fixmystreetwallonie.be/graphql";
            default:
                throw new Error("This environment is not defined");
        }
    }
}
