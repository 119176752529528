import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { SET_CATEGORY_DISPATCHING, GET_CATEGORY_DISPATCHING, LIST_CATEGORY_DISPATCHINGS } from "./category-dispatching.queries";
import { ICategoryDispatchingInput } from "../../interfaces/dispatching.interface";

@Injectable({
  providedIn: "root"
})
export class CategoryDispatchingQueriesService {

  constructor(private _apollo: Apollo) { }

  public setCategoryDispatching(categoryDispatching: ICategoryDispatchingInput ) {
    return this._apollo.mutate({
      mutation: SET_CATEGORY_DISPATCHING,
      variables: {categoryDispatching},
      fetchPolicy: "no-cache",
      // refetchQueries: [{
      //   query: GET_INCIDENTS
      // }]
    });
  }

  public getCategoryDispatching(categoryId?: number, organisationId?: number) {
    return this._apollo.query({
      query: GET_CATEGORY_DISPATCHING,
      variables: {categoryId, organisationId},
      fetchPolicy: "network-only"
    });
  }

  public listCategoryDispatchings(pagination?: any, filter?: any) {
    return this._apollo.query({
      query: LIST_CATEGORY_DISPATCHINGS,
      variables: {pagination, filter},
      fetchPolicy: "network-only"
    });
  }
}
