import { Component, OnInit } from "@angular/core";
import { IHeaderOptions } from "src/app/facades/interfaces/header.interface";
import { IChartIncident } from "src/app/facades/interfaces/chart.interface";
import { ChartService } from "src/app/facades/services/chart/chart.service";
import { IncidentQueriesService } from "src/app/facades/queries/incident/incident-queries.service";
import * as moment from "moment";
import { TranslateService } from "src/app/facades/services/translate.service";
import { ITabs } from "src/app/presentationnal/tabs/facades/interfaces/tabs.interface";
import { IComment } from "src/app/facades/interfaces/comment.interface";
import { IAttachment } from "src/app/facades/interfaces/attachment.interface";
import { IContainerComment } from "src/app/presentationnal/comment/facades/interfaces/container-comment.interface";
import { AuthService } from "src/lib/auth/auth.service";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { ModalDetailIncidentComponent } from "src/app/modal/component/modal-detail-incident/modal-detail-incident.component";
import { EnumUserRoles } from "../../../../facades/interfaces/user.interface";
import { Router } from "@angular/router";

enum dashboardIncidentTabs {
  ALL_INCIDENTS,
  ASSIGNED_INCIDENTS,
  ATTRIBUTED_INCIDENTS,
  REPORTED_INCIDENTS,
  SUBSCRIBED_INCIDENTS
}


@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.css"]
})
export class DashboardPageComponent implements OnInit {

  public optionsHeader: IHeaderOptions = {
    title: "dashboard_pageTitle-txt"
  };
  public tabsData: ITabs[] = [];
  public tabs: ITabs[] = [];
  public histories = [];
  public data: any;
  public selectedTab;


  public firstDate = moment().startOf("month").add(-1, "month").calendar();
  public lastDate = moment();
  public attachmentsAndComments = [];
  public roles: string = localStorage.getItem("roles");




  constructor(
    private _chartSrv: ChartService,
    private _incidentSrv: IncidentQueriesService,
    private _translateSrv: TranslateService,
    private _authSrv: AuthService,
    private _modalSrv: ModalService,
    private _router: Router
  ) { }

  ngOnInit() {
    if (this.roles === EnumUserRoles.READONLY_ADMIN) this._router.navigate(["/incidents"]);
    this.tabsData = [
      { value: dashboardIncidentTabs.ALL_INCIDENTS, label: this._translateSrv.translate("incident_allIncidents-link"), isActive: false, roles: ["ADMIN", "SUPER_ADMIN"] },
      { value: dashboardIncidentTabs.ATTRIBUTED_INCIDENTS, label: this._translateSrv.translate("incident_myResponsabilities-link"), isActive: false, roles: ["MANAGER"] },
      { value: dashboardIncidentTabs.ASSIGNED_INCIDENTS, label: this._translateSrv.translate("incident_myAssignments-link"), isActive: false, roles: ["AGENT", "ASCERTAINING_AGENT"] },
      { value: dashboardIncidentTabs.REPORTED_INCIDENTS, label: this._translateSrv.translate("incident_myIncidents-link"), isActive: false, roles: ["ADMIN", "AGENT", "ASCERTAINING_AGENT", "MANAGER", "SUPER_ADMIN"] },
      { value: dashboardIncidentTabs.SUBSCRIBED_INCIDENTS, label: this._translateSrv.translate("incident_mySubscriptions-link"), isActive: false, roles: ["ADMIN", "AGENT", "ASCERTAINING_AGENT", "MANAGER", "SUPER_ADMIN"] },
    ];
    this.statisticIncident();
    this.tabs = this.buildTabs();
    this.listHistory(dashboardIncidentTabs[this.tabs[0].value]);
    this.listAttachmentsAndComments(dashboardIncidentTabs[this.tabs[0].value]);
  }

  public statisticIncident() {
    this._incidentSrv.getStatisticsIncident({ from: this.firstDate, to: this.lastDate }).subscribe(result => {
      const tmp = result.data["dailyStatistics"];
      this.data = this._chartSrv.formatDataIncident(tmp);
    });
  }

  public changeTabIncident(event) {
    this.selectedTab = <dashboardIncidentTabs>event.find(tab => tab.isActive).value;
    this.listHistory(dashboardIncidentTabs[this.selectedTab]);

  }

  public changeTabComment(event) {
    this.selectedTab = <dashboardIncidentTabs>event.find(tab => tab.isActive).value;
    this.listAttachmentsAndComments(dashboardIncidentTabs[this.selectedTab]);
  }

  public formatDate(date: any) {
    return moment(date).format("L");
  }

  public listHistory(type) {
    this._incidentSrv.getLastHistories({ from: "07/09/2019", type: type, limit: 20 }).subscribe(result => {
      this.histories = result.data["lastHistories"];
    });
  }

  public listAttachmentsAndComments(type) {
    this._incidentSrv.getLastCommentsAndAttachmnents({ from: "07/09/2019", type: type, limit: 10 }).subscribe(result => {
      const tmp = result.data["lastCommentsAndAttachments"];
      this.attachmentsAndComments = [];
      this.formatCommentDatas(tmp["comments"], tmp["attachments"]);
    });
  }

  private formatCommentDatas(comments: IComment[], attachments: IAttachment[]) {
    const allComments: IComment[] | IAttachment[] = [...comments, ...attachments].sort((a, b) => {
      return (a.createdAt > b.createdAt) ? -1 : 1;
    });
    allComments.map(comment => {
      this.attachmentsAndComments.push(this.setCommentData(comment));
    });
    // console.log(this.attachmentsAndComments);
  }

  private setCommentData(comment: IComment | IAttachment): IContainerComment {
    return {
      id: comment.id,
      datas: {
        isPublic: comment.isPublic,
        isComment: !(<IAttachment>comment).filePath,
        incidentId: comment.incidentId,
        title: comment.incident.orgacode + "-" + comment.incident.generatedid
      },
      status: comment.incident.status,
      address: comment.incident.address,
      title: `${this._translateSrv.translate("incident_commentAdded-txt")} ${moment(comment.createdAt).format("DD/MM/YYYY HH:mm")}
      ${this._translateSrv.translate("incident_commentBy-txt")} ${this.formatAuthor(comment)}`,
      comment: comment.comment,
      imageSrc: (<IAttachment>comment).filePath ? (<IAttachment>comment).filePath : "../../../../assets/img/text_message_1x.png"
    };
  }

  /**
   * @description Format author of a comment / attachment for display in comment's / attachment's title
   * @author Quentin Wolfs
   * @private
   * @param {(IComment | IAttachment)} comment
   * @returns {string}
   * @memberof ModalDetailIncidentComponent
   */
  private formatAuthor(comment: IComment | IAttachment): string {
    if (!!comment.user) {
      return `${comment.user.lastName} ${comment.user.firstName}`;
    } else if (!!comment.citizen) {
      return `${this._translateSrv.translate("incident_commentNeightbour-txt")} (${comment.citizen.lastName} ${comment.citizen.firstName}`
        + `${comment.citizen.phone ? " | " + comment.citizen.phone : ""}${comment.citizen.email ? " | " + comment.citizen.email : ""})`;
    } else if (!!comment.external) {
      return `${comment.external.name}`
    }
    else {
      return this._translateSrv.translate("incident_commentNeightbour-txt");
    }
  }

  public buildTabs() {
    const tmpRoles = this._authSrv.getRolesCurrent();
    const tmpArray = [];
    let isOk = false;
    this.tabsData.map(result => {
      isOk = false;
      result.roles.forEach(element => {
        if (tmpRoles.includes(element) && !isOk) {
          tmpArray.push(result);
          isOk = true;
        }
      });
    });
    if (tmpArray) {
      tmpArray[0].isActive = true;
      return tmpArray;
    }
  }

  public openIncidentDetail(incidentId: number, titleId: any) {
    if (typeof incidentId === "string") {
      incidentId = +incidentId;
    }
    const detailIncident = this._modalSrv.openModal(ModalDetailIncidentComponent,
      {
          title: this._translateSrv.translate("incident_detailIncidentNumberX-title", { incidentId: titleId }),
          type: null,
          data: {
            incidentId
          },
          modalStyle: {
            modalWidth: "custom",
          },
          customData: {
            displayCancelButton: false,
            displayConfirmButton: false,
          }
        }
    );
    // TODO Check if we can delete > Useless

  }
}

