import { Component, OnInit, Inject, OnDestroy, SimpleChange } from "@angular/core";
import { ModalContentForm } from "src/app/facades/model/modal-content-form";
import { ModalService, DATA } from "src/app/facades/services/modal/modal.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IDropListItem, IDropListLabels, IDropListData } from "src/app/presentationnal/drop-list/facades/interfaces/drop-list.interface";
import { IGroupInptade, IGroup } from "src/app/facades/interfaces/group.interface";
import { UserQueriesService } from "src/app/facades/queries/user/user-queries.service";
import { EnumUserRoles, IUser, IFilterUsers } from "src/app/facades/interfaces/user.interface";
import { AuthService } from "src/lib/auth/auth.service";
import { OrganisationQueriesService } from "src/app/facades/queries/organisation/organisation-queries.service";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { Subscription } from "rxjs";
import { MANAGER_DROP_LIST_LABELS, AGENT_DROP_LIST_LABELS, ASCERTAINING_AGENT_DROP_LIST_LABELS } from "./modal-create-group-user.config";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { ROLES } from "src/app/facades/enum/roles.enum";
import { IOrganisation } from "src/app/facades/interfaces/organisation.interface";
import { CUSTOM_VALIDATOR } from "src/app/presentationnal/input/facades/custom-validator";

interface IUserGroupModalReceivedData {
  data: {
    group: IGroup
  };
}

@Component({
  selector: "app-modal-create-group-user",
  templateUrl: "./modal-create-group-user.component.html",
  styleUrls: ["./modal-create-group-user.component.css"]
})
export class ModalCreateGroupUserComponent extends ModalContentForm implements OnInit, OnDestroy {
  private _organisationIdSub: Subscription;
  private _needAddingUser: boolean = false;
  private _addingUserType: string = ROLES.MANAGER;
  private _groupData: IGroup;
  public __ROLES = ROLES;
  public managerGroupsData: IDropListData = {linked: [], available: []};
  public agentGroupsData: IDropListData = {linked: [], available: []};
  public ascertainingAgentGroupsData: IDropListData = {linked: [], available: []};
  public organisationOptions: ISelectOption[];
  public managerGroupsLabels: IDropListLabels = MANAGER_DROP_LIST_LABELS;
  public agentGroupsLabels: IDropListLabels = AGENT_DROP_LIST_LABELS;
  public ascertainingAgentGroupsLabels: IDropListLabels = ASCERTAINING_AGENT_DROP_LIST_LABELS;
  public filter: string;
  public organisations;
  public associationFormGroup: FormGroup;
  public checked : Boolean = false;
	public frequencesOptions: ISelectOption[] = [];
  constructor(protected modalSrv: ModalService,
    protected fb: FormBuilder,
    protected _snackBar: SnackService,
    private _userQueriesSrv: UserQueriesService,
    private _authSrv: AuthService,
    private _organisationQueriesSrv: OrganisationQueriesService,
    protected _errorMessageSrv: ErrorMessageService,
    private _translateSrv: TranslateService,
    @Inject(DATA) private _data: IUserGroupModalReceivedData) {
      super(modalSrv, fb, _snackBar, _errorMessageSrv);
      this._groupData = this._data.data.group;
      this.inputErrorsLabelMap = new Map<string, string>([
        [ "nameFr", this._translateSrv.translate("group_nameFr-input")],
        [ "nameEn", this._translateSrv.translate("group_nameEn-input")],
        [ "nameNl", this._translateSrv.translate("group_nameNl-input")],
        [ "nameDe", this._translateSrv.translate("group_nameDe-input")],
        [ "organisationId", this._translateSrv.translate("group_organisation-input")],
        [ "email", this._translateSrv.translate("group_mail-input")]
      ]);
  }

    ngOnInit() {
      this.initForm();
      this.loadUsersList('managerIds');
      this.loadUsersList('agentIds');
      this.loadUsersList('ascertainingAgentIds');
      if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) && (!this._groupData || !this._groupData.id)) {
        this.loadOrganisation();
      }
      this.frequencesOptions = [
        {label: this._translateSrv.translate("every_week-options"), value:"ONE_WEEK"},
        {label: this._translateSrv.translate("every_two_weeks-options"), value:"TWO_WEEK"},
        {label: this._translateSrv.translate("every_month-options"), value:"ONE_MONTH"}
      ]
    }

    ngOnDestroy(): void {
      super.ngOnDestroy();
      if (this._organisationIdSub) { this._organisationIdSub.unsubscribe(); }
    }
    checkValue(){
      if (this._formGroup.value.reminderReport) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    }
    private loadOrganisation() {
      this._organisationQueriesSrv.getOrganisationListQuery().subscribe( result => {
        const resultData: any = result.data;
        if (resultData.organisations) {
          this.organisationOptions = resultData.organisations.map( organisation => {
            return {
              value: organisation.id,
              label: organisation.name
            };
          });
          this._organisationIdSub = this._formGroup.get("organisationId").valueChanges.subscribe( () => {
            this.loadUsersList('managerIds');
            this.loadUsersList('agentIds');
            this.loadUsersList('ascertainingAgentIds');
          });
        }
      }, error => {
        console.log("ERROR GETTING ORGANISATION LIST", {error});
      });
    }

    private initForm() {
      this._formGroup = this.fb.group({
        dailyRepport: this._groupData ? this._groupData.dailyRepport : false,
        reopenIncident: this._groupData ? this._groupData.reopenIncident : false,
        newIncident: this._groupData ? this._groupData.newIncident : false,
        incidentNews: this._groupData ? this._groupData.incidentNews : false,
        solvedIncident: this._groupData ? this._groupData.solvedIncident : false,
        reminderReport: this._groupData ? this._groupData.reminderReport ? this.checked = true : this.checked = false : false,
        frequences : this._groupData ? this._groupData.frequences :  null,
        ...this.setGroupDatas()
      });

      this._formGroup.get("reminderReport").valueChanges.subscribe(res => {
        if(res && this._formGroup.get("frequences").value == null){
          this._formGroup.get("frequences").setValue("ONE_WEEK");
        } else {
          this._formGroup.get("frequences").setValue(null);
        }
      })

      this._formGroup.get("notifyGroup").valueChanges.subscribe(res => {
        if (res) {
          this._formGroup.get("email").setValidators([Validators.required]);
          this._formGroup.get("email").updateValueAndValidity();
        } else {
          this._formGroup.get("email").clearValidators();
          this._formGroup.get("email").updateValueAndValidity();
        }
      });
    }

    private setGroupDatas(): IGroupInptade | any {
      return {
        id: this._groupData ? this._groupData.id : null,
        email: this._groupData ? this._groupData.email : null,
        phone: [this._groupData ? this._groupData.phone : null, Validators.pattern(CUSTOM_VALIDATOR.belgianPhone)],
        isActive: this._groupData ? this._groupData.isActive : true,
        notifyGroup: this._groupData ? this._groupData.notifyGroup : false,
        notifyMembers: this._groupData ? this._groupData.notifyMembers : false,
        organisationId: this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN) ?
          [this._groupData ? this._groupData.organisationId : null, Validators.required] :
          this._groupData ? this._groupData.organisationId : null,
        nameFr: [this._groupData && this._groupData.translation && this._groupData.translation.fr ?
          this._groupData.translation.fr.name : null, Validators.required],
        nameNl: [this._groupData && this._groupData.translation && this._groupData.translation.nl ?
          this._groupData.translation.nl.name : null, Validators.required],
        nameEn: [this._groupData && this._groupData.translation && this._groupData.translation.en ?
          this._groupData.translation.en.name : null, Validators.required],
        nameDe: [this._groupData && this._groupData.translation && this._groupData.translation.de ?
          this._groupData.translation.de.name : null, Validators.required],
      };
    }

    private loadUsersList(keyToCheck: string) {
      // const keyToCheck: string = isManager ? "managerIds" : "agentIds";
      if (this._formGroup.get("organisationId").value || !this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)) {
        const roles: EnumUserRoles = keyToCheck === "managerIds" ? EnumUserRoles.MANAGER : (keyToCheck === "agentIds"? EnumUserRoles.AGENT : EnumUserRoles.ASCERTAINING_AGENT);
        const queryData: IFilterUsers = {
          roles: [roles]
        };
        if (this._authSrv.getRolesCurrent().includes(EnumUserRoles.SUPER_ADMIN)) {
          queryData.organisationId = this._formGroup.get("organisationId").value;
        }
        this._userQueriesSrv.getUsersList(queryData).subscribe(result => {
          const resultData: any = result.data;
          if ( resultData.users) {
            if (keyToCheck === "managerIds") {
              this.managerGroupsData = this.setDropListData(resultData.users, keyToCheck);
            } else if(keyToCheck === "agentIds") {
              this.agentGroupsData = this.setDropListData(resultData.users, keyToCheck);
            } else {
              this.ascertainingAgentGroupsData = this.setDropListData(resultData.users, keyToCheck);
            }
          }
        }, error => {
          console.log(`ERROR LOADING ${roles}`, {error});
        });
      } else {
        if (keyToCheck === "managerIds") {
          this.managerGroupsData = this.setDropListData([], keyToCheck);
        } else if(keyToCheck === "agentIds") {
          this.agentGroupsData = this.setDropListData([], keyToCheck);
        } else {
          this.ascertainingAgentGroupsData = this.setDropListData([], keyToCheck);
        }
      }
    }

    private setDropListData(users: IUser[], keyToCheck: string): IDropListData {
      let newDropListData: IDropListData;
      if (this._groupData) {
        newDropListData = {
          linked: this.setDropListItedm(users.filter(user => this._groupData[keyToCheck].indexOf(user.id) !== -1)),
          available: this.setDropListItedm(users.filter(user => this._groupData[keyToCheck].indexOf(user.id) === -1))
        };
      } else {
        newDropListData = {
          linked: [],
          available: this.setDropListItedm(users)
        };
      }
      return newDropListData;
    }

    private setDropListItedm(users: any[]): IDropListItem[] {
      return users.map( user => {
        return {
          id: user.id,
          name: `${user.lastName} ${user.firstName}`
        };
      });
    }

    public onAddLinkedData(userType: string = ROLES.MANAGER) {
      this._needAddingUser = true;
      this._addingUserType = userType;
      this.confirmModal();
    }

    private formatGroupData(): IGroupInptade {
      const tempDataToDelete: string[] = ["nameNl", "nameFr", "nameDe", "nameEn"];
      const groupData: IGroupInptade = {
        ...this._formGroup.value,
        managerIds: this.managerGroupsData.linked.map( user => {
          return user.id;
        }),
        agentIds: this.agentGroupsData.linked.map( user => {
          return user.id;
        }),
        ascertainingAgentIds: this.ascertainingAgentGroupsData.linked.map( user => {
          return user.id;
        }),
        name: this._formGroup.get("nameFr").value,
        translation: {
          fr: {name: this._formGroup.get("nameFr").value},
          en: {name: this._formGroup.get("nameEn").value},
          de: {name: this._formGroup.get("nameDe").value},
          nl: {name: this._formGroup.get("nameNl").value},
        }
      };
      tempDataToDelete.forEach( key => {
        delete groupData[key];
      });
      return groupData;
    }

    public confirmModal(): void {
      if ( this._formGroup.valid || this._needAddingUser) {
        this.save();
        this.modalSrv.closeModal();
      } else {
        this.markFormGroupTouched(this._formGroup);
        this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
      }
    }

    public save () {
      const objData = {
        confirm: true,
        data: this.formatGroupData(),
        needAddingUser: this._needAddingUser,
        userType: this._addingUserType
      };
      this.afterClosed.next(objData);
    }

    public search(event){
      this.filter = event;
      this.organisations = this._organisationQueriesSrv.getOrganisationListQuery({name: this.filter? this.filter: null}).subscribe(result => {
        const resultData: {organisations: IOrganisation[]} = <any>result.data;
        if (resultData && resultData.organisations && resultData.organisations.length > 0) {
          this.organisationOptions = resultData.organisations.filter(o => o.isActive).map( organisation => {
            return {
              value: organisation.id,
              label: organisation.name
            };
          });
          this._formGroup.get("organisationId").patchValue(resultData.organisations[0].id);
        }
      }, error => {
        console.log("ERROR LOADING ORGANISATION", {error});
      })
    }
  
    public searchFieldMap = new Map([
        ["organisationsIds", "name"],
    ])

}