import { EnumIncidentStatuses } from "src/app/facades/interfaces/incident.interface";

export const PinMap = new Map([
    [EnumIncidentStatuses.CREATED, {
        type: "picture-marker",
        url: "../../../../assets/img/newPins/admin_created_simple.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }],
    [EnumIncidentStatuses.IN_PROGRESS, {
        type: "picture-marker",
        url: "../../../../assets/img/newPins/agent_created_simple.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }],
    [EnumIncidentStatuses.CLOSED, {
        type: "picture-marker",
        url: "../../../../assets/img/newPins/ambassador_created_simple.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }],
    [EnumIncidentStatuses.DISMISSED, {
        type: "picture-marker",
        url: "../../../../assets/img/newPins/citizen_created_simple.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }],
    [EnumIncidentStatuses.SOLVED, {
        type: "picture-marker",
        url: "../../../../assets/img/newPins/agentbis_created_simple.png",
        height: "30px",
        width: "30px",
        color: [51, 204, 51, 0.3]
    }]
]);