import { Component, Input, Renderer2, EventEmitter, Output, SimpleChanges, OnChanges,
   AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { AInputs } from "../../facades/inputs";
import { ISelectOption } from "../../facades/interfaces/selectOption.interface";
import { ISearchCreateOption } from "../../facades/interfaces/searchCreateOption.interface";
import { IClickOutsideComponent } from "src/app/facades/directives/click-outside.directive";
import { ErrorMessageService } from "../../facades/services/error-message.service";

@Component({
  selector: "app-select-search-input",
  templateUrl: "./select-search-input.component.html",
  styleUrls: ["./select-search-input.component.scss"]
})
export class SelectSearchInputComponent extends AInputs implements OnChanges, IClickOutsideComponent, AfterViewInit {
  public valueSelect;
  public isClosed = true;
  private debouncer: Subject<any> = new Subject();
  private isClear = false;
  private _handler: any;
  public isActive: boolean = false;
  public disable: boolean = false;
  public labelSelected: string = "";
  public selectLabel: string = "";
  private _valueSelectInitialize: boolean = false;
  @Input() selectName: string;
  @Input() options: ISelectOption[];
  @Input() autoSelect = true;

  @Output() public search: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild("inputFocus", {static: true}) inputFocus: ElementRef;

  constructor(protected renderer: Renderer2,
              private _elRef: ElementRef,
              protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
    // Send This.search for the parent
    this.debouncer.pipe(debounceTime(200)).subscribe( value => {
      this.search.emit(value);
    });
  }

  ngOnInit() {
    const tmp = this.options.filter((d) => d.value === this.group.get(this.name).value);
    if(tmp[0]) this.valueSelect = tmp[0].label;
    if (tmp.length === 0 && this.autoSelect && this.options[0]) {
      this.valueSelect = this.options[0].label;
      this.group.get(this.name).patchValue(this.options[0].value);
      this._valueSelectInitialize = true;
    }

    this.group.get(this.name).valueChanges.subscribe(res => {
      if(!res) this.valueSelect = null;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (this.options.length === 1 && this.autoSelect ) {
      this.autoSelect = false;
      this.close(this.options[0]);
      this.inputFocus.nativeElement.blur();
    }
    if (changes && changes.options && changes.options.currentValue && changes.options.currentValue.length > 0 && !this._valueSelectInitialize) {
      const tmp = this.options.filter((d) => d.value === this.group.get(this.name).value);
      this.valueSelect = tmp && tmp[0] && tmp[0].label;
      this.autoSelect = false;
      this._valueSelectInitialize = true;
    }
    if (this.isClear) {
      this.isClear = false;
      this.inputFocus.nativeElement.focus();
    }
    if (changes.group && !changes.group.firstChange) {
      this.valueSelect = "";
      this.search.emit(this.valueSelect);
    }
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      setTimeout(() => {
        this.open();
        this.inputFocus.nativeElement.focus();
      }, 400);
    }
  }

  public showInfo() {
    if (!this.isClosed) { this.close(null); }
  }

  public open() {
    this.inputReference.nativeElement.focus();
    this.isClosed = false;
  }

  public close(option: any): void {
    if (option) {
      this.group.get(this.name).patchValue(option.value);
      this.group.get(this.name).markAsTouched();
      this.valueSelect = option.label;
      this.debouncer.next(this.valueSelect);
      
    }
    this.isClosed = true;
  }

  clearSelect(event: Event) {
    this.isClear = true;
    if (event) { event.stopPropagation(); }
    this.group.get(this.name).patchValue(null);
    this.valueSelect = "";
    this.search.emit(this.valueSelect);
    this.open();
  }
  public findInput(event) {
    this.debouncer.next(this.valueSelect); // Send value for send "this.search" before X seconds
  }

  public showList() {
    if(!this.disable){
      if ( this._handler ) { this._handler(); }
      if ( !this.isActive) {
        this._handler = this.renderer.listen(document, "click", event => this.checkIsElement(event));
      } 
      this.isActive = !this.isActive;
    }
  }

  private checkIsElement(event: any) {
    if (!this._elRef.nativeElement.contains(event.target) && this.isActive) {
      this.showList();
    }
  }

}
