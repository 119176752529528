export const LOGIN_ERRORS_MAP = new Map([
    [40000, "badRequest-error"],
    [40024, "emailAlreadyRegistered-error"],
    [40027, "noEmailConfirmPendingForThisEmail-error"],
    [40028, "codeNotValid-error"],
    [40029, "codeExpired-error"],
    [40030, "passwordMustContainXCharacters-error"],
    [40103, "noUserExist-error"],
    [40101, "authenticationFail-error"],
    [40102, "missingToken-error"]
]);