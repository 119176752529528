import { Component, OnInit, Inject } from "@angular/core";
import { ModalContentForm } from "src/app/facades/model/modal-content-form";
import { ModalService, DATA } from "src/app/facades/services/modal/modal.service";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { ISelectOption } from "src/app/presentationnal/input/facades/interfaces/selectOption.interface";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";
import { headerTableDefaultIncident } from "src/app/facades/configs/incident-list-filters";

@Component({
  selector: "app-modal-choice-header",
  templateUrl: "./modal-choice-header.component.html",
  styleUrls: ["./modal-choice-header.component.css"]
})
export class ModalChoiceHeaderComponent extends ModalContentForm implements OnInit {

  public fieldsBuilder =  [];
  public fieldsCurrent = [];
  public fieldsDefault = [];
  public TYPE_BUTTON_ENUM = TYPE_BUTTON;
  public resetColumnButtonTitle = "";
  constructor(
    protected _modalSrv: ModalService,
    protected _fb: FormBuilder,
    protected _snackBar: SnackService,
    private _translateSrv: TranslateService,
    @Inject(DATA) private _data,
    protected _errorMessageSrv: ErrorMessageService) {
      super(_modalSrv, _fb, _snackBar, _errorMessageSrv);
      this.fieldsBuilder = this._data.data.defaultHeader;
      this.fieldsCurrent = this._data.data.currentHeader;
      this.fieldsDefault = this._data.data.headerOptionsDefault;
    }

  ngOnInit() {
    if (this._data && this._data.data && this._data.data.resetColumnButtonTitle) {
      this.resetColumnButtonTitle = this._data.data.resetColumnButtonTitle;
    }
    this.buildForm();
    this.updateData();
  }

  public confirmModal() {
    if (this._formGroup.valid) {
      this.save();
      this._modalSrv.closeModal();
    }
    }

  protected save(): void {
    this.afterClosed.next(this._formGroup);
  }

  private buildForm() {
    this._formGroup =  this._fb.group({});
    const group = {};
     this.fieldsBuilder.forEach( result => {
       group[result] = new FormControl("");
     });
     this._formGroup = new FormGroup(group);
  }

  public translateLabel(item) {
    const key = headerTableDefaultIncident.find(header => header.key == item);
    return key ? this._translateSrv.translate(key.translate) : item;
  }

  updateData() {
    this.fieldsCurrent.forEach( result => {
      const control = this._formGroup.get(result.key);
      if (!!control) { control.setValue(true); }
    });
  }

  /**
   * resetFields
   */
  public resetFields() {
    this.fieldsBuilder.forEach(result => {
      this._formGroup.get(result).setValue(false);
    });

    this.fieldsDefault.forEach(result => {
      this._formGroup.get(result).setValue(true);
    });
  }

}
