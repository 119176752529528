import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { CONVERT_LAMBERT_TO_LAT_LONG } from "./conversion.queries";

@Injectable({
  providedIn: "root"
})
export class ConversionQueriesService {

  constructor(private _apollo: Apollo) { }

  public convertLambertToLatLong(x: number, y: number) {
    return this._apollo.query({
      query: CONVERT_LAMBERT_TO_LAT_LONG,
      variables: {x, y},
      fetchPolicy: "no-cache"
    });
  }
}
