
import { Component, OnInit, Input } from "@angular/core";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";
import { STATUS_ALERTS } from "src/app/facades/enum/status.enum";

@Component({
  selector: "app-snack",
  templateUrl: "./snack.component.html",
  styleUrls: ["./snack.component.css"]
})
export class SnackComponent implements OnInit {
  @Input() public status: string;
  @Input() public snackUuid: number;
  @Input() public delay: number;
  public statusSnack = STATUS_ALERTS;
  private _interval: any;
  public generatedClass: string = "";
  public iconClass: string = "";
  public average: any;
  constructor(public _snackService: SnackService) {
  }

  ngOnInit() {
    this.generatedClass += this.status ? " snack__" + this.status.toLowerCase() : "";
    this.setAverageInterval();
    this.CheckCssIcon(this.status);
  }

  private setAverageInterval() {
    let count = 0;
    this._interval = setInterval(() => {
      count += 100;
      this.average = (count / this.delay) * 100;
      if (count >= this.delay) {
        clearInterval(this._interval);
      }
    }, 100);
  }

  public CheckCssIcon(name: string) {
    switch (name.toUpperCase()) {
      case this.statusSnack.DANGER:
          this.iconClass = "fa-exclamation-triangle";
        break;
      case this.statusSnack.WARNING:
          this.iconClass = "fa-check-circle";
        break;
      case this.statusSnack.INFO:
          this.iconClass = "fa-info-circle";
        break;
      case this.statusSnack.SUCCESS:
          this.iconClass = "fa-check-circle";
        break;
      default:
          this.iconClass = "fa-hashtag";
        break;
    }
  }


  public removeSnack() {
    this._snackService.remove(this.snackUuid);
  }

}
