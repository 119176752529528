import { Component, Renderer2, Input } from "@angular/core";
import { AInputs } from "../../facades/inputs";
import { ErrorMessageService } from "../../facades/services/error-message.service";

@Component({
  selector: "app-number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.scss"]
})
export class NumberInputComponent extends AInputs {

  @Input() suffix: string;
  constructor(public renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
  }

}
