import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { IIncidentInput, IIncidentUpdate, IIncidentColumnFilter, EnumIncidentStatuses } from "../../interfaces/incident.interface";
import { CREATE_INCIDENT, GET_INCIDENTS, GET_INCIDENT, UPDATE_INCIDENT, SET_INCIDENT_ALL_PUBLIC, SET_INCIDENT_STATUS, SET_INCIDENT_CATEGORY, SET_INCIDENT_COMPLEMENTARY_ADDRESS, SET_INCIDENT_PLANNED_DATE, SET_INCIDENT_PRIORITY, SET_INCIDENT_VISIBILITY, SET_INCIDENT_MASKED, SET_INCIDENT_THIRD_PARTY_RESPONSABILITY, SET_INCIDENT_PRIVATE_LOCATION, SET_INCIDENT_MULTIPLE_LOCATIONS, ALL_INCIDENTS, MY_ATTRIBUTED_INCIDENTS, MY_ASSIGNED_INCIDENTS, MY_SUBSCRIBED_INCIDENTS, MY_REPORTED_INCIDENTS, MY_TRANSFERRED_INCIDENTS, ALL_INCIDENTS_FOR_MAP, MY_ATTRIBUTED_INCIDENTS_FOR_MAP, MY_ASSIGNED_INCIDENTS_FOR_MAP, MY_SUBSCRIBED_INCIDENTS_FOR_MAP, MY_REPORTED_INCIDENTS_FOR_MAP, MY_TRANSFERRED_INCIDENTS_FOR_MAP, CHECK_INCIDENT_ID, ACCEPT_INCIDENT, ASSIGN_INCIDENT, TRANSFERT_INCIDENT_TO_GROUP, TRANSFERT_INCIDENT_TO_ORGANISATION, DISMISS_INCIDENT, CLOSE_INCIDENT, REOPEN_INCIDENT, CAN_MODIFY_INCIDENT, SOLVE_INCIDENT, GET_POSSIBLE_DUPLICATES, ADD_COMMENTS_AND_ATTACHMENTS, MERGE_INCIDENTS, GET_DUPLICATE, INCIDENTS_BY_IDS, INCIDENTS_BY_IDS_FOR_MAP, MUNICIPALITY_LIST, SEND_PDFMAIL, GET_EXISTING_YEARS, GET_STATISTICS_INCIDENT, GET_LAST_HISTORIES, CHECK_LOCATION, SET_ALL_INCIDENTS_MASKED_BY_ORGANISATION, CHECK_INCIDENT_BY_CUSTOM_ID, GET_DUPLICATE_BY_CUSTOM_ID, EXPORT_XLS, GET_MY_STATS } from "./incident.queries";
import { ICommentInput, ICommentUpdate } from "../../interfaces/comment.interface";
import { CREATE_COMMENT, UPDATE_COMMENT, DELETE_COMMENT, LAST_COMMENTS_AND_ATTACHMENTS } from "./comment.queries";
import { IPagination } from "../../interfaces/pagination.interface";
import { EnumIncidentListFilter } from "../../enum/incident-list-filters.enum";
import { IAttachmentInput, IAttachmentUpdate } from "../../interfaces/attachment.interface";
import { CREATE_ATTACHMENT, DELETE_ATTACHMENT, UPDATE_ATTACHMENT } from "./attachment.queries";
import { ApolloQueryResult } from "apollo-client";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export enum EnumUpdateQueryType {
  STATUS = "STATUS",
  CATEGORY = "CATEGORY",
  COMPLEMENTARY_ADDRESS = "COMPLEMENTARY_ADDRESS",
  PLANNED_DATE = "PLANNED_DATE",
  PRIORITY = "PRIORITY",
  VISIBILITY = "VISIBILITY",
  MASKED = "MASKED",
  THIRD_PARTY_RESPONSABILITY = "THIRD_PARTY_RESPONSABILITY",
  PRIVATE_LOCATION = "PRIVATE_LOCATION",
  MULTIPLE_LOCATIONS = "MULTIPLE_LOCATIONS",
  SET_ALL_PUBLIC = "SET_ALL_PUBLIC"
}

@Injectable({
  providedIn: "root"
})
export class IncidentQueriesService {
  private _lastIncidentListLoaded: { query: any, variables: any } = {
    query: ALL_INCIDENTS,
    variables: {},
  };
  private _incidentPagination: IPagination = { limit: 20, page: 1 };
  private _baseListPagination: IPagination = { limit: 20, page: 1 };
  private _baseMapPagination: IPagination = { limit: 0, page: 1 };

  constructor(private _apollo: Apollo) { }

  public get lastIncidentListLoaded() { return this._lastIncidentListLoaded; }
  public get incidentPagination(): IPagination { return this._incidentPagination; }
  public set incidentPagination(pagination: IPagination) { this._incidentPagination = pagination; }
  public set baseListPagination(pagination: IPagination) { this._baseListPagination = pagination; }


  public resetIncidentPagination() {
    this._incidentPagination = {
      ...this._incidentPagination,
      ...this._baseListPagination
    };
  }
  public switchPaginationTableMap(isDisplayedMap: boolean = false) {
    this._incidentPagination = isDisplayedMap ?
      {
        ...this._incidentPagination,
        ...this._baseMapPagination
      } : {
        ...this._incidentPagination,
        ...this._baseListPagination
      };
  }

  public getIncidents() {
    return this._apollo.watchQuery({
      query: GET_INCIDENTS,
      fetchPolicy: "no-cache"
    });
  }

  private getIncidentsListQuery(type: EnumIncidentListFilter, displayMap?: boolean) {
    switch (type) {
      case EnumIncidentListFilter.MY_ATTRIBUTE_INCIDENTS:
        return displayMap ? MY_ATTRIBUTED_INCIDENTS_FOR_MAP : MY_ATTRIBUTED_INCIDENTS;
      case EnumIncidentListFilter.MY_ASSIGNED_INCIDENTS:
        return displayMap ? MY_ASSIGNED_INCIDENTS_FOR_MAP : MY_ASSIGNED_INCIDENTS;
      case EnumIncidentListFilter.MY_SUBSCRIBED_INCIDENTS:
        return displayMap ? MY_SUBSCRIBED_INCIDENTS_FOR_MAP : MY_SUBSCRIBED_INCIDENTS;
      case EnumIncidentListFilter.MY_REPORTED_INCIDENTS:
        return displayMap ? MY_REPORTED_INCIDENTS_FOR_MAP : MY_REPORTED_INCIDENTS;
      case EnumIncidentListFilter.MY_TRANSFERRED_INCIDENTS:
        return displayMap ? MY_TRANSFERRED_INCIDENTS_FOR_MAP : MY_TRANSFERRED_INCIDENTS;
      case EnumIncidentListFilter.INCIDENT_BY_IDS:
        return displayMap ? INCIDENTS_BY_IDS_FOR_MAP : INCIDENTS_BY_IDS;
      default:
        return displayMap ? ALL_INCIDENTS_FOR_MAP : ALL_INCIDENTS;
    }
  }

  public getMunicipalityList(filter: any = null): Observable<ApolloQueryResult<string[]>> {
    return this._apollo.query({
      query: MUNICIPALITY_LIST,
      variables: {
        filter
      },
      fetchPolicy: "no-cache"
    });
  }

  public loadPlanningIncidents(queryType: EnumIncidentListFilter, plannedDate: any){
    return this._apollo.query({
      query: this.getIncidentsListQuery(queryType),
      fetchPolicy: "no-cache",
      variables: {
        filter: {
          isPlanned: true,
          plannedDate
        },
        pagination: {
          ...this._incidentPagination,
          limit: 20,
          page: 1
        }
      }
    });
  }


  public getIncidentList(type: EnumIncidentListFilter, incidentsIds: Array<number>, filter?: IIncidentColumnFilter, year?: number, pagination?: any, displayMap?: boolean) {
    this._lastIncidentListLoaded = {
      query: this.getIncidentsListQuery(type, displayMap),
      variables: {
        filter,
        pagination: pagination? pagination : this._incidentPagination,
        year
      },
    };
    if (incidentsIds) {
      this._lastIncidentListLoaded.variables["ids"] = incidentsIds;
    }
    return this._apollo.watchQuery({
      ...this._lastIncidentListLoaded,
      fetchPolicy: "network-only"
    });
  }

  public getVisibilityIncident(type: EnumIncidentListFilter, incidentsIds: number[], filter?: IIncidentColumnFilter, year?: number){
    const variables = {
      filter: {
        ...filter,
        isMasked: true
      },
      pagination: {
        ...this._incidentPagination,
        limit: 0,
        page: 1
      },
      year
    };
    if (!!incidentsIds) { variables["ids"] = incidentsIds; }

    return this._apollo.query({
      query: this.getIncidentsListQuery(type),
      variables,
      fetchPolicy: "no-cache"
    });
  }

  public createIncident(incident: IIncidentInput) {
    return this._apollo.mutate({
      mutation: CREATE_INCIDENT,
      variables: { incident },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        ...this._lastIncidentListLoaded
      }]
    });
  }

  public checkIncidentByCustomId(id: string) {
    return this._apollo.query({
      query: CHECK_INCIDENT_BY_CUSTOM_ID,
      variables: { id },
      fetchPolicy: "no-cache"
    });
  }

  public getIncident(id: number) {
    return this._apollo.query({
      query: GET_INCIDENT,
      variables: { id },
      fetchPolicy: "no-cache"
    });
  }

  public canModifyIncident(id: number) {
    return this._apollo.query({
      query: CAN_MODIFY_INCIDENT,
      variables: { id },
      fetchPolicy: "no-cache"
    });
  }

  public watchIncident(id: number) {
    return this._apollo.watchQuery({
      query: GET_INCIDENT,
      variables: { id },
      fetchPolicy: "network-only"
    });
  }

  // Not use >> now it's "updateIncidentValue"
  public updateIncident(id: number, incident: IIncidentUpdate) {
    return this._apollo.mutate({
      mutation: UPDATE_INCIDENT,
      variables: { id, incident },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_INCIDENT,
        variables: { id }
      }]
    });
  }

  private getMutationToUse(value: EnumUpdateQueryType) {
    switch (value) {
      case EnumUpdateQueryType.STATUS:
        return SET_INCIDENT_STATUS;
      case EnumUpdateQueryType.CATEGORY:
        return SET_INCIDENT_CATEGORY;
      case EnumUpdateQueryType.COMPLEMENTARY_ADDRESS:
        return SET_INCIDENT_COMPLEMENTARY_ADDRESS;
      case EnumUpdateQueryType.PLANNED_DATE:
        return SET_INCIDENT_PLANNED_DATE;
      case EnumUpdateQueryType.PRIORITY:
        return SET_INCIDENT_PRIORITY;
      case EnumUpdateQueryType.VISIBILITY:
        return SET_INCIDENT_VISIBILITY;
      case EnumUpdateQueryType.MASKED:
        return SET_INCIDENT_MASKED;
      case EnumUpdateQueryType.THIRD_PARTY_RESPONSABILITY:
        return SET_INCIDENT_THIRD_PARTY_RESPONSABILITY;
      case EnumUpdateQueryType.PRIVATE_LOCATION:
        return SET_INCIDENT_PRIVATE_LOCATION;
      case EnumUpdateQueryType.MULTIPLE_LOCATIONS:
        return SET_INCIDENT_MULTIPLE_LOCATIONS;
      case EnumUpdateQueryType.SET_ALL_PUBLIC:
        return SET_INCIDENT_ALL_PUBLIC;
    }
  }

  // Variables content id (incidentId) and the value for the request
  public updateIncidentValue(valueToUpdate: EnumUpdateQueryType, variables: any) {
    return this._apollo.mutate({
      mutation: this.getMutationToUse(valueToUpdate),
      variables,
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_INCIDENT,
        variables: { id: variables.id }
      }]
    });
  }

  public acceptIncident(incidentId: number) {
    return this._apollo.mutate({
      mutation: ACCEPT_INCIDENT,
      variables: { incidentId },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_INCIDENT,
        variables: { id: incidentId }
      }]
    });
  }

  private getIncidentStatusQuery(type: EnumIncidentStatuses | any) {
    switch (type) {
      case EnumIncidentStatuses.DISMISSED:
        return DISMISS_INCIDENT;
      case EnumIncidentStatuses.CLOSED:
        return CLOSE_INCIDENT;
      case EnumIncidentStatuses.SOLVED:
        return SOLVE_INCIDENT;
      // case EnumIncidentStatuses.REOPEN_INCIDENT:
      //   return REOPEN_INCIDENT;
    }
  }

  public updateIncidentStatus(type: EnumIncidentStatuses, incidentId: number, reason: string) {
    const variables: any = type === EnumIncidentStatuses.SOLVED ? { incidentId, infos: reason } : { incidentId, reason };
    if (variables.infos === null || variables.infos === "") { delete variables.infos; }

    return this._apollo.mutate({
      mutation: this.getIncidentStatusQuery(type),
      variables,
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_INCIDENT,
        variables: { id: incidentId }
      }]
    });
  }

  public reopenIncidentStatus(incidentId: number) {
    return this._apollo.mutate({
      mutation: REOPEN_INCIDENT,
      variables: { incidentId },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_INCIDENT,
        variables: { id: incidentId }
      }]
    });
  }

  public assignIncident(incidentId: number, groupId: number, agentId: number) {
    return this._apollo.mutate({
      mutation: ASSIGN_INCIDENT,
      variables: { incidentId, groupId, agentId },
      fetchPolicy: "no-cache"
    });
  }

  public transferIncidentToGroup(incidentId: number, groupId: number) {
    return this._apollo.mutate({
      mutation: TRANSFERT_INCIDENT_TO_GROUP,
      variables: { incidentId, groupId },
      fetchPolicy: "no-cache"
    });
  }

  public transferIncidentToOrganisation(incidentId: number, organisationId: number) {
    return this._apollo.mutate({
      mutation: TRANSFERT_INCIDENT_TO_ORGANISATION,
      variables: { incidentId, organisationId },
      fetchPolicy: "no-cache"
    });
  }

  /* comment methods */
  public createComment(comment: ICommentInput) {
    return this._apollo.mutate({
      mutation: CREATE_COMMENT,
      variables: { comment },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_INCIDENT,
        variables: { id: comment.incidentId }
      }]
    });
  }

  public createAttachment(attachment: IAttachmentInput, file: File) {
    return this._apollo.mutate({
      mutation: CREATE_ATTACHMENT,
      variables: { attachment, file },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_INCIDENT,
        variables: { id: attachment.incidentId }
      }]
    });
  }

  public updateComment(id: number, comment: ICommentUpdate) {
    return this._apollo.mutate({
      mutation: UPDATE_COMMENT,
      variables: { id, comment },
      fetchPolicy: "no-cache"
    });
  }

  public updateAttachment(id: number, attachment: IAttachmentUpdate) {
    return this._apollo.mutate({
      mutation: UPDATE_ATTACHMENT,
      variables: { id, attachment },
      fetchPolicy: "no-cache"
    });
  }

  public deleteComment(id: number) {
    return this._apollo.mutate({
      mutation: DELETE_COMMENT,
      variables: { id },
      fetchPolicy: "no-cache"
    });
  }

  public deleteAttachment(id: number) {
    return this._apollo.mutate({
      mutation: DELETE_ATTACHMENT,
      variables: { id },
      fetchPolicy: "no-cache"
    });
  }

  public getPossibleDuplicate(position: any, categoryId: number) {
    return this._apollo.query({
      query: GET_POSSIBLE_DUPLICATES,
      variables: {
        position,
        categoryId
      },
      fetchPolicy: "no-cache"
    });
  }

  public addCommentsAndAttachments(variables) {
    return this._apollo.mutate({
      mutation: ADD_COMMENTS_AND_ATTACHMENTS,
      variables,
      fetchPolicy: "no-cache",
      refetchQueries: [{
        ...this._lastIncidentListLoaded
      }]
    });
  }

  public mergeIncidents(fromIncidentId: number, toIncidentId: number) {
    return this._apollo.mutate({
      mutation: MERGE_INCIDENTS,
      variables: {
        fromIncidentId,
        toIncidentId
      },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        ...this._lastIncidentListLoaded
      }]
    });
  }

  public getDuplicateIncident(baseIncidentId: number, duplicateId: string) {
    return this._apollo.query({
      query: GET_DUPLICATE_BY_CUSTOM_ID,
      variables: {
        baseIncidentId,
        duplicateId
      },
      fetchPolicy: "no-cache",
    });
  }

  public sendPdfMail(incidentId: number, addresses: string[], isPro: boolean, isIntern: boolean, subject: string, message: string) {
    return this._apollo.mutate({
      mutation: SEND_PDFMAIL,
      variables: {
        incidentId,
        addresses,
        isPro,
        isIntern,
        subject,
        message
      },
      fetchPolicy: "no-cache",
    });
  }

  public getExistingYears() {
    return this._apollo.query({
      query: GET_EXISTING_YEARS,
      fetchPolicy: "cache-first"
    });
  }

  public getStatisticsIncident(filter: any) {
    return this._apollo.query({
      query: GET_STATISTICS_INCIDENT,
      variables: {
        filter: filter,
      },
      fetchPolicy: "no-cache"
    });
  }
  public getLastHistories(filter: any) {
    return this._apollo.query({
      query: GET_LAST_HISTORIES,
      variables: {
        filter: filter,
      },
      fetchPolicy: "no-cache",
    });
  }

  public getMyStats(filter: any) {
    return this._apollo.query({
      query: GET_MY_STATS,
      variables: {
        filter: filter,
      },
      fetchPolicy: "no-cache",
    });
  }

  public getLastCommentsAndAttachmnents(filter: any) {
    return this._apollo.query({
      query: LAST_COMMENTS_AND_ATTACHMENTS,
      variables: {
        filter: filter,
      },
      fetchPolicy: "no-cache",
    });
  }

  public checkLocation(x: number, y: number, categoryId?: number): Observable<{ organisationId: number, message?: string }> {
    return this._apollo.query({
      query: CHECK_LOCATION,
      variables: { x, y, categoryId },
      fetchPolicy: "no-cache",
    }).pipe(map(res => {
      return <{ organisationId: number, message?: string }>res.data["checkLambertLocation"];
    }));
  }

  public setAllIncidentsMaskedByOrganisation(isMasked: boolean, incidentIds){
    return this._apollo.mutate({
      mutation: SET_ALL_INCIDENTS_MASKED_BY_ORGANISATION,
      variables: {
        isMasked,
        incidentIds
      },
      fetchPolicy: "no-cache",
    })
  }

  public exportXls(queryType: EnumIncidentListFilter, incidentsIds: number[], tableHeaders: any[], filter?: IIncidentColumnFilter, year?: number) {
    const variables = {
      filter,
      year,
      queryType,
      tableHeaders,
      pagination: {
        ...this._incidentPagination,
        limit: 0,
        page: 1
      },
    };
    if (!!incidentsIds) { variables["ids"] = incidentsIds; }

    return this._apollo.query({
      query: EXPORT_XLS,
      variables,
      fetchPolicy: "no-cache"
    });
  }
}
