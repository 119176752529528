import { NgModule } from "@angular/core";
import { Routes, RouterModule, UrlMatcher } from "@angular/router";
import { LoginPageComponent } from "./container/pages/login-page/login-page.component";
import { UserPageComponent } from "./container/pages/users/user-page/user-page.component";
import { UserGroupListComponent } from "./container/pages/users-groups/user-group-list/user-group-list.component";
import { OrganisationListComponent } from "./container/pages/organisation/organisation-list/organisation-list.component";
import { IncidentListComponent } from "./container/pages/incident/incident-list/incident-list.component";
import { AuthService } from "src/lib/auth/auth.service";
import { DispatchingPage } from "./container/pages/dispatching/dispatching-page.component";
import { DispatchingCategoriesPageComponent } from "./container/pages/dispatching-categories-page/dispatching-categories-page.component";
import { DispatchingGroupesPageComponent } from "./container/pages/dispatching-groupes-page/dispatching-groupes-page.component";
import { CategoriesPageComponent } from "./container/pages/categories/categories-page.component";
import { DashboardPageComponent } from "./container/pages/dashboard/dashboard-page/dashboard-page.component";
import { TranslationPageComponent } from "./container/pages/translations/translation-page/translation-page.component";
import { CopywritingPage } from './container/pages/copywriting/copywriting.component';
import { CitizenPageComponent } from './container/pages/citizens/citizens-page.component';
import { PlanningPageComponent } from "./container/pages/planning/planning-page/planning-page.component";
import { ArchivingPageComponent } from './container/pages/archiving/archiving-page.component';
import { ExternalLoginComponent } from "./container/pages/login-page-wep/login-page-external.component";
import {AccountPageComponent} from "./container/pages/account/account-page/account-page.component";
import { HistoryComponent } from "./container/pages/history/history.component";
import { ReportingPageComponent } from "./container/pages/reporting/reporting-page/reporting-page.component";
import { ThemesPageComponent } from "./container/pages/themes/themes-page.component";


const routes: Routes = [
  { path: "",
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: "wep",
    component: ExternalLoginComponent
  },
  {
    path: "login",
    component: LoginPageComponent,
    children: [
      { path: "askReset", component: LoginPageComponent },
      { path: "resetPassword/:token", component: LoginPageComponent },
    ]
  },
  {
    path: "user",
    canActivate: [AuthService],
    children: [
        { path: "", component : UserPageComponent},
    ]
  },
  {
    path: "shop",
    canActivate: [AuthService],
    component: AccountPageComponent
  },
  {
    path: "citizen",
    canActivate: [AuthService],
    children: [
        { path: "", component : CitizenPageComponent},
    ]
  },
  {
    path: "group",
    canActivate: [AuthService],
    children: [
        { path: "", component : UserGroupListComponent},
    ]
  },
  {
    path: "organisation",
    canActivate: [AuthService],
    children: [
        { path: "", component : OrganisationListComponent},
    ]
  },
  {
    path: "incident",
    canActivate: [AuthService],
    children: [
        { path: "", component : IncidentListComponent},
        { path: ":id", component : IncidentListComponent},
    ]
  },
  {
    path: "dispatching",
    canActivate: [AuthService],
    children: [
        { path: "", component : DispatchingPage},
        { path: "categories", component : DispatchingCategoriesPageComponent},
        { path: "groupes", component : DispatchingGroupesPageComponent},
    ]
  },
  {
    path: "copywriting",
    canActivate: [AuthService],
    children: [
        { path: "", component : CopywritingPage}
    ]
  },
  {
    path: "themes",
    canActivate: [AuthService],
    children: [
      { path: "", component: ThemesPageComponent },
    ]
  },
  {
    path: "categories",
    canActivate: [AuthService],
    children: [
        { path: "", component : CategoriesPageComponent},
    ]
  },
  {
    path: "archiving",
    canActivate: [AuthService],
    children: [
        { path: "", component : ArchivingPageComponent},
    ]
  },
  {
    path: "dashboard",
    canActivate: [AuthService],
    children: [
        { path: "", component : DashboardPageComponent},
    ]
  },
  {
    path: "planning",
    canActivate: [AuthService],
    children: [
        { path: "", component : PlanningPageComponent},
    ]
  },
  {
    path: "translations",
    canActivate: [AuthService],
    children: [
        { path: "", component : TranslationPageComponent},
    ]
  },
  {
    path: "history",
    canActivate: [AuthService],
    children: [
        { path: "", component : HistoryComponent},
    ]
  },
  {
    path: "reporting",
    canActivate: [AuthService],
    children: [
      { path: "", component: ReportingPageComponent },
    ]
  },
  { path: "**",
    redirectTo: "/incident"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
