import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-multiple-box-info",
  templateUrl: "./multiple-box-info.component.html",
  styleUrls: ["./multiple-box-info.component.scss"]
})
export class MultipleBoxInfoComponent implements OnInit {

  @Input() public infoBoxes: any;
  @Input() public type: any;

  constructor() { }

  ngOnInit() {
  }
}
