import { Component, OnInit, Input } from "@angular/core";
import { IExpandContent } from "../../facades/interfaces/expand-content.interface";

@Component({
  selector: "app-expand-content",
  templateUrl: "./expand-content.component.html",
  styleUrls: ["./expand-content.component.css"]
})
export class ExpandContentComponent implements OnInit {
  @Input() public expandContent: IExpandContent[] = [];
  constructor() { }

  ngOnInit() {
  }

}
