import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS, Apollo } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApplicationConfig } from "./app.config";
import { AuthService, ROLES_KEY, TOKEN_STORAGE_KEY } from "src/lib/auth/auth.service";
import { ApolloLink, from } from "apollo-link";
import { onError } from "apollo-link-error";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "src/lib/auth/auth.interceptor";
import { Router } from "@angular/router";
import { createUploadLink } from "apollo-upload-client";
import { TranslateService } from "./facades/services/translate.service";
const jwt_decode = require("jwt-decode");

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
})
export class GraphQLModule {
  constructor(
    private _apollo: Apollo,
    private _router: Router,
    private _auth: AuthService,
    private _translateSrv: TranslateService,
    private _authSrv: AuthService,
    // httpLink: HttpLink
  ) {
    // const http = httpLink.create({ uri: ApplicationConfig.EndPointURI });
    const uploadLink = createUploadLink({ uri: ApplicationConfig.EndPointURI });

    const authLink = new ApolloLink((operation, forward) => {
      const token = localStorage.getItem("token");
      if (token) {
        operation.setContext({
          headers: {
            "Authorization": token ? `Bearer ${token}` : "",
            "LANG": this._translateSrv.activeLanguage
          }
        });
      }

      return forward(operation);
    });

  const addTokenLink = new ApolloLink((operation, forward) => {
      // Set token after server response
      return forward(operation).map((response) => {
          if (response && response.data && response.data.authorization) {
            
            const roles = jwt_decode(response.data.authorization.token).roles;
            if(!(roles && roles.length == 1 && roles.indexOf("EXTERNAL") != -1)){
              localStorage.setItem(TOKEN_STORAGE_KEY, response.data.authorization.token);
            }
            localStorage.setItem(ROLES_KEY, roles );
            this._authSrv.roles = roles;
          }
          return response;
      });
  });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, infos, statusCode }) => {
          console.error(`[GraphQL error]: Message: ${message}, infos: ${infos}, statusCode: ${statusCode}`);
            if (statusCode === 401) {
              this._router.navigate(["/login"]);
              this._auth.logout();
            }
        }
        );
      }
    });

    this._apollo.create({
      link: from([authLink, addTokenLink, errorLink, uploadLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          errorPolicy: "all"
        },
        query: {
          errorPolicy: "all"
        }
      }
    });

    // const token = localStorage.getItem("token");
    // const uploadLink = createUploadLink({
    //   uri: ApplicationConfig.EndPointURI,
    //   cache: new InMemoryCache(),
    //   headers: {
    //     "Authorization": token ? `Bearer ${token}` : ""
    //   },
    //   defaultOptions: {
    //     watchQuery: {
    //       errorPolicy: "all"
    //     },
    //     query: {
    //       errorPolicy: "all"
    //     }
    //   }
    // });

    // _apollo.create({
    //   link: from([authLink, errorLink, http]),
    //   cache: new InMemoryCache(),
    //   defaultOptions: {
    //     watchQuery: {
    //       errorPolicy: "all"
    //     },
    //     query: {
    //       errorPolicy: "all"
    //     }
    //   }
    // });
  }
}
