import { Component, OnInit, ViewChild, Input, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { chartColor } from "../chart-colors";

@Component({
  selector: "app-bar-chart",
  templateUrl: "./barchart.component.html",
  styleUrls: ["./barchart.component.css"]
})
export class BarChartComponent implements OnInit, OnDestroy, OnChanges {
  public myChart: Chart;
  public colors = chartColor;
  @ViewChild("barChart", { static: true }) private chartRef;
  @Input() diagramData: any;
  @Input() stacked = true;

  constructor() { }

  ngOnInit() {
    this.myChart = new Chart(this.chartRef.nativeElement, {
      type: "bar",
      data: this.diagramData,
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          datalabels: {
            color: function (context) {
              const progression = context.dataset['progression'];
              if (context.dataIndex === 1 && progression) {
                return progression < 0 ? "#c42e2e" : "#13c95f"
              }
              return '#36454F';
            },
            textAlign: 'center',
            align: 'end',
            font: {
              weight: 'bold',
            },
            rotation: (context) => {
              const progression = context.dataset['progression'];
              if (context.dataIndex === 1 && progression) {
                const progressionValue = progression || 0;
                const labelWidth = context.chart.ctx.measureText(`(${progressionValue >= 0 ? '+' : '-'}${Math.abs(progressionValue)}%)`).width;
                const chartData = context.chart.data;
                const numberOfBars = chartData.datasets.length * chartData.labels.length;
                const chartWidth = context.chart.width;
                const barWidth = chartWidth / numberOfBars;
                const realBarWidth = barWidth * (context.dataset.barPercentage || 1);
                return realBarWidth < labelWidth ? -20 : 0;
              } else {
                return 0;
              }
            },
            formatter: (value, context) => {
              value = (this.isObjectWithProperty(value, 'y')) ? value.y : value;
              const progression = context.dataset['progression'];
              if (context.dataIndex === 1 && progression) {
                const progressionValue = Number(progression);
                if (progressionValue) {
                  return `${value} \n (${progressionValue > 0 ? '+' : ''}${Math.round(progressionValue)}%)`;
                }
                return value;
              }
              return value;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            stacked: this.stacked
          }]
        },
        animation: {
          duration: 750
        },
        legend: {
          display: true,
          // onClick: function () { },
          labels: {
            // generateLabels: function (chart) {
            //   const original = Chart.defaults.global.legend.labels.generateLabels.bind(this);
            //   const labels = original(chart);
            //   return labels.map(label => ({ ...label, hidden: false }));
            // },
          }
        }
      }
    });

  }

  private isObjectWithProperty(variable: any, propertyName: string) {
    return typeof variable === 'object' && variable !== null && propertyName in variable;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.myChart) {
      this.myChart.destroy();
    }
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.myChart.destroy();
  }
}
