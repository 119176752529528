import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IBlockList, IBlockListHeader } from "../../facades/interfaces/block-list.interface";
import { TYPE_BUTTON } from "src/app/presentationnal/button/type-button.enum";

@Component({
  selector: "app-block-list",
  templateUrl: "./block-list.component.html",
  styleUrls: ["./block-list.component.css"]
})
export class BlockListComponent implements OnInit {
  public _TYPE_BUTTON = TYPE_BUTTON;
  @Input() blockListHeader: IBlockListHeader[];
  @Input() blockListDatas: IBlockList[] = [];
  @Input() needAddingAction: boolean = false;
  @Input() needEditingAction: boolean = false;
  @Output() onAddData: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditData: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  public addData() {
    this.onAddData.emit();
  }

  public editData(data) {
    this.onEditData.emit(data);
  }

}
