export const  chartColor = {
    solved : {
        fill: "#8CD790",
        stroke: "#73b076"
    },
    in_progress : {
        fill: "#f9b233",
        stroke: "#CC922A"
    },
    dismissed : {
        fill: "#EF476F",
        stroke: "#C43B5B"
    },
    created : {
        fill: "#D1D1D1",
      stroke: "#B0B0B0"
    },
    closed : {
        fill: "#4D5061",
        stroke: "#394250"
    },
    fusion : {
        fill: "#e78edc",
        stroke: "#812d77"
    }
};
