import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class GeoservicesWallonieService {
  private _geoServiceUrlc: string = "https://geoservices.wallonie.be/geolocalisation/rest";
  private _geoServiceUrl: string = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer";

  constructor( private _http: HttpClient) { }

  public getOldNearestPosition(x: number, y: number) {
    const path: string = "getNearestPosition";
    return this._http.get(`${this._geoServiceUrlc}/${path}/${x}/${y}`);
  }

  public getNearestPosition(x: number, y: number) {
    const path: string = "reverseGeocode?f=pjson&featureTypes=PointAddress&location=";
    return this._http.get(`${this._geoServiceUrl}/${path}%7B"x":${x},"y":${y},"spatialReference":%7B"wkid":31370%7D%7D&outSr=31370`);
  }

  public searchPosition(address: string, searchExtent) {
    const searchParams = new URLSearchParams();
    searchParams.append("searchExtent", JSON.stringify(searchExtent));
    searchParams.append("category", "City,Postal,Address");
    searchParams.append("maxSuggestions", "15");
    searchParams.append("countryCode", "BEL");
    searchParams.append("f", "pjson");
    searchParams.append("text", address);

    let value = searchParams.toString();
    const path: string = `suggest?`;
    return this._http.get(`${this._geoServiceUrl}/${path}${value}`);
  }

  public findAddress(text: string, magicKey: string, searchExtent) {
    const searchParams = new URLSearchParams();
    searchParams.append("category", "City,Postal,Address");
    searchParams.append("featureTypes", "PointAddress");
    searchParams.append("maxSuggestions", "15");
    searchParams.append("SingleLine", text);
    searchParams.append("magicKey", magicKey);
    searchParams.append("countryCode", "BEL");
    searchParams.append("outSr", "31370");
    searchParams.append("outFields", "*");
    searchParams.append("f", "pjson");

    const path: string = `findAddressCandidates?`;
    const value = searchParams.toString();

    return this._http.get(`${this._geoServiceUrl}/${path}${value}`);

  }

  public searchRues(address: string) {
    const path: string = "searchRues";
    return this._http.get(`${this._geoServiceUrlc}/${path}/${address}`);
  }

  public searchAll(address: string) {
    const path: string = "searchAll";
    return this._http.get(`${this._geoServiceUrl}/${path}/${address}`);
  }

  getCommunesList() {
    const path: string = "getListeCommunes";
    return this._http.get(`${this._geoServiceUrlc}/${path}`);
  }

  // ERROR CORS
  public getLongitudeLatitude(mapPoint: any) {
    const params: any = {
      inSR: 31370,
      outSR: 4326,
      transformation: 15928,
      geometries: JSON.stringify({
        geometryType: "esriGeometryPoint",
        geometries: [mapPoint]
      }),
      transformForward: false,
      f: "json"
    };
    const url: string = "https://applications.walloniepluspropre.be/server/rest/services/Utilities/Geometry/GeometryServer/project";
    return this._http.get(url, { params });
  }
}
