import { Component, OnInit, OnChanges, Output, EventEmitter, Input, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { ISelectOption } from "../../input/facades/interfaces/selectOption.interface";
import { IFilterBarFilter, IFilterBarPagination } from "../facades/interfaces/filter-bar.interface";

@Component({
  selector: "app-filter-bar",
  templateUrl: "./filter-bar.component.html",
  styleUrls: ["./filter-bar.component.css"]
})
export class FilterBarComponent implements OnInit, OnChanges {
  public formGroup: FormGroup;
  @Input() isIncident: boolean = false;
  @Input() filterList: IFilterBarFilter[];
  @Input() needExportingAction: boolean = false;
  @Input() specialActions: any = [];
  @Input() needRefreshingAction: boolean = true;
  @Input() refreshButtonName: string = "general_refreshList-button";
  @Input() exportButtonName: string = "general_exportExcel-button";
  @Input() filterTitle: string = "";
  @Input() pagination: IFilterBarPagination = {
    label: "filterBar.incidentOn",
    listNumber: 10,
    listTotalCounter: null
  };
  @Input() archivesOptions: ISelectOption[] = null;
  @Input() loadingExport: boolean = false;
  @Output() eventSearch = new EventEmitter<string>();
  @Output() onRefreshList = new EventEmitter<boolean>();
  @Output() canExportExcel = new EventEmitter<boolean>();
  @Output() onChangeFilter = new EventEmitter<any>();
  @Output() countItemsChanged = new EventEmitter<any>();
  @Output() yearChanged = new EventEmitter<number>();


  // public selectOptions: ISelectOption[] = [
  //   {
  //     label: "Pas d'archive",
  //     value: 1 ,
  //   },
  //   {
  //     label: "archive 2018",
  //     value: 2 ,
  //   },
  //   {
  //     label: "archive 2017",
  //     value: 3 ,
  //   }
  // ];
  public selectPagination: ISelectOption[] = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "100", value: 100 }
  ];

  constructor(public fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["archivesOptions"] && changes["archivesOptions"].currentValue && changes["archivesOptions"].currentValue.length == 0 ) {
      this.archivesOptions = [{
        label: "Pas d'archive",
        value: null
      }];
    }
  }
  private initForm( ) {
    this.formGroup = this.fb.group({
      archive: null,
      printByPage: this.pagination ? this.pagination.listNumber : null,
    });

    this.formGroup.get("printByPage").valueChanges.subscribe(res => {
      this.countItemsChanged.emit(res);
    });

    this.formGroup.get("archive").valueChanges.subscribe(res => {
      this.yearChanged.emit(res);
    });
    this.checkChange();
  }

  public checkChange(): void {
    this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe(val => {
        this.eventSearch.emit(val);
    });
  }

  public exportToExcel(): void {
    this.canExportExcel.emit(true);
  }

  public refreshList(): void {
    this.onRefreshList.emit(true);
  }

  public changeFilter(filterType: any) {
    this.onChangeFilter.emit(filterType);
  }

}
