import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimelineVerticalComponent } from "./organisms/timeline-vertical/timeline-vertical.component";
import { PipeModule } from "src/app/facades/pipes/pipe.module";



@NgModule({
  declarations: [
    TimelineVerticalComponent
  ],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [
    TimelineVerticalComponent
  ]
})
export class TimelineModule { }
