

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthService, ROLES_KEY, TOKEN_STORAGE_KEY } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    public constructor(
        private _injector: Injector
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       return next.handle(req).pipe(map( event => {
            if (event instanceof HttpResponse) {
                if (event && event.body && event.body.data && event.body.data["authorization"]) {
                    localStorage.setItem(TOKEN_STORAGE_KEY, event.body.data["authorization"] && event.body.data["authorization"]["token"] ? event.body.data["authorization"][TOKEN_STORAGE_KEY] : null);
                    localStorage.setItem(ROLES_KEY, event.body.data["authorization"] && event.body.data["authorization"]["roles"] ? event.body.data["authorization"][ROLES_KEY] : [] );
                }
            }
            return event;
        }));
    }
}