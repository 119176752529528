import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { updateTranslationsMutation, translationQuery, allTranslationsQuery } from "./translation.queries";

@Injectable({
    providedIn: "root"
})
export class TranslationQueriesService {

    constructor(private _apollo: Apollo) { }

    public updateTranslations(data: { [fileName: string]: { [key: string]: string } }) {
        return this._apollo.mutate({
            mutation: updateTranslationsMutation,
            variables: {
                translations: data
            },
            fetchPolicy: "no-cache",
        });
    }

    public getTranslation() {
        return this._apollo.query({
            query: translationQuery,
            fetchPolicy: "no-cache",
        });
    }

    public getAllTranslations() {
        return this._apollo.query({
            query: allTranslationsQuery,
            fetchPolicy: "no-cache",
        });
    }

}
