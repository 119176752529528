import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextInputComponent } from "./atoms/text-input/text-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TextareaInputComponent } from "./atoms/textarea-input/textarea-input.component";
import { SelectSearchInputComponent } from "./atoms/select-search-input/select-search-input.component";
import { SelectInputComponent } from "./atoms/select-input/select-input.component";
import { NumberInputComponent } from "./atoms/number-input/number-input.component";
import { PasswordInputComponent } from "./atoms/password-input/password-input.component";
import { RadioInputComponent } from "./atoms/radio-input/radio-input.component";
import { CheckboxInputComponent } from "./atoms/checkbox-input/checkbox-input.component";
import { FilterInputComponent } from "./atoms/filter-input/filter-input.component";
import { SharedDirectivesModule } from "src/app/facades/directives/directives.modules";
import { SearchInputComponent } from "./atoms/search-input/search-input.component";
import { SelectMultipleInputComponent } from "./atoms/select-multiple-input/select-multiple-input.component";
import { PipeModule } from "src/app/facades/pipes/pipe.module";
import { ImgInputComponent } from "./organisms/img-input/img-input.component";
import { TextSearchInputComponent } from "./atoms/text-search-input/text-search-input.component";
import { FilesBlockComponent } from "./organisms/files-block/files-block.component";
import { ErrorMessageService } from "./facades/services/error-message.service";
import { ImgBlockComponent } from "./organisms/img-block/img-block.component";
import { SelectCheckboxInputComponent } from "./organisms/select-checkbox-input/select-checkbox-input.component";
import { DateInputComponent } from "./atoms/date-input/date-input.component";
import { DatePickerComponent } from "./molecules/date-picker/date-picker.component";
import { FileInputComponent } from "./organisms/file-input/file-input.component";
import { SwitchInputComponent } from "./atoms/switch-input/switch-input.component";
import { SelectSearchMultipleInputComponent } from "./organisms/select-search-multiple-input/select-search-multiple-input.component";


const COMPONENTS = [
  TextInputComponent,
  TextSearchInputComponent,
  TextareaInputComponent,
  SelectInputComponent,
  SelectSearchInputComponent,
  NumberInputComponent,
  PasswordInputComponent,
  RadioInputComponent,
  CheckboxInputComponent,
  FilterInputComponent,
  SearchInputComponent,
  SelectMultipleInputComponent,
  SelectSearchMultipleInputComponent,
  ImgInputComponent,
  FileInputComponent,
  FilesBlockComponent,
  ImgBlockComponent,
  SelectCheckboxInputComponent,
  DateInputComponent,
  DatePickerComponent,
  SwitchInputComponent
];
@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    PipeModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: [
    ErrorMessageService
  ]
})
export class InputModule { }

