import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "null"
})
@Pipe({name: "null"})
export class NullPipe implements PipeTransform {
  transform(value): string {
    return value == null ? " - " : value;
  }

}
