import { ModalContent } from "./modal-content";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { ModalService } from "src/app/facades/services/modal/modal.service";
import { SnackService } from "../services/snackbar/snackbar.service";
import { STATUS_ALERTS } from "../enum/status.enum";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";

/*
 * @Author: Kevin Palade
 * @Date: 2018-09-08 16:54:22
 * @Last Modified by: Kevin Palade
 * @Last Modified time: 2019-08-22 16:55:13
 */

export abstract class ModalContentForm extends ModalContent {
    public _formGroup: FormGroup;
    public inputErrorsLabelMap: Map<string, string>;
    public inputErrorsLabel: Array<IBoxInfo> | Object;

    constructor(protected modalSrv: ModalService,
                protected fb: FormBuilder,
                protected _snackBar: SnackService,
                protected _errorMessageSrv: ErrorMessageService,
                ) {
        super(modalSrv);
    }

    public confirmModal(): void {
        if ( this._formGroup.valid ) {
            this.save();
            this.modalSrv.closeModal();
        } else {
            this.markFormGroupTouched(this._formGroup);
            this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this._formGroup, this.inputErrorsLabelMap, true);
            // this.displayErrorMessage();
        }
    }

    /**
     *  isCloseCrossButton can be used by component that override this method for knowing if the click is on bottom button or on the close cross
     */
    public cancelModal(isCloseCrossButton?: boolean): void {
        this.modalSrv.closeModal();
    }

    protected markFormGroupTouched(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach(control => {
            (control as FormControl).updateValueAndValidity();
            control.markAsTouched();
            if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
        });
    }

    protected abstract save(): void;

    protected displayErrorMessage() {
        this._snackBar.open(
            "general_snackbarTitleError-txt",
            "general_formNotCorrectlyFill-txt",
            STATUS_ALERTS.DANGER ,
            5000
        );
    }



}
