import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_LIST_ORGANISATIONS, SET_ORGANISATION, GET_ORGANISATION, DELETE_ORGANISATION, GET_TRANSFERABLE_ORGANISATIONS, GET_LIST_ORGANISATIONS_TEST, GET_EXISTING_ORGANISATION_CODE, GET_ORGANISATIONS_XLS } from "./organisation.queries";
import { IOrganisationInpdate, IFilterOrganisations, IOrganisationCode } from "../../interfaces/organisation.interface";
import { IPagination } from "../../interfaces/pagination.interface";
import { IIncidentColumnFilter } from "../../interfaces/incident.interface";

@Injectable({
  providedIn: "root"
})
export class OrganisationQueriesService {
  private _organisationFilters: IFilterOrganisations = null;
  private _organisationPagination: IPagination = {limit: 20, page: 1};
  private _appliedFilters: IIncidentColumnFilter = null;

  constructor(private _apollo: Apollo) { }

  public set organisationFilters(filters: IFilterOrganisations) { this._organisationFilters = filters; }
  public set organisationPaginations(pagination: IPagination) { this._organisationPagination = pagination; }
  public get organisationPaginations() { return this._organisationPagination; }

  public get appliedFilters(): IIncidentColumnFilter { return this._appliedFilters; }
  public set appliedFilters(appliedFilters: IIncidentColumnFilter) { this._appliedFilters = appliedFilters; }

  public resetPagination() {
    this._organisationPagination = {
      ...this._organisationPagination,
      page: 1
    };
  }

  public listOrganisations(pagination?: any, filter?: any) {
    if (this._appliedFilters) {
      Object.keys(this._appliedFilters).forEach(key => {
        if (this._appliedFilters[key] != null && (this._appliedFilters[key] === "" || this._appliedFilters[key].length === 0)) {
          this._appliedFilters[key] = null;
        }
      });
    }
    return this._apollo.watchQuery({
      query: GET_LIST_ORGANISATIONS,
      variables: {
        filter: filter? filter : this._appliedFilters,
        pagination: pagination ? pagination : this._organisationPagination
      },
      fetchPolicy: "cache-and-network",
    });
  }

  public getOrganisationListQuery(filter: any = null) {
    return this._apollo.query({
      query: GET_LIST_ORGANISATIONS_TEST,
      variables: {
        filter
      },
      fetchPolicy: "no-cache",
    });
  }

  public getTransferableOrganisations() {
    return this._apollo.query({
      query: GET_TRANSFERABLE_ORGANISATIONS,
      fetchPolicy: "no-cache"
    });
  }

  public setOrganisation( organisation: IOrganisationInpdate) {
    return this._apollo.mutate({
      mutation: SET_ORGANISATION,
      variables: { organisation },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_LIST_ORGANISATIONS,
        variables: {
          filter: this._appliedFilters,
          pagination: this._organisationPagination
        }
      }]
    });
  }

  public getOrganisation( id: number) {
    return this._apollo.query({
      query: GET_ORGANISATION,
      variables: {id},
      fetchPolicy: "no-cache",
    });
  }

  public deleteOrganisation( id: number) {
    return this._apollo.mutate({
      mutation: DELETE_ORGANISATION,
      variables: {id},
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: GET_LIST_ORGANISATIONS,
        variables: {
          filter: this._appliedFilters,
          pagination: this._organisationPagination
        }
      }]
    });
  }

  public getOrganisationsXls(tableHeaders: any[]) {
    if (this._appliedFilters) {
      Object.keys(this._appliedFilters).forEach(key => {
        if (this._appliedFilters[key] === "") {
          this._appliedFilters[key] = null;
        }
      });
    }
    const pagination = {
      ...this._organisationPagination,
      limit: 0,
      page: 1
    };

    return this._apollo.query({
      query: GET_ORGANISATIONS_XLS,
      variables: {
        filter: this._appliedFilters,
        pagination: pagination,
        tableHeaders
      },
      fetchPolicy: "no-cache"
    });
  }

  public checkExistingCode(code: string, id?: number) {
    return this._apollo.query({
      query: GET_EXISTING_ORGANISATION_CODE,
      variables: {
        code: code,
        id: id,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    });
  }

}
