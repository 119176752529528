import { Component, OnInit, Inject, ɵConsole } from "@angular/core";
import { ModalService, DATA } from "src/app/facades/services/modal/modal.service";
import { IModalData } from "src/app/facades/interfaces/modal-data.interface";
import { ModalContent } from "../../../facades/model/modal-content";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { element, Key } from 'protractor';
import { ISelectOption } from 'src/app/presentationnal/input/facades/interfaces/selectOption.interface';


@Component({
  selector: "app-modal-confirm",
  templateUrl: "./modal-confirm.component.html",
  styleUrls: ["./modal-confirm.component.css"]
})
export class ModalConfirmComponent extends ModalContent {
  public data: IModalData;
  public formGroup: FormGroup;
  public formGroupOptions: any;

  constructor(protected modalSrv: ModalService,
              private _fb: FormBuilder,
              @Inject(DATA) private _data: IModalData) {
    super(modalSrv);
    this.data = _data;
  }

  ngOnInit() {
    if(this.data && this.data.data && this.data.data.formGroup && this.data.data.formGroup.length > 0){
      this.formGroupOptions = this.data.data.formGroup;
      this.createFormGroup();
    }
  }

  public confirmModal() {
    if ( this._data && this._data.confirmCallback) {
      let value = this.formGroup? this.formGroup.value : null;
      this._data.params['formGroupValue'] = value;
      this._data.confirmCallback(this._data.params);
    }
    this.modalSrv.closeModal();
  }

  public cancelModal() {
    if ( this._data && this._data.cancelCallback) {
      this._data.cancelCallback(this._data.params);
    }
    this.modalSrv.closeModal();
  }

  public createFormGroup(){
    let obj = {};
    this.formGroupOptions.forEach(fbOptions => {
      obj[fbOptions.name]= fbOptions.value? fbOptions.value : null;
    });
    this.formGroup = this._fb.group(obj);
  }

}


