import { Component, ViewChild, Input, Output, EventEmitter, ElementRef, OnInit } from "@angular/core";
import { FILES_BLOCK_ACTIONS_ENUM } from "../../facades/enums/files-block-actions.enum";
import { SnackService } from "../../../../facades/services/snackbar/snackbar.service";
import { TranslateService } from "../../../../facades/services/translate.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-file-input",
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.css"]
})
export class FileInputComponent implements OnInit {

  @Input() public disabled = false;
  @Input() public hiddenInput = false;
  // @Input() files: File[] = [];
  @Input() required: boolean = false;
  @Input() isFileError: boolean = false;
  @Input() accept: string = "*/*";
  @Input() reset: Subject<void>;
  @Output() public sendImg = new EventEmitter<any>();
  @Output() public sendxls = new EventEmitter<any>();
  @ViewChild("fileInput", { static: false }) public fileInput: ElementRef;

  public fileType: string;
  public filename: string;

  public FILES_BLOCK_ACTIONS_ENUM = FILES_BLOCK_ACTIONS_ENUM;
  constructor(private _snackBar: SnackService,
    private _translateSrv: TranslateService) { }

  ngOnInit() {
    if (this.reset) {
      this.reset.subscribe(() => this.resetForm());
    }
  }

  public chooseFile() {
    if (!this.disabled) {
      this.fileInput.nativeElement.click();
    }
  }

  /**
   * @description Add the file in the filesList and read it for preview
   */
  public set(file: File, event: any) {
    if (event) {
      event.stopPropagation();
    }
    if (file) {
      if (["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"].includes(file.type)) {
        this.fileType = "xls";
        this.filename = file.name;
        this.sendxls.emit(file);
      } else if (file.type.indexOf("image/") !== -1) {
        // Read file for preview
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          file["previewUrl"] = reader.result;
          this.sendImg.emit(file);
          this.fileInput.nativeElement.value = "";
        };
      } else {
        this.fileType = "default";
        this.filename = file.name;
      }
    }
  }

  private resetForm() {
    this.fileInput.nativeElement.value = "";
    delete this.filename;
    delete this.fileType;
  }

  public onDrop(ev: any) {
    ev.preventDefault();
    if (!!ev.dataTransfer.files[0]) { this.set(ev.dataTransfer.files[0], null); }
  }

  public avoid(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();
  }
}
