import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class XlsService {
  constructor() { }

  public downloadXls(file: { filename: string, content: string }): void {
    const byteArray = new Uint8Array(
      atob(file.content)
        .split("")
        .map(char => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: "application/vnd.ms-excel" });
    const fileURL = URL.createObjectURL(blob);

    // Construct the 'a' element
    let link = document.createElement("a");
    link.download = file.filename;
    link.target = "_blank";

    // Construct the URI
    link.href = fileURL;
    document.body.appendChild(link);
    link.click();

    // Cleanup the DOM
    document.body.removeChild(link);
  }
}
