import { ApplicationConfig } from 'src/app/app.config';
/*
  Copyright 2019 Esri
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
*/

import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, ɵɵi18nAttributes } from "@angular/core";
import { loadModules } from "esri-loader";
import esri = __esri; // Esri TypeScript Types
import { ESRI_MAP_CONFIG, LAYER_IDS } from "../../facades/configs/esri-map.config";
import { GeoservicesWallonieService } from "src/app/facades/services/geoservices-wallonie/geoservices-wallonie.service";
import { IMarkerData, IQueryTaskDatas } from "../../facades/interfaces/esri-map.interface";
import { ConversionQueriesService } from "src/app/facades/queries/conversion/conversion-queries.service";
import { ILatLong } from "src/app/facades/interfaces/conversion.interface";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { IGeoZoneInput, ILayer, IGeoZone } from "src/app/facades/interfaces/dispatching.interface";
import { PinMap } from "./facades/pin.map";
import { EnumIncidentStatuses, IIncidentMap, IIncidentMarker } from "src/app/facades/interfaces/incident.interface";
import { SelectedPinMap } from "./facades/selected-pin.map";
import { USER_ID } from 'src/lib/auth/auth.service';
import { TranslateService } from 'src/app/facades/services/translate.service';
@Component({
  selector: "app-esri-map",
  templateUrl: "./esri-map.component.html",
  styleUrls: ["./esri-map.component.scss"]
})
export class EsriMapComponent implements OnInit, OnChanges {

  private _orthoLastConfig: any = require("../../facades/configs/esri-map-ortho-last.config.json");
  private _esriMapConfig: any = ESRI_MAP_CONFIG;
  private _view: esri.MapView;
  private _graphicsLayer: esri.GraphicsLayer;
  private _debouncer: Subject<any> = new Subject();

  @Input() additionnalLayer: esri.GraphicsLayer;
  @Output() mapLoadedEvent = new EventEmitter<boolean>();
  @Input() isSelectPointMap: boolean = false;
  @Output() onClickMap: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickMarker: EventEmitter<any> = new EventEmitter<any>();
  @Input() heightMap: string = "50vh";
  @Input() isDispatchingMap: boolean = false;

  @Input() displayIncidentsMap: Array<IIncidentMap> = [];
  @Input() selectedIncidentIds: Array<number> = [];
  @Input() currentUserAvatarUrl?: String = null;

  @Input() dispatchLayerToUse: ILayer;
  @Input() selectedLayerZone: IGeoZoneInput;
  @Input() geoZoneForGraphicLayer: IQueryTaskDatas[];
  @Output() onChangeGraphicList: EventEmitter<IGeoZoneInput[]> = new EventEmitter<IGeoZoneInput[]>();

  @ViewChild("mapViewNode", { static: true }) private mapViewNode: ElementRef;

  private _markerGraphics: any[] = [];

  private _zoom = 12;
  private _center: Array<number> = [0.1278, 51.5074];
  private _basemap = "streets";
  private _loaded = false;
  private _scale = 1000;
  private _markerClickInitialized: boolean = false;

  get mapLoaded(): boolean { return this._loaded; }

  @Input() set zoom(zoom: number) { this._zoom = zoom; }
  get zoom(): number { return this._zoom; }

  @Input() set center(center: Array<number>) { this._center = center; }
  get center(): Array<number> { return this._center; }

  @Input() set basemap(basemap: string) { this._basemap = basemap; }
  get basemap(): string { return this._basemap; }

  @Input() canClickOnMap: boolean = true;
  @Input() markerData: IMarkerData = null;

  constructor(
    private _geoserviceWallonieSrv: GeoservicesWallonieService,
    private _conversionQueriesSrv: ConversionQueriesService,
    private _translateSrv: TranslateService) {
    this._debouncer.pipe(debounceTime(400)).subscribe((value) => this.convertPointToLatLong(value));
  }

  ngOnInit() {
    this.initializeMapLayerTransformation().then((mapView) => {
      this._view = mapView;
      this.getMapCenter();
      if (this.markerData) { this.createGraphicMarker(this.markerData); }
      if (this.displayIncidentsMap && this.displayIncidentsMap.length > 0) { this.createMultipleGraphicMarker(); }
      if (this.isDispatchingMap && this.dispatchLayerToUse) { this.setDispatchLayerToUse(); }
      
      this.houseKeeping(mapView);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
   
    if (changes.markerData && this.markerData && this._view) {
      this._view.graphics.removeAll();
      this.createGraphicMarker(this.markerData);
    }
    if ((changes.displayIncidentsMap || changes.selectedIncidentIds) && this._view) {
      // if (this._graphicsLayer) { this._view.map.remove(this._graphicsLayer); }
      this.createMultipleGraphicMarker();
    }

    if (changes.dispatchLayerToUse && this._view) {
      this.setDispatchLayerToUse();
    }

    if (changes.selectedLayerZone && this._view && !this.selectedLayerZone) {
      this.updateGraphicsSelected();
    }

    if (changes.geoZoneForGraphicLayer && this._view) {
      this.getGraphicsDataFromSelectedGeoZone();
    }
    if (this.additionnalLayer) {
      this._view.map.add(this.additionnalLayer);
    }
  }
  // To use if we don't have x and y but a zone
  private async createGraphicMarkerFromZone(result: any) {
    const [EsriGraphic, EsriExtent] = await loadModules([
      "esri/Graphic",
      "esri/geometry/Extent",
    ]);

    const extent: esri.Extent = new EsriExtent({
      xmin: result.xMin,
      xmax: result.xMax,
      ymin: result.yMin,
      ymax: result.yMax,
      spatialReference: { wkid: ESRI_MAP_CONFIG.wkid }
    });
    const graphic: esri.Graphic = new EsriGraphic({
      geometry: extent,
      symbol: PinMap.get(EnumIncidentStatuses.CREATED)
    });
    this._view.graphics.add(graphic);
    this._view.goTo(extent);
  }

  // Finalize a few things once the MapView has been loaded
  private houseKeeping(mapView) {
    mapView.when(() => {
      console.log("mapView ready: ", mapView.ready);
      this._loaded = mapView.ready;
      // this.getMapCenter();
      this.mapLoadedEvent.emit(true);
    });
  }

  private displayCoordinate(view: esri.MapView) {
    // Add div element to show coordates
    const coordsWidget = document.createElement("div");
    coordsWidget.id = "coordsWidget";
    coordsWidget.className = "esri-widget esri-component";
    coordsWidget.style.padding = "7px 15px 5px";
    view.ui.add(coordsWidget, "bottom-right");

    const showCoordinatesStationary = () => {
      if (view && view.center) {
        this._debouncer.next({ coordsWidget, mapPoint: { x: view.center.x, y: view.center.y } });
      } else {
        this._debouncer.next({ coordsWidget, mapPoint: { x: 0, y: 0 } });
      }
    };
    view.watch(["stationary"], showCoordinatesStationary.bind(this));

    const showCoordinatesPointerMove = (evt) => {
      this._debouncer.next({ coordsWidget, mapPoint: { x: evt.x, y: evt.y } });
    };
    // Add event to show mouse coordinates on click and move >> TODO CHECK IF NEEDED OR IF WE JUST NEED THE MAP CENTER
    // view.on(["pointer-down", "pointer-move"], showCoordinatesPointerMove.bind(this));
  }

  private async convertPointToLatLong(data: { coordsWidget: any, mapPoint: any }) {
    const { coordsWidget, mapPoint } = data;
    this._conversionQueriesSrv.convertLambertToLatLong(mapPoint.x, mapPoint.y).subscribe(result => {
      const resultData: { convertLambertToLatlong: ILatLong } = <any>result.data;
      if (resultData && resultData.convertLambertToLatlong) {
        const coords = "Lat/Lon " + resultData.convertLambertToLatlong.latitude + ", " + resultData.convertLambertToLatlong.longitude;
        coordsWidget.innerHTML = coords;
        return resultData.convertLambertToLatlong;
      }
    }, error => {
      console.log(error);
    });
  }

  async initializeMapLayerTransformation() {
    try {
      // Load the modules for the ArcGIS API for JavaScript
      const [EsriMap, EsriMapView, EsriMapImageLayer, EsriTileLayer, EsriBasemap, EsriWebTileLayer, EsriSpatialReference, EsriExtent, EsriTileInfo] = await loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/MapImageLayer",
        "esri/layers/TileLayer",
        "esri/Basemap",
        "esri/layers/WebTileLayer",
        "esri/geometry/SpatialReference",
        "esri/geometry/Extent",
        "esri/layers/support/TileInfo"
      ]);

      // Définit le type de référence spatial. Les layers et la map doivent avoir la même
      const spatialReference: esri.SpatialReference = new EsriSpatialReference({
        wkid: this._esriMapConfig.wkid
      });

      const map: esri.Map = new EsriMap({
        basemap: new EsriBasemap({
          baseLayers: this.getBaseLayers(spatialReference, EsriTileInfo, EsriWebTileLayer, EsriMapImageLayer),
          // thumbnailUrl: "http://geoservices.wallonie.be/arcgis/rest/services/IMAGERIE/ORTHO_LAST/MapServer/tile/0/203/176"
        }),
        layers: this.buildLayers(EsriTileLayer, EsriMapImageLayer)
      });

      // Initialize the MapView
      const mapViewProperties: esri.MapViewProperties = {
        container: this.mapViewNode.nativeElement,
        map: map
      };

      const view: esri.MapView = new EsriMapView(mapViewProperties);
      // Redéfinit la référence spatial pour matcher avec les tuiles
      view.extent = new EsriExtent({
        xmax: this._orthoLastConfig.initialExtent.xmax,
        xmin: this._orthoLastConfig.initialExtent.xmin,
        ymax: this._orthoLastConfig.initialExtent.ymax,
        ymin: this._orthoLastConfig.initialExtent.ymin,
        spatialReference: spatialReference
      });

      // this.displayLayerList(view, EsriLayerList);
      if (this.isDispatchingMap) {
        view.on("click", this.getAnnotationData.bind(this));
      } else {
        this.displayCoordinate(view);
      }
      if (this.isSelectPointMap) {
        view.on("click", this.getAddressPoint.bind(this));
      }
      return view;

    } catch (error) {
      console.log("EsriLoader: ", error);
    }
  }

  private getAddressPoint(event: esri.MapViewClickEvent) {
    const lambert: { x: number, y: number } = {
      x: event.mapPoint.x,
      y: event.mapPoint.y
    };
    this._geoserviceWallonieSrv.getNearestPosition(lambert.x, lambert.y).subscribe((result: any) => {
      if(result.error){
        this._geoserviceWallonieSrv.getOldNearestPosition(lambert.x, lambert.y).subscribe((oldResult: any) => { 
          this._view.graphics.removeAll();
          this.createGraphicMarker(lambert, true);
          this.onClickMap.emit({
            ...oldResult,
            ...lambert,
            oldUrl: true
          });
        }, error => {
          console.log(error);
        })
      } else {
        this._view.graphics.removeAll();
        this.createGraphicMarker(lambert, true);
        this.onClickMap.emit({
          ...result,
          ...lambert
        });
      }
    }, error => {
      console.log(error);
      
    });
  }

  private async createMultipleGraphicMarker() {
    const [EsriGraphic, EsriPoint] = await loadModules([
      "esri/Graphic",
      "esri/geometry/Point",
    ]);

    const newMarkerGraphics: Array<IIncidentMarker> = [];
    const markerIncidentIds: number[] = this._markerGraphics.map(marker => marker.incidentId);
    const markerToKeep: Array<IIncidentMap> = [];
    const incidentToCreate: Array<IIncidentMap> = [];
    let markerToRemove: Array<IIncidentMap> = [];

    // Define the incident marker who need to be create or that we need to keep
    this.displayIncidentsMap.forEach(incident => {
      const index = markerIncidentIds.indexOf(incident.id);
      if (index === -1) {
        incidentToCreate.push(incident);
      } else {
        markerToKeep.push(this._markerGraphics[index]);
      }
    });

    // Define the incident markers who need to be remove
    markerToRemove = this._markerGraphics.filter(marker => markerToKeep.indexOf(marker) === -1);
    incidentToCreate.forEach(incident => {
      // Create point
      const mapPoint: esri.Point = new EsriPoint({
        x: incident.x,
        y: incident.y,
        spatialReference: { wkid: ESRI_MAP_CONFIG.wkid }
      });

      const isSelected = this.selectedIncidentIds.indexOf(incident.id) >= 0;
      const symbol = {
        type: "picture-marker",
        url: ApplicationConfig.Url.replace('/graphql', '') + incident.avatar_url,
        height: "40px",
        width: "40px",
        color: [51, 204, 51, 0.3]
    };

      // Create graphic
      const graphic: esri.Graphic = new EsriGraphic({
        geometry: mapPoint,
        symbol,
        // popupTemplate: template
        attributes: {
          id: incident.id,
          status: incident.status,
          lambert: {
            x: incident.x,
            y: incident.y
          }
        }
      });
      newMarkerGraphics.push({ incidentId: incident.id, graphic });
    });
    this.setGraphicLayer(newMarkerGraphics, markerToRemove);
    this._markerGraphics = [...markerToKeep, ...newMarkerGraphics];

    if (!this._markerClickInitialized) {
      this._markerClickInitialized = true;
      this._view.on("click", (evt) => {
        this._view.hitTest(evt).then(this.markerClick);
      });
    }
  }

  private async setGraphicLayer(newMarkerGraphics, markerToRemove) {
    const [GraphicsLayer] = await loadModules(["esri/layers/GraphicsLayer"]);
    if (this._graphicsLayer) {
      this._graphicsLayer.removeMany(markerToRemove.map(marker => marker.graphic));
      this._graphicsLayer.addMany(newMarkerGraphics.map(marker => marker.graphic));
    } else {
      this._graphicsLayer = new GraphicsLayer({ graphics: newMarkerGraphics.map(marker => marker.graphic) });
      this._view.map.add(this._graphicsLayer);
    }
  }

  private async createGraphicMarker(result: any, isClickAction: boolean = false) {    
    const [EsriGraphic, EsriPoint] = await loadModules([
      "esri/Graphic",
      "esri/geometry/Point",
    ]);
    const mapPoint: esri.Point = new EsriPoint({
      x: result.x,
      y: result.y,
      spatialReference: { wkid: ESRI_MAP_CONFIG.wkid },
    });

    const symbol = {
      type: "picture-marker",
      // url: ApplicationConfig.Url.replace('/graphql', '') + result.avatar_url,
      url: (result && result.avatar_url? ApplicationConfig.Url.replace('/graphql', '')+result.avatar_url : (this.currentUserAvatarUrl? this.currentUserAvatarUrl : ApplicationConfig.Url.replace('/graphql', '')+'/img/avatars/base/base_citoyen_created.png')),
      height: "40px",
      width: "40px",
      color: [51, 204, 51, 0.3]
  };

    const graphic: esri.Graphic = new EsriGraphic({
      geometry: mapPoint,
      symbol,
      attributes: {
        id: result.id,
        lambert: {
          x: result.x,
          y: result.y
        }
      }
    });

    this._view.graphics.add(graphic);
    await this._view.goTo(mapPoint); // Need to wait the goTo has finished before zoom
    if (!isClickAction) {
      this._view.goTo({ scale: this._scale });
    }
    if (isClickAction && this._view.zoom < this._zoom) {
      this._view.goTo({ zoom: this._zoom });
    }
  }


  public markerClick = (event) => {
    loadModules(["esri/geometry/Point", "esri/Graphic"]).then(([EsriPoint, EsriGraphic]) => {

      if (event && event.results && event.results.length > 0) {
        const { graphic: { attributes } } = event.results[0];
        const mapPoint: esri.Point = new EsriPoint({
          x: attributes.lambert.x,
          y: attributes.lambert.y,
          spatialReference: { wkid: ESRI_MAP_CONFIG.wkid }
        });

        const incident  = this.displayIncidentsMap.filter(inci => inci.id === attributes.id);

        const isSelected = this.selectedIncidentIds.indexOf(attributes.id) >= 0;
        const symbol = isSelected ? {
          type: "picture-marker",
          url: ApplicationConfig.Url.replace('/graphql', '') + incident[0].avatar_url,
          height: "40px",
          width: "40px",
          color: [51, 204, 51, 0.3]
      } : SelectedPinMap.get(attributes.status);
        const graphic: esri.Graphic = new EsriGraphic({
          geometry: mapPoint,
          symbol: symbol,
          attributes
        });

        const index = this._markerGraphics.map(marker => marker.incidentId).indexOf(attributes.id);
        const currentGraphic = this._markerGraphics[index].graphic;
        this._graphicsLayer.remove(currentGraphic);
        this._graphicsLayer.add(graphic);
        this._markerGraphics[index] = { incidentId: this._markerGraphics[index].incidentId, graphic: graphic };

        this.onClickMarker.emit(attributes.id);
      }
    });
  }

  private async setDispatchLayerToUse() {
    const [EsriMapImageLayer] = await loadModules([
      "esri/layers/MapImageLayer",
    ]);
    const mapImageLayerProperties: esri.MapImageLayerProperties = {
      url: `${this.dispatchLayerToUse.url}`,
      sublayers: [
        {
          id: +this.dispatchLayerToUse.mapServerId
        }
      ]
    };
    this._view.map.removeAll();
    this.updateGraphicsSelected();
    this._view.map.add(new EsriMapImageLayer(mapImageLayerProperties));
  }

  private updateGraphicsSelected(graphics: IGeoZoneInput[] = null) {
    // TODO Remove only graphics added and not already save >> Create a layer with all graphic already saved > see graphicLayer 'https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-GraphicsLayer.html'
    this._view.graphics.removeAll();
    if (graphics) {
      this.onChangeGraphicList.emit(graphics);
    }
  }

  private async getGraphicsDataFromSelectedGeoZone() {
    const symbol: any = { type: "simple-fill", color: [32, 130, 181, 0.5] };
    const [EsriQueryTask, EsriQuery] = await loadModules([
      "esri/tasks/QueryTask",
      "esri/tasks/support/Query",
    ]);
    let queryTask: esri.QueryTask;
    let query: esri.Query;
    let graphics: esri.Graphic[] = [];
    for (const queryTaskDatas of this.geoZoneForGraphicLayer) {
      queryTask = new EsriQueryTask({
        url: queryTaskDatas.url
      });
      query = new EsriQuery();
      query.returnGeometry = true;
      query.outFields = ["*"];
      query.objectIds = queryTaskDatas.objectIds;
      const results = await queryTask.execute(query);
      graphics = [...graphics, ...(<esri.Graphic[]>results.features)];
    }
    graphics.forEach(graphic => {
      graphic.symbol = symbol;
    });
    this.createGraphicLayerOfSelectedGeoZone(graphics);
  }

  private async createGraphicLayerOfSelectedGeoZone(graphics: esri.Graphic[]) {
    const [EsriGraphicsLayer] = await loadModules([
      "esri/layers/GraphicsLayer"
    ]);
    const layer: esri.Layer = this._view.map.findLayerById(LAYER_IDS.geoZoneSelected);
    if (layer) {
      (<esri.GraphicsLayer>layer).graphics.removeAll();
      (<esri.GraphicsLayer>layer).graphics.addMany(graphics);
    } else {
      const graphicsLayer: esri.GraphicsLayer = new EsriGraphicsLayer({ id: LAYER_IDS.geoZoneSelected, graphics });
      this._view.map.add(graphicsLayer);
    }
  }

  private async getAnnotationData(event: esri.MapViewClickEvent) {
    const [EsriPoint, EsriQueryTask, EsriQuery] = await loadModules([
      "esri/geometry/Point",
      "esri/tasks/QueryTask",
      "esri/tasks/support/Query",
    ]);
    const symbol: any = { type: "simple-fill", color: [249, 178, 51, 0.5] };
    const layerUrlToQuery: string = `${this.dispatchLayerToUse.url}/${this.dispatchLayerToUse.mapServerId}`;
    const queryTask = new EsriQueryTask({
      url: layerUrlToQuery
    });
    const query: esri.Query = new EsriQuery();
    query.returnGeometry = true;
    query.outFields = ["*"];
    query.geometry = new EsriPoint({
      x: event.mapPoint.x,
      y: event.mapPoint.y,
      spatialReference: { wkid: 31370 }
    });
    queryTask.execute(query).then(results => {
      const graphicFound: esri.Graphic[] = <esri.Graphic[]>results.features;

      let objectIdKey = layerUrlToQuery.indexOf("geoservices.wallonie.be") == -1? "objectid" : "OBJECTID";
  
      const attributeName = this.dispatchLayerToUse.attributeKey == "IC" ? "IC" : "NAME"
      const lng = this.getLanguage()
      this.updateGraphicsSelected(graphicFound.map(graph => {
        return {
          layerUrl: `${layerUrlToQuery}/query`,
          objectId: graph.attributes[objectIdKey],
          polygonName: graph.attributes[attributeName] || graph.attributes[attributeName + lng]
        };
      }));
      graphicFound.forEach(graphic => {
        // const graphIndex = this._view.graphics.findIndex( graph => {
        //   return Object.keys(graph.attributes).every( key => graph.attributes[key] === graphic.attributes[key]);
        // });
        // if (graphIndex  === -1) {
        //   graphic.symbol = this.dispatchLayerToUse.symbol;
        //   this._view.graphics.add(graphic);
        // } else {
        //   this._view.graphics.removeAt(graphIndex);
        // }
        graphic.symbol = symbol;
        this._view.graphics.add(graphic);
      });
    }).catch(error => {
      console.log({ ...this.dispatchLayerToUse, error });
    });
  }

  private getLanguage() {
    const lng = this._translateSrv.activeLanguage
    switch(lng) {
      case 'FR':
        return 'FRE'
      case 'DE':
        return 'GER'
      case 'NL':
        return 'DUT'
      default:
        return 'FRE'
    }
  }

  private async getMapCenter() {
    const [EsriPoint] = await loadModules([
      "esri/geometry/Point",
    ]);
    const mapPoint: esri.Point = new EsriPoint({
      ...ESRI_MAP_CONFIG.defaultCenter,
      spatialReference: { wkid: ESRI_MAP_CONFIG.wkid }
    });
    this._view.goTo(mapPoint);
  }

  private displayLayerList(view: esri.MapView, EsriLayerList) {
    view.when(() => {
      const layerList: esri.LayerList = new EsriLayerList({
        view: view
      });

      // Add widget to the top right corner of the view
      view.ui.add(layerList, "top-right");
    });
  }

  // Définit les layers qu'on va utiliser comme base map. L'attribut fullExtent permet de délimiter les tuiles qu'on va aller chercher sur le webservice
  private getBaseLayers(spatialReference: esri.SpatialReference, EsriTileInfo: any, EsriWebTileLayer: any, EsriMapImageLayer: any): esri.TileLayer[] {
    const layers: esri.TileLayer[] = [];
    let config: any;
    let tileInfo: esri.TileInfo;
    let layerData: any;
    const baseTileLayer: any = this._esriMapConfig.baseTileLayers.filter(tileData => (tileData.display && (!this.isDispatchingMap || tileData.displayInDispatching)));
    baseTileLayer.forEach(tileData => {
      config = require(`../../facades/configs/${tileData.json}.config.json`);
      tileInfo = new EsriTileInfo(this.setTileInfo(config));
      layerData = {
        urlTemplate: `${tileData.url}/tile/{level}/{row}/{col}`,
        subDomains: ["level", "col", "row"],
        tileInfo: tileInfo,
        spatialReference: spatialReference,
        fullExtent: {
          xmax: config.fullExtent.xmax,
          xmin: config.fullExtent.xmin,
          ymax: config.fullExtent.ymax,
          ymin: config.fullExtent.ymin,
        }
      };
      switch (tileData.key) {
        case "orthoLast":
          layerData.copyright = "";
          break;
        case "annotation":
          layerData.maxScale = (config.maxScale * 2) + 1;
          break;
        default:
          break;
      }
      layers.push(new EsriWebTileLayer(layerData));
      // Add layer for annotation
      layers.push(new EsriMapImageLayer({
        url: "http://geoservices.wallonie.be/arcgis/rest/services/DONNEES_BASE/FDC_SPW_ANNOTATIONS/MapServer/",
        bbox: "EsriTileLayer.fullExtent.xmin, EsriTileLayer.fullExtent.ymin, EsriTileLayer.fullExtent.xmax, EsriTileLayer.fullExtent.ymax ",
        bboxSR: 31370,
        imageSR: 31370,
        f:"image",
        show: "2,4,6,8,9,10,12,13,17"
      }));

    });

    return layers;
  }
  // Représente chaque tuile. ATTENTION: les tuiles de la wallonie ne sont pas de la même taille (512) que celles par défaut (256)
  private setTileInfo(config: any) {
    return {
      ...config.tileInfo,
      size: [config.tileInfo.rows, config.tileInfo.cols]
    };
  }

  private buildLayers(EsriTileLayer, EsriMapImageLayer): esri.Layer[] {
    const layers: esri.Layer[] = [];
    // layers.push(new EsriTileLayer({
    //   url: "https://geoservices.wallonie.be/arcgis/rest/services/DONNEES_BASE/FOND_PLAN_ANNOTATIONS_RW/MapServer",
    //   maxScale: (this._layersConfig.maxScale * 2) + 1, // Loading error if we put 250
    // }));

    // TODO: DEFINE Max Scale
    // layers.push(new EsriMapImageLayer({
    //   url: "http://geoservices.wallonie.be/arcgis/rest/services/DONNEES_BASE/FDC_SPW_ANNOTATIONS/MapServer/",
    //   bbox: "EsriTileLayer.fullExtent.xmin, EsriTileLayer.fullExtent.ymin, EsriTileLayer.fullExtent.xmax, EsriTileLayer.fullExtent.ymax ",
    //   bboxSR: 31370,
    //   imageSR: 31370,
    //   f:"image",
    //   show: "2,4,6,8,9,10,12,13,17"
            
    // }));  
    return layers;
  }

  public unSelectedDisplayedMarkers() {
    const incidentToSet: IIncidentMap[] = this.displayIncidentsMap.filter(incident => this.selectedIncidentIds.indexOf(incident.id) !== -1);
    incidentToSet.forEach(incident => {
      const markerToSet = this._markerGraphics.find(marker => marker.incidentId === incident.id);
      markerToSet.graphic.symbol = PinMap.get(incident.status);
    });
  }

}
