import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ICategoryStateHistoryFilters, IFilterHistory } from '../../interfaces/histories.interface';
import { IIncidentColumnFilter } from '../../interfaces/incident.interface';
import { IPagination } from '../../interfaces/pagination.interface';
import { GET_CATEGORY_STATE_HISTORY, GET_CATEGORY_STATE_HISTORY_XLS, GET_ORGANISATION_STAGE_HISTORY, GET_ORGANISATION_STAGE_HISTORY_XLS } from './history.queries';
@Injectable({
  providedIn: "root"
})

export class HistoryQueriesService {

    public _historyPagination: IPagination = { limit: 20, page: 1 };
    private _appliedFilters: IFilterHistory = null;
  private _categoryStateAppliedFilters: ICategoryStateHistoryFilters = null;
    constructor(private _apollo: Apollo) { }

    public set historyPagination(pagination: IPagination) { this._historyPagination = pagination; }
    public get historyPagination(): IPagination { return this._historyPagination; }

    public get appliedFilters(): IFilterHistory{ return this._appliedFilters; }
    public set appliedFilters(appliedFilters: IFilterHistory) { this._appliedFilters = appliedFilters; }

    public get categoryStateAppliedFilters(): ICategoryStateHistoryFilters{ return this._categoryStateAppliedFilters; }
    public set categoryStateAppliedFilters(categoryStateAppliedFilters: ICategoryStateHistoryFilters) { this._categoryStateAppliedFilters = categoryStateAppliedFilters; }

    public resetPagination() {
      this._historyPagination = {
        ...this._historyPagination,
        page: 1
      };
    }

    public listOrganisationPhaseHistory(pagination?: any) {
      if (this._appliedFilters) {
        Object.keys(this._appliedFilters).forEach(key => {
          if (this._appliedFilters[key] === "") {
            this._appliedFilters[key] = null;
          }
        });
      }
      return this._apollo.watchQuery({
        query: GET_ORGANISATION_STAGE_HISTORY,
        variables: {
          filter: this._appliedFilters,
          pagination: this._historyPagination
        },
        fetchPolicy: "cache-and-network"
      });
    }

    public listCategoryStateHistory(pagination?: any) {
      if (this._categoryStateAppliedFilters) {
        Object.keys(this._categoryStateAppliedFilters).forEach(key => {
          if (this._categoryStateAppliedFilters[key] === "") {
            this._categoryStateAppliedFilters[key] = null;
          }
        });
      }
      return this._apollo.watchQuery({
        query: GET_CATEGORY_STATE_HISTORY,
        variables: {
          filter: this._categoryStateAppliedFilters,
          pagination: this._historyPagination
        },
        fetchPolicy: "cache-and-network"
      });
    }

  public getOrganisationsStageHistoryXls(tableHeaders: any[]) {
      if (this._appliedFilters) {
        Object.keys(this._appliedFilters).forEach(key => {
          if (this._appliedFilters[key] === "") {
            this._appliedFilters[key] = null;
          }
        });
      }

      const pagination = {
        ...this._historyPagination,
        limit: 0,
        page: 1
      };

      return this._apollo.query({
        query: GET_ORGANISATION_STAGE_HISTORY_XLS,
        variables: {
          filter: this._appliedFilters,
          pagination: pagination,
          tableHeaders
        },
        fetchPolicy: "no-cache"
      });
    }

  public getCategoryStateHistoryXls(tableHeaders: any[]) {
      if (this._appliedFilters) {
        Object.keys(this._appliedFilters).forEach(key => {
          if (this._appliedFilters[key] === "") {
            this._appliedFilters[key] = null;
          }
        });
      }

      const pagination = {
        ...this._historyPagination,
        limit: 0,
        page: 1
      };

      return this._apollo.query({
        query: GET_CATEGORY_STATE_HISTORY_XLS,
        variables: {
          filter: this._categoryStateAppliedFilters,
          pagination: pagination,
          tableHeaders
        },
        fetchPolicy: "no-cache"
      });
    }

  }
