import { Injectable } from "@angular/core";
import { HttpClient} from "@angular/common/http";
import { EnumUtil } from "src/lib/enum";
import { LANGS } from "../enum/langs.enum";
import { TranslationQueriesService } from "../queries/translation/translation-queries.service";

export const localStorageLanguageKey = "FMX_BO_LANG";

@Injectable()
export class TranslateService {

  data: any = {};
  private _availableLanguages = EnumUtil.getStringValues(LANGS);
//   private _availableLanguages = ["FR", "NL", "EN", "DE"];
  private _defaultLanguage: string = "FR";
  private _activeLanguage: string;
  public translations: any = null;

   // GETTERS & SETTERS
  public get availableLanguages(): string[] { return this._availableLanguages; }
  public get activeLanguage(): string { return this._activeLanguage; }
  public set activeLanguage(language: string) {
    this._activeLanguage = language;
    localStorage.setItem(localStorageLanguageKey, language);
    this.formatLanguage(this._activeLanguage);
    window.location.reload();
  }

  constructor(private http: HttpClient,
              private _translationQueriesSrv: TranslationQueriesService) { }

  formatLanguage(language?: string ): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      // const langPath = `assets/i18n/${language.toLowerCase() || "en"}.json`;
      // this.http.get<{}>(langPath).subscribe (
      //   translation => {
      //     this.data = Object.assign({}, translation || {});
      //     resolve(this.data);
      //   },
      //   error => {
      //     this.data = {};
      //     resolve(this.data);
      //   }
      // );
      if (this.translations) {
        this.data = Object.assign({}, this.translations || {});
        resolve(this.data);
      } else {
        this.data = {};
        resolve(this.data);
      }
    });
  }

  public translate(key: any, params?: any) {
    let returnTranslate: any = this.data;
    const translateKey: string[] = key.split(".");
    translateKey.forEach( translate => {
      returnTranslate = returnTranslate[translate] ? returnTranslate[translate] : `${key}`;
      if (params && typeof returnTranslate === "string") {
        Object.keys(params).forEach( (paramKey) => {
          returnTranslate = returnTranslate.replace("{{" + paramKey + "}}", params[paramKey]);
        });
      }
    });
    return returnTranslate;
  }

  public smartTranslate(key: any) {
    const regex = /\{\{(.*?)\}\}/g;
    let result = this.data[key] ? this.data[key] : `${key}`;
    const matches = result.match(regex);
    matches && matches.forEach(match => {
        const formattedMatch = match.slice(2).slice(0, -2)
        result = result.replace("{{" + formattedMatch + "}}", this.data[formattedMatch])
        console.log(formattedMatch)
      });

    return result;
  }

  public async initLanguage() {
    this._activeLanguage = localStorage.getItem(localStorageLanguageKey);
    if (!this._activeLanguage) {
      // Get navigator language
      this._activeLanguage = navigator && navigator.language ? navigator.language.toUpperCase() : null;
      if (this._availableLanguages.indexOf(this.activeLanguage) === -1 ) {
        // Set language to default language
        this._activeLanguage = this._defaultLanguage;
      }
      localStorage.setItem(localStorageLanguageKey, this.activeLanguage);
    }
    // this.formatLanguage(this._activeLanguage);
    return this.getTranslation();

  }

  public async getTranslation() {
    const result  = await this._translationQueriesSrv.getTranslation().toPromise();
    const datas: any = result && result.data ? result.data : null;
    if (datas && datas.translation) {
      this.translations = datas.translation;
    }
    await this.formatLanguage(this._activeLanguage);



    // return this._translationQueriesSrv.getTranslation().subscribe(res => {
    //   const datas: any = res && res.data ? res.data : null;
    //   if (datas && datas.translation) {
    //     console.log("getTranslation2");
    //     this.translations = datas.translation;
    //     console.log(this.translations);
    //     return this.formatLanguage(this._activeLanguage);
    //   }
    // }, err => {
    //   console.log("LOADING TRANSLATION ERROR : ", err);
    //   return this.formatLanguage(this._activeLanguage);
    // });
  }
}
