import { Input, OnInit, ViewChild, ElementRef, Renderer2, SimpleChanges, OnChanges, AfterViewInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ErrorMessageService } from "./services/error-message.service";

export abstract class AInputs implements OnInit, OnChanges, AfterViewInit {
    @Input() public name: string;
    @Input() public id: string;
    @Input() public group: FormGroup;
    @Input() public disable = false;
    @Input() public autofocus = false;
    @Input() public displayError = true;
    @Input() public require = false;
    protected errorClass = "input__error";

    @ViewChild("input", {static: true}) protected inputReference: ElementRef;
    public errorMessage = "";

    public constructor(protected renderer: Renderer2,
                    protected _errorMessageSrv: ErrorMessageService) { }

    ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disable && this.group) {
            if (this.disable) {
                this.group.get(this.name).disable();
            } else {
                this.group.get(this.name).enable();
            }
        }
        if (changes.group) {
            this.validationCheck();
        }
    }

    ngAfterViewInit() {
        if (this.autofocus) {
            setTimeout(() => { this.inputReference.nativeElement.focus(); }, 400);
        }
    }

    protected validationCheck() {
        this.group.get(this.name).valueChanges.subscribe(result => {
            let msg = "";
            if (!this.group.get(this.name).errors) {
                this.renderer.removeClass(this.inputReference.nativeElement, this.errorClass);
            } else {
                this.renderer.addClass(this.inputReference.nativeElement, this.errorClass);
                msg = this._errorMessageSrv.getInputErrors(this.group.get(this.name).errors);
            }
            this.errorMessage = msg.length > 0 ? msg : null;
        });
    }
}
