import { Component, OnInit, Renderer2, OnChanges, SimpleChanges, Input, ViewChild, ElementRef } from "@angular/core";
import * as moment from "moment";
import { ErrorMessageService } from "../../facades/services/error-message.service";
import { AInputs } from "../../facades/inputs";
import { IClickOutsideComponent } from "src/app/facades/directives/click-outside.directive";


@Component({
  selector: "app-date-input",
  templateUrl: "./date-input.component.html",
  styleUrls: ["./date-input.component.css"]
})
export class DateInputComponent extends AInputs implements OnInit, OnChanges, IClickOutsideComponent {
  public isHidden: boolean = true;
  public valueDate: string;
  public showModifier: boolean = false;

  @ViewChild('element', {static: false}) element: ElementRef;

  constructor(public renderer: Renderer2,
    protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
  }

  ngOnInit() {
    if (this.group.get(this.name).value) {
      this.valueDate = moment(this.group.get(this.name).value).format("DD/MM/YYYY");
    } else {
      this.valueDate = "";
    }
  }

  public onBlur(event): void {
    if(this.group.get(this.name).value){
      let splitted = this.group.get(this.name).value.split('/');
      if(splitted && splitted.length == 3){
        let newDate = new Date(`${splitted[2]}-${splitted[1]}-${splitted[0]}`);
        this.valueDate = newDate.toString() == "Invalid Date"? this.valueDate : moment(newDate.getTime()).format("DD/MM/YYYY");
        this.group.get(this.name).patchValue(newDate.toString() == "Invalid Date"? null : newDate.getTime());
      }
    }
    this.showModifier = false;
  }

  public open() {
    if(!this.disable){
      this.isHidden = false;
    }
  }
  public showInfo() {
    if (!this.isHidden) {this.isHidden = true; }
  }

  public updateDate(event) {
    this.valueDate = moment(event * 1000).format("DD/MM/YYYY");
    this.group.get(this.name).patchValue(event * 1000);
    this.showInfo();
  }
  protected validationCheck() {
    this.group.get(this.name).valueChanges.subscribe(result => {
        if (result == null) {
          this.valueDate = "";
        }
    });
  }

  onFocus(event){
    this.group.get(this.name).patchValue(null);
    this.showModifier = true;
    setTimeout(() => { this.element.nativeElement.focus(); })
  }
}
