import gql from "graphql-tag";

export const GEO_ZONES_BY_ORGANISATION = gql`
  query geoZonesByOrganisation($organisationId: Int) {
    geoZonesByOrganisation(organisationId: $organisationId){
      id
      name
      layerId
      objectId
      layer {
        id
        name
        url
        attributeKey
        mapServerId
      }
    }
  }
`;

export const GEO_ZONES_BY_ORGANISATION_POLYGONS = gql`
  query geoZonesByOrganisation($organisationId: Int) {
    geoZonesByOrganisation(organisationId: $organisationId){
      id
      name
      layerId
      objectId
      polygons
      layer {
        id
        name
        url
        attributeKey
        mapServerId
      }
    }
  }
`;

export const GEO_ZONES_BY_LAYER_ID = gql`
  query geoZonesByLayerId($layerId: Int!){
    geoZonesByLayerId(layerId: $layerId){
      id
      objectId
      name
      layerId
      layer {
        url
        mapServerId
        attributeKey
      }
      organisations {
        name
        id
      }
    }
  }
`;

export const GEO_ZONES_BY_INCIDENT = gql`
  query geoZonesByIncident($id: Int!) {
    geoZonesByIncident(id: $id){
      id
      name
      objectId
      organisationId
      layer {
        id
        name
        url
        attributeKey
        mapServerId
      }
    }
  }
`;

export const GEO_ZONES_BY_LOCATION = gql`
  query geoZonesByLocation($x: Float!, $y: Float!) {
    geoZonesByLocation(x: $x, y: $y){
      id
      name
      objectId
      organisationId
      layer {
        id
        name
        url
        attributeKey
        mapServerId
      }
    }
  }
`;

export const GET_GEO_ZONES = gql`
  query getGeoZone($id: Int!){
    geoZone(id: $id){
      id
      name
      objectId
      layer {
        id
        name
        url
        attributeKey
        mapServerId
      }
    }
  }
`;

export const SET_GEO_ZONE = gql`
  mutation setGeoZone($input: GeoZoneInput!) {
    geoZone(input: $input) {
      id
      name
      objectId
      layer {
        id
        name
        url
        attributeKey
        mapServerId
      }
    }
  }
`;

export const DELETE_GEO_ZONE = gql`
  mutation deleteGeoZone($geozoneId: Int!, $groupId: Int, $organisationId: Int){
    deleteGeoZone(geozoneId: $geozoneId, groupId: $groupId, organisationId: $organisationId)
  }
`;

export const GROUPS_GEO_ZONES_BY_LAYER_ID = gql`
  query groupsGeoZonesByLayerId($layerId: Int!){
    geoZonesByLayerId(layerId: $layerId){
      id
      objectId
      name
      layerId
      layer {
        url
        mapServerId
        attributeKey
      }
      groups {
        id
        name
        organisationId
      }
    }
  }
`;

export const CHECK_GEO_ZONE_FOR_GROUP = gql`
  query checkGeozoneForGroup($layerUrl: String!, $objectId: Int!) {
    checkGeozoneForGroup(layerUrl: $layerUrl, objectId: $objectId)
  }
`;

export const CHECK_GEO_ZONES_FOR_GROUP = gql`
  query checkGeozonesForGroup($layerUrl: String!, $objectIds: [Int]!) {
    checkGeozonesForGroup(layerUrl: $layerUrl, objectIds: $objectIds)
  }
`;
