import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DropListComponent } from "./organisms/drop-list/drop-list.component";
import { ButtonModule } from "../button/button.module";
import { PipeModule } from "src/app/facades/pipes/pipe.module";
import { LabelModule } from "../label/LabelModule";
import { InputModule } from "../input/input.module";



@NgModule({
  declarations: [DropListComponent],
  imports: [
    CommonModule,
    ButtonModule,
    PipeModule,
    LabelModule,
    InputModule
  ],
  exports: [DropListComponent]
})
export class DropListModule { }
