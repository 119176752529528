import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { LoginQueriesService } from "src/app/facades/queries/login/login-queries.service";
import { EnumLoginForms, ExternalService } from "src/app/facades/services/external/external.service";
import { TranslateService } from "src/app/facades/services/translate.service";
import { BOX_INFO_TYPE } from "src/app/presentationnal/box-info/facades/enums/box-info-type.enum";
import { IBoxInfo } from "src/app/presentationnal/box-info/facades/interfaces/box-info.interface";
import { checkSamePassword } from "src/app/presentationnal/input/facades/custom-validator";
import { ErrorMessageService } from "src/app/presentationnal/input/facades/services/error-message.service";
import { AuthService } from "src/lib/auth/auth.service";
import { LOGIN_ERRORS_MAP } from "../facades/login-errors-map";

@Component({
    selector: "app-login-reset-password-external",
    templateUrl: "./login-reset-password-external.component.html",
    styleUrls: ["./login-reset-password-external.component.css"]
})
export class LoginResetPasswordExternalComponent implements OnInit {

    // FORMGROUP
    public formGroup: FormGroup;
    // ERROR GESTURE
    public inputErrorsLabel: Array<IBoxInfo> | Object;
    public inputErrorsLabelMap: Map<string, string> = new Map<string, string>([
        ["password", this._translateSrv.translate("login_password-input")],
        ["confirmPassword", this._translateSrv.translate("login_confirmPassword-input")],
        // ["samePassword", this._translateSrv.translate("login_password-input")]
    ]);
    constructor(private _fb: FormBuilder,
        public externalSrv: ExternalService,
        private _translateSrv: TranslateService,
        private _errorMessageSrv: ErrorMessageService,
        private _loginSrv: LoginQueriesService,
        private _authSrv: AuthService) { }

    ngOnInit(): void {
        this.initFormGroup();
    }

    /**
     * @description Init the form Group
     * @author Kevin Palade
     * @private
     * @memberof LoginResetPasswordComponent
     */
    private initFormGroup() {
        this.formGroup = this._fb.group({
            password: [null, [Validators.required, Validators.minLength(6)]],
            confirmPassword: [null, [Validators.required, Validators.minLength(6)]]
        }, { validator: checkSamePassword });
    }

    /**
     * @description Reset the user password
     * @author Kevin Palade
     * @memberof LoginResetPasswordComponent
     */
    public resetPassword() {
        if (this.formGroup.valid) {
            this.inputErrorsLabel = [];
            this._loginSrv.resetPassword(this.formGroup.get("password").value).subscribe(result => {
                if(result.errors && result.errors[0]){
                    const code = (<any>result.errors[0]).code;
                    this.getErrors(code);
                } else {
                    // Login the user
                    // this._authSrv.setKeysInStorage(result.data, "resetPassword");
                    // Reset the loginBox service value
                    if(this.externalSrv.wepUserToken) this.setKeysInStorage(this.externalSrv.wepUserToken);
                    this.externalSrv.currentForm = EnumLoginForms.LOGIN;
                }
            }, error => {
                if (error.graphQLErrors && error.graphQLErrors[0]) {
                    const code = error.graphQLErrors[0].code;
                    this.getErrors(code);
                }
            });
        } else {
            this.markFormGroupTouched(this.formGroup);
            this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.formGroup, this.inputErrorsLabelMap, true);
            if (this.formGroup.errors && this.formGroup.errors.notSamePassword) {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate("login_password-input")}: ${this._translateSrv.translate("login_notSamePassword-error")}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            }
        }
    }
    
    public getErrors(errorCode){
        if(errorCode) {
            const errorKey = LOGIN_ERRORS_MAP.get(errorCode);
            if(errorKey) {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate(errorKey)}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            } else {
                (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                    message: `${this._translateSrv.translate("errorOccured-error")}`,
                    type: BOX_INFO_TYPE.DANGER
                });
            }
        } else {
            (<Array<IBoxInfo>>this.inputErrorsLabel).push({
                message: `${this._translateSrv.translate("errorOccured-error")}`,
                type: BOX_INFO_TYPE.DANGER
            });
        }
    }

    public markFormGroupTouched = (formGroup: FormGroup) => {
        (Object as any).values(formGroup.controls).forEach(control => {
            (control as FormControl).updateValueAndValidity();
            control.markAsTouched();
            if (control.controls) { control.controls.forEach(c => this.markFormGroupTouched(c)); }
        });
    };

    private setKeysInStorage(token: string) {
        if (token) {
          localStorage.setItem("token", token);
        }
      }
}
