import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from "@angular/core";
import { FILES_BLOCK_ACTIONS_ENUM } from "../../facades/enums/files-block-actions.enum";

@Component({
  selector: "app-img-block",
  templateUrl: "./img-block.component.html",
  styleUrls: ["./img-block.component.css"]
})

export class ImgBlockComponent {

    @Input() public disabled = false;
    @Input() public hiddenAction = false;
    @Input() file: any;
    @Input() overlay: boolean = true;
    @Output() public fileTouched = new EventEmitter<any>();
    public FILES_BLOCK_ACTIONS_ENUM = FILES_BLOCK_ACTIONS_ENUM;

    constructor() { }

    public touchFile(action) {
        this.fileTouched.emit({action: action});
    }
}
