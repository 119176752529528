import { ITableFilter, EnumTableDataType } from "src/app/presentationnal/table/facades/interfaces/table.interface";

export const  headerInputDefaultOrganisation: ITableFilter[] = [
    { key: "code", type: EnumTableDataType.text },
    { key: "name", type: EnumTableDataType.text },
    { key: "type", type: EnumTableDataType.selectcheckbox },
    { key: "email", type: EnumTableDataType.text },
    { key: "phone", type: EnumTableDataType.text },
    { key: "INS_Code", type: EnumTableDataType.text },
    { key: "stage", type: EnumTableDataType.selectcheckbox },
    { key: "createdAt", type: EnumTableDataType.date },
    { key: "isActive", type: EnumTableDataType.select, options: [{value : null, label: "organisation-isActiveAll-filter"}, {value : true, label: "organisation-isActiveTrue-filter"}, {value : false, label: "organisation-isActiveFalse-filter"}], },
];