import { Component, OnInit, Input, ElementRef, Renderer2, EventEmitter, Output } from "@angular/core";
import { IContainerComment } from "../../facades/interfaces/container-comment.interface";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.css"]
})
export class CommentComponent implements OnInit {
  @Input() comment: IContainerComment;
  @Input() displayActions: boolean = true;
  @Input() noblank: boolean = false;
  @Output() public onDeleteAction: EventEmitter<IContainerComment> = new EventEmitter<IContainerComment>();
  public printConfirmDelete: boolean = false;
  public confirmDeleteClass: string = "isConfirmDelete";
  private _handler: any;


  constructor(private _elRef: ElementRef, private _renderer: Renderer2) { }

  ngOnInit() {
    this._renderer.addClass(this._elRef.nativeElement, "comment-box");
  }

  private checkCancelAction(event) {
    if (event.target.className.indexOf(this.confirmDeleteClass) === -1 && this.printConfirmDelete) {
      this.changePrintConfirmDelete(false);
    }
  }

  public changePrintConfirmDelete(value: boolean = true) {
    if ( this._handler ) { this._handler(); }
    if (value) {
      this._handler = this._renderer.listen(document, "click", event => this.checkCancelAction(event));
      setTimeout( () => this.printConfirmDelete = value , 100);
    } else {
      this.printConfirmDelete = value;
    }
  }

  public confirmDelete(comment: IContainerComment) {
    this._handler();
    this.onDeleteAction.emit(comment);
  }

  blank(url) {
    if (!this.noblank) {
      window.open(url, "_blank");
    }
  }

}
