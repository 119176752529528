import { Component, OnInit, OnChanges, Renderer2, Input, SimpleChanges, ElementRef, Output, EventEmitter } from "@angular/core";
import { AInputs } from "../../facades/inputs";
import { ISelectMultipleOptions, ISelectMultipleBaseData } from "../../facades/interfaces/selectMultiple.interface";
import { recursiveBreadcrumbs, formatBreacrumb } from "./facades/recursive-breadcrumb.helper";
import { ErrorMessageService } from "../../facades/services/error-message.service";


@Component({
  selector: "app-select-multiple-input",
  templateUrl: "./select-multiple-input.component.html",
  styleUrls: ["./select-multiple-input.component.css"]
})
export class SelectMultipleInputComponent extends AInputs implements OnInit, OnChanges {
  @Input() public optionsMultiselect: ISelectMultipleOptions[] = [];
  @Input() public sizeFixed: boolean = false;
  @Input() public alwaysUpdate: boolean = false;
  @Input() public scrollable: boolean = false;
  private _handler: any;
  public isActive: boolean = false;
  public breadCrumbSelect: ISelectMultipleBaseData[] = [];
  public txtBreadCrumbSelect: string;
  public tmpBreadCrumbs: any[] = [];
  public labelSelected: string = "";
  public displayCheckbox: boolean = false;
  @Output() precisionsUpdate: EventEmitter<number[]> = new EventEmitter();
  @Output() currentItemChange: EventEmitter<any> = new EventEmitter();

  public optionSelect: any = [];
  @Input() precisions: any[] = [];
  @Input() precisionKey: string = null;
  @Input() updateWhenChildrenExist: boolean = false;
  constructor(
    private _elRef: ElementRef,
    public renderer: Renderer2,
    protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
    this.errorClass = "select-multiple__error";
  }

  ngOnInit() {
    if (this.group.get(this.name).value) {
      const valueContent = this.group.get(this.name).value;
      this.setValue(valueContent);
    }
    this.setOptionSelect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.optionsMultiselect) {
      this.setValue(this.group.get(this.name).value);
      this.setOptionSelect();
    }
  }

  public setOptionSelect() {
    this.optionSelect = !this.txtBreadCrumbSelect ? this.optionsMultiselect  : []  ;
    // Set category details as options
    const lastOption = this.breadCrumbSelect[this.breadCrumbSelect.length - 1 ];
    if (this.precisionKey && lastOption && lastOption[this.precisionKey] && lastOption[this.precisionKey].length > 0) {
      this.optionSelect = lastOption[this.precisionKey];
      this.displayCheckbox = true;
    }
  }

  private checkIsElement(event: any) {
    if (!this._elRef.nativeElement.contains(event.target) && this.isActive) {
      this.showList();
    }
  }

  public showList() {
    if ( this._handler ) { this._handler(); }
    if ( !this.isActive) {
      this._handler = this.renderer.listen(document, "click", event => this.checkIsElement(event));
    } else if (this.displayCheckbox) {
      this.precisionsUpdate.emit(this.precisions);
      this.labelSelected = formatBreacrumb(this.breadCrumbSelect) + " ";
      if (this.precisions && this.precisions.length > 0) {
        this.labelSelected += "( " + this.precisions.map(prec => prec.name).join(", ") + " )";
      }
    }
    this.isActive = !this.isActive;
  }

  public selectItem(item) {
    if (!this.displayCheckbox) {
      this.currentItemChange.emit(item);
      setTimeout( () => {
        this.displayCheckbox = false;
        if (this.precisionKey && item[this.precisionKey] && item[this.precisionKey].length > 0) {
          this.displayCheckbox = true;
          this.group.get(this.name).patchValue(item.id);
        }
        this.optionSelect = item.children && item.children.length > 0 ? item.children : (this.precisionKey && item[this.precisionKey] && item[this.precisionKey].length > 0 ? item[this.precisionKey] : []);
        if (!this.breadCrumbSelect) { this.breadCrumbSelect = []; }
        this.breadCrumbSelect.push({id: item.id, name: item.name});
        if (this.alwaysUpdate || !item.children || item.children.length === 0 ) {
          this.group.get(this.name).patchValue(item.id);
        }
        if ( (!item.children || item.children.length === 0) && (!this.precisionKey || !item[this.precisionKey] || item[this.precisionKey].length === 0) ) {
          this.showList();
        }

        this.labelSelected = formatBreacrumb(this.breadCrumbSelect);
        this.txtBreadCrumbSelect = formatBreacrumb(this.breadCrumbSelect);
      }, 0);
    }
  }

  public checkItem(event: Event, item) {
    if (event) { event.stopPropagation(); }
    if (this.precisions.map(prec => prec.id).indexOf(item.id) === -1) {
      this.precisions.push(item);
    } else {
      this.precisions.splice(this.precisions.map(prec => prec.id).indexOf(item.id), 1);
    }
  }

  public validatePrecision() {
    this.labelSelected = formatBreacrumb(this.breadCrumbSelect) + " ";
      if (this.precisions && this.precisions.length > 0) {
        this.labelSelected += "( " + this.precisions.map(prec => prec.name).join(", ") + " )";
      }
      this.precisionsUpdate.emit(this.precisions);
      this.showList();

    // } else {
    //   this.currentItemChange.emit(this.breadCrumbSelect[this.breadCrumbSelect.length - 1]);
    // }

  }

  public getPrecisionIds() {
    return this.precisions.map(prec => prec.id);
  }

  public previousItem() {
    setTimeout( () => {
      if (this.displayCheckbox) {
        
        this.displayCheckbox = false;
      }
      this.precisions = [];
      let tmp;
      this.breadCrumbSelect.splice(this.breadCrumbSelect.length - 1, 1);
      this.breadCrumbSelect.map((element, index) => {
        if (index === 0) {
          tmp = this.optionsMultiselect.filter(data => data.id === element.id);
        } else {
          tmp = tmp[0].children.filter(data => data.id === element.id);
        }
      }
      );
      if (this.alwaysUpdate) {
        this.group.get(this.name).patchValue(tmp && tmp[0] ? tmp[0].id : null);
      }
      this.precisionsUpdate.emit(this.precisions);
      this.optionSelect = this.breadCrumbSelect.length > 0 ? tmp[0].children : this.optionsMultiselect;
      this.txtBreadCrumbSelect = formatBreacrumb(this.breadCrumbSelect);
      this.labelSelected = formatBreacrumb(this.breadCrumbSelect);
    }, 0);
  }

  public setValue(id) {
    if (id) {
      this.breadCrumbSelect = recursiveBreadcrumbs(this.optionsMultiselect, id);
      this.labelSelected = formatBreacrumb(this.breadCrumbSelect);
      if (this.precisions && this.precisions.length > 0) {
        this.labelSelected += " ( " + this.precisions.map(prec => prec.name).join(", ") + " )";
      }
      this.txtBreadCrumbSelect = formatBreacrumb(this.breadCrumbSelect);
    } else {
      this.breadCrumbSelect = [];
      this.precisions = [];
      this.displayCheckbox = false;
      this.labelSelected = "";
      this.txtBreadCrumbSelect = "";
    }
  }

  protected validationCheck() {
    this.group.get(this.name).valueChanges.subscribe(result => {
      if ( !result ) {
        this.setValue(null);
        this.setOptionSelect();
      }
      let msg = "";
      if (!this.group.get(this.name).errors) {
          this.renderer.removeClass(this.inputReference.nativeElement, this.errorClass);
      } else {
          this.renderer.addClass(this.inputReference.nativeElement, this.errorClass);
          msg = this._errorMessageSrv.getInputErrors(this.group.get(this.name).errors);
      }
      this.errorMessage = msg.length > 0 ? msg : null;
    });
  }
}