import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/lib/auth/auth.service";
import { TranslateService } from "src/app/facades/services/translate.service";

@Component({
  selector: "app-enter-reset-code",
  templateUrl: "./enter-reset-code.component.html",
  styleUrls: ["./enter-reset-code.component.css"]
})
export class EnterResetCodeComponent implements OnInit {

  @Input() email: string;
  @Output() back = new EventEmitter<boolean>();
  @Output() next = new EventEmitter<string>();
  public formGroup: FormGroup;
  public displayedMessage: string;
  public messageSentNotice: string;

  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _translateSrv: TranslateService
  ) { }

  ngOnInit() {
    this.messageSentNotice = this._translateSrv.translate("login_codeSentNotice-txt", { email: this.email });
    this.initForm();
  }

  private initForm() {
    this.formGroup = this._fb.group({
      reset_code: [null, Validators.required]
    });
  }

  public confirmCode() {
    if (this.formGroup.valid) {
      this.displayedMessage = null;
      this._authService.verifyCode(this.email, this.formGroup.get("reset_code").value).subscribe(
        result => {
          if (result.errors && result.errors.length > 0) {
            if (result.errors[0].code == 40027) {
              this.displayedMessage = this._translateSrv.translate("login_emailNotPending-error");
            } else if (result.errors[0].code == 40028) {
              this.displayedMessage = this._translateSrv.translate("login_invalidCode-error");
            } else if (result.errors[0].code == 40029) {
              this.displayedMessage = this._translateSrv.translate("login_expiredCode-error");
            } else {
              this.displayedMessage = result.errors[0].infos;
            }
          } else {
            this.next.emit((<any>result.data).confirmPasswordCode);
          }
        },
        error => {
          if (error) {
            this.displayedMessage = this._translateSrv.translate("login_serverIsUnreachable-error");
          }
        }
      );
    } else {
      this.formGroup.controls["reset_code"].updateValueAndValidity();
    }
  }

  public sendMailAgain() {
    this.displayedMessage = null;
    this._authService.resendCode(this.email).subscribe(
      result => {
        if (result.errors && result.errors.length > 0) {
          if (result.errors[0].code == 40103) {
            this.displayedMessage = this._translateSrv.translate("login_notAnExistingEmail-error");
          } else {
            this.displayedMessage = result.errors[0].infos;
          }
        } else {
          this.displayedMessage = this._translateSrv.translate("login_codeSentAgainNotice-txt");
        }
      },
      error => {
        if (error) {
          this.displayedMessage = this._translateSrv.translate("login_serverIsUnreachable-error");
        }
      }
    );
  }

  public goBackToLogin() {
    this.back.emit(true);
  }

}
