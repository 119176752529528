// tslint:disable: variable-name
import { Component } from "@angular/core";
import { TranslateService } from "./facades/services/translate.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "back-office";

  constructor(private _translateSrv: TranslateService) {
    // this._translateSrv.initLanguage();
  }
}
