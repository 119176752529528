import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SnackComponent } from "./molecules/snack/snack.component";
import { SnackBarComponent } from "./organisms/snackbar/snackbar.component";
import { SnackService } from "src/app/facades/services/snackbar/snackbar.service";



@NgModule({
  declarations: [SnackComponent, SnackBarComponent],
  imports: [
    CommonModule
  ],
  exports: [
    SnackComponent,
    SnackBarComponent
  ],
  providers: [
    SnackService
  ]
})
export class SnackbarModule { }
