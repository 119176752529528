import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { BOX_INFO_TYPE } from "../../facades/enums/box-info-type.enum";

@Component({
  selector: "app-box-info",
  templateUrl: "./box-info.component.html",
  styleUrls: ["./box-info.component.scss"]
})
export class BoxInfoComponent implements OnInit, OnChanges {

  @Input() public type: BOX_INFO_TYPE = BOX_INFO_TYPE.SUCCESS;
  @Input() public message: string = "";
  @Input() public autoHeight: boolean = false;
  public generatedClass: string;
  public iconClass: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.generatedClass = "box";
    this.iconClass = "fas";
    this.defineInfoBoxType();
  }

  defineInfoBoxType() {
    if (this.type != null) {
      switch (this.type) {
        case BOX_INFO_TYPE.SUCCESS:
          this.generatedClass += " box__success";
          this.iconClass += " ffa-check-circle";
          break;
        case BOX_INFO_TYPE.INFO:
          this.generatedClass += " box__info";
          this.iconClass += " fa-info-circle";
          break;
        case BOX_INFO_TYPE.WARNING:
          this.generatedClass +=  " box__warning";
          this.iconClass += " fa-exclamation-triangle";
          break;
        case BOX_INFO_TYPE.DANGER:
          this.generatedClass +=  " box__danger";
          this.iconClass += " fa-minus-circle";
          break;
        default:
          break;
      }
    }
    if (this.autoHeight) {
      this.generatedClass += " box_auto-height";
    }
  }

}
